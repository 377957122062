import axios from 'axios'
import React, { useEffect, useState } from 'react'
import OverTrends from './OverTrends'

const OverLappingTrends = ({selectedTrend, setSelectedCount, setSelectedTrend,selectedCount,overLapingTrends, pk, fetch, setFetch }) => {
    const mergeTrend = () => {
        const ids = []
        for (let i = 0; i < selectedTrend.length; i++) {
            if (selectedTrend[i]) {
                ids.push(overLapingTrends[i].pk)
            }
        }
        axios.post('/trend/merge', { trend_ids: ids })
            .then(res => {
                console.log(res)
                setFetch(!fetch)
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <div>
            <div>
                <button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 py-3 rounded-3xl' onClick={mergeTrend} style={{ opacity: selectedCount < 2 ? '50%' : '100%' }} disabled={selectedCount < 2} >Merge</button>
            </div>
            <div>
                {
                    overLapingTrends.map((trend, index) => <OverTrends fetch={fetch} setFetch={setFetch} setSelectedCount={setSelectedCount} selectedTrend={selectedTrend[index]} index={index} setSelectedTrend={setSelectedTrend} trend={trend} key={trend.pk} />)
                }
            </div>
        </div>
    )
}

export default OverLappingTrends