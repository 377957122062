import React from 'react'
import Trend from './Trend'

const SelectedTrend = ({ mergeData,setSelectedImage, selectedImage, fetch, setFetch, setToMerge }) => {
  return (
    <div>
      {
        mergeData.map(trend => <Trend setSelectedImage={setSelectedImage} selectedImage={selectedImage} toMerge={mergeData} setToMerge={setToMerge} key={trend.id} trend={trend} setFetch={setFetch} fetch={fetch} />)
      }
    </div>
  )
}

export default SelectedTrend