import { useEffect, useState } from "react";
import Popup from "../../component/Popup";
import axios from "axios";
import { MagnifyingGlass } from "react-loader-spinner";
import SimilarProductImage from "./SimilarProductImage";
import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";


const SimilarProductsTrend = ({ trend, forPairItWith }) => {

	const [response, setResponse] = useState(false)
	const [allCatsResult, setAllCatsResult] = useState({})
	const [categories, setCategories] = useState([])
	const [currentCategory, setCurrentCategory] = useState('')
	const [currentEcommerce, setCurrentEcommerce] = useState('All')
	const [currentProductList, setCurrentProductList] = useState([])
	const [barChartData, setBarChartData] = useState([])
	const [sortBy, setSortBy] = useState('Similarity Score')
	const ecommerceList = [ 'All', 'Ajio', 'H&M', 'Myntra', 'Nykaa', 'TataCliq',  'Urbanic',  'Zara' ]
	const sortByOptions = ['Similarity Score' , 'price - low to high' , 'price - high to low']
	const catsToMaster = {
		'Dress Trends': "One-piece",
		'Jumpsuit Trends': "One-piece",
		'Topwear Trends': "Topwear",
		'Jeans & Trouser Trends':"Bottomwear",
		'Skirt Trends': "Bottomwear",
		'Trendy Shorts': "Bottomwear",
		'Outerwear Trends': "Outerwear",
		'Trendy Footwear': "Footwear",
		'Trendy Bags': "Bags",
		'Trendy Accessories': "Accessories" ,
		'All Trends': "'All"
	    }
	useEffect(()=>{
		getProducts();
	},[])

	const categoriesPriority = {
		'Dress Trends': 3,
		'Jumpsuit Trends': 3,
		'Topwear Trends': 1,
		'Jeans & Trouser Trends':2,
		'Skirt Trends': 2,
		'Trendy Shorts': 2,
		'Outerwear Trends': 4,
		'Trendy Footwear': 6,
		'Trendy Bags': 5,
		'Trendy Accessories': 7 
	}

	const getCategoriesAbove50 = (results) =>{
		const categories = Object.keys(results).filter(cat => results[cat] && results[cat].filter(res => res.score>0.5).length>0)
		categories.sort((a,b)=> categoriesPriority[a]-categoriesPriority[b])
		return categories
	}

	const getProducts = async ()=>{
		if(trend && trend.id){
			const response = await axios.get(`/products/trend/${trend.category}/${trend.id}`)
			if(response && response.data.results){
				setAllCatsResult(response.data.results)
				if(trend.category == 'All Trends'){
					const categories = getCategoriesAbove50(response.data.results);
					setCategories(categories)
					setCurrentCategory(categories[0])
					changeProducts(currentEcommerce,response.data.results[categories[0]])
				} else {
					if(forPairItWith){
						const categories = getCategoriesAbove50(response.data.results);
						let allCatsNames = categories.filter(c => catsToMaster[c]!=catsToMaster[trend.category])
						if(catsToMaster[trend.category]=="Topwear" || catsToMaster[trend.category] ==  "Bottomwear"){
							allCatsNames = allCatsNames.filter(cat =>  catsToMaster[cat]!= "One-piece")
						}
						if(catsToMaster[trend.category]=="One-piece"){
							allCatsNames = allCatsNames.filter(cat =>  catsToMaster[cat]!= "Topwear" && catsToMaster[cat]!=  "Bottomwear" )
						}
						if( allCatsNames.length>0 ){
							setCurrentCategory(allCatsNames[0])
							setCategories(allCatsNames)
							changeProducts(currentEcommerce,response.data.results[allCatsNames[0]])

						} else {
							setAllCatsResult({})
						}
					} else {
						setCurrentCategory(trend.category)
						changeProducts(currentEcommerce,response.data.results[trend.category])
					}
				}

			}
			setResponse(true)
		} else {
			setResponse(true)
		}

	}
	const getBarChartData = (price) => {
		price.sort(function (a, b) { return a - b })
		price= price.filter(p => p!=null && p<10000)
		let arr = []
		let s = price[0]
		let e = price[price.length - 1]
		let t = Math.floor(s / 100) * 100;
		let t2 = 0;
		while (t < e) {
			let t1 = {}
			t1.priceRange = `${t + 1}-${t + 100}`
			t1.count = price.filter(p => p >= t + 1 && p <= t + 100).length
			arr.push(t1)
			t += 100
		}
		return arr;
	}
	const changeSort = (sort) => {
		setSortBy(sort);
		changeProducts(currentEcommerce,allCatsResult[currentCategory], sort)
	}

	const changeProducts = (ecom , products, sortingOption)=>{
		if(!products){
			console.log("empty products list")
			setCurrentProductList([])
			return ;
		}
		if(ecom!='All'){
			products = products.filter( prod => prod.ecommerce==ecom)
		}
		if(!sortingOption){
			sortingOption = sortBy;
		}
		if(sortingOption == 'price - low to high'){
			products.sort((a,b)=> a.price-b.price )			
		} else if(sortingOption == 'price - high to low'){
			products.sort((a,b)=> b.price-a.price )
		} else {
			products.sort((a,b)=> b.score-a.score )
		}
		products = products.filter(prod => prod.score > 0.50)
		const productIds = {}
		const uniqueProducts = []
		for(let prod of products){
			if(!productIds[prod.id]){
				productIds[prod.id] = true
				uniqueProducts.push(prod)
			}
		}
		console.log(productIds)
		const tempPrice = products.map(prod => prod.price)
		const barChartData = getBarChartData(tempPrice)
		setCurrentProductList(uniqueProducts)
		setBarChartData(barChartData)
	}

	const changeCurrentCategory = (cat)=>{
		setCurrentCategory(cat)
		changeProducts(currentEcommerce,allCatsResult[cat])
	}

	const changeEcommerce = (ecom) =>{
		setCurrentEcommerce(ecom);
		changeProducts(ecom, allCatsResult[currentCategory])
	}


	return (		
		<div className="w-[80vw] h-[80vh] ">
			{!forPairItWith && currentProductList && currentProductList.length > 0 && <div className="flex justify-center">
				<ResponsiveContainer height={250} width={"100%"}>
					<BarChart data={barChartData} margin={{bottom:50}}>
						<XAxis tick={false} dataKey={'priceRange'} ><Label value={"Range of Price"} dy={52}/> </XAxis>
						<YAxis allowDecimals={false} dataKey={'count'}><Label value={"Quantity"} position={'insideLeft'} angle={-90} offset={30} /></YAxis>
						<Tooltip />
						<Bar fill="#475569" dataKey={'count'} >
							<LabelList dataKey={'priceRange'} fill="black" angle={-90} position={'insideBottom'} offset={-30} style={{fontSize:'8px'}} />
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</div>}
			<hr className="w-4/5 bg-slate-700 m-auto mt-10" />
			<h2 className="text-center font-light text-xl mb-5">{forPairItWith ? `Products to pair with this  ` : `Products in this `}<span className="font-semibold">{trend.name ?trend.name.slice(0,1).toUpperCase()+ trend.name.slice(1).toLowerCase()+" trend" : "trend"}</span></h2>
			{forPairItWith && <div className="w-full flex">
				<div className="w-4/5 flex flex-nowrap mx-0">
					{categories.map(category =><button className={`rounded ${category==currentCategory?'bg-slate-600 text-white border-2':'white text-black border-2'} p-2 my-4 mx-2 justify-start basis-36`} onClick={()=>{ changeCurrentCategory(category) }} key={category}>{category}</button>)}
				</div>
				
			</div>}
			{response &&<div className="w-full flex flex-row justify-center">
				
				<div className=" mx-0 relative flex flex-col ml-4">
						<span className="text-light text-sm">Sort By</span>
						<div  className=" ] mb-4 mx-2 overflow-hidden "  >
							{sortByOptions.map( (sort,i ) => <button key={sort} onClick={()=>{ changeSort(sort)}} className={`px-2 py-1 text-sm m-0 ${i==0?"":"border-l-0"} border border-slate-500 ${sort==sortBy?'bg bg-slate-500 text-white':''}`}>{sort}</button>)}
						</div>
				</div>
				<div className=" mx-3 relative ">
					<div className="flex flex-col"> 
						<span className="text-light text-sm mx-2 ">Ecommerce</span>
						<select  className="ml-4 px-6 mb-4 mx-2  w-28 bg-slate-500 text-white py-[7px] text-sm border" value={currentEcommerce} onChange={(e)=>{ changeEcommerce(e.target.value)}}>
							{ecommerceList.map( ecom => <option key={ecom}  >{ecom}</option>)}
						</select>
					</div>
				</div>
			</div>}
			<div className="w-full py-6 px-2 ">
				{
					response && (!currentProductList || currentProductList==0) && <p className=""> No Similar products found</p>
				}
				{
					!response && <>
						
							<MagnifyingGlass visible={true} height="40"
								width="40"
								ariaLabel="loading"
								wrapperStyle={{}}
								wrapperClass="MagnifyingGlass-wrapper mx-auto"
								glassColor = '#ddd'
								color = '#777'/>
						<p className="text-center">Searching</p>
					</>
				}
				{currentProductList && currentProductList.length > 0 && <div className="w-[90%] flex flex-row flex-wrap py-5">
					{
						currentProductList.map((product, index) => {
							return (
								<a key={index} href={product.product_url} target="_blank" className=" p-2 basis-1/4 shadow ">
									<SimilarProductImage imageUrl={product.image_url} />
									<p className="text-left mb-2 px-4">{product.title}</p>
									<div className="flex mb-4 px-4">
										<p className="text-left w-1/2">{product.price}<span>&#8377;</span></p>
										<p className="text-right w-1/2 font-semibold">{product.ecommerce}</p>
									</div>
									<p className="text-left mb-2 px-4">{Math.floor(product.score * 1000) / 10}% similar</p>
								</a>
							)
						})
					}
				</div>}
			</div>
		</div>
	)
}

export default SimilarProductsTrend