import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FemaleTrendToStlCategory, MaleTrendToStlCategory } from "../../constants";

const DesignTrendsFarFetch = () => {

  const [trends, setTrends] = useState([]);
  const [count, setCount] = useState(0)
  const [pages, setPages] = useState(1);
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    setParams((prev) => {
      if (!prev.get('category')) {


        prev.set('category', prev.get('gender') === 'M' ? 'Tops' : 'Dresses');
      }
      if (!prev.get('page')) {
        prev.set('page', '1');
      }
      prev.set('limit', prev.get('limit') || 20)
      prev.set('gender', prev.get('gender') || 'F')

      return prev
    })
  }, [])

  const getTrends = () => {
    if (!params.get('page'))
      return

    axios.get(`/retailer-api/designer-trends?category=${params.get('category')}&page=${params.get('page')}&limit=${params.get('limit')}`).then(response => {
      setTrends(response.data.trends)
      setCount(response.data.count)
      setPages(Math.ceil(response.data.count / params.get('limit')))
    }).catch(e => {
      console.log(e);
    })
  }

  function updatePage(change) {
    setParams(prev => {
      prev.set('page', parseInt(prev.get('page')) + change)
      return prev
    })
  }

  function changeParams(event) {
    setParams(prev => {
      prev.set(event.target.name, event.target.value)
      if (event.target.name === 'gender') {
        if (event.target.value === 'F') {
          prev.set('category', 'Dresses');
        } else {
          prev.set('category', 'Tops');
        }
      }
      return prev
    })
  }

  useEffect(getTrends, [params])

  return (<div>
    <div className="flex flex-row sticky bg-white py-2 top-12">
      <div className="px-6">
        <select value={params.get('gender')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='gender'>
          <option value='' disabled={true}>--gender--</option>
          <option value='F'>Female</option>
          <option value='M'>Male</option>
        </select>

        <select value={params.get('category')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='category'>
          <option value='' disabled={true}>--category--</option>
          {(params.get('gender') == 'F' ? FemaleTrendToStlCategory : MaleTrendToStlCategory).map(cat => <option key={cat} value={cat}>{cat}</option>)}
        </select>
      </div>
      <div className="ml-auto pr-6">
        <button className="primary-btn-padding" onClick={() => updatePage(-1)} disabled={params.get('page') <= 1}>Prev</button>
        {count} Trends
        <button className="primary-btn-padding" onClick={() => updatePage(1)} disabled={params.get('page') >= pages}>Next</button>
      </div>
    </div>
    <div className="w-screen pb-20">
      {trends.map((trend, i) => {
        return <div key={i} className="w-screen flex overflow-auto h-80  px-6">

          {trend.similarProducts.map(prod => {
            return <a className="align-bottom w-60 shrink-0 h-full py-4 mx-1 hover:shadow" href={prod.product_url}>
              <img src={prod.image_url} className="h-4/5" />
              <h3>{prod.title}</h3>
              <p>&#8377;{prod.price}</p>

            </a>
          })}
        </div>
      })}
    </div>
  </div >)
}
export default DesignTrendsFarFetch
