import axios from "axios";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Piechart from "./Piechart";
import Popup from '../../component/Popup';
import ProductsPopup from './ProductsPopup'
import ProductsPriceGraph from './ProductsPriceGraph'
const ProductsAnalysis = () => {

  const allRanks = [
    '1-50',
    '1-100',
    '1-250',
    '1-500',
    '1-1000',
    '1-2500',
    '1-5000',
    '1-10000',
  ]
  const ecommerces = ['Urbanic', 'Zara India']
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { userLoggedIn } = useSelector(state => state)
  const [charts, setCharts] = useState([])
  const [showProductsPopup, setShowProductsPopup] = useState(false)
  const [selectedTagData, setSelectedTagData] = useState(null)
  const [productPrices, setProductPrices] = useState([])
  const [categories, setCategories] = useState([])
  const initialState = () => {
    const category = searchParams.get('category') || 'Dresses';
    const ecommerce = searchParams.get('ecommerce') || 'Zara India';
    const rank = searchParams.get('ranks') || '1-10000';
    const gender = searchParams.get('gender') || 'f'
    setSearchParams({
      category, ecommerce, rank, gender
    })
  }

  const getData = () => {
    axios.get(`retailer-analysis-api/chart-data?category=${searchParams.get('category').split('&').join('%26')}&ecommerce=${searchParams.get('ecommerce')}&rank=${searchParams.get('rank')}`)
      .then(res => {
        setCharts(res.data.charts)
        setProductPrices(res.data.productsPrices)
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    initialState();
  }, [])

  useEffect(() => {
    axios.get(`retailer-analysis-api/categories?ecommerce=${searchParams.get('ecommerce')}&rank=${searchParams.get('rank')}`)
      .then(res => {
        setCategories(res.data.categories)
      }).catch(console.error)
      .finally(() => {
      })
  }, [searchParams.get('ecommerce')])

  useEffect(() => {
    if (!searchParams.has('category')) {
      initialState()
    } else
      getData()
  }, [searchParams])


  const updateValue = (e) => {
    setSearchParams(prev => {
      prev.set(e.target.name, e.target.value)
      return prev
    })
  }

  const handleProducts = (showPopup, tagName, tagValue) => {
    if (!showPopup) {
      setShowProductsPopup(false);
      setSelectedTagData(null);
    } else {
      setShowProductsPopup(true)
      setSelectedTagData({ tagName, tagValue })
    }
  }
  return <div>
    <div className="m-2 p-4">
      {/*
      <select className='mr-1 p-2 rounded-md outline-none text-center ' value={searchParams.get('ecommerce')} onChange={updateValue} name="ecommerce">
        <option value="" disabled={true}>--Select Product Ecommerce--</option>
        <option value="All" >All Ecommerce</option>
        {ecommerces.map((ecom, index) => <option key={index} value={ecom}>{ecom}</option>)}
      </select>
*/}

      <select className='mr-1 p-2 rounded-md outline-none text-center ' value={searchParams.get('category')} onChange={updateValue} name="category">
        <option value="" disabled={true}>--Select Product Category--</option>
        {categories.map((category, index) => <option key={index} value={category}>{category}</option>)}
      </select>

      <select className='mr-1 p-2 rounded-md outline-none text-center ' value={searchParams.get('rank')} onChange={updateValue} name="rank">
        <option value="" disabled={true}>--Select Product Rank--</option>
        {allRanks.map((rank, index) => <option key={index} value={rank}>Rank {rank}</option>)}
      </select>
    </div>
    {loading && <div className=' h-screen w-screen flex justify-center items-center'>
      <TailSpin
        height="80"
        width="80"
        color="grey"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible="true"
      />
    </div>
    } <div className="mt-10 flex flex-row w-full flex-wrap">
      {
        charts.map(chart => <div key={chart.tag} className='w-1/3 shrink-0 '> <Piechart data={chart} handleProductsPopup={handleProducts} /> </div>)
      }
    </div>
    <hr className="w-40 mx-auto mb-20" />
    <div className="w-4/5 m-auto mb-10">
      <ProductsPriceGraph products={productPrices} />
    </div>
    <Popup isPopUp={showProductsPopup} setIsPopUp={handleProducts}>
      <ProductsPopup tagsData={selectedTagData} category={searchParams.get('category')} ecommerce={searchParams.get('ecommerce')} rank={searchParams.get('rank')} />
    </Popup>
  </div>
}

export default ProductsAnalysis
