import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";




const CurateSimilarProducts = ({ pk, category }) => {

  const [similarImages, setSimilarImages] = useState([])
  const [products, setProducts] = useState([])
  const [results, setResults] = useState({})
  const [fetch, setFetch] = useState(true)


  const fetchData = () => {
    if (!fetch) return;

    axios.get(`/products/trend-products/${pk}`).then(res => {
      setSimilarImages(res.data.similarCategoryImages)
      setResults(res.data.products)

    }).finally(() => {
      setFetch(false)
    })


  }


  const toggleProduct = async (ecommerce_id, deleted) => {
    axios[deleted ? 'delete' : 'put'](`/products/filter-products/${pk}/${ecommerce_id}`).then(() => {
      setFetch(true);
    })

  }

  useEffect(() => {
    fetchData();
  }, [pk, fetch])



  return <div className="w-[100vw] overflow-hidden h-[100vh]">
    {false && <div className="flex flex-row bg-slate-200 w-[95%] overflow-x-auto h-[35%]">
      {
        similarImages.map(img => <div className="shrink-0 w-28 h-full overflow-x-auto mx-2 "><img src={img.imageUrl} /></div>)

      }
    </div>}
    <div className=" flex flex-wrap h-[100%] w-[95%] overflow-auto pb-[10rem]">
      {results[category]?.map((prod, i) => {
        return <div className="w-1/5 border border-slate-200 p-2 shrink-0">
          <a href={prod.product_url} target='_blank'>
            <div className="w-full h-80"><img src={prod.image_url} className="max-h-80 max-w-full" /></div>
            <h3 className="font-semibold">{prod.ecommerce}</h3>
            <h3>{prod.price} &#8377;</h3>
            <h3>{prod.title}</h3>
            <h3>{prod.description}</h3>
            <p className="my-2 font-semibold">{Math.round(prod.score * 1000) / 10}%</p>
          </a>
          <button className={`primary-btn ${prod.deleted ? ' bg-red-300 hover:bg-red-400' : ''}`} onClick={() => toggleProduct(prod.id, !prod.deleted)}>{prod.deleted ? 'Show' : 'Delete'}</button>
        </div>

      })}

    </div>


  </div>

}


export default CurateSimilarProducts;
