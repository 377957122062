import axios from "axios"



const updateSaveData = (isSaved,type, id, setFetch)=>{
	
	if(type=='trend')
	{
		axios[isSaved?'delete':'put'](`/save/trend/${id}`).then(()=>{
		setFetch(true)
		})

	} else {
		axios[isSaved?'delete':'put'](`/save/image/${id}`).then(()=>{
		setFetch(true)
		})


	}


}

export  { updateSaveData  };
