import TagsUpdatePopup from "../ImageTagsTable/TagsUpdatePopup"
import { useParams } from "react-router-dom"

const Page = () => {

  const mediaId = useParams().mediaId
  return <TagsUpdatePopup mediaId={mediaId} />

}

export default Page
