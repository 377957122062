import React, { useEffect, useState, Suspense, lazy } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { updateLogin } from '../../Redux/Slices/loginStatusSlice';
import { TailSpin } from 'react-loader-spinner'
import Trend from './Trend';
import { HiOutlineChevronDown } from 'react-icons/hi'

const ProductsTrendDisplay = ({designPage = false}) => {
    const [searchParmas, setSearchParms] = useSearchParams()
    const navigate = useNavigate();
    const { userLoggedIn } = useSelector(state => state)
    const [showLoader, setShowLoader] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        if (!userLoggedIn.loggedIn) {
            navigate('/login');
        }
    }, []);
    const iniFormData = {
        gender: searchParmas.get('gender') || "f",
        category: searchParmas.get('category') || 'All Trends',
        rank:'all',
	  trendstype:'all-ecommerce'
    }
    const trendCategory = [
        "All Trends",
        "Topwear Trends",
        "Dress Trends",
        "Jeans & Trouser Trends",
        "Skirt Trends",
        "Jumpsuit Trends",
        "Trendy Shorts",
        "Outerwear Trends",
        "Trendy Bags",
        "Trendy Footwear",
    ]

    const mensTrendsCategory = [
        "All Trends",
        "Trendy Accessories",
        "Trendy Bags",
        "Bottomwear Trends",
        "Trendy Footwear",
        "Outerwear Trends",
        "Topwear Trends"
    ]
    const [page, setPage] = useState(() => parseInt(searchParmas.get('page')) || 1)
    const limit = 50
    const [count, setCount] = useState(0)
    const [formdata, setFormData] = useState(iniFormData)
    const [fetch, setFetch] = useState(false)
    const [socialTrendNames, setSocialTrendNames] = useState([])
    const fillForm = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))

        if (name == 'gender') {
            setFormData(prev => ({
                ...prev,
                category: "All Trends",
		    rank:'all'
            }))
        }
    }



    const getTrend = (page = 1, limit = 50) => {
        const category = formdata.category.replace(/&/g, "%26")
        setShowLoader(true)
	  
        axios.get(`/retailer-api/trends?gender=${formdata.gender}&category=${category}&page=${page}&limit=${limit}&rank=${formdata.rank}&trendstype=${formdata.trendstype}`)
            .then(res => {
                if (res.data.unauthorized == true || res.data.cookieExpired == true) {
                    const curator = false, admin = false;
                    dispatch(updateLogin({ loggedIn: false, curator, admin }));
                    navigate('/login');
                } else {
                    setSearchParms({ ...formdata, page: page })
                    setCount(res.data.count)
                    setTrendData(res.data.data)
                    
                }
            })
            .catch(err => {
                console.log(err)
            }).finally(()=>{
			setShowLoader(false)
		})
    }
    const fetchTrend = (e) => {
        e.preventDefault()
    }
    const [trendData, setTrendData] = useState([])
    useEffect(() => {
       
            getTrend(1)
            setPage(1)
		getTrendNames()
    }, [fetch, formdata])

    const decreasePage = () => {
        setPage(page - 1)
        getTrend(page - 1)
        window.scrollTo(0, 0)
    }
    const increasePage = () => {
        setPage(page + 1)
        getTrend(page + 1)
        window.scrollTo(0, 0)
    }




    const getTrendNames = async ()=>{
		const category = formdata.category.replace(/&/g, "%26")

		axios.get(`/retailer-api/social-trend-names?gender=${formdata.gender}&category=${category}`)
		.then(res => {
			const names = res.data.sort((a,b) =>{
				return a.name.trim().toUpperCase() > b.name.trim().toUpperCase()?1:-1
			})
			setSocialTrendNames(names)
				
		}).catch(err => {
			console.log(err)
		})

    }

    if (showLoader) {
        return (
            <div className=' h-screen w-screen flex justify-center items-center'>
                <TailSpin
                    height="80"
                    width="80"
                    color="grey"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible="true"
                />
            </div>
        )
    }
    else {
        return (
            <div className='mx-5 max-[1160px]:mx-1 max-[980px]:mx-0'>
                <div className='flex justify-between my-4 h-16 sticky top-12 mt-2 bg-white z-10 max-[950px]:items-start'>
            	<form onSubmit={fetchTrend} className='flex items-center flex-wrap'>
				{/* <select className='mr-1 p-2 rounded-md outline-none text-center max-[1160px]:w-24 ' value={formdata.gender} onChange={fillForm} name="gender" >
                            <option value="" hidden>--select Gender--</option>
                            <option value="m">Male</option>
                            <option value="f">Female</option>
                        </select> */}
                        <select className='mr-1 w-80 p-2 rounded-md outline-none text-center ' value={formdata.trendstype} onChange={fillForm} name="trendstype">
					<option value="all-ecommerce">Trends seen across retailers</option>
					<option value="same-ecommerce">Trends seen on individual retailers</option>
                        </select>
				<select className='mr-1 p-2 rounded-md outline-none text-center ' value={formdata.category} onChange={fillForm} name="category">
                            <option value="" hidden>--Product Categoty--</option>
                            {formdata.gender == 'f' ?
                                trendCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                                : mensTrendsCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                            }
                        </select>
                        
				<select className='mr-1 p-2 w-52 rounded-md outline-none text-center mt-1' value={formdata.rank} onChange={fillForm} name="rank">
					<option value='select' disabled={true} >Select Rank</option>
					<option value="all">All Product Ranks</option>
					<option value="0-100">0 - 100</option>
					<option value="100-200">100 - 200</option>
					<option value="200-300">200 - 300</option>
					<option value="300-400">300 - 400</option>
					<option value="400-">400 and above</option>
                        </select>
				
                        <div className='min-[564px]:hidden'>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page <= 1} onClick={decreasePage}>prev</button>
                            <span className='mx-1'>{page}/{Math.ceil(count / limit)}</span>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
                        </div>
                        <div className='ml-2 min-[564px]:hidden'>{count} trends</div>
            	</form>
                    <div className='flex items-center flex-wrap justify-end max-[563px]:hidden'>
                        <div className=''>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page <= 1} onClick={decreasePage}>prev</button>
                            <span className='mx-1'>{page}/{Math.ceil(count / limit)}</span>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
                        </div>
                        <div className='ml-2'>{count} trends</div>
                    </div>
                </div>
		    	<div className='w-full mx-0 my-4 px-16'>
			  <p className=' m-0 text-sm font-thin'>This tool analyzes the weekly products launched by fast fashion retailers worldwide. The source list includes Zara, Asos, SheIn, Princess Polly, Boohoo, Missguided, NastyGal, Fashionova, H&M, and Urbanic.</p>
			  <hr className='w-full my-4 m-auto '/>
			</div>
                <div>
                    {trendData.map((trend) => <Trend key={trend.id} trend={trend} setFetch={setFetch} fetch={fetch} gender={formdata.gender} socialTrendNames={socialTrendNames}/>)}
                </div>
            </div>
        )
    }
}

export default ProductsTrendDisplay