import axios from "axios";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mensTrendsCategory, femaleTrendCategory } from '../../constants'
import './index.css'
import { SentenceCase } from '../../utility/helper_functions';
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../component/Popup";
import DesignTrends from "../trendDisplayPage/DesignTrends";
import TrendTrack from "../../component/TrendTrack";
import TagsPopup from "./TagsPopup";
import RetailerTagsPopup from './RetailerTagsPopup';
import RetailerProductsPopup from './RetailerProductsPopup';
import { updateLogin } from "../../Redux/Slices/loginStatusSlice";

const Leaderboard = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [trends, setTrends] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const { userLoggedIn } = useSelector(state => state)
  const [showDesignTrends, setShowDesignTrends] = useState(false)
  const [showTrackData, setShowTrackData] = useState(false)
  const [trendTrackData, setTrendTrackData] = useState(null)
  const [searchText, setSearchText] = useState(searchParams.get('search') || "")
  const [pieChartsTrend, setPieChartsTrend] = useState(null);
  const [retailerPieChartsTrend, setRetailerPieChartsTrend] = useState(null);
  const [retailerProductsTrend, setRetailerProductsTrend] = useState(null);
  const [trendData, setTrendData] = useState(null)
  const initialState = () => {
    const page = searchParams.get('page') || 1;
    const category = searchParams.get('category') || 'All Trends';
    const gender = searchParams.get('gender') || 'f';
    const sort = searchParams.get('sortby') || 'image-count';
    const period = searchParams.get('period') || '7-days';
    const limit = searchParams.get('limit') || 200
    const search = searchParams.get('search') || ''
    setSearchParams({
      page, category, gender, sort, period, limit, search
    })
  }

  useEffect(() => {
    initialState();
  }, [])

  useEffect(() => {
    if (!searchParams.has('category')) {
      initialState()
    } else {
      const cleanupFunc = getTrends()
      return cleanupFunc
    }
  }, [searchParams])



  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
  }, [userLoggedIn]);
  const updateField = (e) => {
    setSearchParams((params) => {
      const newParams = {}
      newParams.page = params.get('page')
      newParams.sort = params.get('sort')
      newParams.period = params.get('period')
      newParams.category = params.get('category')
      newParams.gender = params.get('gender')
      newParams.limit = params.get('limit')
      newParams.search = params.get('search')
      newParams[e.target.name] = e.target.value
      if (e.target.name === "gender") {
        newParams.category = "All Trends"
      }

      return newParams;
    })

  }

  const updateDesignTrend = (show, trend) => {
    if (show === false) {
      setTrendData(null);
      setShowDesignTrends(false);
    } else {
      setTrendData(trend)
      setShowDesignTrends(true)
    }

  }


  const updateTrackTrend = (show, trend) => {
    if (show === false) {
      setTrendTrackData(null);
      setShowTrackData(false);
    } else {
      setTrendTrackData(trend)
      setShowTrackData(true)
    }

  }

  const getTrends = () => {
    if (!searchParams.get('gender'))
      return () => { };
    setTimeout(() => { setLoading(true) }, 100)

    const controller = new AbortController();
    const signal = controller.signal;
    axios.get(`/leaderboard-api/trends?gender=${searchParams.get('gender')}&category=${searchParams.get('category').split('&').join('%26')}&page=${searchParams.get('page')}&limit=${searchParams.get('limit')}&sort=${searchParams.get('sort')}&period=${searchParams.get('period')}&search=${searchParams.get('search')}`,
      { signal })
      .then(res => {
        if (res.data.unauthorized === true) {
          dispatch(updateLogin({ loggedIn: false, curator: false, admin: false, updater: false, clientName: '' }))
        }
        else {
          setTrends(res.data.data)
          setTotalCount(res.data.count)
        }
      }).catch((e) => {
        if (!axios.isCancel(e))
          console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      controller.abort()
    }
  }

  const updatePage = (change) => {
    const e = { target: { name: "page", value: parseInt(searchParams.get('page')) + change } }
    updateField(e)


  }

  return (
    <div className="w-full px-10 relative leaderboard-page">
      <div className="flex w-full sticky top-12 bg-white py-4">
        <div className="flex flex-row w-3/4 flex-wrap">
          <select value={searchParams.get("gender") || 'f'} onChange={updateField} name="gender">
            <option value={"f"}>Female</option>
            <option value={"m"}>Male</option>
          </select>
          <select value={searchParams.get("category") || 'All Trends'} onChange={updateField} name="category">
            {
              searchParams.get("gender") === 'f' ?
                femaleTrendCategory.map(cat => <option key={cat} value={cat}>{cat}</option>)
                : mensTrendsCategory.map(cat => <option key={cat} value={cat}>{cat}</option>)
            }
          </select>
          <select className="" value={searchParams.get("sort") || 'latest'} onChange={updateField} name="sort">
            <option value='image-count'>Sort By Occurence Count</option>
            <option value='latest'>Sort By Last Seen</option>


          </select>
          <select className="" value={searchParams.get("period") || '7-days'} onChange={updateField} name="period">
            <option value='7-days' >Past 7 days</option>
            <option value='14-days' >Past 14 days</option>
            <option value='21-days' >Past 21 days</option>
            <option value='30-days' >Past 30 days</option>
            <option value='45-days' >Past 45 days</option>
            <option value='60-days' >Past 60 days</option>
            <option value='90-days' >Past 90 days</option>
            <option value='180-days' >Past 180 days</option>
          </select>
          {
            //<div>
            //<input type='text' className='mr-1 p-2 rounded-md outline-none text-left  px-8' placeholder='search' value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
            //<button onClick={updateSearchText} className='mx-5 p-1 rounded-lg bg-blue-500 text-white'>Search</button>
            //</div>
          }
          <input type='text' className='mr-1 p-2 rounded-md outline-none text-left  px-8 ml-10 bg-slate-100 text-sm ' placeholder='search' value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
          <button onClick={() => { updateField({ target: { name: 'search', value: searchText } }) }} className='primary-btn-padding'>Search</button>
        </div>
        <div className="justify-end flex w-1/4">
          <button disabled={searchParams.get('page') <= 1} onClick={e => updatePage(-1)} className="primary-btn">prev</button>
          <span className="m-2">{searchParams.get('page') || 1}/{Math.ceil(totalCount / searchParams.get('limit'))}</span>
          <button disabled={searchParams.get('page') >= Math.ceil(totalCount / searchParams.get('limit'))} onClick={e => updatePage(1)} className="primary-btn">next</button>
          <span className="m-2">{totalCount} trends</span>
        </div>
      </div>
      {
        !loading && trends?.length > 0 &&
        <table className="leaderboard-table w-full p-2 mx-0 my-5 border-3 border-slate-300">
          <thead className="border-b-2 border-t-2 border-slate-200 px-2 py-3 font-semibold bg-white">
            <tr>
              <th className="w-20">Rank</th>
              <th className="w-56">Trend Name</th>
              <th className="w-40">Category</th>
              {false && <>
                <th className="w-40">Top Specifications</th>
                <th className="w-40">Which designs will sell more</th>
              </>}
              <th className="w-60">Occurence Count</th>
              {
                false && <th className="w-56">Chatter Weightage</th>
              }
              <th className="w-20">Global Trend Lifecycle</th>
              <th className="w-20">Trend waterfall to India</th>
              <th className="w-100">Tier Distribution for indian images</th>
              <th className="w-56">Geography Distribution</th>
              {						//<th className="w-56">Source Distribution</th>
              }					{false && <th className="w-12">First Seen</th>}
              <th className="w-12">Last Seen</th>
            </tr>
          </thead>
          <tbody>
            {trends.map(trend => {

              return (
                <tr className="w-full border-b-2 border-slate-100 py-2 h-8" key={trend.pk}>
                  <td>{trend.rank}</td>
                  <td><a className="font-bold text-blue-700 underline" href={`/t/${trend._id}`} target='_blank'>{SentenceCase(trend.name)}</a></td>
                  <td>{trend.searchedCategory}</td>
                  {false && <>
                    <td>
                      <button className="primary-btn-padding font-bold text-sm px-3 py-2" onClick={() => { setPieChartsTrend(trend) }}>Social specifications</button>
                      {(userLoggedIn.curator || (trend.retailerTagsData && trend.retailerTagsData.retailerCategory && !['Trendy Footwear', 'Trendy Bags', 'Trendy Accessories', 'Ethnic Wear'].includes(trend.searchedCategory))) &&
                        <>
                          <button className="primary-btn-padding font-bold text-sm px-3 py-2" onClick={() => { setRetailerPieChartsTrend(trend) }}>Retailer specifications</button>
                          <button className="primary-btn-padding font-bold text-sm px-3 py-2" onClick={() => { setRetailerProductsTrend(trend) }}>Retailer products</button>
                        </>
                      }
                      {userLoggedIn.curator &&
                        <>
                          {
                            trend?.retailerTagsData?.selectedTags && Object.keys(trend.retailerTagsData.selectedTags).length ?
                              Object.keys(trend.retailerTagsData.selectedTags).map(k => <p ><span className="font-bold">{k}</span> -{trend.retailerTagsData.selectedTags[k]} </p>)
                              :
                              <p className=" text-sm text-red-600 font-bold ">No tags selected</p>
                          }</>
                      }
                    </td>
                    <td>{trend.countOfVariants > 0 && <button className="primary-btn-padding font-bold text-sm" onClick={() => { updateDesignTrend(true, trend) }}>{trend.countOfVariants} variants</button>}</td>
                  </>
                  }
                  <td>
                    <p>{trend.totalImages}</p>
                    <p> (with 99% accuracy)</p>
                    <p>{trend.imageswith70}</p>
                    <p> (with 70% accuracy)</p>
                    { // Occurrences
                      //with <span className="font-semibold">{// trend.occurence_count}</span> in last { //parseInt(searchParams.get('period'))} days 
                      //
                    }
                  </td>
                  {false &&
                    <td>{(trend.avarage_weightage && trend.avarage_weightage > 0.5) ? trend.avarage_weightage : 'awaiting data'}</td>
                  }
                  <td><button onClick={() => { updateTrackTrend(true, trend) }} className="font-bold text-blue-600" >{trend.lifeCycle}</button></td>
                  <td><button onClick={() => { updateTrackTrend(true, trend) }} className="font-bold text-blue-600" >{trend.indianLifeCycle}</button></td>
                  <td>
                    <div>
                      <div className="mb-4 border-b border-slate-200">India Tier 1<span className="font-semibold float-right">{trend.distribution.tier.tier1}%</span></div>
                      <div className="mb-4 border-b border-slate-200">India Tier 2<span className="font-semibold float-right">{trend.distribution.tier.tier2}%</span></div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className="mb-4 border-b border-slate-200">Asian <span className="font-semibold float-right">{trend.distribution.geography.asian}%</span></div>
                      <div className="mb-4 border-b border-slate-200">Indian <span className="font-semibold float-right">{trend.distribution.geography.indian}%</span></div>
                      <div className="mb-4 border-b border-slate-200">Western <span className="font-semibold float-right">{trend.distribution.geography.western}%</span></div>
                    </div>
                  </td>
                  {false &&
                    <td>
                      <div>
                        {
                          ['Celebrity', 'Mega Influencer', 'Micro Influencer', 'Pages/Magazines', 'Stores/Brands'].map(source => <div className="flex flex-row border-b border-slate-200 mb-4" key={source}>
                            <div className="w-3/4">{source.split('/').join(' /')}</div>
                            <div className="font-semibold float-right w-1/4">{trend.distribution.source[source]}%</div>
                          </div>)}
                      </div>
                    </td>
                  }
                  {false && <td>{new Date(trend.firstSeen).toLocaleDateString()
                  }</td>}
                  <td>{new Date(trend.lastSeen).toLocaleDateString()
                  }</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
      {
        !loading && trends.length === 0 && <div className="relative">
          <p className="absolute top-1/2 left-1/2">
            Please increase the timeframe
          </p>
        </div>
      }
      {
        loading && <div className="h-[80vh] w-screen flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <div className="w-[80px] mb-2">
              <TailSpin
                color="grey"
                height="80"
                width="80"
                radius={"1"}
              />
            </div>
            <p className="p-[10%] text-center font-semibold">Fetching latest trends, weightages, distributions, timelines... </p>
          </div>
        </div>
      }
      <Popup isPopUp={showDesignTrends} setIsPopUp={updateDesignTrend} popupfull={true}  >
        <DesignTrends trend={trendData} gender={trendData?.gender} category={trendData?.searchedCategory} />
      </Popup>
      <Popup isPopUp={showTrackData} setIsPopUp={setShowTrackData}   >
        <TrendTrack trend={trendTrackData} gender={trendTrackData?.gender} category={trendTrackData?.searchedCategory} />
      </Popup>
      <Popup isPopUp={pieChartsTrend} setIsPopUp={setPieChartsTrend}   >
        <TagsPopup trend={pieChartsTrend} />
      </Popup>
      <Popup isPopUp={retailerPieChartsTrend} setIsPopUp={setRetailerPieChartsTrend}   >
        <RetailerTagsPopup trend={retailerPieChartsTrend} />
      </Popup>
      <Popup isPopUp={retailerProductsTrend} setIsPopUp={setRetailerProductsTrend}   >
        <RetailerProductsPopup trend={retailerProductsTrend} />
      </Popup>
    </div >
  )
}


export default Leaderboard;
