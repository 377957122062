import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const TopCommentsPosts = ({ images,category }) => {
	const { userLoggedIn } = useSelector(state => state)
	const [commentsList, setCommentsList] = useState([])

	const [responseReceived, setResponseReceived] = useState(false);
	const [posts, setPosts]  = useState([])
	const getComments = async()=>{
		const mediaIds =  images.map(img => img.mediaId)
		const response = await axios.post('/comments-api/images',{
			mediaIds,
			category
		})
		setResponseReceived(true);
		const data = response.data.filter(post => post.comments&&post.comments.length>0)
		data.forEach(post => {
			post.image = images.filter(img => img.postCode==post.postCode)[0]
		})
		setPosts(data);
	}

	useEffect(()=>{
		getComments();
	},[])

	useEffect(()=>{
		let commentsArray = []
		for(let post of posts){
			commentsArray = commentsArray.concat(...post.comments)
		}
		commentsArray = commentsArray.sort((b,a)=>{
			return a.genericWeightage+(a.categoryWeightages && a.categoryWeightages.hasOwnProperty(category)?a.categoryWeightages[category]:0) - (b.genericWeightage +(b.categoryWeightages && b.categoryWeightages.hasOwnProperty(category)?b.categoryWeightages[category]:0))
		})
		commentsArray.forEach(comment => {
			comment.weightage = comment.genericWeightage+(comment.categoryWeightages && comment.categoryWeightages.hasOwnProperty(category)?comment.categoryWeightages[category]:0)
		})
		setCommentsList(commentsArray)
	},[posts])



	return (
		<div className='self center w-[75vw] m-5  h-[75vh] px-5 '>
			<h1 className='text-center font-medium text-lg mb-10'>TOP COMMENTS</h1>
			{
				userLoggedIn.curator? posts.map(post => {
					return<> 
						<div key={post.postCode} className='border-b-2 border-2 mb-6 p-5 flex h-[70vh]'>
							<div className='w-1/4 pt-20'>
								<a href={post.image.postUrl} target='_blank'>
									<img src={post.image.imageUrl}/>
								</a>
								<a href={`https://www.instagram.com/${post.image.username}`} className='text-xs text-slate-600 font-semibold'  target='_blank'>@{post.image.username}</a>
								<br />
								<a href={post.image.postUrl} className='text-xs text-blue-600 ' target='_blank'>{post.image.postUrl}</a>
							</div>
							<div className='w-[65%] px-12 overflow-y-auto'>
								{
									post.comments.map((comment,i) => {
										let weightage = 0;
										if(comment.genericWeightage)	weightage+=comment.genericWeightage;
										if(comment.categoryWeightages && comment.categoryWeightages.hasOwnProperty(category)){
											weightage+=comment.categoryWeightages[category]
										}
										return <div className='mb-2 border-b' key={i}>
											<p className='font-bold'>{comment.commentOwnerUsername}<span className='float-right'>+{weightage}</span></p>
											<p className='pl-10'>{comment.commentText}</p>
										</div>
									})
								}
							</div>
						</div>
					</>
				}):<>
					{commentsList.map((comment,i)  => {
						return <div className='mb-4 px-10 border-b' key={i}>
							<p className='font-bold'>{comment.commentOwnerUsername}<span className='float-right'>+{comment.weightage}</span></p>
							<p className='pl-10'>{comment.commentText}</p>
						</div>
					})}
				</>
			}
			{
				responseReceived?null:<div className=' relative w-full h-1/2'>
					<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ' ><TailSpin color='black'/></div>
				</div>
			}
		</div>
	)
}

export default TopCommentsPosts
