import React,{useEffect} from 'react'
import TopBar from './TopBar'
import UploadImg from './UploadImg'
import ImageSet from './ImageSet'
import {useNavigate} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
	const navigate = useNavigate();
	const { userLoggedIn } = useSelector(state=>state)

	useEffect(() => {
		if(!userLoggedIn.loggedIn){
			navigate('/login');
		} else if(!userLoggedIn.curator){
			navigate('/')
		}
	},[]);
	
	return (
		<div className='flex'>
			<UploadImg/>
		</div>
	)
}

export default Home