import { useEffect } from "react"
import { Cell, LabelList, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import './index.css'

const Piechart = ({ data, handleProductsPopup }) => {
  const COLORS = [
    "#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"
  ]
  if (!data) {
    return <></>
  }

  const funct = (target, event) => {
    const tagValue = event?.target?.attributes?.name?.value
    if (tagValue) {
      handleProductsPopup(true, data.tag, tagValue)

    }

  }
  return <div className="w-full piechart-fonts-label">
    <h3 className="m-4 text-center font-semibold text-lg ">{data.tag}</h3>
    <ResponsiveContainer width={'100%'} height={350}>
      <PieChart width={'100%'} height={'100%'} onClick={funct}>
        <Pie data={data.pieChart} dataKey="value" nameKey="name" cx="50%" cy="40%" outerRadius={120} fill="#8884d8">
          <LabelList dataKey="name" position={'outside'} />
          {data.pieChart.map((entry, index) => <Cell
            fill={COLORS[index % COLORS.length]}
            key={`cell-${index}`}
          />
          )}
        </Pie>
        <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
      </PieChart>
    </ResponsiveContainer>
  </div>
}

export default Piechart
