import { TailSpin } from "react-loader-spinner";

const Loadingwrapper = ({ children, loading, className }) => {

  return loading ? <div className={`w-full flex justify-center items-center ${className}`}>
    <TailSpin />
  </div> : children
}

export default Loadingwrapper;
