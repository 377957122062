export const allBrands = [
  '(di)vision',
  '*BABY MILO® STORE BY *A BATHING APE®',
  '032c',
  '1017 ALYX 9SM',
  '11 By Boris Bidjan Saberi',
  '12 STOREEZ',
  '120% Lino',
  '123 RIVINGTON',
  '16Arlington',
  '1989 STUDIO',
  '3.1 Phillip Lim',
  '360Cashmere',
  '3PARADIS',
  '3juin',
  '3x1',
  '424',
  '44 LABEL GROUP',
  '4SDESIGNS',
  '66 North',
  '7 For All Mankind',
  '711',
  '813',
  '8:00 PM',
  'A BATHING APE®',
  'A BETTER MISTAKE',
  'A Kind of Guise',
  'A Paper Kid',
  'A-COLD-WALL*',
  'A. ROEGE HOVE',
  'A.A. Spectrum',
  'A.EMERY',
  'A.L.C.',
  'A.P.C.',
  'A.W.A.K.E. Mode',
  'AAPE BY *A BATHING APE®',
  'AARON ESH',
  'ACRONYM',
  'ACT Nº1',
  'ACUPUNCTURE 1993',
  'AERON',
  'AFB',
  'AG Jeans',
  'AGL',
  'AGOLDE',
  'AIREI',
  'AISLING CAMPS',
  'ALABAMA MUSE',
  'ALEMAIS',
  'ALESSANDRO VIGILANTE',
  'ALIX NYC',
  'ALOHAS',
  'ALTRA',
  'AMBUSH',
  'AMI Paris',
  'AMIRI',
  'AMOTEA',
  'ANDREA IYAMAH',
  'ANDREĀDAMO',
  'ANINE BING',
  'ANOUKI',
  'APL: ATHLETIC PROPULSION LABS',
  'ARCS',
  'AREA',
  'ARMARIUM',
  'ARTE',
  'ASICS',
  'ASPESI',
  'ATP Atelier',
  'AV Vattev',
  'AVAVAV',
  'AZ FACTORY',
  'AZEEZA',
  'AZZALIA',
  'Acler',
  'Acne Studios',
  'Adam Lippes',
  'Ader Error',
  'Adieu Paris',
  'Adish',
  'Advisory Board Crystals',
  'Aesther Ekme',
  'Aeyde',
  'Agent Provocateur',
  'Agnona',
  'Agua By Agua Bendita',
  'Ahluwalia',
  'Aje',
  'Akris',
  'Alanui',
  'Alberta Ferretti',
  'Alberto Biani',
  'Alberto Fasciani',
  'Alchemist',
  'Alchemy',
  'Alejandra Alonso Rojas',
  'Aleksandre Akhalkatsishvili',
  'Alessandra Rich',
  'Alex Perry',
  'Alexander McQueen',
  'Alexander Wang',
  'Alexandra Golovanoff',
  'Alexandre Birman',
  'Alexandre Vauthier',
  'Alexis',
  'Alienina',
  'Allude',
  'Almaz',
  'Alpha Industries',
  'Alpha Tauri',
  'Altea',
  'Altuzarra',
  'Alysi',
  'Amazuìn',
  'Amen',
  'Amina Muaddi',
  'Amir Slama',
  'Amomento',
  'Amsale',
  'Ana Radu',
  'Andersson Bell',
  'Andrea Ya\'aqov',
  'Andrew Gn',
  'Android Homme',
  'Ann Demeulemeester',
  'Anna October',
  'Anna Quan',
  'Another Aspect',
  'Another Tomorrow',
  'Antonelli',
  'Antonino Valenti',
  'Anya Hindmarch',
  'Apparis',
  'Aquazzura',
  'Aranaz',
  'Arc\'teryx',
  'Aries',
  'Arizona Love',
  'Arma',
  'Armani',
  'Armani Exchange',
  'Arteana',
  'Asceno',
  'Ash',
  'Ashish',
  'Aspinal Of London',
  'Attachment',
  'Atu Body Couture',
  'Auralee',
  'Autry',
  'Ava Adore',
  'Avant Toi',
  'Awake NY',
  'Axel Arigato',
  'Aya Muse',
  'Ayda',
  'Aztech Mountain',
  'B SIDES',
  'BAPY BY *A BATHING APE®',
  'BARROW',
  'BAZZA ALZOUMAN',
  'BCBG Max Azria',
  'BEAMS PLUS',
  'BEC + BRIDGE',
  'BETTINA VERMILLON',
  'BETTTER',
  'BIASIA',
  'BITE Studios',
  'BLACK SUEDE STUDIO',
  'BLAEST',
  'BLUE SKY INN',
  'BLUEMARBLE',
  'BODE',
  'BOGNER',
  'BOGNER FIRE+ICE',
  'BOSS',
  'BRAND BLACK',
  'BRERAS MILANO',
  'BY FAR',
  'Ba&Sh',
  'Bacon',
  'Badgley Mischka',
  'Bagnoli Sartoria Napoli',
  'Baldinini',
  'Balenciaga',
  'Ballantyne',
  'Bally',
  'Balmain',
  'Bambah',
  'Bao Bao Issey Miyake',
  'Baracuta',
  'Barba',
  'Barbara Bologna',
  'Barbara Bui',
  'Barbour',
  'Barbour International',
  'Barena',
  'Barrett',
  'Barrie',
  'Baruni',
  'Baserange',
  'Baum Und Pferdgarten',
  'Bazar Deluxe',
  'Baziszt',
  'Belstaff',
  'Benedetta Bruzziches',
  'Bernadette',
  'Bernhard Willhelm',
  'Bianca Saunders',
  'Billionaire',
  'Billionaire Boys Club',
  'Bimba y Lola',
  'Birkenstock',
  'Biyan',
  'Black Comme Des Garçons',
  'Blanca Vita',
  'Blancha',
  'Blauer',
  'Blazé Milano',
  'Blugirl',
  'Blumarine',
  'Boggi Milano',
  'Boglioli',
  'Bond-eye',
  'Bonpoint',
  'Bonsai',
  'Borgo De Nor',
  'Boris Bidjan Saberi',
  'Borrelli',
  'Both',
  'Botter',
  'Boyarovskaya',
  'Brandon Maxwell',
  'Briglia 1949',
  'Brioni',
  'Brunello Cucinelli',
  'Bruno Manetti',
  'Bugatti',
  'Burberry',
  'Burton',
  'Buscemi',
  'Buttero',
  'By Malene Birger',
  'By Walid',
  'C.P. Company',
  'C.P. Company Kids',
  'C2h4',
  'CANAKU',
  'CANNARI CONCEPT',
  'CARNE BOLLENTE',
  'CAROLINE HU',
  'CAVIA',
  'CFCL',
  'CHANEL Pre-Owned',
  'CHIARA BONI La Petite Robe',
  'CHOCOOLATE',
  'CHÉ',
  'CK Calvin Klein',
  'CLOT',
  'COLE BUXTON',
  'COMMAS',
  'CONCEPTO',
  'CORMIO',
  'CROQUIS',
  'CRUSH CASHMERE',
  'Cala de la Cruz',
  'Calvin Klein',
  'Calvin Klein Jeans',
  'Camilla',
  'Camper',
  'CamperLab',
  'Canada Goose',
  'Canadian Club',
  'Canali',
  'Cara Cara',
  'Caravana',
  'Carel Paris',
  'Carhartt',
  'Carhartt WIP',
  'Carine Gilson',
  'Cariuma',
  'Carolina Herrera',
  'Caruso',
  'Casablanca',
  'Casadei',
  'Casey Casey',
  'Cashmere In Love',
  'Catherine Regehr',
  'Cecilie Bahnsen',
  'Celia B',
  'Cenere GB',
  'Cesare Attolini',
  'Champion',
  'Charles Jeffrey Loverboy',
  'Charlott',
  'Charo Ruiz Ibiza',
  'Chet Lo',
  'Chiara Ferragni',
  'Chie Mihara',
  'Children Of The Discordance',
  'Chinti & Parker',
  'Chloe Nardin',
  'Chloé',
  'Chopova Lowena',
  'Christian Dior',
  'Christian Dior Pre-Owned',
  'Christian Wijnants',
  'Christopher Esber',
  'Christopher John Rogers',
  'Chufy',
  'Church\'s',
  'Château Lafleur-Gazin',
  'Ciao Lucia',
  'Cinq A Sept',
  'Cinzia Rocca',
  'Circolo 1901',
  'Citizens of Humanity',
  'Clarks',
  'Clarks Originals',
  'Claudie Pierlot',
  'Clergerie',
  'Closed',
  'Co',
  'Coach',
  'Coccinelle',
  'Cole Haan',
  'Collegium',
  'Collina Strada',
  'Colmar',
  'Colombo',
  'Commando',
  'Comme Des Garçons',
  'Comme Des Garçons Comme Des Garçons',
  'Comme Des Garçons Girl',
  'Comme Des Garçons Play',
  'Comme Des Garçons Play x Converse',
  'Comme Des Garçons Shirt',
  'Comme Des Garçons Wallet',
  'Comme des Garçons Homme',
  'Comme des Garçons Homme Deux',
  'Comme des Garçons Homme Plus',
  'Comme des Garçons TAO',
  'Commission',
  'Common Projects',
  'Conner Ives',
  'Converse',
  'Coperni',
  'Corneliani',
  'Corridor',
  'Costarellos',
  'Costumein',
  'Courrèges',
  'Craig Green',
  'Cristina Savulescu',
  'Crockett & Jones',
  'Cruciani',
  'Cult Gaia',
  'Cynthia Rowley',
  'Câllas Milano',
  'Céline Pre-Owned',
  'D.A.T.E.',
  'D.Exterior',
  'D4.0',
  'DANCASSAB',
  'DARKPARK',
  'DELAGE',
  'DENTRO',
  'DES PHEMMES',
  'DKNY',
  'DL1961',
  'DOMREBEL',
  'DONDUP',
  'DRAGON DIFFUSION',
  'DRHOPE',
  'DRIES VAN NOTEN',
  'DUNST',
  'DVF Diane von Furstenberg',
  'Daily Paper',
  'Daniel Wellington',
  'Daniela Gregis',
  'Daniele Alessandrini',
  'Danton',
  'Dark Park',
  'David Koma',
  'De La Vali',
  'DeMellier',
  'Dee Ocleppo',
  'Del Core',
  'Dell\'oglio',
  'Denim Tears',
  'Denimist',
  'Deperlu',
  'Derek Lam 10 Crosby',
  'Desa 1972',
  'Descente ALLTERRAIN',
  'Destin',
  'Di Petsa',
  'Diadora',
  'Diemme',
  'Diesel',
  'Dion Lee',
  'District Vision',
  'Dolce & Gabbana',
  'Dolce & Gabbana DGVIB3',
  'Domenico Formichetti',
  'Doppiaa',
  'Doriani Cashmere',
  'Dorothee Schumacher',
  'Doublet',
  'Doucal\'s',
  'Dr. Martens',
  'Drome',
  'Drumohr',
  'Drôle De Monsieur',
  'Dsquared2',
  'Dunhill',
  'Durazzi Milano',
  'Dusan',
  'Duvetica',
  'ECCO',
  'EGONlab.',
  'ELECTRIC & ROSE',
  'ENTIRE STUDIOS',
  'ERALDO',
  'ERES',
  'ERL',
  'ERMANNO FIRENZE',
  'ESCVDO',
  'ESTER MANAS',
  'ETRO',
  'EVISU',
  'EYTYS',
  'Ea7 Emporio Armani',
  'Each X Other',
  'Eckhaus Latta',
  'Edward Achour Paris',
  'Edward Cuming',
  'Egg',
  'Eileen Fisher',
  'Eleventy',
  'Elie Saab',
  'Elisabetta Franchi',
  'Elleme',
  'Emilia Wickstead',
  'Emporio Armani',
  'Emporio Sirenuse',
  'Endless Joy',
  'Enfants Riches Déprimés',
  'Engineered Garments',
  'Enterprise Japan',
  'Equipment',
  'Erdem',
  'Erika Cavallini',
  'Ermanno Ermanno',
  'Ermanno Scervino',
  'Ermenegildo Zegna',
  'Ernest W. Baker',
  'Essentiel Antwerp',
  'Etudes',
  'F.A.M.T.',
  'F.R.S For Restless Sleepers',
  'FARM Rio',
  'FEAR OF GOD ESSENTIALS',
  'FEBEN',
  'FIVE CM',
  'FLÂNEUR',
  'FRAME',
  'FURSAC',
  'Fabiana Filippi',
  'Facetasm',
  'Faith Connexion',
  'Faithfull the Brand',
  'Faliero Sarti',
  'Family First',
  'Fay',
  'Fay Kids',
  'Fear Of God',
  'Fedeli',
  'Federica Tosi',
  'Fely Campo',
  'Fendi',
  'Feng Chen Wang',
  'Fenty X Puma',
  'Ferragamo',
  'Ferrari',
  'Fila',
  'Fileria',
  'Filippa K',
  'Filling Pieces',
  'Filson',
  'Finamore 1925 Napoli',
  'Fiorucci',
  'Fleur Du Mal',
  'Folk',
  'Foret',
  'Forme D\'expression',
  'Forte Dei Marmi Couture',
  'Forte Forte',
  'Fortela',
  'Francesco Russo',
  'Frankies Bikinis',
  'Fratelli Rossetti',
  'Fred Perry',
  'Frenckenberger',
  'Frescobol Carioca',
  'Fumito Ganryu',
  'Furla',
  'Fusalp',
  'G/FORE',
  'GALLERY DEPT.',
  'GANNI',
  'GAUGE81',
  'GHŌUD',
  'GIABORGHINI',
  'GOES BOTANICAL',
  'GOLDSIGN',
  'GR10K',
  'GUESS USA',
  'Gabriela Hearst',
  'Gabriele Pasini',
  'Gaby Charbachy',
  'Galvan London',
  'Gauchère',
  'Gcds',
  'Gedebe',
  'Gemy Maalouf',
  'Genny',
  'Gentry Portofino',
  'Gestuz',
  'Giada Benincasa',
  'Giambattista Valli',
  'Gianluca Capannolo',
  'Gianvito Rossi',
  'Gimaguas',
  'Giorgio Armani',
  'Giorgio Brato',
  'Girlfriend Collective',
  'Gitman Vintage',
  'Giuliano Galiano',
  'Giuliva Heritage',
  'Giuseppe Di Morabito',
  'Giuseppe Zanotti',
  'Givenchy',
  'Glanshirt',
  'Gloria Coelho',
  'GmbH',
  'Gold Hawk',
  'Goldbergh',
  'Golden Goose',
  'Goldwin',
  'Good American',
  'Goyard Pre-Owned',
  'Gramicci',
  'Greg Lauren',
  'Ground Zero',
  'Gucci',
  'Gucci Pre-Owned',
  'Guest In Residence',
  'Guidi',
  'HARDOT',
  'HELIOT EMIL',
  'HODAKOVA',
  'HOKA',
  'HOPE MACAULAY',
  'HUGO',
  'Hackett',
  'Haculla',
  'Haider Ackermann',
  'Haikure',
  'Hale Bob',
  'Halfboy',
  'Halmanera',
  'Han Kjøbenhavn',
  'Hand Picked',
  'Handred',
  'Hanro',
  'Harmony Paris',
  'Harris Wharf London',
  'Harrys of London',
  'Hayley Menzies',
  'Hebe Studio',
  'Hed Mayner',
  'Hellessy',
  'Helmstedt',
  'Helmut Lang',
  'Henderson Baracco',
  'Henrik Vibskov',
  'Hereu',
  'Hermès Pre-Owned',
  'Herno',
  'Heron Preston',
  'Hevo',
  'Hide&Jack',
  'Hogan',
  'Holzweiler',
  'Homme Plissé Issey Miyake',
  'HommeGirls',
  'Honey Fucking Dijon',
  'Honor The Gift',
  'House Of Dagmar',
  'Huf',
  'Huishan Zhang',
  'Hunter',
  'Hunza G',
  'Hyein Seo',
  'Héros',
  'IBRIGU',
  'ICECREAM',
  'IRO',
  'ISABEL MARANT',
  'IVY OAK',
  'IXIAH',
  'Iceberg',
  'Ienki Ienki',
  'Ih Nom Uh Nit',
  'Il Bisonte',
  'Incentive! Cashmere',
  'Incotex',
  'Innerraum',
  'Inuikii',
  'Inès & Maréchal',
  'Ioana Ciolacu',
  'Ioannes',
  'Iris Von Arnim',
  'Isa Boulder',
  'Isaac Sellam Experience',
  'Isabel Benenato',
  'Isabel Sanchis',
  'Isaia',
  'Isolda',
  'Issey Miyake',
  'Issey Miyake Men',
  'Izaak Azanei',
  'J LINDEBERG',
  'J&M Davidson',
  'J.LAL',
  'J.Lindeberg',
  'JANE',
  'JEAN-LOUIS SABAJI',
  'JNBY',
  'JORDANLUCA',
  'JOSEPH',
  'JUNTAE KIM',
  'JW Anderson',
  'Jacob Cohën',
  'Jacob Lee',
  'Jacquemus',
  'Jade Cropper',
  'Jakke',
  'James Perse',
  'Jason Wu',
  'Jason Wu Collection',
  'Jean Paul Gaultier',
  'Jeanerica',
  'Jeff Hamilton',
  'Jejia',
  'Jenny Packham',
  'Ji WON CHOI',
  'Jil Sander',
  'Jimmy Choo',
  'JiyongKim',
  'Johanna Ortiz',
  'John Elliott',
  'John Richmond',
  'John Smedley',
  'Johnstons of Elgin',
  'Jordan',
  'Jordan Kids',
  'Joshua Sanders',
  'Judith Leiber',
  'Julius',
  'Junya Watanabe',
  'Junya Watanabe Comme des Garçons Pre-Owned',
  'Junya Watanabe MAN',
  'Just Cavalli',
  'Justine Clenquet',
  'Juun.J',
  'Jérôme Dreyfuss',
  'K Swiss',
  'KANGHYUK',
  'KAPITAL',
  'KASSL Editions',
  'KEEN FOOTWEAR',
  'KG Kurt Geiger',
  'KHAITE',
  'KNWLS',
  'KUSIKOHC',
  'Kalmanovich',
  'Kappa',
  'Kara',
  'Karl Lagerfeld',
  'Karl Lagerfeld Jeans',
  'Kate Spade',
  'Kenzo',
  'Kerne.Milk',
  'Khrisjoy',
  'KidSuper',
  'Kika Vargas',
  'Kiki de Montparnasse',
  'Kiko Kostadinov',
  'Kimhekim',
  'Kired',
  'Kiton',
  'Kitri',
  'Kolor',
  'Kristensen Du Nord',
  'Ksubi',
  'Kurt Geiger London',
  'L\'Agence',
  'L\'Alingi',
  'L\'IDÉE',
  'LAPOINTE',
  'LASTFRAME',
  'LE PETIT TROU',
  'LEMAIRE',
  'LES HOMMES',
  'LGN LOUIS GABRIEL NOUCHI',
  'LIU JO',
  'LOEWE',
  'LUAR',
  'LUG VON SIGA',
  'LUIGI BIANCHI MANTOVA',
  'LVIR',
  'La Collection',
  'La DoubleJ',
  'La Seine & Moi',
  'LaQuan Smith',
  'Labrum London',
  'Lacoste',
  'Lala Berlin',
  'Lama Jouni',
  'Lamberto Losani',
  'Lancel',
  'Laneus',
  'Lanvin',
  'Lardini',
  'Late Checkout',
  'Lauren Manoogian',
  'Lauren Ralph Lauren',
  'Laurence Dacade',
  'Le Kasha',
  'Le Silla',
  'Le Tricot Perugia',
  'Leathersmith of London',
  'Lee Mathews',
  'Lenny Niemeyer',
  'Leo Lin',
  'Les Benjamins',
  'Les Coyotes De Paris',
  'Les Tien',
  'Levi\'s',
  'Levi\'s: Made & Crafted',
  'Li-Ning',
  'Liberaiders',
  'Liberal Youth Ministry',
  'Lidfort',
  'Limitato',
  'Lisa Yang',
  'Liska',
  'Litkovskaya',
  'Local Authority',
  'Loeffler Randall',
  'Longchamp',
  'Lorena Antoniazzi',
  'Loro Piana',
  'Louis Shengtao Chen',
  'Louis Vuitton',
  'Louisa Ballou',
  'Louise Misha',
  'Loulou',
  'Loulou Studio',
  'Love Moschino',
  'LoveShackFancy',
  'Low Brand',
  'Low Classic',
  'Ludovic de Saint Sernin',
  'Luisa Cerano',
  'Lygia & Nanny',
  'LØCI',
  'LỰU ĐẠN',
  'MACH & MACH',
  'MADE FOR A WOMAN',
  'MANNING CARTELL',
  'MANURI',
  'MARANT',
  'MARANT ÉTOILE',
  'MARKET',
  'MC2 Saint Barth',
  'MCM',
  'MEMBERS OF THE RAGE',
  'MERYLL ROGGE',
  'MISBHV',
  'MISHA',
  'MM6 Maison Margiela',
  'MM6 Maison Margiela X Salomon',
  'MONOCHROME',
  'MORDECAI',
  'MOSCHINO JEANS',
  'MOTHER',
  'MOUTY',
  'MRZ',
  'MSFTSrep',
  'MSGM',
  'MUNTHE',
  'Maccapani',
  'Macgraw',
  'Mackage',
  'Mackintosh',
  'Made in Tomboy',
  'MadeWorn',
  'Madeleine Thompson',
  'Madison.Maison',
  'Maeden',
  'Magda Butrym',
  'Magliano',
  'Magnanni',
  'Maharishi',
  'Maiami',
  'Maisie Wilen',
  'Maison Close',
  'Maison Flaneur',
  'Maison Kitsuné',
  'Maison Labiche',
  'Maison Margiela',
  'Maison Mihara Yasuhiro',
  'Maison Skorpios',
  'Maje',
  'Majestic Filatures',
  'Mallet',
  'Malo',
  'Malone Souliers',
  'Mame Kurogouchi',
  'Man On The Boon.',
  'Manokhi',
  'Manolo Blahnik',
  'Mansur Gavriel',
  'Manu Atelier',
  'Manuel Ritz',
  'Manzoni 24',
  'Mara Hoffman',
  'Marané',
  'Marc Jacobs',
  'Marc Le Bihan',
  'Marcelo Burlon County of Milan',
  'Marchesa',
  'Marchesa Notte',
  'Marchesa Rosa',
  'Marco Rambaldi',
  'Margaret Howell',
  'Marge Sherwood',
  'Margherita MACCAPANI',
  'Maria Lucia Hohan',
  'Marina Rinaldi',
  'Marine Serre',
  'Markarian',
  'Marlies Dekkers',
  'Marni',
  'Marni Market',
  'Marques\'Almeida',
  'Marshall Columbia',
  'Marsèll',
  'Martine Rose',
  'Masha Popova',
  'Masnada',
  'Massimo Alba',
  'Mastermind Japan',
  'Mastermind World',
  'Materiel',
  'Maticevski',
  'Matteau',
  'Mauna Kea',
  'Maurizio Miri',
  'Max & Moi',
  'Maygel Coronel',
  'Mazzarelli',
  'Medea',
  'Melissa Odabash',
  'Melissa x Undercover',
  'Melitta Baumeister',
  'Merci',
  'Mes Demoiselles',
  'Meta Campania Collective',
  'Michael Kors',
  'Michael Kors Collection',
  'Michael Michael Kors',
  'Michel Vivien',
  'Michelle Mason',
  'Milly',
  'Mira Mikati',
  'Misa Los Angeles',
  'Missoni',
  'Miu Miu',
  'Mizuno',
  'Molly Goddard',
  'Moma',
  'Moncler',
  'Moncler + Rick Owens',
  'Moncler Grenoble',
  'Monique Lhuillier',
  'Monse',
  'Moon Boot',
  'Moorer',
  'Moose Knuckles',
  'Moreau',
  'Moschino',
  'Mostly Heard Rarely Seen',
  'Mostly Heard Rarely Seen 8-Bit',
  'Mou',
  'Mou Kids',
  'Moussy Vintage',
  'Mr. Mittens',
  'Mugler',
  'Mulberry',
  'Muller Of Yoshiokubo',
  'Munderingskompagniet',
  'Murmur',
  'Museum Of Peace & Quiet',
  'Musier',
  'Musium Div.',
  'Myths',
  'Mônot',
  'N.Peal',
  'NAADAM',
  'NAKED WOLFE',
  'NAMESAKE',
  'NELLS NELSON',
  'NEOUS',
  'NIKE x SACAI',
  'NILøS',
  'NISSA',
  'NN07',
  'NOAH NY',
  'Nackiyé',
  'Nahmias',
  'Namacheko',
  'Nanamica',
  'Nanushka',
  'Napapijri',
  'Natasha Zinko',
  'Needle & Thread',
  'Needles',
  'Neighborhood',
  'Neil Barrett',
  'Nensi Dojaka',
  'New Balance',
  'Niccolò Pasqualetti',
  'Nicholas',
  'Nicholas Daley',
  'Nicolas Andreas Taralis',
  'Nicole Benisti',
  'Nicole Saldaña',
  'Nike',
  'Nike X Off-White',
  'Nike x Ambush',
  'Nili Lotan',
  'Nina Ricci',
  'Noir Kei Ninomiya',
  'Noire Swimwear',
  'Norma Kamali',
  'Norrøna',
  'Norse Projects',
  'Norwegian Wool',
  'Nubikk',
  'Nude',
  'Nudie Jeans',
  'Nuur',
  'Nuè',
  'Nº21',
  'OAMC',
  'OBJECTS IV LIFE',
  'OUR LEGACY',
  'OVER OVER',
  'Oakley',
  'Odeeh',
  'Off-White',
  'Officine Creative',
  'Officine Generale',
  'Olivia Rubin',
  'Olly Shinder',
  'Olympia Le-Tan',
  'On Running',
  'One Of These Days',
  'Onitsuka Tiger',
  'OpéraSPORT',
  'Orciani',
  'Orian',
  'Orlebar Brown',
  'Oscar de la Renta',
  'Osklen',
  'Osoi',
  'Ostrya',
  'Oséree',
  'Ottolinger',
  'Ouest PARIS',
  'Oyuna',
  'P.A.R.O.S.H.',
  'P.E Nation',
  'P.L.N.',
  'PACCBET',
  'PAIGE',
  'PATTA',
  'PAULA',
  'PH5',
  'PHILEO',
  'PINKO',
  'PMD',
  'PNK',
  'POSTER GIRL',
  'PRESIDENT\'S',
  'PRISM²',
  'PS Paul Smith',
  'PT Torino',
  'PUBLISHED BY',
  'PUCCI',
  'PUMA',
  'Pal Zileri',
  'Palace',
  'Palm Angels',
  'Palmer//Harding',
  'Palmes',
  'Paloma Barceló',
  'Paloma Wool',
  'Paltò',
  'Paraboot',
  'Parajumpers',
  'Paris Georgia',
  'Paris Texas',
  'Parlor',
  'Pasadena Leisure Club',
  'PatBO',
  'Patagonia',
  'Patou',
  'Patrizia Pepe',
  'Paul & Shark',
  'Paul Smith',
  'Paul Warmer',
  'Paula Canovas del Vas',
  'Paule Ka',
  'Paura',
  'Peak Performance',
  'Pendleton',
  'Perfect Moment',
  'Perks And Mini',
  'Peserico',
  'Petar Petrov',
  'Peter Do',
  'Peuterey',
  'Philipp Plein',
  'Philippe Model Paris',
  'Philosophy Di Lorenzo Serafini',
  'Piazza Sempione',
  'PierAntonioGaspari',
  'Pierre Hardy',
  'Pierre-Louis Mascia',
  'Pippa Holt',
  'Plan C',
  'Pleasures',
  'Pleats Please Issey Miyake',
  'Plein Sport',
  'Pollini',
  'Polo Ralph Lauren',
  'Pop Trading Company',
  'Ports 1961',
  'Post Archive Faction',
  'Prada',
  'Prada Pre-Owned',
  'Premiata',
  'President’S',
  'Pringle of Scotland',
  'Private 0204',
  'Private Stock',
  'Proenza Schouler',
  'Proenza Schouler White Label',
  'Puppets and Puppets',
  'Purple Brand',
  'Pyrenex',
  'QUIRA',
  'Qasimi',
  'R13',
  'RANRA',
  'RASARIO',
  'RAXXY',
  'RE/DONE',
  'RED Valentino',
  'REMAIN',
  'REV',
  'RHODE',
  'RHUDE',
  'RLX Ralph Lauren',
  'ROA',
  'ROMEO HUNTE',
  'ROTATE',
  'RRD',
  'RTA',
  'RUI',
  'RUN OF',
  'RXQUETTE',
  'Rabanne',
  'Rachel Gilbert',
  'Raf Simons',
  'Raf Simons X Fred Perry',
  'Rains',
  'Raised By Wolves',
  'Ralph Lauren Collection',
  'Ralph Lauren Purple Label',
  'Ralph Lauren RRL',
  'Ramael',
  'Random Identities',
  'Rapha',
  'Raquel Allegra',
  'Rave Review',
  'Readymade',
  'Rebecca Minkoff',
  'Rebecca Vallance',
  'Red Wing Shoes',
  'Reebok',
  'Reebok LTD',
  'Reese Cooper',
  'Reformation',
  'Reike Nen',
  'Reina Olga',
  'Rejina Pyo',
  'Renli Su',
  'René Caovilla',
  'Represent',
  'Retrofete',
  'Reveres 1949',
  'Rhea Costa',
  'Rhode',
  'Rianna + Nina',
  'Richard Quinn',
  'Rick Owens',
  'Rick Owens DRKSHDW',
  'Rick Owens Lilies',
  'Rick Owens X Champion',
  'Rier',
  'Rixo',
  'Roberta Einer',
  'Roberto Cavalli',
  'Roberto Collina',
  'Roberto Festa',
  'Robyn Lynch',
  'Rochas',
  'Rodebjer',
  'Rodo',
  'Rokh',
  'Roksanda',
  'Roland Mouret',
  'Rombaut',
  'Ronny Kobo',
  'Rosantica',
  'Roseanna',
  'Rosetta Getty',
  'Rosie Assoulin',
  'Rossignol',
  'Rota',
  'Rowen Rose',
  'Rundholz',
  'Rupert Sanderson',
  'Rus',
  'Róhe',
  'S.S.DALEY',
  'S.W.O.R.D 6.6.44',
  'SA SU PHI',
  'SABLYN',
  'SAGE NATION',
  'SAINT MXXXXXX',
  'SAMSOE SAMSOE',
  'SANDRO',
  'SANTHA',
  'SAPIO',
  'SARTORIO NAPOLI',
  'SAULINA',
  'SERPUI',
  'SHATHA ESSA',
  'SHIATZY CHEN',
  'SHUSHU/TONG',
  'SIEDRES',
  'SIR.',
  'SLVRLAKE',
  'SMFK',
  'SONGZIO',
  'SPANX',
  'SPORT b. by agnès b.',
  'SRVC Studio',
  'STADIUM GOODS®',
  'STAND STUDIO',
  'STAUD',
  'STORY mfg.',
  'STUDIO TOMBOY',
  'STYLAND',
  'SUICOKE FUROSHIKI',
  'SWEAR',
  'Sabina Musayev',
  'Sachin & Babi',
  'Safiyaa',
  'Saiid Kobeisy',
  'Saint Laurent',
  'Saint Laurent Pre-Owned',
  'Salomon',
  'Saloni',
  'Salvatore Santoro',
  'Samantha Sung',
  'Santa Brands',
  'Santoni',
  'Sara Roka',
  'Sarah Chofakian',
  'Sartore',
  'Sartoria Tramarossa',
  'Satta',
  'Saucony',
  'Saul Nash',
  'Save The Duck',
  'Savette',
  'Scarosso',
  'Schutz',
  'Scotch & Soda',
  'Sea',
  'Sease',
  'Sebago',
  'See by Chloé',
  'Seen Users',
  'Self-Portrait',
  'Semicouture',
  'Senso',
  'Serapian',
  'Sergio Rossi',
  'Sergio Tacchini',
  'Seventy',
  'Shanghai Tang',
  'Shanshan Ruan',
  'Shinola',
  'Shona Joy',
  'Shoreditch Ski Club',
  'Si Rossi',
  'Silvano Sassetti',
  'Silvia Tcherassi',
  'Simkhai',
  'Simon Miller',
  'Simone Rocha',
  'Simonetta Ravizza',
  'Sinead O\'Dwyer',
  'Skall Studio',
  'Sleeper',
  'Smythe',
  'Snow Peak',
  'Société Anonyme',
  'Soeur',
  'Sofie D\'hoore',
  'Solace London',
  'Song For The Mute',
  'Sonia Rykiel',
  'Sonora',
  'Sophia Webster',
  'Souliers Martinez',
  'Soulland',
  'Spalwart',
  'Spencer Badu',
  'Spoonyard',
  'Sportmax',
  'Sporty & Rich',
  'Sprwmn',
  'Ssheena',
  'St. Agni',
  'St. John',
  'Stain Shade',
  'Stefan Cooke',
  'Stefano Mortari',
  'Stefano Ricci',
  'Stella McCartney',
  'Stella McCartney Kids',
  'Stella Nova',
  'Stine Goya',
  'Stockholm Surfboard Club',
  'Stone Island',
  'Stouls',
  'Strathberry',
  'Stuart Weitzman',
  'Studio Amelia',
  'Studio Nicholson',
  'Stüssy',
  'Suicoke',
  'Sun 68',
  'Sunflower',
  'Sunnei',
  'Sunspel',
  'Superga',
  'Supreme',
  'Suzusan',
  'Sylvie Schimmel',
  'System',
  'Séfr',
  'TAMMY & BENJAMIN',
  'TASCHEN',
  'TEAM WANG design',
  'TEKLA',
  'TELA GENOVA',
  'THE ANDAMANE',
  'THE GIVING MOVEMENT',
  'TOM FORD',
  'TOTEME',
  'TOVE',
  'TTSWTRS',
  'TU LIZE\'',
  'TWINSET',
  'TWP',
  'Tadashi Shoji',
  'Tagliatore',
  'Takahiromiyashita The Soloist',
  'Talbot Runhof',
  'Taller Marmo',
  'Tanya Taylor',
  'Tatras',
  'Ted Baker',
  'Teddy Cashmere',
  'Tela',
  'Ten C',
  'The Animals Observatory',
  'The Attico',
  'The Elder Statesman',
  'The Frankie Shop',
  'The Garment',
  'The Gigi',
  'The Mannei',
  'The New Arrivals Ilkyaz Ozel',
  'The North Face',
  'The North Face Kids',
  'The Row',
  'The Upside',
  'The Vampire\'s Wife',
  'The Viridi-Anne',
  'Themoirè',
  'Theory',
  'There Was One',
  'Thom Browne',
  'Thom Krom',
  'Throwback.',
  'Tibi',
  'Timberland',
  'Tintoria Mattei',
  'Tod\'s',
  'Toga',
  'Toga Pulla',
  'Toga Virilis',
  'Tommy Hilfiger',
  'Tommy Jeans',
  'Tonello',
  'Toogood',
  'Tory Burch',
  'Toteme (VIP)',
  'Traiano Milano',
  'Transit',
  'Tricker\'s',
  'True Religion',
  'Trussardi',
  'Tsatsas',
  'Twenty Montreal',
  'UGG',
  'Ulla Johnson',
  'Uma Wang',
  'Under Armour',
  'Undercover',
  'United Nude',
  'Universal Works',
  'Unreal Fur',
  'Urbancode',
  'V:PM ATELIER',
  'VEJA',
  'VETEMENTS',
  'VITELLI',
  'VOZ',
  'VTMNTS',
  'Valentino Garavani',
  'Valextra',
  'Valstar',
  'Vanina',
  'Vans',
  'Vaquera',
  'Varley',
  'VeeCollective',
  'Veilance',
  'Veronica Beard',
  'Versace',
  'Versace Jeans Couture',
  'Versace Pre-Owned',
  'Via Roma 15',
  'Vic Matie',
  'Victoria Beckham',
  'Viktor & Rolf',
  'Vilebrequin',
  'Vince',
  'Virón',
  'Vision Of Super',
  'Vita Kin',
  'Vivetta',
  'Vivienne Westwood',
  'Volta',
  'V°73',
  'WARDROBE.NYC',
  'WESTFALL',
  'WOERA',
  'WTAPS',
  'Wales Bonner',
  'Walter Van Beirendonck',
  'Wandler',
  'Warm-Me',
  'We Are Kindred',
  'We11done',
  'Weinsanto',
  'White Mountaineering',
  'White:Space',
  'Who Decides War',
  'Wild Cashmere',
  'Windsor',
  'Winnie NY',
  'Wolford',
  'Wood Wood',
  'Woolrich',
  'Wooyoungmi',
  'Xacus',
  'Ximon Lee',
  'Y\'s',
  'Y-3',
  'Y/Project',
  'YANYAN KNITS',
  'YASSMIN SALEH',
  'YMC',
  'YSL',
  'YUME YUME',
  'Yohji Yamamoto',
  'Yoshiokubo',
  'Youths In Balaclava',
  'Yuiki Shimoji',
  'Yuzefi',
  'Yves Salomon',
  'Z Zegna',
  'ZEYNEP ARCAY',
  'ZIMMERMANN',
  'ZSIGMOND',
  'ZZERO BY SONGZIO',
  'Zac Zac Posen',
  'Zadig&Voltaire',
  'Zanellato',
  'Zanone',
  ' egna',
  'Zeus+Dione',
  'Ziggy Chen',
  'Zuhair Murad',
  '_J.L-A.L_',
  'adidas',
  'adidas Golf',
  'adidas Yeezy',
  'adidas by Stella McCartney',
  'agnès b.',
  'alessandro enriquez',
  'alice + olivia',
  'and Wander',
  'arch4',
  'b+ab',
  'birgitte herskind',
  'byTiMo',
  'casa raki',
  'colville',
  'costume national contemporary',
  'extreme cashmere',
  'hrh',
  'izzue',
  'kujten',
  'la milanesa',
  'lululemon',
  'marina yee',
  'mfpen',
  'norda',
  'rag & bone',
  'sacai',
  'sulvam',
  'tout a coup',
  'visvim'
]

export const tagNames = [
  'color',
  'multicolor',
  'pattern',
  'style',
  'texture',
  'surface_styling',
  'fabric',
  'neckline',
  'collar',
  'sleeve_style',
  'sleeve_length',
  'fit',
  'shape',
  'length',
  'back',
  'pants_fit',
  'rise_type',
  'cuff_hem_style',
  'hemline',
  'shoulder_style',
  'trim_and_edges',
  'transparency',
  'effect',
  'slits',
  'bag_shape',
  'bag_handle',
  'footwear_style',
  'toe_shape',
  'coord_item',
  'closure_type',
  'waistband',
  'stitching_details',
  'lapel_design',
  'feature',
  'shoulder_strap',
  'fastening_type',
]
