import axios from "axios";
import { useEffect, useState } from "react"
import ModalImage from "react-modal-image";
import { useSelector } from "react-redux";
import Loadingwrapper from "../../component/LoadingWrapper"
import { indexedPostUrl } from "../../utility/helper_functions";
import ImageTagsDetails from "./ImageTagsDetails";


const SocialTrendsTagsImages = ({ selectedTag, gender, searchParams }) => {

  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageCount, setImageCount] = useState(1);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const { userLoggedIn } = useSelector(state => state)
  const getImages = async () => {
    if (!selectedTag || !selectedTag.tag) {
      return
    }
    setLoading(true)
    const resp = await axios.get(`/social-trends-v2-api/${searchParams.get('trends') == 'retailer' ? 'retailer-' : ''}images?gender=${gender || 'Female'}&tag=${selectedTag.tag ? selectedTag.tag.split('#').join('%23').split('&').join('%26') : ''}&value=${selectedTag.value ? selectedTag.value.split('#').join('%23').split('&').join('%26') : ''}&category=${(searchParams.get('category') || 'Dresses').split('&').join('%26')}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&page=${page || 1}&limit=${limit}`)
    setLoading(false)
    setImageCount(resp.data.count)
    setImageList(resp.data.images)
  }

  useEffect(() => {
    getImages()
  }, [page])

  return selectedTag == null ? null : <div className="w-[80vw] h-[80vh] ">
    <h2 className="text-center">{selectedTag.value} ({selectedTag.tag}) images</h2>
    <div className="flex justify-end"><div>
      <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-400 px-4 py-1 rounded-xl text-sm text-white "
        disabled={page == 1}
        onClick={() => {
          setPage(prev => {
            if (prev > 1) {
              return prev - 1
            }
            return 1
          })
        }}
      >prev</button>
      <span>{page}/{Math.ceil(imageCount / limit)} page</span>
      <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-400 px-4 py-1 rounded-xl text-sm text-white "
        disabled={page * limit >= imageCount}
        onClick={() => {
          setPage(prev => {
            if (prev * limit >= imageCount) {
              return prev
            }
            return prev + 1
          })
        }}
      >next</button>
    </div></div>
    <Loadingwrapper loading={loading}>
      {imageList.length > 0 ? <div className="flex flex-wrap ">
        {imageList.map(img => <div key={img.mediaId} className="p-1 min-w-fit mb-4">
          <ModalImage src={img.imageUrl} className=' h-64 my-1' small={img.imageUrl} large={img.imageUrl} hideZoom={false} hideDownload={true} />
          <a
            href={indexedPostUrl(img.postUrl, img.mediaId)}
            target='_blank'
            className="text-sm text-blue-500 hover:text-blue-400 hover:underline">{img.username}</a>
          <div>{img.timeStamp ? new Date(img.timeStamp).toLocaleDateString() : ''}</div>
          {searchParams.get('trends') == 'social' && userLoggedIn.curator && <a href={`/curator-meta-tags/${img.mediaId}`} className='text-white bg-slate-800 rounded px-2' target='_blank'>Data</a>}
          {searchParams.get('trends') == 'social' ? <ImageTagsDetails mediaId={img.mediaId} /> : null}
        </div>)}

      </div> : <div className="w-full p-40">
        <p className="text-center">No Products Found</p>
      </div>

      }

    </Loadingwrapper>

  </div >
}

export default SocialTrendsTagsImages
