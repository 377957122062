import React from 'react'
import './popup.css'

const Popup = ({ isPopUp, setIsPopUp, children, isButton, buttonFunction, popupfull = false, className = '' }) => {
  return (
    <div>
      {
        isPopUp && <div className='popup ' onClick={() => setIsPopUp(false)}>
          <div className={`popup-inner ${popupfull ? 'w-full h-full' : ''} ${className}`} onClick={(e) => e.stopPropagation()}>
            <div className={`flex justify-end items-center ${popupfull ? 'mb-4' : ''}`}>
              {
                isButton && <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-3 mx-2 rounded-3xl' onClick={buttonFunction}>Merge</button>
              }
              <p onClick={() => setIsPopUp(false)} className='cursor-pointer text-2xl ml-4 font-bold text-red-500'>X</p>
            </div>
            <div className={`${popupfull ? 'w-screen h-screen' : 'scrollable'}`}>
              {children}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Popup
