import axios from 'axios'
import { useEffect, useState } from 'react'
import ModalImage from 'react-modal-image'
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ProductsPopup = ({ gender, category, tagName, tagValue, rank, brandsString, month, filteredTagsUrl }) => {
  const [page, setPage] = useState(1)
  const limit = 50
  const [products, setProducts] = useState([])
  const [count, setCount] = useState(1)
  const [imageListModal, setImageListModal] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const getProducts = () => {
    setProducts([])
    axios.get(`/retailer-api/designer-feature-products?gender=${gender}&category=${category}&page=${page}&limit=${limit}&tagName=${tagName}&tagValue=${tagValue}&rank=${rank}${brandsString}&month=${month}${filteredTagsUrl}`).then(response => {
      setProducts(response.data.products);
      setCount(response.data.count);
    })
  }

  const setImagesForProductId = (productEvent) => {
    const productId = productEvent.target.id
    const product = products.filter(prod => prod._id === productId)[0]
    if (!product)
      return;
    const images = product.image_url_list ? product.image_url_list.slice(1).filter(c => c) : []
    setImageListModal([product.image_url, ...images])
    setPhotoIndex(0)
  }


  useEffect(getProducts, [page])
  return <div className='w-[80vw] h-[80vh] flex flex-col'>
    <h3 className='text-center text-2xl font-medium'>{tagName} - {tagValue} products</h3>
    <div className="flex flex-row sticky bg-white  top-0 w-full flex-wrap ">
      <div className="px-6 w-3/4 shrink-0">

      </div>
      <div className="ml-auto pr-6 w-1/4 shrink-0">
        <button className="primary-btn-padding" onClick={() => setPage(p => p - 1)} disabled={page <= 1}>Prev</button>
        {count} Products
        <button className="primary-btn-padding" onClick={() => setPage(p => p + 1)} disabled={page >= count / limit}>Next</button>
      </div>
    </div>

    <div className="flex flex-row bg-white  top-0 w-full flex-wrap overflow-y-auto ">
      {products.map((prod, i) => {
        return <div key={i} className="align-bottom w-60 shrink-0 h-96 py-4 mx-1 hover:shadow" >
          <div className='h-3/5 w-full'>
            <img className='h-[13rem]' src={prod.image_url} onClick={setImagesForProductId} id={prod._id} />
          </div>
          <p className="text-sm">Recency - {prod.rank}</p>
          <p className="font-bold">{prod.brand}</p>
          <p>{prod.title}</p>
        </div>
      })}
    </div>
    <Lightbox
      open={imageListModal.length > 0}
      close={() => { setImageListModal([]) }}
      slides={imageListModal.map(img => ({ src: img }))}
      styles={{ container: { backgroundColor: "rgba(0, 0, 0, .7)" } }}
      plugins={[Thumbnails]}
    />
  </div>

}

export default ProductsPopup;
