import React, { Suspense, useState } from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Blocks } from 'react-loader-spinner'

const TrendImage = ({ product }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  const onImageLoaded = () => {
    setImageLoaded(true)
  }
  return (
    <div className='mr-10 max-[500px]:mr-1 w-60 text-center border-2 border-slate-50 flex-shrink-0'>
      <div className='flex mb-4 flex-col items-end min-h-80' style={{ minHeight: '19.5rem' }}>
        <div className="relative">
          <img className='-mb-8 h-80'
            src={product.image_url}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={onImageLoaded}
            onError={(err) => { console.log('error', err) }}
            alt="name"
          />
          {
            !imageLoaded && <div className='mx-5 h-80 w-52 flex items-center justify-center'>
              <Blocks
                visible={true}
                height="50"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            </div>
          }
        </div>
      </div>
      <a className='mx-2 flex flex-col hover:cursor-pointer' href={product.product_url} target="_blank" rel="noopener noreferrer">
          <div className='flex items-center font-semibold'>{product.ecommerce}</div>
          <div className='flex items-center w-full'>{product.description.slice(0,50)+(product.description && product.description.length>50?'...':'')}</div>
          <div className='flex items-center mt-4 align-bottom'>Price : {product.price}</div>
          {product.rank && <div className='flex items-center'>Category Page Rank : {product.rank}</div>}
      </a>
    </div>
  )
}

export default TrendImage