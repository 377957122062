import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ProductsGrid from "./ProductsGrid";


const ProductsPopup = ({gender, category, urlText , foreignEcommerce=false})=>{

	const [response, setResponse] = useState(false)
	const [apiResponse, setApiResponse] = useState({})


	const getProducts = async ()=>{
		setResponse(false);
		axios.post('/products/product-search',{
			urlText, 
			gender,
			foreignEcommerce
		}).then(response => {
			setApiResponse(response);
		}).catch(console.error)
		.finally(()=>{
			setResponse(true);
		})
	}

	useEffect( ()=>{
		getProducts();
	},[])




	return (
		<ProductsGrid response={response} apiResponse={apiResponse} category={category}/>
	)
}

export default ProductsPopup