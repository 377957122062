import axios from "axios";
import { useEffect, useState } from "react";
import TrendSet from "./TrendSet";

const AllTrendSet = ({ queryData, presentMediaIds, selectedImages, mergeTrend }) => {
  const [page, setPage] = useState(1)
  const [trends, setTrends] = useState([])
  const [searchText, setSearchText] = useState('')
  const getTrends = () => {
    setTrends([])
    if (!queryData.gender || !queryData.searchedCategory)
      return
    axios.get(`/trend/query-category-trends?page=${page}&category=${queryData.searchedCategory.split('&').join('%26')}&gender=${queryData.gender}&search=${searchText}`).then(response => {
      setTrends(response.data.trends)
    })
  }


  useEffect(getTrends, [page, queryData.searchedCategory, queryData.gender, searchText])


  return (<div className=" w-full">
    <div className="w-full sticky top-2 bg-slate-100">
      <div className=" w-1/3 float-left">
        <input id="textbox-trendsearch" className='bg-slate-200 rounded px-4 py-1 mx-1' />
        <button onClick={() => { const text = document.querySelector('#textbox-trendsearch').value; setPage(1); setSearchText(text); }} className='primary-btn mr-3'>Search</button>


      </div>

      <div className=" w-1/3 float-right ">
        <button onClick={() => setPage(page => page - 1)} disabled={page === 1} className='primary-btn mr-3'>Prev</button>{page}<button onClick={() => { setPage(page => page + 1) }} className='primary-btn ml-3'>Next</button>
      </div>
    </div>
    <div className="mt-6 w-full">
      <TrendSet trends={trends} presentMediaIds={presentMediaIds} mergeTrend={mergeTrend} />
    </div>
  </div>)
}
export default AllTrendSet
