import React, { Suspense, useState } from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Blocks } from 'react-loader-spinner'
import SimilarProductsPopup from './SimilarProductspopup'

const TrendImage = ({ image, selectedImage, socialTrendId, setSelectedImage, uVal, setFetch, category, gender }) => {

  const { userLoggedIn } = useSelector(state => state)
  const [imageSaved, setImageSaved] = useState(image.isSaved)
  const [imageLoaded, setImageLoaded] = useState(false)

  const updateSavedData = async (type, dataId) => {
    try {
      if (!imageSaved)
        axios.put(`/save/${type}/${dataId}`)
      else
        axios.delete(`/save/${type}/${dataId}`)
      setImageSaved(!imageSaved)
    } catch (e) {
      console.error(e)
    }
  }

  const updateDeleteImage = () => {
    const deleted = !image.deleted == true;
    axios.post(`/design-api/delete-image/${socialTrendId}/${uVal}/${image.mediaId}`, { deleted }).then(() => {
      setFetch(true)
    })
  }


  return (
    <div className='mr-6 max-[500px]:mr-1 min-w-max text-center'>
      <div className='flex mb-2 flex-col items-end min-h-80' style={{ minHeight: '15.5rem' }}>
        <div className="relative">
          <img className='-mb-8 h-64'
            src={image.imageUrl}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={() => { setImageLoaded(true) }}
            onError={(err) => { console.log('error', err) }}
            alt="name"
          />
          {
            !imageLoaded && <div className='mx-5 h-64 w-40 flex items-center justify-center'>
              <Blocks
                visible={true}
                height="40"
                width="30"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            </div>
          }
          {userLoggedIn.curator &&
            <button className={`absolute top-1 left-1 px-2 py-1 text-white bg-blue-500 rounded-xl ${image.deleted ? 'bg-slate-500 hover:bg-slate-600' : 'bg-red-400 hover:bg-red-500'}`} onClick={updateDeleteImage}>{image.deleted ? 'Restore' : 'Delete'}</button>
          }
          {!userLoggedIn.curator &&
            <button className="absolute top-1 right-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { updateSavedData('image', image.mediaId) }}>{imageSaved ? 'Remove' : 'Save'}</button>
          }
          {/* {gender == "f" && <button className="absolute top-1 left-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { queryProductsForImage(image.mediaId, image.imageUrl) }}>Find Similar</button>} */}
        </div>

      </div>
      {<a className='mx-2 flex justify-evenly items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
        <div>
          <div className='flex items-center'>
            {parseInt(image.noOfLikes) <= 3 ? 'Hidden' : image.noOfLikes}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span>
          </div>
        </div>
        <div>
          <div className='flex items-center'>
            {image.username}<span className='text-xl'></span>
          </div>
        </div>
        <div>
          {/* <div className='flex items-center'>
			{image.noOfComments}<span className='text-xl'><BiCommentDots /></span>
		</div> */}
        </div>
      </a>}
      {image.timeStamp && <p>{moment(image.timeStamp).format("DD MMM YYYY")}</p>}
      {image.likes && <p>({image.likes}) Likes</p>}
      {
        userLoggedIn.curator &&
        <>
          <p>{image.score}%</p>
          <p>{image.isTwoWay ? "Two Way" : "One Way"}</p>
          <p>{image.primary ? "Primary" : "Secondary"}</p>
        </>
      }
    </div>
  )
}

export default TrendImage
