import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import LoadingWrapper from "../../component/LoadingWrapper";
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";
import Popup from "../../component/Popup";
import DeleteCollectionName from "./DeleteCollection";
import UpdateCollectionName from "./UpdateCollectionName";
import Swal from "sweetalert2";

const SavedImages = () => {

  const [collections, setCollections] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [socialImages, setSocialImages] = useState([])
  const [retailerImages, setRetailerImages] = useState([])
  const [totalImages, setTotalImages] = useState(1)
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const selectCollection = (e) => {
    if (!e.target.id) {
      return
    }
    setSearchParams(prev => {
      prev.set('currentCollection', e.target.id)
      return prev
    })
  }
  const getCollections = async (keepCurrent) => {
    const resp = await axios.get(`/api/saved-images/collections`)
    setCollections(resp.data.collections)
    setLoading(false)
    if (resp.data.collections && resp.data.collections[0]) {
      if (!keepCurrent) {
        setSearchParams(prev => {
          prev.set('currentCollection', resp.data.collections[0]._id)
          return prev
        })
      }
    }
    else {
      setSearchParams(prev => {
        prev.delete('currentCollection')
        return prev
      })
    }
  }

  const deleteImageFromCollection = async (image, collectionId, trendType) => {
    const body = {
      imageId: image._id,
      collectionId
    }
    body.delete = true
    await axios.post(`/api/saved-images/image/${trendType}`, body)
    getCollectionImages()
  }

  const getCollectionImages = async () => {
    if (!searchParams.get('currentCollection')) return
    setLoading(true)
    const resp = await axios.get(`/api/saved-images/images/${searchParams.get('currentCollection')}?page=${page}`)
    setLoading(false)
    setSocialImages(resp.data.socialimages)
    setRetailerImages(resp.data.retailerImages)
    setTotalImages(resp.data.count)
  }


  useEffect(() => {
    getCollections()
  }, [])

  useEffect(() => {
    getCollectionImages()
  }, [page, searchParams.get('currentCollection')])

  return <div className="flex ">
    <div className="w-40 relative h-screen bg-slate-600 flex flex-col justify-center overflow-y-auto shrink-0 ">
      {
        collections.map(coll => <button key={coll._id} className={`  py-2 px-2 text-[16px]  ${searchParams.get('currentCollection') == coll._id ? 'bg-white text-black font-semibold ' : 'text-white hover:bg-slate-600'} relative`} id={coll._id} onClick={selectCollection}>
          {coll.collectionName}
          {
            coll._id == searchParams.get('currentCollection') ? <>
              <div className="h-4 z-10 absolute w-4 right-0 top-full bg-white"></div>
              <div className="h-4 z-10 absolute w-4 right-0 bottom-full bg-white"></div>
              <div className="h-8 z-20 absolute w-8 right-0 top-full bg-slate-600 rounded-full"></div>
              <div className="h-8 z-20 absolute w-8 right-0 bottom-full bg-slate-600 rounded-full"></div>
              <div className="h-2 z-10 absolute w-2 left-0 top-0 bg-slate-600"></div>
              <div className="h-2 z-10 absolute w-2 left-0 bottom-0 bg-slate-600"></div>
              <div className="h-4 z-20 absolute w-4 left-0 top-0 bg-white rounded-full"></div>
              <div className="h-4 z-20 absolute w-4 left-0 bottom-0 bg-white rounded-full"></div>
            </> : null
          }
        </button>)
      }


    </div>

    <div className="w-full min-h-[80vh]">
      {searchParams.get('currentCollection') &&
        <>
          <h3 className="text-center text-xl my-3 ">{collections.filter(coll => coll._id == searchParams.get('currentCollection'))[0]?.collectionName} Collection Images </h3>
          <div className="flex justify-between px-10">
            <div>
              <button className="primary-btn mx-2 text-xs py-0" onClick={() => setShowUpdatePopup(true)}>Edit</button>
              <button className="primary-btn bg-red-600 hover:bg-red-700 text-xs py-0" onClick={() => setShowDeletePopup(true)}>Delete</button>
            </div>
            <div>
              <button className="primary-btn bg-slate-600 hover:bg-slate-700 text-xs py-0"
                onClick={() => {
                  window.navigator.clipboard.writeText(window.location.host + `/saved-images/${searchParams.get('currentCollection')}`)
                  Swal.fire({ timer: 1500, icon: 'success', title: 'Public URL for collection Copied!' })
                }}
              >Share Collection</button>
            </div>
          </div>
        </>
      }

      <LoadingWrapper loading={loading}>
        {collections.length > 0 ?
          <div className="flex w-full ">
            <div className="w-1/2 border-r h-full px-2">
              <h4 className="text-center font-medium text-xl">Social Images</h4>
              <div className="flex justify-center">
                <hr className="w-3/4" />
              </div>
              <div className="flex flex-wrap w-full ">
                {socialImages.map(img => <div key={img._id} className="w-1/3 px-1 border py-2">
                  <div className="flex justify-center">

                    <img src={img.imageUrl} className='h-40' />

                  </div>
                  <div className="px-2 text-sm">
                    <a href={`${img.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{img.username}</a>
                    <p>{new Date(img.timeStamp).toDateString()}</p>
                    <div></div>
                  </div>

                  <div className="flex text-sm my-2 justify-between px-2">
                    <ImageTagsDetails mediaId={img.mediaId} />

                    <button className="rounded border active:bg-slate-700 active:text-white px-2 " onClick={() => deleteImageFromCollection(img, searchParams.get('currentCollection'), 'social')}>Remove</button>
                  </div>


                </div>)}
              </div>
              <div className="flex w-full h-full py-40">
                {socialImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
              </div>

            </div>
            <div className="w-1/2 border-r h-full px-2">
              <h4 className="text-center font-medium text-xl">Retailer Images</h4>
              <div className="flex justify-center">
                <hr className="w-3/4" />
              </div>
              <div className="flex flex-wrap w-full">
                {retailerImages.map(img => <div key={img._id} className="w-1/3 px-1 my-2 border">
                  <div className="flex justify-center">

                    <img src={img.image_url} className='h-40' />
                  </div>
                  <div className="px-3">
                    <a href={`${img.product_url}`} className='text-blue-600 font-medium' target='_blank'>{img.retailer}</a>
                    <p>Brand : {img.brand}</p>
                  </div>

                  <div className="flex text-sm my-2 justify-between px-2">
                    <ImageTagsDetails mediaId={img.pk} isretailer={true} />

                    <button className="rounded border active:bg-slate-700 active:text-white px-2 " onClick={() => deleteImageFromCollection(img, searchParams.get('currentCollection'), 'retailer')}>Remove</button>
                  </div>

                </div>)}
              </div>
              <div className="flex w-full h-full py-40">
                {retailerImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
              </div>
            </div>
          </div> : <h3 className="text-center my-4 ">No Collections Created</h3>
        }

      </LoadingWrapper>

    </div>
    {showUpdatePopup && <Popup isPopUp={showUpdatePopup} setIsPopUp={setShowUpdatePopup}>
      <UpdateCollectionName collectionId={searchParams.get('currentCollection')} collectionName={collections.filter(coll => coll._id == searchParams.get('currentCollection'))[0]?.collectionName} getCollections={getCollections} setShowPopup={setShowUpdatePopup} />

    </Popup>}

    {showDeletePopup && <Popup isPopUp={showDeletePopup} setIsPopUp={setShowDeletePopup}>
      <DeleteCollectionName collectionId={searchParams.get('currentCollection')} collectionName={collections.filter(coll => coll._id == searchParams.get('currentCollection'))[0]?.collectionName} getCollections={getCollections} setShowPopup={setShowDeletePopup} />

    </Popup>}

  </div>
}

export default SavedImages
