import axios from 'axios';
import { useEffect, useState } from 'react';
import { updateSaveData } from '../Api/updateTrendSave';
import Trend from '../pages/Trend';




const SaveButton = ({ trendPk }) => {


  const [isSaved, setIsSaved] = useState(false)
  const [fetch, setFetch] = useState(true)

  const getSaved = () => {
    if (!trendPk)
      return;
    axios.get(`/save/trend/${trendPk}`).then(response => {
      setIsSaved(response.data)
    }).finally(() => {
      setFetch(false)
    })

  }

  useEffect(() => {
    getSaved()

  }, [trendPk, fetch])


  return <button onClick={() => updateSaveData(isSaved, 'trend', trendPk, setFetch)} className="primary-btn-padding">{isSaved ? "Remove Trend" : "Save Trend"}</button>

}


export default SaveButton;
