
const TrendSet = ({ trends, presentMediaIds, selectedImages, mergeTrend }) => {


  return (<div className="w-full h-[80vh] z-0 mt-[5vh]">
    <div className="flex flex-col flex-wrap w-full">
      {trends && trends.length > 0 &&
        trends.map(trend => <div className=''>
          <div className="flex my-2">
            <p className="mx-2">Name : {trend.name}</p>
            <p className="mx-2">Images : {trend.similarCategoryImages.length}</p>
            <button className="primary-btn mx-2 ml-4" onClick={() => mergeTrend(trend.pk)}>Merge with query</button>
            <a target='_blank' href={`/t/${trend._id}`} className='primary-btn'>Go to trend page</a>
          </div>
          <div className="flex flex-row w-[75vw] overflow-auto h-80">
            {
              trend.similarCategoryImages
                .sort((a, b) => {
                  if (presentMediaIds.includes(a.mediaId) && !presentMediaIds.includes(b.mediaId)) return -1;
                  if (!presentMediaIds.includes(a.mediaId) && presentMediaIds.includes(b.mediaId)) return 1;
                  return new Date(b.timeStamp) - new Date(a.timeStamp)
                }).map((img) => {
                  return <div className="mx-8 h-full shrink-0 ">
                    <div className="h-4/5">
                      <img src={img.imageUrl} className={`h-full ${presentMediaIds.includes(img.mediaId) ? 'border-red-500 border-8' : ''}`} />
                    </div>
                    <p>{new Date(img.timeStamp).toLocaleString()}</p>
                  </div>
                })

            }
          </div>
        </div>)

      }
    </div>
  </div>)
}
export default TrendSet
