import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaBookmark, FaPlus, FaRegBookmark } from 'react-icons/fa';
import Popup from '../../component/Popup';
const NewCollection = ({ show, setIsPopUp, fetchCollections, addImageToCollection, image, collections }) => {

  const [newCollectionName, setNewCollectionName] = useState('');
  const [loading, setLoading] = useState(false)
  const addCollection = async () => {
    const response = await axios.post('/api/saved-images/collection', { collection: newCollectionName });
    return response.data.collectionId;


  }
  const handleButtonClick = async () => {
    const collectionId = await addCollection();
    await addImageToCollection(image, collectionId)
    setNewCollectionName('')
    await fetchCollections()
    setIsPopUp(false)
  }


  return <Popup isPopUp={show} setIsPopUp={setIsPopUp} className='bg-slate-800 w-[25rem] px-0 text-white'>
    <h3 className='font-bold text-center pb-2'>New Collection</h3>
    <hr className='border-slate-200 border-[0.1px] h-[0.1px]' />
    <div className='flex justify-center px-10 pt-10'>
      <img src={image.imageUrl ? image.imageUrl : image.image_url} className='rounded-lg h-40' />
    </div>
    <div className='flex justify-center'>

      <input value={newCollectionName} onChange={e => setNewCollectionName(e.target.value)} className='bg-slate-500 font-normal text-white outline-none rounded px-4 py-1 border border-slate-200 my-2' placeholder='Collection Name' />
    </div>
    {collections.map(c => c.collectionName).includes(newCollectionName) ? <p className='text-red-400 font-semibold px-4 text-sm'>Collection {newCollectionName} already exists.</p> : null}
    <hr className='border-slate-200 border-[0.1px] h-[0.1px]' />
    <button className='w-full text-center font-semibold pt-2 disabled:text-slate-300' disabled={loading || !newCollectionName || collections.map(c => c.collectionName).includes(newCollectionName)} onClick={handleButtonClick} >Save</button>

  </Popup>

}

export default NewCollection
