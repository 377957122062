import React,{ useState } from 'react';


const Pagination = ({totalpages, currentPage, changePage,paginationDisabled})=>{

	return (
		<div className='static z-10 w-full overflow-auto mb-4'>
		  <div className='float-right'>
			<button className={`${currentPage==1?'bg-blue-400':'bg-blue-500'} px-5 py-2 text-white font-medium rounded-full`} disabled={currentPage==1 || paginationDisabled} onClick={()=>{ changePage(currentPage-1) }}>prev</button>
			<span className='m-3'>{currentPage+" of "+totalpages}</span>
			<button className={`${currentPage==totalpages?'bg-blue-400':'bg-blue-500'} px-5 py-2 text-white font-medium rounded-full`} disabled={currentPage==totalpages || paginationDisabled} onClick={()=>{ changePage(currentPage+1) }}>next</button>
		  </div>
		</div>
	)
}

export default Pagination;