import React, { Suspense, useEffect, useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrEdit } from 'react-icons/gr'
import axios from 'axios'
import Graph from './Graph'
import Popup from '../../component/Popup'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots, BiLink } from 'react-icons/bi'
import { MdRestore } from 'react-icons/md'
import { FaImages, FaLink } from 'react-icons/fa'
import OverLappingTrends from './OverLappingTrends'
import { useSelector } from 'react-redux'
import TrendImage from './TrendImage'
import SimilarProductsTrend from './SimilarProductsTrend'
import DesignTrends from './DesignTrends'
import TopCommentsPosts from '../../component/TopCommentsPosts'
import ProductIds from '../../component/ProductIds'
import AmazonProductsDisplay from '../../component/AmazonProductsDisplay'


const Trend = ({ trend, selectedImage, setSelectedImage, fetch, setFetch, setToMerge, toMerge, gender, forAmazon }) => {
  const { userLoggedIn } = useSelector(state => state)
  const [trendSaved, setTrendSaved] = useState(trend.isSaved)
  const location = window.location.pathname === '/deleted-trend'
  const deleteTrend = (id) => {
    const response = window.confirm(`Do You really want to delete trend with id ${id}`)
    if (response) {
      axios.delete(`/trend/${id}`)
        .then(res => {
          console.log(res.data)
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
      console.log("deleted trend with id", id);
    }
  }
  const restoreTrend = (id) => {
    const response = window.confirm(`Do You really want to restore trend with id ${id}`)
    if (response) {
      axios.post(`/trend/restore-trend/${id}`)
        .then(res => {
          console.log(res)
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const updateSavedData = async (type, dataId) => {
    try {
      if (!trendSaved)
        axios.put(`/save/${type}/${dataId}`)
      else
        axios.delete(`/save/${type}/${dataId}`)
      setTrendSaved(!trendSaved)
    } catch (e) {
      console.error(e)
    }
  }

  const duplicateTrend = (id) => {
    const response = window.confirm(`Do You really want to duplicate trend with id ${id}`)
    if (response) {
      axios.post(`/trend/duplicate/${id}`)
        .then(res => {
          console.log(res.data)
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const changeName = () => {
    if (!userLoggedIn.curator)
      return;
    const newName = prompt("Set new trend name", trend.name)
    if (newName && newName.trim() && newName !== trend.name) {
      axios.patch(`/trend/${trend.id}`, { nameToChange: newName })
        .then(res => {
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const handleChange = () => {
    setToMerge(prev => {
      if (isSelected) {
        const temp = prev.filter(trends => trends.id !== trend.id)
        return temp
      }
      else {
        return [...prev, trend]
      }
    })
    setIsSelected(!isSelected)
  }
  useEffect(() => {
    let ans = false
    for (let i = 0; i < toMerge.length; i++) {
      if (toMerge[i].id === trend.id) {
        ans = true;
        break
      }
    }
    setIsSelected(ans)
  }, [toMerge])
  const [isSelected, setIsSelected] = useState(false)
  const [showGraph, setShowGraph] = useState(false)
  const [graphType, setGraphType] = useState(0)
  const [showOverLaps, setShowOverlaps] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const [overLapingTrends, setOverLappingTrends] = useState([])
  const [overlappingSelectedTrend, setOverlappingSelectedTrend] = useState([])
  const [showCycleGraph, setShowCycleGraph] = useState(false)
  const [showPairItWith, setShowPairItWith] = useState(false)
  const [showPriceRange, setShowPriceRange] = useState(false)
  const [showDesignTrends, setShowDesignTrends] = useState(false)
  const [showTrendComments, setShowTrendComments] = useState(false)
  const [commentsPopupTrendImages, setCommentsPopupTrendImages] = useState([])
  const [showAmazonProductsPopup, setShowAmazonProductsPopup] = useState(false)
  const [showInsertProductIds, setshowInsertProductIds] = useState(false)
  const toogleGraph = () => {
    setShowGraph(!showGraph)
  }
  useEffect(() => {
    if (userLoggedIn.curator) {
      axios.get(`/trend/overlapping-trend/${trend.id}`)
        .then(res => {
          setOverLappingTrends(res.data)
          const t = res.data.map(e => false)
          setSelectedCount(0)
          setOverlappingSelectedTrend(t)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [fetch])
  const capitalizeEachWord = (mySentence) => {
    if (mySentence) {
      const words = mySentence.trim().split(" ").filter(word => word && word != "");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
      }
      const ans = words.join(" ");
      return ans
    }
    return ""
  }
  const handleGoingLive = () => {
    const response = window.confirm(`Do You really want to ${trend.reviewed ? 'remove live' : 'go live'} trend with id ${trend.id}`)
    if (response) {
      axios.patch(`/trend/reviewed/${trend.id}`, { reviewed: !trend.reviewed })
        .then(res => {
          console.log(res.data)
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const downloadTrendProductsData = async (pk) => {
    const products = await axios.get(`/products/amazon-saved-products/${pk}`)


    let csvString = 'Product Id,Product title,Product URL,Product Image URL,Brand,Price\n'
    for (let prod of products.data.sort((a, b) => b.price - a.price)) {
      csvString = csvString + `"${prod.ASIN}","${prod.title}","${prod.url}","${prod.thumbnailImage}","${prod.brand}","${prod.price || ''}"\n`
    }
    const link = document.createElement("a");
    const file = new Blob([csvString], { type: 'text/csv;encoding:utf-8' });
    link.href = URL.createObjectURL(file);
    link.download = `products_${trend.id}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  const handleShowTrendComments = () => {
    setCommentsPopupTrendImages(trend.images)
    setShowTrendComments(true)
  }

  return (
    <div className=' my-10'>
      <div className='flex justify-between items-center my-5'>
        <div className='flex items-center flex-wrap'>
          {userLoggedIn.curator && <input className='' type="checkbox" name="toMergeCheckBox" checked={isSelected} onChange={handleChange} />}
          {(userLoggedIn.curator || trend.name) &&
            <p className='flex justify-center items-center cursor-pointer mx-2' onClick={userLoggedIn.curator ? changeName : null}><span>Trend Name: </span><span className='ml-1 font-extrabold'>{capitalizeEachWord(trend.name)}</span> {userLoggedIn.curator && <span><GrEdit /></span>}</p>
          }
          <a href={`/t/${trend._id}`} target='_blank'>< FaLink />  </a>
          <p className='mx-2'>Category: <span className='font-bold'>{trend.category}</span></p>
          {
            !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={toogleGraph}>Timeline Graph<img className='h-7' src="./growth.png" alt="growth" /></p>
          }
          {
            // !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => setShowCycleGraph(true)}>Rate Graph<img className='h-7' src="./growth.png" alt="growth" /></p>
          }
          <p className='mx-2 flex items-center'>{trend.images.length} <span className='text-xl'> <FaImages /></span></p>
          {trend.totalLikes && trend.totalLikes > 1 && <p className='mx-2 flex items-center'>{trend.totalLikes} <span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></p>}
          {/* <p className='mx-2 flex items-center'>{trend.totalComments}<span className='text-xl'><BiCommentDots /></span></p> */}
          {
            trend.commentsData && trend.commentsData.weightage && trend.commentsData.weightage > 0 && <>
              <p className='mx-2 flex items-center'>Comments analyzed : <span className='font-bold'>{trend.commentsData.commentsProcessed}</span></p>
              <p className='mx-2 flex items-center'>Shortlisted : <span className='font-bold'>{trend.commentsData.commentsFiltered}</span></p>
              <p className='mx-2 flex items-center'>Trend chatter weightage : <span className='font-bold'>{trend.commentsData.weightage}</span></p>
              <p className='mx-2 flex items-center'>Normalized chatter weightage : <span className='font-bold'>{trend.commentsData.avarageWeightage}</span></p>
            </>
          }
          {userLoggedIn.curator && !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => { deleteTrend(trend.id) }}>Delete Trend <span className=' text-red-700'><RiDeleteBinLine /></span></p>}
          {userLoggedIn.curator && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => { setShowOverlaps(true) }}><span>({overLapingTrends.length - 1 >= 0 ? overLapingTrends.length - 1 : 0})Overlapping Trends</span> <img src="overlapping-circles.png" className=' h-6' alt="overlapping" /> </p>}
          {userLoggedIn.curator && <p className='flex justify-center items-center cursor-pointer mx-2 br-5 px-2' onClick={() => { duplicateTrend(trend.id) }}><span>Duplicate Trend</span> </p>}
          {userLoggedIn.curator && <p className='mx-2'>Trend Id: {trend.id}</p>}
          {/*          <button className='mx-1 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => setShowPairItWith(true)}>Pair-it-with Beta</button> */}
          <button className='mx-1 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white flex items-center disabled:bg-blue-400' onClick={() => setShowPriceRange(true)} disabled={!trend.similarProductsAvailable}>Price Range & Similar Products</button>
          {false && <button className='mx-1 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => setShowDesignTrends(true)}>Popular Design Hints</button>}
          {/*          <button className={`mx-1 ${trend.apifyProductsCount > 0 ? "bg-yellow-500 hover:bg-yellow-600" : "bg-slate-500"} px-3 py-1 rounded-xl text-white flex items-center`} onClick={e => { if (trend.apifyProductsCount > 0) setShowAmazonProductsPopup(true) }}  >View Amazon Products ({trend.apifyProductsCount || 0})</button>
          <button className={`mx-1 ${trend.apifyProductsCount > 0 ? "bg-yellow-500 hover:bg-yellow-600" : "bg-slate-500"}  px-3 py-1 rounded-xl text-white flex items-center`} onClick={e => { downloadTrendProductsData(trend.id) }} disabled={!trend.apifyProductsCount > 0} >Download Amazon Products ({trend.apifyProductsCount || 0})</button>
          */}

          {
            !location && <button className='mx-4 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white' onClick={() => { updateSavedData('trend', trend.id) }}>{trendSaved ? 'Remove' : 'Save'}</button>
          }
          {
            location && <button className='mx-4 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => { restoreTrend(trend.id) }}> <span>Restore Trend</span> <MdRestore /></button>
          }
          {
            userLoggedIn.curator && <button className={trend.reviewed ? 'mx-1 bg-yellow-500 px-3 py-1 rounded-xl text-white flex items-center' : "'mx-1 bg-green-500 px-3 py-1 rounded-xl text-white flex items-center'"} onClick={handleGoingLive}>{trend.reviewed ? 'Go Review' : 'Go Live'}</button>
          }

          {
            // userLoggedIn.curator && 
            <button className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={handleShowTrendComments}>Top Comments</button>
          }
          {
            userLoggedIn.curator && <button className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => setshowInsertProductIds(true)}>Add Products</button>
          }
        </div>
      </div>
      <Popup isPopUp={showOverLaps} setIsPopUp={setShowOverlaps}>
        <OverLappingTrends pk={trend.id} fetch={fetch} setFetch={setFetch} selectedTrend={overlappingSelectedTrend} setSelectedCount={setSelectedCount} setSelectedTrend={setOverlappingSelectedTrend} selectedCount={selectedCount} overLapingTrends={overLapingTrends} />
      </Popup>
      <Popup isPopUp={showGraph} setIsPopUp={setShowGraph}>
        <Graph graphData={graphType === 0 ? trend.graphData : trend.nonCumGraphType} graphType={graphType} setGraphType={setGraphType} showDates={trend.showDates} />
      </Popup>
      <Popup isPopUp={showCycleGraph} setIsPopUp={setShowCycleGraph}>
        <Graph graphData={trend.cycleGraph} showDates={trend.showDates} />
      </Popup>
      <Popup isPopUp={showDesignTrends} setIsPopUp={setShowDesignTrends}>
        <DesignTrends trend={trend} gender={gender} category={trend.category} />
      </Popup>
      <Popup isPopUp={showPairItWith} setIsPopUp={setShowPairItWith}>
        <SimilarProductsTrend trend={trend} forPairItWith={true} />
      </Popup>
      <Popup isPopUp={showPriceRange} setIsPopUp={setShowPriceRange}>
        <SimilarProductsTrend trend={trend} forPairItWith={false} />
      </Popup>
      <Popup isPopUp={showTrendComments} setIsPopUp={setShowTrendComments}>
        <TopCommentsPosts images={commentsPopupTrendImages} category={trend.category} />
      </Popup>
      <Popup isPopUp={showInsertProductIds} setIsPopUp={setshowInsertProductIds}>
        <ProductIds trend={trend} />
      </Popup>
      <Popup isPopUp={showAmazonProductsPopup} setIsPopUp={setShowAmazonProductsPopup} >
        <AmazonProductsDisplay pk={trend.id} downloadTrendProductsData={downloadTrendProductsData} ecommerce="Amazon" />
      </Popup>
      <div className='flex overflow-auto'>
        {
          trend.images.map((image, index) => <TrendImage selectedImage={selectedImage} key={index} setSelectedImage={setSelectedImage} setFetch={setFetch} uVal={trend.id} image={image} category={trend.category} gender={gender} />)
        }
      </div>
    </div>
  )
}

export default Trend
