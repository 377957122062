
import axios from "axios";
import { useEffect, useState } from "react";
const CuratorTagsUpdate = ({ trendId, setParentFetch, charts }) => {

  const [loading, setLoading] = useState(true);
  const [retailerTagsData, setretailerTagsData] = useState(null)
  const [fetch, setFetch] = useState(true)
  const [hidetagValue, setHideTagValue] = useState('')
  const [selecttag, setSelectTag] = useState({ name: '', value: '' })
  const getData = () => {
    if (!trendId || !charts)
      return
    axios.get(`/trend-api/list-current-retailer-tags/${trendId}`).then(res => {
      setretailerTagsData(res.data.retailerTagsData)
      setFetch(false)
    })
  }

  const updateTags = (type) => {

    const body = {}
    if (type === 'hide') {

      body['type'] = 'hide tag'
      body['tagName'] = hidetagValue
    } else {

      body['type'] = 'add tag'
      body['tagName'] = selecttag.name
      body['value'] = selecttag.value
    }
    axios.post(`/trend-api/update-associated-retailer-data/${trendId}`, body).then(() => {
      setFetch(true)
      setParentFetch(true)
      setHideTagValue('')
      setSelectTag({ name: '', value: '' })
    })
  }

  const unselectTags = (tagName, tagValue) => {

    axios.delete(`/trend-api/delete-associated-tag-data/${trendId}?tagName=${tagName}&tagValue=${tagValue}`).then(() => {
      setFetch(true)
      setParentFetch(true)
    })
  }


  const unselectTagsName = (tagName) => {
    axios.delete(`/trend-api/delete-associated-tag-name/${trendId}?tagName=${tagName}`).then(() => {
      setFetch(true)
      setParentFetch(true)
    })
  }

  useEffect(getData, [trendId, fetch])
  return <div className="w-full">
    <div className='rounded border py-10 '>
      <h3 className='text-center text-lg'>Select Tags for products(Curator)</h3>
      {retailerTagsData &&
        <div className='flex flex-row' >
          <div className='w-1/2 p-10 bg-slate-100'>
            <h4 className=' mb-4 text-center text-xl font-semibold'>Hidden Tags</h4>
            <ul className='px-10'>
              {retailerTagsData.hiddenTags &&
                Object.keys(retailerTagsData.hiddenTags).map((tag, i) => <li key={tag}>{i + 1}. {tag} <button className='bg-red-600 text-white px-2 m-1 rounded ' onClick={() => { unselectTags(tag) }}>Unselect</button></li>)
              }
            </ul>
            <hr className='w-2/3 my-3 border-2 border-slate-600' />
            <div>
              Hide Tags

              <br />
              <select value={hidetagValue} onChange={(e) => { setHideTagValue(e.target.value) }}>
                <option value='' disabled={true}>Select tag to hide</option>
                {charts.map(tagName => <option value={tagName.tag}>{tagName.tag}</option>)}
              </select>
              <br />
              <button className='primary-btn' onClick={() => updateTags('hide')}>Hide Tag</button>
            </div>
          </div>
          <div className='w-1/2 p-10'>
            <h4 className=' mb-4 text-center text-xl font-semibold'>Used Tags</h4>
            <ul>
              {retailerTagsData.selectedTags &&
                Object.keys(retailerTagsData.selectedTags).map((tag, i) => <li key={tag}>
                  {i + 1}. {tag} : <button className='bg-red-400 text-white px-2 m-1 rounded ' onClick={() => { unselectTagsName(tag) }}>Unselect Tag</button>
                  {retailerTagsData.selectedTags[tag].map((val, i) => <div key={val} className='pl-8'>
                    {val}<button className='bg-red-600 text-white px-2 m-1 rounded ' onClick={() => { unselectTags(tag, val) }}>Unselect</button>
                  </div>)}
                </li>)
              }
            </ul>
            <hr className='w-2/3 my-3 border-2 border-slate-600' />
            <div>
              Select Tags
              <br />
              <select value={selecttag.name} onChange={(e) => { setSelectTag({ name: e.target.value, value: '' }) }}>
                <option value='' disabled={true}>Select tag name</option>
                {charts.map(tagName => <option value={tagName.tag}>{tagName.tag}</option>)}
              </select>
              <br />
              {selecttag && selecttag.name &&
                <select value={selecttag.value} onChange={(e) => { setSelectTag(prev => { const data = { ...prev }; data.value = e.target.value; return data }) }}>
                  <option value='' disabled={true}>Select Value</option>
                  {charts.filter((item) => item.tag == selecttag.name)[0].pieChart.map(tagName => <option value={tagName.name}>{tagName.name}</option>)}
                </select>
              }
              <br />
              <button className='primary-btn' onClick={() => updateTags('select')}>Select Tag</button>
            </div>
          </div>
        </div>}
    </div>
  </div >
}

export default CuratorTagsUpdate 
