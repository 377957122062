import axios from "axios";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Piechart from "../ProductsAnalysis/Piechart.jsx";
import CuratorTagsUpdate from './CuratorTagsUpdate'
import ProductsPriceGraph from '../ProductsAnalysis/ProductsPriceGraph.jsx'
import Popup from "../../component/Popup.jsx";
import ProductsPopup from "../ProductsAnalysis/ProductsPopup.jsx";
const RetailerTagsPopup = ({ trend }) => {

  const [loading, setLoading] = useState(true);
  const { userLoggedIn } = useSelector(state => state)
  const [charts, setCharts] = useState([])
  const [fetch, setFetch] = useState(true)
  const [productPrices, setProductPrices] = useState([])
  const [showProductsPopup, setShowProductsPopup] = useState(false);
  const [selectedTagData, setSelectedTagData] = useState(null);
  const getData = () => {
    if (!trend || !fetch)
      return

    axios.get(`retailer-analysis-api/trend-charts/${trend._id}`)
      .then(res => {
        setCharts(res.data.charts)
        setProductPrices(res.data.productsPrices)
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      }).finally(() => {
        setFetch(false)
      })
  }

  const handleProducts = (showPopup, tagName, tagValue) => {
    if (!showPopup) {
      setShowProductsPopup(false);
      setSelectedTagData(null);
    } else {
      setShowProductsPopup(true)
      setSelectedTagData({ tagName, tagValue })
    }
  }

  useEffect(getData, [trend, fetch])
  return <div className="h-[80vh] w-[80vw]">
    <h2 className='text-2xl font-bold text-center'>Retailer Specifications</h2>
    {userLoggedIn.curator && <CuratorTagsUpdate charts={charts} trendId={trend ? trend._id : null} setParentFetch={setFetch} />}
    {loading && <div className=' flex justify-center items-center'>
      <TailSpin
        height="80"
        width="80"
        color="grey"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible="true"
      />
    </div>
    }
    <div className="mt-10 flex flex-row w-full flex-wrap">
      {
        charts.map(chart => <div key={chart.tag} className='w-1/3 shrink-0 '> <Piechart data={chart} handleProductsPopup={handleProducts} /> </div>)
      }
    </div>
    <hr className="w-40 mx-auto mb-20" />
    <div className="w-4/5 m-auto mb-10">
      <ProductsPriceGraph products={productPrices} />
    </div>
    <Popup isPopUp={showProductsPopup} setIsPopUp={handleProducts}>
      <ProductsPopup tagsData={selectedTagData} category={trend.retailerTagsData.retailerCategory} ecommerce={'Myntra'} rank={'1-10000'} trend={trend} />
    </Popup>
  </div>
}

export default RetailerTagsPopup
