import { useState , useEffect } from "react";
import Popup from "../../component/Popup";
import { MagnifyingGlass} from 'react-loader-spinner'
import axios from "axios";
import SimilarProductImage from './SimilarProductImage'


const SimilarProductsPopup = ({showSimilarProductsPopup,queryImageData,hideSimilarProductsPopup})=>{

	
	const [response, setResponse] = useState(false)
	const [allCatsResult, setAllCatsResult] = useState({})
	const [categories , setCategories] = useState([])
	const [currentCategory, setCurrentCategory] = useState('')
	useEffect(()=>{
		
		getProducts()
	},[])


	const getProducts = async ()=>{
		if(queryImageData && queryImageData.category && queryImageData.mediaId){
			const response = await axios.get(`/products/${queryImageData.category}/${queryImageData.mediaId}`)
			if(response && response.data.results){
				console.log('category,',queryImageData.category)
				setAllCatsResult(response.data.results)
				if(queryImageData.category == 'All Trends'){
					setCategories(Object.keys(response.data.results))
					setCurrentCategory(Object.keys(response.data.results)[0])
					console.log(response.data.results)
				} else {
					setCurrentCategory(queryImageData.category )
				}

			}
			setResponse(true)
		} else {
			setResponse(true)
		}

	}
	

	return (
		<div >
			<Popup isPopUp={showSimilarProductsPopup} setIsPopUp={hideSimilarProductsPopup}>
				<div className="h-[80vh] w-[80vw] pb-15">
					<div className="w-full">
						<img src={queryImageData.imageUrl} className="w-60 mx-auto"/>
					</div>
					<hr  className="m-5 h-2 w-3/4 mx-auto border-1"/>
					<div className="w-full">
						<h2 className="text-left px-16 my-10 text-xl font-semibold">Similar Products for {queryImageData.category}</h2>
					</div>
					<div className="w-full flex">
						{categories.map(category =><button className={`rounded ${category==currentCategory?'bg-slate-600 text-white':'white text-black border-2'} p-2 my-4 mx-2 justify-start `} onClick={()=>{ setCurrentCategory(category) }}>{category}</button>)}
					</div>
					<div className="w-full">
						{
							response && (!allCatsResult[currentCategory] || allCatsResult[currentCategory].length==0) && <p className=""> No Similar products found</p>
						}
						{
							!response && <>
								
									<MagnifyingGlass visible={true} height="40"
										width="40"
										ariaLabel="loading"
										wrapperStyle={{}}
										wrapperClass="MagnifyingGlass-wrapper mx-auto"
										glassColor = '#ddd'
										color = '#777'/>
								<p className="text-center">Searching</p>
								</>
						}
						{allCatsResult[currentCategory] && allCatsResult[currentCategory].length >0 && <div className="w-[90%] flex flex-row flex-wrap py-5">
							{
								allCatsResult[currentCategory].map((product,index)=>{
									return (
										<a key={index} href={product.product_url} target="_blank" className=" p-2 basis-1/4 shadow ">
											<SimilarProductImage imageUrl={product.image_url} />
											<p className="text-left mb-2 px-4">{product.title}</p>
											<div className="flex mb-4 px-4">
												<p className="text-left w-1/2">{product.price}<span>&#8377;</span></p>
												<p className="text-right w-1/2 font-semibold">{product.ecommerce}</p>
											</div>
											<p className="text-left mb-2 px-4">{Math.floor(product.score*1000)/10}% similar</p>
										</a>
									)
								} )
							}
						</div>}
					</div>
				</div>
			</Popup>
		</div>
	)
}

export default SimilarProductsPopup