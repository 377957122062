import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updateLogin } from './../Redux/Slices/loginStatusSlice';
import { useNavigate } from "react-router-dom"
import { login } from './../Api/uploadNewImage';
import sweetalert2 from 'sweetalert2'



const Login = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLoggedIn } = useSelector(state => state)
  useEffect(() => {
    if (userLoggedIn.loggedIn) {
      navigate('/');
    }
  }, []);


  async function submit() {
    const passwordKey = document.querySelector('#password-key').value;

    if (!passwordKey) {
      sweetalert2.fire(null, "Please enter a password", 'warning');
    } else {
      const response = await login(passwordKey);
      if (!response.successful) {
        sweetalert2.fire("Login failed", "No key found", 'error');
      } else {
        const { curator, admin, updater, clientName } = response;
        dispatch(updateLogin({ loggedIn: true, curator, admin, updater, clientName }));
        navigate('/')
      }
    }
  }

  return (
    <div className='grid h-screen place-items-center'>
      <div className='bg-slate-50 text-center py-[40px] px-[50px] w-1/2 max-[750px]:w-96 max-[400px]:w-11/12 rounded-2xl border-2 shadow-md hover:shadow-lg'>
        <h2 className=' text-4xl max-[400px]:text-2xl max-[300px]:text-xl text-slate-400' >Enter login key</h2>
        <input id="password-key" className='mt-[80px] mb-[40px] px-3 py-2 align-bottom bg-white border shadow-sm self-center border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 w-full rounded-md sm:text-sm focus:ring-1' placeholder="secret key" type='password' name='key' />
        <button className='text-white bg-slate-500 font-bold align-bottom bottom-0 px-[30px] p-[10px] rounded-full' onClick={submit} >SUBMIT</button>
      </div>
    </div>
  )
}

export default Login;
