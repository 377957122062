import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

const DateRangeFilter = ({ dateFilter, setDateFilter, onGo }) => {
    const [showDateFilter, setShowDateFilter] = useState(false)
    // const [dateRange,setDateRange] = useState({
    //     maxDate:"",
    //     minDate:"",
    // })
    // useEffect(()=>{ 
    //     axios.get('/trend/date')
    //     .then(res=>{
    //         setDateRange({
    //             maxDate:moment(res.data.maxDate,"YYYY-MM-DD").format("YYYY-MM-DD"),
    //             minDate:moment(res.data.minDate,"YYYY-MM-DD").format("YYYY-MM-DD"),
    //         })
    //     })
    //     .catch(e=>{
    //         console.log(e)
    //     })
    // },[])
    const fillForm = (e) => {
        const { name, value } = e.target;
        setDateFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const doFocus = () => {
        setShowDateFilter(true)
    }
    const doBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowDateFilter(false)
        }
    }
    const iniDateFilter = {
        from: "",
        to: "",
    }
    const handleReset = (e) => {
        e.preventDefault()
        setDateFilter(iniDateFilter)
        onGo(0)
    }
    const handlego = (e) => {
        e.preventDefault()
        onGo(1)
    }
    return (
        <div className='cursor-pointer' onFocus={doFocus} onBlur={doBlur} tabIndex={0}>
            <div className='flex items-center flex-wrap'>
                <div className='flex mr-1 items-center'>
                    <p className=''>Loaded Date-filter</p>
                    <img className='h-5' src="calendar.png" alt="calander" />
                </div>
                <div className='mr-1 text-gray-400'>
                    <p>{dateFilter.from.length > 0 && `From:${dateFilter.from}`}</p>
                </div>
                <div className='mr-1 text-gray-400'>
                    <p>{dateFilter.to.length > 0 && `To:${dateFilter.to}`}</p>
                </div>
            </div>
            {
                showDateFilter && <div className='flex flex-col p-2 rounded-md bg-white absolute justify-center z-10 shadow-2xl'>
                    <div className='flex items-center flex-wrap'>
                        <div>
                            <label htmlFor="from-date">From:</label>
                            <input className='p-1 mr-1 rounded-md outline-none text-center' onChange={fillForm} value={dateFilter.from} type="datetime-local"
                                max={dateFilter.to || new Date().toISOString().split('T')[0]}
                                // min={} 
                                name="from"
                                id="from-date" />
                        </div>
                        <div>
                            <label htmlFor="to-date">To:</label>
                            <input className='p-1 rounded-md outline-none text-center' onChange={fillForm} value={dateFilter.to}
                                max={new Date().toISOString().split('T')[0]}
                                min={dateFilter.from}
                                type="datetime-local" name="to" id="to-date" />
                        </div>
                        {/* <input type="radio" name="dateType" id="originating" onChange={fillForm} value = 'originating' checked={dateFilter.dateType === 'originating'} /> <label htmlFor="originating">Originating</label>
                        <input onChange={fillForm} value = 'range' checked = {dateFilter.dateType === 'range'} type="radio" name="dateType" className='ml-2' id="range" /><label htmlFor="range">Range</label> */}
                    </div>
                    <div className='flex'>
                        <button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 mr-2 py-2 rounded-3xl' onClick={handlego}>Go</button>
                        <button onClick={handleReset} className='ml-2 py-2 hover:bg-red-700 text-white bg-red-500 px-7 rounded-3xl'>clear</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default DateRangeFilter