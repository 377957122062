import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchHash:''
}

export const blurHashSlice = createSlice({
    name: 'blurHash',
    initialState,
    reducers: {
        setBlurHashLink: (state,action) => {
            state.searchHash = action.payload.searchHash
        },
    },
})  
export const { setBlurHashLink } = blurHashSlice.actions

export default blurHashSlice.reducer
