import React, { useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Popup from '../../component/Popup'
import Trend from './Trend'

const Graph = ({ graphData, uVal, trendData, fetch, setFetch, dateToShow }) => {
    const [activeTrend, setActiveTrend] = useState("")
    const [showTrend, setShowTrend] = useState(false)
    const [clickedTrendId, setClickedTrendId] = useState(0)
    const handlMouseEnter = (props) => {
        const { id } = props
        setActiveTrend(id)
    }
    const handleMouseLeave = () => {
        setActiveTrend("")
    }
    const handleClickOnLineGraph = (e) => {
        setShowTrend(true)
        setClickedTrendId(e.id)
    }
    // console.log(dateToShow)
    // console.log(graphData)
    return (
        <div className='relative flex justify-center items-center mt-12 bg-white'>
            <div>
                {
                    showTrend && <Popup isPopUp={showTrend} setIsPopUp={setShowTrend}>
                        <Trend fetch={fetch} setFetch={setFetch} trend={trendData[clickedTrendId]} />
                    </Popup>
                }
            </div>
            <ResponsiveContainer height={600} width="95%" >
                <LineChart margin={{ top: 5, bottom: 15, right:0 }} data={graphData} >
                    {
                        uVal.map((trend, index) => <Line onMouseEnter={handlMouseEnter} key={index}
                            strokeWidth={activeTrend === index ? 4 : 2}
                            onClick={handleClickOnLineGraph} onMouseLeave={handleMouseLeave} type="monotone" id={index} stroke={activeTrend === index ? "#6cb4ee" : "#dcdcdc"} dot={false} dataKey={trend} />)
                    }
                    <XAxis dataKey="date" angle={0} fontSize={16} allowDuplicatedCategory={false} ticks={dateToShow}>
                    </XAxis>
                    <YAxis allowDecimals={false} >
                    </YAxis>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Graph