
import { useState, useEffect } from "react";
import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getBarChartData } from "../../utility/helper_functions";
const PriceGraph = ({ products }) => {
  useEffect(() => {
    if (!products) {
      setBarChartData([])
    } else
      setBarChartData(getBarChartData(products.map(prod => prod.price)))
  }, [products])

  const [barChartData, setBarChartData] = useState([])
  return <>
    <h3 className="text-slate-400 mt-2 text-center ">Range of Price</h3>
    <ResponsiveContainer height={350} width={"95%"}>
      <BarChart data={barChartData} margin={{ bottom: 150 }}>
        <XAxis tick={false} dataKey={'priceRange'} >
          <Label value={""} dy={22} /> </XAxis>
        <YAxis allowDecimals={false} dataKey={'count'}><Label value={"Quantity"} position={'insideLeft'} angle={-90} offset={10} /></YAxis>
        <Tooltip />
        <Bar fill="#475569" dataKey={'count'} >
          <LabelList dataKey={'priceRange'} fill="black" angle={-90} position={'insideBottom'} offset={-30} style={{ fontSize: '8px' }} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </>
}
export default PriceGraph;
