
export const SentenceCase = (name) => {
  if (!name) {
    return ""
  }

  const cleanedWords = name.trim().split("_").join(" ").split(' ').filter(word => word && word != "").join(' ');
  const sentenceCaseName = cleanedWords.slice(0, 1).toUpperCase() + cleanedWords.slice(1).toLowerCase();

  return sentenceCaseName;
}

export const getBarChartData = (price) => {
  price.sort(function(a, b) { return a - b })
  price = price.filter(p => p != null && p < 10000)
  let arr = []
  let s = price[0]
  let e = price[price.length - 1]
  let t = Math.floor(s / 100) * 100;
  let t2 = 0;
  while (t < e) {
    let t1 = {}
    t1.priceRange = `${t + 1}-${t + 100}`
    t1.count = price.filter(p => p >= t + 1 && p <= t + 100).length
    arr.push(t1)
    t += 100
  }
  return arr;
}


export const formatWeek = (weeknumber, year) => {
  const d = (1 + (weeknumber - 1) * 7);
  const startingDate = new Date(year, 0, d);
  const endDate = new Date(year, 0, d + 6);
  return `${startingDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`
}

export const indexedPostUrl = (postUrl, mediaId) => {

  if (!mediaId) {
    return postUrl
  }
  try {
    if (mediaId[mediaId.length - 2] == '_') {
      const index = parseInt(mediaId[mediaId.length - 1]) + 1
      const indexedPostUrl = postUrl + `?img_index=${index}`

      return indexedPostUrl
    }

  } catch (e) {
    console.error(e)
  }
  return postUrl
}
