import { useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlass } from "react-loader-spinner";
import SimilarProductImage from "../trendDisplayPage/SimilarProductImage";
import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AiFillStar } from "react-icons/ai";

export default  ({ response, forPairItWith=false , apiResponse, category}) => {

	const [allCatsResult, setAllCatsResult] = useState({})
	const [categories, setCategories] = useState([])
	const [currentCategory, setCurrentCategory] = useState('')
	const [currentEcommerce, setCurrentEcommerce] = useState('All')
	const [currentProductList, setCurrentProductList] = useState([])
	const [barChartData, setBarChartData] = useState([])
	const [forAmazon, setForAmazon] = useState(false);
	const [sortBy, setSortBy] = useState('Similarity Score')
	const [selectedProductIds, setSelectedProductIds] = useState([]);
	const ecommerceList = [ 'All','Amazon', 'Ajio', 'H&M', 'Myntra', 'Nykaa', 'TataCliq',  'Urbanic',  'Zara' ]
	const sortByOptions = ['Similarity Score' , 'price - low to high' , 'price - high to low']
	const catsToMaster = {
		'Dress Trends': "One-piece",
		'Jumpsuit Trends': "One-piece",
		'Topwear Trends': "Topwear",
		'Jeans & Trouser Trends':"Bottomwear",
		'Skirt Trends': "Bottomwear",
		'Trendy Shorts': "Bottomwear",
		'Outerwear Trends': "Outerwear",
		'Trendy Footwear': "Footwear",
		'Trendy Bags': "Bags",
		'Trendy Accessories': "Accessories" ,
		'All Trends': "'All"
	    }

	const categoriesPriority = {
		'Dress Trends': 3,
		'Jumpsuit Trends': 3,
		'Topwear Trends': 1,
		'Jeans & Trouser Trends':2,
		'Skirt Trends': 2,
		'Trendy Shorts': 2,
		'Outerwear Trends': 4,
		'Trendy Footwear': 6,
		'Trendy Bags': 5,
		'Trendy Accessories': 7 
	}

	const getCategoriesAbove50 = (results) =>{
		const categories = Object.keys(results).filter(cat => results[cat] && results[cat].filter(res => res.score>0.5).length>0)
		categories.sort((a,b)=> categoriesPriority[a]-categoriesPriority[b])
		return categories
	}

	const getProducts =  ()=>{
		const response = apiResponse
		if(!response.data){
			return 
		}
		if(response && response.data.results){
			setAllCatsResult(response.data.results)
			if(category == 'All Trends'){
				const categories = getCategoriesAbove50(response.data.results);
				setCategories(categories)
				setForAmazon(response.data.forAmazon)
				setCurrentCategory(categories[0])
				changeProducts(currentEcommerce,response.data.results[categories[0]])
			} else {
				if(forPairItWith){
					const categories = getCategoriesAbove50(response.data.results);
					let allCatsNames = categories.filter(c => catsToMaster[c]!=catsToMaster[category])
					if(catsToMaster[category]=="Topwear" || catsToMaster[category] ==  "Bottomwear"){
						allCatsNames = allCatsNames.filter(cat =>  catsToMaster[cat]!= "One-piece")
					}
					if(catsToMaster[category]=="One-piece"){
						allCatsNames = allCatsNames.filter(cat =>  catsToMaster[cat]!= "Topwear" && catsToMaster[cat]!=  "Bottomwear" )
					}
					if( allCatsNames.length>0 ){
						setCurrentCategory(allCatsNames[0])
						setCategories(allCatsNames)
						changeProducts(currentEcommerce,response.data.results[allCatsNames[0]])

					} else {
						setAllCatsResult({})
					}
				} else {
					setForAmazon(response.data.forAmazon)
					setCurrentCategory(category)
					changeProducts(currentEcommerce,response.data.results[category])
				}
			}
		}
	}

	useEffect(()=>{
		getProducts()
	},[apiResponse])

	const getBarChartData = (price) => {
		price.sort(function (a, b) { return a - b })
		price= price.filter(p => p!=null && p<10000)
		let arr = []
		let s = price[0]
		let e = price[price.length - 1]
		let t = Math.floor(s / 100) * 100;
		let t2 = 0;
		while (t < e) {
			let t1 = {}
			t1.priceRange = `${t + 1}-${t + 100}`
			t1.count = price.filter(p => p >= t + 1 && p <= t + 100).length
			arr.push(t1)
			t += 100
		}
		return arr;
	}
	const changeSort = (sort) => {
		setSortBy(sort);
		changeProducts(currentEcommerce,allCatsResult[currentCategory], sort)
	}

	const changeProducts = (ecom , products, sortingOption)=>{
		if(!products){
			console.log("empty products list")
			setCurrentProductList([])
			return ;
		}
		if(ecom!='All'){
			products = products.filter( prod => prod.ecommerce==ecom)
		}
		if(!sortingOption){
			sortingOption = sortBy;
		}
		if(sortingOption == 'price - low to high'){
			products.sort((a,b)=> a.price-b.price )			
		} else if(sortingOption == 'price - high to low'){
			products.sort((a,b)=> b.price-a.price )
		} else {
			products.sort((a,b)=> b.score-a.score )
		}
		// products = products.filter(prod => prod.score > 0.50)
		const productIds = {}
		const uniqueProducts = []
		for(let prod of products){
			if(!productIds[prod.id]){
				productIds[prod.id] = true
				uniqueProducts.push(prod)
			}
		}

		const tempPrice = products.map(prod => prod.price)
		const barChartData = getBarChartData(tempPrice)
		setCurrentProductList(uniqueProducts)
		setBarChartData(barChartData)
	}

	const changeCurrentCategory = (cat)=>{
		setCurrentCategory(cat)
		changeProducts(currentEcommerce,allCatsResult[cat])
	}

	const changeEcommerce = (ecom) =>{
		setCurrentEcommerce(ecom);
		changeProducts(ecom, allCatsResult[currentCategory])
	}

	const downloadAmazonData = async ()=>{
		let csvString = 'Product Id,Product title,Product description,Product URL,Product Image URL,Category,Ecommerce,Price,Rating,Rating text\n'
		let imagesString = ''
		// for(let img of trend.images){
		// 	imagesString = imagesString+img.imageUrl+','
		// }
		const amazonProducts = currentProductList.filter(product => selectedProductIds.includes(product.id))
		for(let prod of amazonProducts){
			csvString = csvString+`${prod.id},${prod.title},${prod.description},${prod.product_url},${prod.image_url},${prod.subcat},${prod.ecommerce},${prod.price},${imagesString},${prod.ratingValue?prod.ratingValue:""},${prod.ratingString&& prod.ratingString.includes('star') ?prod.ratingString.trim():""},${prod.brandType?prod.brandType:""}\n`
		}
		// console.log(csvString)
		const link = document.createElement("a");
		const file = new Blob([csvString], { type: 'text/csv;encoding:utf-8' });
		link.href = URL.createObjectURL(file);
		link.download = `${new Date().getTime().toString()}_${category}.csv`;
		link.click();
		URL.revokeObjectURL(link.href);
	}




	const selectProducts = (action,ids)=>{
		if(action=='add'){
			setSelectedProductIds(prev => [...prev, ...ids])
		} else if(action=='remove'){
			setSelectedProductIds(prev =>{ 
				return prev.filter(id => !ids.includes(id))
			})
		}
	}


	return (		
		<div className="w-[80vw] h-[80vh] ">
			{currentProductList && currentProductList.length > 0 && <div className="flex justify-center">
				<ResponsiveContainer height={250} width={"100%"}>
					<BarChart data={barChartData} margin={{bottom:50}}>
						<XAxis tick={false} dataKey={'priceRange'} ><Label value={"Range of Price"} dy={52}/> </XAxis>
						<YAxis allowDecimals={false} dataKey={'count'}><Label value={"Quantity"} position={'insideLeft'} angle={-90} offset={30} /></YAxis>
						<Tooltip />
						<Bar fill="#475569" dataKey={'count'} >
							<LabelList dataKey={'priceRange'} fill="black" angle={-90} position={'insideBottom'} offset={-30} style={{fontSize:'8px'}} />
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</div>}
			<hr className="w-4/5 bg-slate-700 m-auto mt-10" />
			{forPairItWith && <div className="w-full flex">
				<div className="w-4/5 flex flex-nowrap mx-0">
					{categories.map(category =><button className={`rounded ${category==currentCategory?'bg-slate-600 text-white border-2':'white text-black border-2'} p-2 my-4 mx-2 justify-start basis-36`} onClick={()=>{ changeCurrentCategory(category) }} key={category}>{category}</button>)}
				</div>
				
			</div>}
			{response &&<div className="w-full flex flex-row justify-center">
				
				<div className=" mx-0 relative flex flex-col ml-4">
						<span className="text-light text-sm">Sort By</span>
						<div  className=" ] mb-4 mx-2 overflow-hidden "  >
							{sortByOptions.map( (sort,i ) => <button key={sort} onClick={()=>{ changeSort(sort)}} className={`px-2 py-1 text-sm m-0 ${i==0?"":"border-l-0"} border border-slate-500 ${sort==sortBy?'bg bg-slate-500 text-white':''}`}>{sort}</button>)}
						</div>
				</div>
				<div className=" mx-3 relative ">
					<div className="flex flex-col"> 
						<span className="text-light text-sm mx-2 ">Ecommerce</span>
						<select  className="ml-4 px-6 mb-4 mx-2  w-28 bg-slate-500 text-white py-[7px] text-sm border" value={currentEcommerce} onChange={(e)=>{ changeEcommerce(e.target.value)}}>
							{ecommerceList.map( ecom => <option key={ecom}  >{ecom}</option>)}
						</select>
					</div>
				</div>
				{ forAmazon && <button onClick={downloadAmazonData} className="bg-slate-500 text-white h-8 text-sm mt-5 px-6 py-1.5">Products Data</button>}
			</div>}
			{response && forAmazon && <div className="w-full flex flex-row justify-end pr-12 mt-4	">
				<button onClick={()=> selectProducts('remove', currentProductList.map(p => p.id) )} className="bg-slate-500 text-white h-8 text-sm px-6 py-1.5 mx-1">Deselect All</button>
				<button onClick={()=> selectProducts('add',currentProductList.map(p => p.id))} className="bg-slate-500 text-white h-8 text-sm px-6 py-1.5 mx-1">Select All</button>
			</div>}
			<div className="w-full py-6 px-2 ">
				{
					response && (!currentProductList || currentProductList.length==0) && <p className=""> No Similar products found</p>
				}
				{
					!response && <>
						
							<MagnifyingGlass visible={true} height="40"
								width="40"
								ariaLabel="loading"
								wrapperStyle={{}}
								wrapperClass="MagnifyingGlass-wrapper mx-auto"
								glassColor = '#ddd'
								color = '#777'/>
						<p className="text-center">Searching</p>
					</>
				}
				{currentProductList && currentProductList.length > 0 && <div className="w-[90%] flex flex-row flex-wrap py-5">
					{
						currentProductList.map((product, index) => {
							return (
								<div className="p-2 basis-1/4 relative mt-2 border boder-b-2 border-b-2">
									<a key={index} href={product.product_url} target="_blank" className="shadow ">
										<SimilarProductImage imageUrl={product.image_url} />
										
										<p className="text-left mb-2 px-4">{product.title}</p>
										<div className="flex mb-4 px-4">
											<p className="text-left w-1/2">{product.price}<span>&#8377;</span></p>
											<p className="text-right w-1/2 font-semibold">{product.ecommerce}</p>
										</div>
										<p className="text-left mb-2 px-4">{Math.floor(product.score * 1000) / 10}% similar</p>
										<div className="flex flex-row px-4">
											<p className="w-1/3 flex">
												{ 
													product.ratingValue 
													?<>{product.ratingValue}<span className="relative top-1"><AiFillStar/></span> </>
													:null 
												}
											</p>
											<p className="w-2/3">
												{ 
													product.ratingString&& product.ratingString.includes('star') 
													?<>{product.ratingString}</>
													:null 
												}
											</p> 
										</div>
										{product.brandType && <div className=" px-4">{product.brandType}</div>}
									</a>	
									{forAmazon && <button className={`${selectedProductIds.includes(product.id)?"bg-slate-500":"bg-slate-400"} px-1 py-0.5 text-xs absolute top-3 right-3 text-white z-10`} onClick={()=>selectProducts(selectedProductIds.includes(product.id)?'remove':'add',[product.id])}>{selectedProductIds.includes(product.id)?'Remove':'Select'}</button>}
								</div>
								
							)
						})
					}
				</div>}
			</div>
		</div>
	)
}
