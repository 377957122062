import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Trend from './DesignTrend'
import { TailSpin } from 'react-loader-spinner';

const DesignTrends = ({ trend, gender, category }) => {
  const [designTrends, setDesignTrends] = useState([])
  const [loading, setLoading] = useState(true)
  const [geography, setGeography] = useState('all')
  const [sort, setSort] = useState("1")
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [fetch, setFetch] = useState(true);

  const updateDesignTrends = async () => {
    setLoading(true)
    const limit = 20
    let images = trend.images ? trend.images.map(img => img.mediaId) : null
    if (!images) {
      const data = await axios.get(`/trend-api/${trend._id}`)
      images = data.data.similarCategoryImages.map(img => img.mediaId)
    }
    const ids = images
    axios.post(`/design-api/subtrends/${trend.pk}?gender=${gender}&category=${category.split('&').join('%26')}&sortBy=${sort}&geography=${geography}&page=${page}&limit=${limit}`, { mediaIds: ids })
      .then(res => {
        setLoading(false)
        setDesignTrends(res.data.designTrends);
        setCount(res.data.count)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      }).finally(() => {
        setFetch(false)
      })
  }

  useEffect(() => {
    if (fetch)
      updateDesignTrends()
  }, [sort, geography, page, fetch])
  return (
    <div className='w-full h-full overflow-y-auto'>
      <div className='w-full flex justify-end '>
        <div className="justify-end flex w-1/4 mr-10">
          <button disabled={page <= 1} onClick={e => { setPage(p => p - 1); setFetch(true); }} className="primary-btn">prev</button>
          <span className="m-2">{page}/{Math.ceil(count / 20)}</span>
          <button disabled={page >= Math.ceil(count / 20)} onClick={e => { setPage(p => p + 1); setFetch(true); }} className="primary-btn">next</button>
          <span className="m-2">{count} trends</span>
        </div>
      </div>
      <h2 className='mb-4 font-bold text-2xl text-center'>Which {trend?.name[0].toUpperCase() + trend.name.slice(1).toLowerCase()} variants will sell more?</h2>
      <div className='relative w-full'>
        <select className='mr-1 p-2 rounded-md outline-none text-center max-[767px]:mt-2  relative left-1/2 -translate-x-1/2 ' value={sort} onChange={(e) => { setSort(e.target.value) }} name="sort">
          <option value="" hidden>--sort by--</option>
          <option value="0">Sort By Latest (Freshly-brewed)</option>
          <option value="1">Sort By Popular(Image Count)</option>
          <option value="3">Sort By Most-liked</option>
        </select>
      </div>
      {loading ?
        <><div className='w-full relative h-[70vh]'>
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <TailSpin color='black' />
            <p className='my-12 font-medium'>Loading...</p>
          </div>

        </div></> :
        designTrends && designTrends.length ? <div>
          {
            designTrends.map((designtrend, index) => <Trend key={index} socialTrendId={trend.pk} trend={designtrend} setFetch={setFetch} />)
          }
        </div>
          : <h2 className='text-center my-12 font-semibold text-xl'>Trend image count below the threshold to derive popular designs</h2>
      }
      <div className='w-full h-40'></div>
    </div>
  )
}

export default DesignTrends
