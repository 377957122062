import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "../../component/Popup";
import ProductsTagsUpdatePopup from "./ProductsTagsUpdatePopup";
import RunwayImage from "./RunwayImage";
import RunwayTagsUpdatePopup from "./RunwayTagsPopup";

const RunwayImages = ({ count, page, setPage, products }) => {

  const { userLoggedIn } = useSelector(state => state)
  const [tagspopupMediaId, setTagsPopupMediaId] = useState(null);
  const [allTagsFilled, setAllTagsFilled] = useState(false)
  const [showTagsPopup, setShowTagsPopup] = useState(false);
  const limit = 100;

  useEffect(() => {
    if (showTagsPopup == false) {
      setTagsPopupMediaId(null)
    }
  }, [showTagsPopup])

  useEffect(() => {
    if (tagspopupMediaId != null) setShowTagsPopup(true)
  }, [tagspopupMediaId])

  return <div>
    <div className='flex items-center  justify-end px-20'>
      <div className='mr-2'>{count} Images</div>
      <div>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={page <= 1} onClick={() => { setPage(prev => prev - 1) }}>prev</button>
        <span className='mr-2'>{page}/{Math.ceil(count / limit)}</span>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl' disabled={page >= Math.ceil(count / limit)} onClick={() => { setPage(prev => prev + 1) }}>next</button>
      </div>
    </div>

    <div className="">
      {products.length > 0 ? <div className="flex flex-wrap">
        {products.map((prod, i) => <RunwayImage prod={prod} key={prod._id} curator={userLoggedIn.curator} updateTagsPopupMediaId={setTagsPopupMediaId} />)}
      </div>
        : <div className="py-40 flex justify-center"><p>No products found</p></div>}
    </div>

    <Popup isPopUp={showTagsPopup} setIsPopUp={(nState) => {
      if (!nState && allTagsFilled)
        setShowTagsPopup(false)
    }} >

      <RunwayTagsUpdatePopup mediaId={tagspopupMediaId} setAllTagsFilled={setAllTagsFilled} allTagsFilled={allTagsFilled} />
    </Popup>
  </div>
}

export default RunwayImages
