import { useState } from "react"
import { FaAngleLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Popup from "../../component/Popup"
import TrendImages from "./TrendImages"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { lifecycleSuggestions } from "../../utility/lifeCycleSuggestions";

const GroupTrend = ({ searchParams, trendsData, index, setSearchParams }) => {

  const [page, setPage] = useState(1)
  const [selectedTagValue, setSelectedTagValue] = useState(null)
  return <div className={`w-1/2 odd:border-r odd:border-r-black border-b border-b-black p-2 shrink-0 ${index % 4 == 2 || index % 4 == 1 ? 'bg-slate-100' : 'bg-white'}`}>
    <h3 className="text-center font-bold my-2 text-[20px] mb-4">{trendsData.tag}</h3>
    <div className="w-full flex ">
      <div className="w-1/2  border-dashed border-black border-r-[0.8px]">
        <h3 className="text-center font-bold text-[14px] p-2">SOCIAL </h3>
        <div className="flex justify-end">
          <hr className="w-3/4 border-dashed border-black border-[0.95px]" />
        </div>
        <div>
          {
            trendsData.socialTrends
              ? trendsData.socialTrends.slice((page - 1) * 5, page * 5).map((trend, index) => <div key={trend._id} className="flex h-24 my-1">
                <div className="shrink-0 w-40">
                  <h3 className="font-semibold hover:text-blue-500 text-blue-600" data-tooltip-id={`breakdown-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `breakdown-${index}`} onClick={() => {
                    setSearchParams(prev => {
                      prev.set('tag', trendsData.tag)
                      prev.set('value', trend.tagFirst.value)
                      return prev
                    })
                  }}>{index + 1 + (page - 1) * 5}. {trend.tagFirst.value != '#N/A' ? trend.tagFirst.value : 'No Specific'}</h3>
                  <p className="text-[11px]">Depth : {parseInt(trend.depth * 1000) / 10}%</p>

                  <ReactTooltip
                    id={`breakdown-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `breakdown-${index}`}

                  ><span className="text-[11px]">Breakdown {trend.tagFirst.value != '#N/A' ? trend.tagFirst.value : 'No Specific'}({trend.tagFirst.tag})</span></ReactTooltip>
                  {/*
                  <p className="text-[11px]" data-tooltip-id={`lifecycle-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `${index}`}> {trend.lifecycle}

                    <ReactTooltip
                      id={`lifecycle-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `${index}`}
                      place="bottom"
                      className="w-60"

                    >
                      <div className="py-1">
                        <div className="">Suggestion : {lifecycleSuggestions[trend.lifecycle]}</div>
                      </div>
                      <div className="py-1 text-[11px]">
                        <div className="">Past 7 day timeframe: {trend.is7dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
                        <div className="">Past 15 day timeframe: {trend.is15dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
                        <div className="">Past 30 day timeframe: {trend.is30dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
                        <div className="">Past 60 day timeframe: {trend.is60dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
                      </div>
                    </ReactTooltip>
                  </p>
                  */}
                </div>
                <div className="h-full px-1">
                  <div className="flex h-full overflow-x-hidden " onClick={() => setSelectedTagValue({ tag: trend.tagFirst.tag, value: trend.tagFirst.value, trendType: 'social' })}>
                    {
                      trend.images ? trend.images.map(image => <img src={image.imageUrl} className="h-full w-auto ml-1" />) : null
                    }
                  </div>
                </div>
              </div>)
              : null
          }

          {
            trendsData.socialTrends && trendsData.socialTrends.length < page * 5 ? Array.from({ length: (page * 5 - trendsData.socialTrends.length) }, (v, i, k,) => i)
              .map((trend, index) => <div key={trend._id} className="flex h-24 my-1">
                <div className="shrink-0 w-40">
                </div>
                <div className="h-full px-1">
                  <div className="flex h-full overflow-x-hidden ">
                  </div>
                </div>
              </div>)
              : null
          }
        </div>
      </div>
      <div className="w-1/2  border-dashed border-black border-l-[0.8px]">
        <h3 className="text-center font-bold text-[14px] p-2">RETAILER </h3>
        <div className="flex justify-start">
          <hr className="w-3/4 border-dashed border-black border-[0.95px]" />
        </div>
        <div>
          {
            trendsData.retailerTrends
              ? trendsData.retailerTrends.slice((page - 1) * 5, page * 5).map((trend, index) => <div key={trend._id} className="flex h-24 my-1">
                <div className="shrink-0 w-40 px-1">
                  <h3 className="font-semibold text-blue-600 hover:text-blue-500"
                    data-tooltip-id={`retailer-breakdown-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `breakdown-${index}`}
                    onClick={() => {
                      setSearchParams(prev => {
                        prev.set('tag', trendsData.tag)
                        prev.set('value', trend.tagFirst.value)
                        return prev
                      })
                    }}>{index + 1 + (page - 1) * 5}. {trend.tagFirst.value != '#N/A' ? trend.tagFirst.value : 'No Specific'}</h3>
                  <p className="text-[11px]">Depth : {parseInt(trend.depth * 1000) / 10}%</p>
                </div>
                <div className="h-full px-1">
                  <div className="flex h-full overflow-x-hidden " onClick={() => setSelectedTagValue({ tag: trend.tagFirst.tag, value: trend.tagFirst.value, trendType: 'retailer' })}>
                    {
                      trend.products ? trend.products.map(image => <img src={image.imageUrl} className="h-full w-auto ml-1 border-[0.5px]" />) : null
                    }
                  </div>
                </div>
                <div className="text-[10px]">
                  <ReactTooltip
                    id={`retailer-breakdown-${trend.tagFirst.tag}-${trend.tagFirst.value}` || `breakdown-${index}`}

                  >Breakdown {trend.tagFirst.value != '#N/A' ? trend.tagFirst.value : 'No Specific'}({trend.tagFirst.tag})</ReactTooltip>

                </div>
              </div>)
              : null
          }

          {
            trendsData.retailerTrends && trendsData.retailerTrends.length < page * 5 ? Array.from({ length: (page * 5 - trendsData.retailerTrends.length) % 5 }, (v, i, k,) => i)
              .map((trend, index) => <div key={trend._id} className="flex h-24 my-1">
                <div className="shrink-0 w-28">
                </div>
                <div className="h-full px-1">
                  <div className="flex h-full overflow-x-hidden ">
                  </div>
                </div>
              </div>)
              : null
          }
        </div>
      </div>
    </div>
    <div className="flex justify-end my-2">
      <div className="flex px-4 ">
        <button onClick={() => setPage(prev => prev - 1)} disabled={page == 1}><FaChevronLeft size={25} color={page == 1 ? 'gray' : 'black'} /></button>
        <button onClick={() => setPage(prev => prev + 1)} disabled={page * 5 >= Math.max(trendsData.socialTrends.length, trendsData.retailerTrends.length)}><FaChevronRight size={25} color={page * 5 >= Math.max(trendsData.socialTrends.length, trendsData.retailerTrends.length) ? 'gray' : 'black'} /></button>
      </div>
    </div>

    <Popup isPopUp={selectedTagValue != null} setIsPopUp={() => { setSelectedTagValue(null); }}>
      {
        selectedTagValue
          ? <TrendImages searchParams={searchParams} trendType={selectedTagValue.trendType} tag={selectedTagValue.tag} value={selectedTagValue.value} />
          : null
      }
    </Popup>

  </div >
}

export default GroupTrend
