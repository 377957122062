
import React, { Suspense, useState } from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Blocks } from 'react-loader-spinner'
import SimilarProductsPopup from '../trendDisplayPage/SimilarProductspopup'
import Popup from '../../component/Popup'
import TopCommentsPosts from '../../component/TopCommentsPosts'
import Swal from 'sweetalert2'

const TrendImage = ({ image, selectedImage, setSelectedImage, uVal, setFetch, category, gender }) => {
  const { userLoggedIn } = useSelector(state => state)
  const [imageSaved, setImageSaved] = useState(image.isSaved)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [showSimilarProductsPopup, setSimilarProductsPopup] = useState(false)
  const [queryImageData, setQueryImageData] = useState({});
  const locationSaved = window.location.pathname === '/saved'
  const locationDeleted = window.location.pathname === '/deleted-trend'
  const locationGraph = window.location.pathname === '/trend-graph'

  const [showTrendComments, setShowTrendComments] = useState(false)
  const [commentsPopupTrendImages, setCommentsPopupTrendImages] = useState([])


  const updateSavedData = async (type, dataId) => {
    try {
      if (!imageSaved)
        axios.put(`/save/${type}/${dataId}`)
      else
        axios.delete(`/save/${type}/${dataId}`)
      setImageSaved(!imageSaved)
    } catch (e) {
      console.error(e)
    }
  }

  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  const queryProductsForImage = (mediaId, imageUrl) => {
    setQueryImageData({
      imageUrl,
      mediaId,
      category
    })
    setSimilarProductsPopup(true);
  }


  const updateSimilarProducts = () => {
    axios.get(`/curator-api/update-similar-products/${image.mediaId}`).then(() => {
      Swal.fire('Successful', 'Products updated', 'success');
    })
  }

  const hideSimilarProductsPopup = () => {
    setQueryImageData({})
    setSimilarProductsPopup(false);
  }

  return (
    <div className='mr-10 max-[500px]:mr-1 max-w-full w-full text-center'>
      <div className='flex mb-4 flex-col items-end min-h-80' style={{ minHeight: '19.5rem' }}>
        <div className="relative">
          <img className='-mb-8 h-80'
            src={image.imageUrl}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={onImageLoaded}
            onError={(err) => { console.log('error', err) }}
            alt="name"
            loading='lazy'
          />
          {
            !imageLoaded && <div className='mx-5 h-80 w-52 flex items-center justify-center'>
              <Blocks
                visible={true}
                height="50"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            </div>
          }
          {
            !locationDeleted && <button className="absolute top-1 right-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { updateSavedData('image', image.mediaId) }}>{imageSaved ? 'Remove' : 'Save'}</button>
          }
          {gender == "f" && <button className="absolute top-1 left-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { queryProductsForImage(image.mediaId, image.imageUrl) }}>Find Similar</button>}
        </div>
      </div>
      {<a className='mx-2 flex justify-evenly items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
        <div>
          <div className='flex items-center'>{parseInt(image.noOfLikes) <= 3 ? 'Hidden' : image.noOfLikes}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></div>
        </div>
        <div>
          <div className='flex items-center'>{image.username}<span className='text-xl'></span></div>
        </div>
        <div>
          {/* <div className='flex items-center'>{image.noOfComments}<span className='text-xl'><BiCommentDots /></span></div> */}
        </div>
      </a>}
      <p>{moment(image.timeStamp).format("DD MMM YYYY")}</p>

      {image.likes && <p>({image.likes}) Likes</p>}
      {
        userLoggedIn.curator &&
        <>
          <p>fetch : <strong>{image.queryTimestamp ?
            image.queryTimestamp.slice(0, 10) :
            "  "
          }</strong></p>
          <button className='primary-btn' onClick={updateSimilarProducts}>Update Products</button>
        </>
      }
      {
        showSimilarProductsPopup && <>
          <SimilarProductsPopup showSimilarProductsPopup={showSimilarProductsPopup} queryImageData={queryImageData} hideSimilarProductsPopup={hideSimilarProductsPopup} />
        </>
      }
      <Popup isPopUp={showTrendComments} setIsPopUp={setShowTrendComments}>
        <TopCommentsPosts images={commentsPopupTrendImages} category={category} />
      </Popup>
    </div>
  )
}

export default TrendImage
