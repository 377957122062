export const WomenCategoryThumbnails = {
  "Bags": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Bags.jpg",
  "Caps & Hats": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Caps_&_Hats.jpg",
  "Coats, Suits, Blazers": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Coats__Suits__Blazers.jpg",
  "Dresses": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Dresses.jpg",
  "Dungaree": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Dungaree.jpg",
  "Ethinic Wear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Ethinic_Wear.jpg",
  "Footwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Footwear.jpg",
  "Jackets": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Jackets.jpg",
  "Jeans": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Jeans.jpg",
  "Jumpsuits": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Jumpsuits.jpg",
  "Leggings": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Leggings.jpg",
  "Lingerie & Swimwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Lingerie_&_Swimwear.jpg",
  "Lounge Coords": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Lounge_Coords.jpg",
  "Other Outerwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Other_Outerwear.jpg",
  "Shirts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Shirts.jpg",
  "Shorts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Shorts.jpg",
  "Skirts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Skirts.jpg",
  "Skorts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Skorts.jpg",
  "Socks & Stockings": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Socks_&_Stockings.jpg",
  "Sunglasses": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Sunglasses.jpg",
  "Sweaters & Cardigans": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Sweaters_&_Cardigans.jpg",
  "Sweatshirts & Hoodies": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Sweatshirts_&_Hoodies.jpg",
  "Topwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Topwear.jpg",
  "Trousers": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Trousers.jpg",
  "Tshirts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Tshirts.jpg",
  "Track/ Sweat/ Pyjama Pants": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Track__Sweat__Pyjama_Pants.jpg",
  "Glove": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Glove.jpg",
  "Belt": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/WOMEN_Belt.jpg",
}


export const MenCategoryThumbnails = {
  "Bags": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Bags.jpg",
  "Caps & Hats": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Caps_&_Hats.jpg",
  "Coats, Suits, Blazers": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Coats__Suits__Blazers.jpg",
  "Ethinic Wear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Ethinic_Wear.jpg",
  "Footwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Footwear.jpg",
  "Jackets": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Jackets.jpg",
  "Jeans": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Jeans.jpg",
  "Lounge Coords": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Lounge_Coords.jpg",
  "Other Outerwear": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Other_Outerwear.jpg",
  "Shirts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Shirts.jpg",
  "Shorts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Shorts.jpg",
  "Sunglasses": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Sunglasses.jpg",
  "Sweaters & Cardigans": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Sweaters_&_Cardigans.jpg",
  "Sweatshirts & Hoodies": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Sweatshirts_&_Hoodies.jpg",
  "Track/ Sweat/ Pyjama Pants": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Track__Sweat__Pyjama_Pants.jpg",
  "Trousers": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Trousers.jpg",
  "Tshirts": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Tshirts.jpg",
  "Glove": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Glove.jpg",
  "Belt": "https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/landing_page_icons/MEN_Belt.jpg",
}
