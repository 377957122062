import { useState } from 'react';
import { Blocks } from 'react-loader-spinner';
const SimilarProductImage = ({imageUrl})=>{

	const [imageLoaded,setImageLoaded] = useState(false)

	return (
		<>
			<img className='h-60 mx-auto mb-4' 
				src={imageUrl} 
				style={{ display: imageLoaded ? 'block' : 'none' }} 
				onLoad={()=>{ setImageLoaded(true) }}
			/> 
			{
				!imageLoaded && <div className='mx-5 h-80 w-52 flex items-center justify-center'>
				<Blocks
					visible={true}
					height="50"
					width="40"
					ariaLabel="blocks-loading"
					wrapperStyle={{}}
					wrapperClass="blocks-wrapper"
				/>
				</div>
			}
		</>
	)
}

export default SimilarProductImage