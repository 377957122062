import React, { useEffect, useState } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Label } from 'recharts';


const Graph = ({ graphData, showDates,graphType,setGraphType }) => {
    return (
        <div>
            {
                setGraphType &&<div className='flex items-center'>
                <input className='ml-2' type="radio" name="graphType" id="cumulative" checked={graphType === 0} onChange={() => setGraphType(0)} />
                <label htmlFor="cumulative">Cumulative</label>
                <input className='ml-2' type="radio" name="graphType" id="non-cumulative" checked={graphType === 1} onChange={() => setGraphType(1)} />
                <label htmlFor="non-cumulative">Non-cumulative</label>
            </div>
            }
            <div>
                <LineChart width={800} margin={{ top: 5, bottom: 15, right: 20 }} height={400} data={graphData}>
                    <Line type="monotone" dot={false} dataKey="noOfImages" stroke="#8884d8" />
                    {/* <CartesianGrid stroke="#ccc" /> */}
                    <XAxis dataKey="date" angle={0} fontSize={16} ticks={showDates}>
                        {/* <Label value="Date" offset={0} position="insideBottom" /> */}
                    </XAxis>
                    <YAxis allowDecimals={false} dataKey="noOfImages">
                        {/* <Label value={"No Of Images"} angle={-90} /> */}
                    </YAxis>
                    <Tooltip />
                </LineChart>
            </div>
        </div>
    )
}

export default Graph