import axios from "axios";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Piechart from "../ProductsAnalysis/Piechart.jsx";
import { mensTrendsCategory, femaleTrendCategory, MaleTrendToStlCategory, FemaleTrendToStlCategory } from '../../constants'
import Popup from '../../component/Popup';
import ProductsPopup from './ProductsPopup.jsx'
const TagsAnalysis = () => {

  const [loading, setLoading] = useState(true);
  const { userLoggedIn } = useSelector(state => state)
  const [charts, setCharts] = useState([])
  const [showProductsPopup, setShowProductsPopup] = useState(false)
  const [selectedTagData, setSelectedTagData] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const getData = () => {
    if (!searchParams.get('category')) {
      return
    }

    axios.get(`image-analysis-api/${searchParams.get('category') === 'default' ? 'default-' : ''}images-chart-data?category=${searchParams.get('category').split('&').join('%26')}&gender=${searchParams.get('gender')}&time=${searchParams.get('time')}`)
      .then(res => {
        setCharts(res.data.charts || [])
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleProducts = (showPopup, tagName, tagValue) => {
    if (!showPopup) {
      setShowProductsPopup(false);
      setSelectedTagData(null);
    } else {
      setShowProductsPopup(true)
      setSelectedTagData({ tagName, tagValue })
    }
  }

  const initialparams = () => {
    const gender = searchParams.get('gender') || 'f'
    const category = searchParams.get('category') || 'default'
    const time = searchParams.get('time') || 'all'
    setSearchParams({ category, gender, time });
  }

  useEffect(initialparams, [])

  const updateField = (e) => {
    setSearchParams(params => {
      params.set(e.target.name, e.target.value)
      if (e.target.name == 'gender') {
        params.set('category', 'default')
      }
      return params
    })
  }

  useEffect(getData, [searchParams.get('category'), searchParams.get('time'), searchParams.get('gender')])
  return <div className="w-screen h-screen">
    <div className="flex flex-row w-3/4 flex-wrap leaderboard-page mx-10 my-6 ">
      <select value={searchParams.get("gender") || 'f'} onChange={updateField} name="gender">
        <option value={"f"}>Female</option>
        <option value={"m"}>Male</option>
      </select>
      <select value={searchParams.get("category") || 'All Trends'} onChange={updateField} name="category">
        <option value={'default'}>Colors & Patterns</option>
        {
          searchParams.get("gender") === 'f' ?
            FemaleTrendToStlCategory.map(cat => <option key={cat} value={cat}>{cat}</option>)
            : MaleTrendToStlCategory.map(cat => <option key={cat} value={cat}>{cat}</option>)
        }
      </select>
      <select value={searchParams.get("time") || 'all'} onChange={updateField} name="time">
        <option value={'all'} >All Images</option>)
        <option value={'7-days'} >7 days</option>)
        <option value={'15-days'} >15 days</option>)
        <option value={'30-days'} >30 days</option>)
        <option value={'60-days'} >60 days</option>)
        <option value={'90-days'} >90 days</option>)
        <option value={'180-days'} >180 days</option>)
        <option value={'360-days'} >360 days</option>)

      </select>
    </div>
    {loading && <div className='pt-60 flex justify-center items-center'>
      <TailSpin
        height="80"
        width="80"
        color="grey"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible="true"
      />
    </div>
    }
    <div className="mt-10 flex flex-row w-full flex-wrap">
      {
        charts.map(chart => <div key={chart.tag} className='w-1/3 shrink-0 '> <Piechart data={chart} handleProductsPopup={handleProducts} /> </div>)
      }
    </div>
    {/*
    <hr className="w-40 mx-auto mb-20" />
    <div className="w-4/5 m-auto mb-10">
        <ProductsPriceGraph products={productPrices} />
    </div>
    */}
    <Popup isPopUp={showProductsPopup} setIsPopUp={handleProducts}>
      <ProductsPopup tagsData={selectedTagData} category={searchParams.get('category')} ecommerce={searchParams.get('ecommerce')} rank={searchParams.get('rank')} />
    </Popup>
  </div>
}

export default TagsAnalysis 
