import { HeaderSets, HeadersRanking, ToolTipText } from "../../constants";


const HeaderButton = ({ tag, category, isSetButton, searchParams, setSearchParams, hoveredCats, setHoveredCats, disabled }) => {
  return <button
    onClick={() => {
      setSearchParams(prev => {
        if (tag == 'Single Characteristics') {
          prev.delete('tag')
          return prev;
        }
        prev.set('tag', tag)
        return prev
      })
    }}
    onMouseOver={() => {
      if (isSetButton && setHoveredCats) {
        setHoveredCats(HeaderSets[category] ? HeaderSets[category][tag] : [])
      }
    }}
    onMouseLeave={() => {
      if (isSetButton && setHoveredCats) {
        setHoveredCats([])
      }
    }}
    disabled={disabled}
    className={`relative group p-0.5 px-1 m-0.5 rounded border-slate-700 border-2 ${tag == searchParams.get('tag') || (tag == 'Single Characteristics' && !searchParams.get('tag')) ? 'bg-[#008000] border-[#008000] text-white' : (hoveredCats && hoveredCats.includes(tag) ? 'bg-slate-300 text-black' : 'bg-white text-black disabled:bg-slate-200 disabled:border-slate-200')} ${isSetButton ? 'text-[10px]' : 'text-[15px]'} ${''} `}
  >{tag}{ToolTipText[tag] && <div className="absolute hidden group-hover:block p-1 m-1 bg-slate-200 border-2 border-slate-400 rounded left-full w-content z-40 top-0 w-60 text-black">{ToolTipText[tag]}</div>}</button>

}

export default HeaderButton;
