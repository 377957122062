import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'

const Image = ({ image, selectedImages, updateSelectedImage }) => {

  return (
    <div className={`flex flex-col items-left justify-center p-5 m-3 `} >
      <div className='relative'>
        <img className='hover:shadow-lg h-80' src={image.imageUrl} alt={'image'} />
        <input type='checkbox' checked={selectedImages.includes(image.mediaId)} className='w-8 h-8 absolute top-0 right-0' onChange={() => { updateSelectedImage(image.mediaId, !selectedImages.includes(image.mediaId)) }} />
      </div>
      <div>
        <p>Similarity Score - {image.similarityScore} %</p>
        <a className=' text-blue-700' href={image.postUrl} target='blank'>Instagram Link</a>
        <p>{moment(image.timeStamp, "YYYY/MM/DD").format('DD MMM YYYY')}</p>
        <p><b>Loaded : {moment(image.insertDate).format('DD MMM YYYY')}</b></p>
        <a href={`https://www.instagram.com/${image.bloggerName}`} target='blank'>{image.bloggerName}</a>
        {image.presentTrendNames && image.presentTrendNames.length > 0 &&
          <div className='mt-2 px-4'>
            <h4>Present trends</h4>
            {image.presentTrendNames.map((name, i) => <p className="font-semibold ">{i + 1}. {name}</p>)}
          </div>
        }
      </div>

    </div>
  )
}

export default Image
