import React, { useEffect, useState, } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';

const TrendDisplay = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useSelector(state => state)
  const [insertfilenames, setInsertFilenames] = useState([])
  const [indexfilenames, setIndexFilenames] = useState([])
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [inputFileKey, setInputFileKey] = useState(0)
  let interval;
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
    if (!userLoggedIn.curator) {
      navigate('/')
    }
    loadIndexingData();
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(loadIndexingData, 30000);

  }, []);



  const loadIndexingData = async () => {
    try {
      const response = await axios.get('/curator-api/get-status')
      setIndexFilenames(response.data.indexingFiles);
      setInsertFilenames(response.data.apifyFiles);
    } catch (e) {
      console.log(e)
    }
  }

  const startApifyButton = async () => {

    const response = await axios.post('/curator-api/start-apify-insert', {})
    if (response.data.successful) {
      Swal.fire("", "Inserting is in process", 'success');
    } else {
      Swal.fire("", "Please try again.", 'error');
    }


  }


  const startVisionIndexing = async () => {

    const response = await axios.post('/curator-api/start-indexing', {})
    if (response.data.successful) {
      Swal.fire("", "Indexing is in process", 'success');
    } else {
      Swal.fire("", "Please try again.", 'error');
    }


  }

  const uploadFiles = async () => {
    let fileInput = document.querySelector('#myFile1');
    if (!fileInput.files[0]) {
      Swal.fire("Please select a file", "", 'warning');
      return;
    }
    const formData = new FormData()
    formData.append('file', fileInput.files[0])
    setIsUploadingFile(true)
    const response = await axios.post('/curator-api/upload-files', formData)
    if (response.data.successful) {
      Swal.fire("Files uploaded successfully!", "Indexing is in process", 'success');
    } else {
      Swal.fire("Files uploading failed!", "Please try again.", 'error');
    }
    setIsUploadingFile(false)
    setInputFileKey(prev => prev + 1)
  }


  return (
    <div className='w-full m-0'>
      <h1 className='text-center m-5 text-4xl font-semibold font-serif'>APIFY Indexing</h1>
      <div className='w-full'>

        <div className='flex flex-col w-2/5 m-auto rounded-lg p-10 shadow-[0_0_10px_1px_rgba(0,0,0,0.3)] bg-slate-200'>
          <input key={inputFileKey} type="file" id="myFile1" name="filename" className='m-4' />
          <button className='font-bold bg-slate-700 w-60 py-2 px-8 rounded-lg text-white hover:bg-slate-600 disabled:bg-slate-300 my-4 mx-auto' disabled={isUploadingFile} onClick={uploadFiles}>UPLOAD FILES</button>
        </div>

      </div>
      <hr className='m-10 border-x-4' />
      <div className='m-[4rem]'>
        <h2 className='text-lg text-center font-semibold'>Apify Files status</h2>
        <div className='bg-slate-200 border-2 my-5 flex flex-col border-slate-400'>
          {
            insertfilenames.map((file, i) => <div key={i} className={`p-0 w-full border-b-2 border-slate-400 flex flex-row`}>
              <div className='w-2/3 m-0 overflow-x-auto p-4'>{file.originalname}</div>
              <div className='w-[2px] bg-white'></div>
              <div>{file.processedCount}/{file.totalNew}</div>
              <div className={`w-1/3 m-0 font-bold  ${file.status == 'complete' ? 'bg-green-500 text-white' : file.status ? 'bg-slate-600 text-white' : 'bg-slate-300'} text-center pt-5 text-xl`}>{file.status || "waiting"}</div>
            </div>)
          }
        </div>
        <div className='flex justify-center'>

          <button className='font-bold bg-green-600 disabled:bg-slate-400 w-60 py-2 px-8 rounded-lg text-white hover:bg-green-500 my-4 mx-auto' onClick={startApifyButton}>Start Inserting Data</button>
        </div>
      </div>

      <hr className='m-10 border-x-4' />

      <div className='m-[4rem]'>
        <h2 className='text-lg text-center font-semibold'>Indexing status</h2>
        <div className='bg-slate-200 border-2 my-5 flex flex-col  border-slate-400'>
          {
            indexfilenames.map((file, i) => <div key={i} className={`p-0 w-full border-b-2 border-slate-400 flex flex-row`}>
              <div className='w-2/3 m-0 overflow-x-auto p-4'>{file.name}</div>
              <div className='w-[2px] bg-white'></div>
              <div className={`w-1/3 m-0 font-bold  ${file.status == 'complete' ? 'bg-green-500 text-white' : file.status ? 'bg-slate-600 text-white' : 'bg-slate-300'} text-center pt-5 text-xl`}>{file.status || "waiting"}</div>
            </div>)
          }
        </div>
        <div className='flex justify-center'>

          <button className='font-bold bg-green-600 disabled:bg-slate-400 w-60 py-2 px-8 rounded-lg text-white hover:bg-green-500 my-4 mx-auto' onClick={startVisionIndexing}>Start Indexing</button>
        </div>
      </div>
    </div>
  )
}

export default TrendDisplay
