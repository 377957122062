
export const lifecycleSuggestions = {
  "MAY GET BORN": "Observe for 2 weeks.",
  "BORN": "Make a small quantity to gain an edge in fast fashion. The trend has been consistent for 2 weeks.",
  "GROWING": "Make in medium quantity. The trend has been consistent for 1 month. Essential for both fashion and seasonal brands.",
  "STABLE": "Produce in large quantities. The trend has been consistent for the past 2 months. Essential for all brands.",
  "REACHED PEAK": "Early signs of fading. Monitor for 2 weeks.",
  "DECLINING": "Clear signs of fading. Pause new production. Monitor for 3 weeks. Consider offering small discounts.",
  "DECLINED": "Clear inventory, offer at a steep discount.",
  "DEAD": "Clear inventory, offer at a steep discount.",
  "ATYPICAL CASE": "Atypical case. Make a manual decision or monitor for 3 weeks.",
}
