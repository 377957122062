import React from 'react'
import TrendImage from './TrendImage'
import { FaImages } from 'react-icons/fa'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { GrEdit } from 'react-icons/gr'
import { RiDeleteBinLine } from 'react-icons/ri'
import OverTrendImage from './OverTrendImage'
import axios from 'axios'

const OverTrends = ({ trend, index, selectedTrend, setSelectedTrend, setSelectedCount,fetch,setFetch }) => {
    const handleChange = () => {
        setSelectedTrend(prev => {
            prev[index] = !selectedTrend
            if (!selectedTrend) {
                setSelectedCount(prev => prev + 1)
            }
            else {
                setSelectedCount(prev => prev - 1)
            }
            return ([...prev])
        })
    }
    const deleteTrend = (id) => {
        const response = window.confirm(`Do You really want to delete trend with id ${id}`)
        if (response) {
            axios.delete(`/trend/${id}`)
                .then(res => {
                    console.log(res.data)
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
            console.log("deleted trend with id", id);
        }
    }
    const changeName = () => {
        const newName = prompt("Set new trend name", trend.name)
        if (newName && newName.trim() && newName !== trend.name) {
            axios.patch(`/trend/${trend.pk}`, { nameToChange: newName })
                .then(res => {
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    return (
        <div className=' my-10'>
            <div className='flex justify-between items-center my-5'>
                <div className='flex items-center'>
                    <input className='' type="checkbox" name="checkBox" checked={selectedTrend} onChange={handleChange} />
                    <p className='mx-4'>Category: <span className='font-bold'>{trend.searchedCategory}</span></p>
                    {/* <p className='flex justify-center items-center cursor-pointer mx-4' onClick={toogleGraph}>Graph<img className='h-7' src="./growth.png" alt="growth" /></p> */}
                    <p className='mx-4 flex items-center'>{trend.similarCategoryImages.length} <span className='text-xl'> <FaImages /></span></p>
                    <p className='mx-4 flex items-center'>{trend.overlappingCount} no of overlaps</p>
                    <p className='mx-4 flex items-center'>{trend.totalLikes} <span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></p>
                    <p className='mx-4 flex items-center'>{trend.totalComments}<span className='text-xl'><BiCommentDots /></span></p>
                    <p className='flex justify-center items-center cursor-pointer mx-4' onClick={changeName}>Trend Name: <span className='ml-1'>{trend.name}</span> <span><GrEdit /></span></p>
                    <p className='flex justify-center items-center cursor-pointer mx-4' onClick={() => { deleteTrend(trend.pk) }}>Delete Trend <span className=' text-red-700'><RiDeleteBinLine /></span></p>
                    <p className='mx-4'>Trend Id: {trend.pk}</p>
                </div>
            </div>
            <div className='flex overflow-auto'>
                {
                    trend.similarCategoryImages.map((image, index) => <OverTrendImage fetch = {fetch} setFetch = {setFetch} uVal={trend.pk} image={image} key={index} />)
                }
            </div>
        </div>
    )
}

export default OverTrends