import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Loadingwrapper from "../../component/LoadingWrapper"

const DesignTrendLifecycle = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [lifecycleData, setLifeCycleData] = useState([])
  const [trendGroups, setTrendGroups] = useState([])
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [maxCount, setMaxcount] = useState(0)

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('time', prev.has('time') ? prev.get('time') : '7 days')
      prev.set('geography', prev.has('geography') ? prev.get('geography') : 'All')
      prev.set('source', prev.has('source') ? prev.get('source') : '')
      return prev
    })
  }, [])

  const getTrends = async (signal) => {
    setLoading(true)
    setTrendGroups([])
    const resp = await axios.get(`/social-trends-v2-api/combination-trends-lifecycle/${searchParams.get('trends')}?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}`, { signal })
    setLoading(false)
    if (searchParams.get('trends') == 'retailer') {
      setTrendGroups(resp.data)
    } else {
      setLifeCycleData(resp.data)
    }

  }


  useEffect(() => {
    if (!lifecycleData) {
      return
    }
    setTrendGroups(lifecycleData.filter(l => l._id == searchParams.get('lifecycle'))[0] ? lifecycleData.filter(l => l._id == searchParams.get('lifecycle'))[0].trendGroups : [])
  }, [searchParams.get('lifecycle')])

  useEffect(() => {
    setMaxcount(trendGroups ? Math.max(0, ...trendGroups.map(g => (g.trends ? g.trends.length : 0))) : 0)
    setSelectedTrend(null)
  }, [trendGroups])


  useEffect(() => {
    if (!lifecycleData) {
      return
    }
    for (let lifecycle of lifecycles) {
      if (lifecycleData.map(l => l._id).includes(lifecycle)) {
        setSearchParams(prev => {
          prev.set('lifecycle', lifecycle)
          return prev
        })
        setTrendGroups(lifecycleData.filter(l => (l._id == lifecycle))[0] ? lifecycleData.filter(l => (l._id == lifecycle))[0].trendGroups : [])
        break
      }
    }
  }, [lifecycleData])

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getTrends()
    } catch (e) {
      console.error(e)
    }

  }, [searchParams.get('trends')])
  const lifecycles = ["PRE-BORN,BORN", "GROWING, STABLE", "REACHED PEAK", "DECAYING, DEAD"]

  return <div>
    <div className="py-2 bg-black sticky top-10 z-20">
      <p className="text-white text-center text-[28px]">{searchParams.get('gender') == 'Female' ? 'WOMEN' : 'MALE'} {' > '} {searchParams.get('category').toUpperCase()} LIFECYCLE    </p>
    </div>
    <div className="flex justify-between ">

      <div className="flex p-3 px-10 text-lg">

        <div className="inline-flex items-center" >
          <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="social">
            <input name="trends" type="radio"
              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              checked={searchParams.get('trends') == 'social'}
              onChange={() => {
                setSearchParams(prev => {
                  prev.set('trends', 'social');
                  return prev;
                })
              }}
              id="social"
            />
            <span
              class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
          </label>
          <label class="mt-px text-gray-700 cursor-pointer select-none" htmlFor="social">
            Social trends
          </label>
        </div>
        < div className="inline-flex items-center mx-4">
          <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="retailer">
            <input name="trends" type="radio"
              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              id="retailer"
              checked={searchParams.get('trends') == 'retailer'}
              onChange={() => {
                setSearchParams(prev => { prev.set('trends', 'retailer'); return prev; })
              }}
            />
            <span
              class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
          </label>
          <label class="mt-px text-gray-700 cursor-pointer select-none" htmlFor="retailer">
            Retailer trends
          </label>
        </div>

      </div>
      <div className="flex items-center">
        {
          searchParams.get('trends') == 'social' &&
          lifecycles.map(lifecycle => <button
            disabled={searchParams.get('lifecycle') == lifecycle}
            onClick={() => {
              setSearchParams(prev => {
                prev.set('lifecycle', lifecycle);
                return prev
              });
            }}
            className='mx-3 font-bold disabled:bg-slate-200 bg-white border border-slate-700 h-8 px-4'
          >{lifecycle}</button>)
        }
      </div>
    </div>

    <Loadingwrapper loading={loading}>

      <div className="w-screen ">
        <div className={`h-[${selectedTrend ? '40vh' : '75vh'}] w-screen overflow-x-auto overflow-y-auto mb-8 `}>
          {maxCount > 0 ?
            <table className="table table-auto">
              {/*
              <thead className="text-center text-2xl pt-2 pb-3 font-semibold border bg-[#807965] text-white border-black text-nowrap">
                <tr className="">
                  {trendGroups.map(t => <th className="min-w-60 pt-2 pb-3 border-2 border-black text-center" key={t.primaryValue}>{t.primaryValue}</th>)}
                </tr>
              </thead>
              */}
              <tbody>
                {
                  trendGroups.map(group => <tr key={group.primaryValue} className="odd:bg-slate-200">
                    <td className="min-w-60 pt-2 pb-3 border-2 text-center bg-[#807965] text-white border-slate-400 sticky left-0" >{group.primaryValue}<span className="font-light"> ({group.count})</span></td>
                    {
                      Array.apply(1, new Array(maxCount)).fill(1).map((d, index) => <td key={`${index}_${group.primaryValue}`} className='border border-slate-400 px-2'>

                        {
                          group.trends && group.trends.length > index ? <button
                            onClick={() => setSelectedTrend(group.trends[index])}
                            disabled={selectedTrend && group.trends[index]._id == selectedTrend._id}
                            className="disabled:bg-sky-200 block w-full h-full py-2 text-nowrap"
                          >{group.trends[index].secondaryValue}({group.trends[index].imageCount})
                          </button> : null
                        }
                      </td>)
                    }
                  </tr>)
                }
              </tbody>

            </table>
            : null}
        </div>
        {
          selectedTrend ? <div className="h-[25vh] flex w-screen overflow-x-auto sticky bottom-0 bg-white pt-2 border-t ">
            {selectedTrend && selectedTrend.images ? selectedTrend.images.map(image => <img src={image.imageUrl} key={image.mediaId} className="h-full mx-1" />) : null}
            {selectedTrend && selectedTrend.products ? selectedTrend.products.map(image => <img src={image.image_url} key={image.pk} className="h-full mx-1" />) : null}
          </div> : null
        }
      </div>
    </Loadingwrapper >

  </div >
}

export default DesignTrendLifecycle 
