import React, { useEffect, useState } from 'react'
import Popup from '../../component/Popup'
import { useSelector } from 'react-redux'
import TrendImage from './DesigntrendImage'
import TopCommentsPosts from '../../component/TopCommentsPosts'
import axios from 'axios'


const Trend = ({ trend, gender, setFetch, socialTrendId }) => {
  const { userLoggedIn } = useSelector(state => state)

  const [trendNameUpdate, setTrendNameUpdate] = useState('');

  const [showTrendComments, setShowTrendComments] = useState(false)
  const [commentsPopupTrendImages, setCommentsPopupTrendImages] = useState([])
  const handleShowTrendComments = () => {
    setCommentsPopupTrendImages(trend.images)
    setShowTrendComments(true)
  }

  const updateName = async () => {
    const name = document.querySelector(`#input${trend.id}`).value;

    axios.patch(`/design-api/name/${trend.id}/${socialTrendId}`, {
      name
    }).then(() => {
      console.log("success")
      setFetch(true)
    }).catch(console.error);

  }

  const updateDeleteTrend = () => {
    const deleted = !trend.deleted == true;
    console.log(deleted)
    axios.post(`/design-api/delete-trend/${socialTrendId}/${trend.id}`, { deleted }).then(() => {
      setFetch(true)
    })
  }

  useEffect(() => {
    setTrendNameUpdate(trend.name);
  }, [trend])


  return (
    <div className=' my-4'>
      <div className='flex justify-between items-center '>
      </div>
      <h3 className='my-1 m-2 font-bold text-2xl text-center ' >Rank-{trend.rank}  {trend.name}</h3>

      <div className='flex flex-row m-2'>
        {
          userLoggedIn.curator && <button className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center w-40' onClick={handleShowTrendComments}>Top Comments</button>
        }
        {
          userLoggedIn.curator &&
          <div>
            <input type='text' className='bg-slate-200 px-8 outline-none mx-2 rounded-xl' id={"input" + trend.id} />
            <button className='primary-btn' onClick={updateName}   >Update Name</button>
            <button className='primary-btn' onClick={updateDeleteTrend}   >{trend.deleted == true ? 'Save' : 'Delete'}</button>
          </div>
        }
      </div>
      <div className='flex overflow-auto'>
        {
          trend.images.map((image, index) => <TrendImage socialTrendId={socialTrendId} key={index} uVal={trend.id} image={image} category={trend.category} gender={gender} setFetch={setFetch} />)
        }
      </div>
      <Popup isPopUp={showTrendComments} setIsPopUp={setShowTrendComments}>
        <TopCommentsPosts images={commentsPopupTrendImages} category={trend.category} />
      </Popup>
    </div>
  )
}

export default Trend
