import axios from "axios";
import { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner";

const SocialV2Curator = () => {

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  /*
   *
Date = 6th Nov 2023
Total raw images added =
Count of handles for which raw images came today = 
Trends queried today =
Count of new images added to existing/new trends = 
New trends made today =
   * */

  const getPageData = () => {
    const limit = 20
    setPageData([])
    setLoading(true)
    axios.get(`/curator-api/social-tags-data?page=${page}&limit=${limit}`).then(response => {
      setPageData(response.data)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getPageData();
  }, [page])



  return <div className="flex flex-col">
    <div className="w-full m-4 float-left">
      <div className="float-right mr-10 flex flex-row">
        <button className="primary-btn m-2" disabled={page == 1} onClick={() => { setPage(page => { return page - 1 }) }}>prev</button>
        <p className="my-2 py-1 font-bold">{page}</p>
        <button className="primary-btn m-2" onClick={() => { setPage(page => { return page + 1 }) }}>next</button>
      </div>
    </div>
    {loading
      ? <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"><TailSpin /></div>
      : <table className="table-auto w-full my-6 bg-slate-100 p-10 border-2 border-slate-300">
        <thead className="p-2 h-12 border-b-2 border-slate-300">
          <tr>
            <th>Date</th>
            <th>Images Added</th>
            <th>Count of handles</th>
            <th>Trends queried</th>
            <th>Images used</th>
            <th>New trends</th>
          </tr>
        </thead>
        <tbody>
          {pageData.map((row, index) => <tr key={index} className="h-12 ">
            <td className="text-center">{row.date}</td>
            <td className="text-center">{row.raw}</td>
            <td className="text-center">{row.handles}</td>
            <td className="text-center">{row.queries}</td>
            <td className="text-center">{row.imagesUsed}</td>
            <td className="text-center">{row.newTrends}</td>
          </tr>)}
        </tbody>
      </table>}
  </div>
}

export default SocialV2Curator
