import axios from "axios";
import { useEffect, useState } from "react"
import ModalImage from "react-modal-image";
import { useSelector } from "react-redux";
import Loadingwrapper from "../../component/LoadingWrapper"
import { indexedPostUrl } from "../../utility/helper_functions";
import ImageTagsDetails from "./ImageTagsDetails";


const CombinationTrendImages = ({ trendId, searchParams, gender, setTrendId }) => {

  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageCount, setImageCount] = useState(1);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const { userLoggedIn } = useSelector(state => state)
  const getImages = async () => {
    if (!trendId) {
      return
    }
    setLoading(true)
    const resp = await axios.get(`/social-trends-v2-api/combination-trend-images/${searchParams.get('trends') == 'retailer' ? 'retailer' : 'social'}/${trendId}`)
    if (searchParams.get('trends') == 'retailer') {
      resp.data.images = resp.data.images.map(prod => ({ mediaId: prod.pk, postUrl: prod.product_url, username: prod.brand, imageUrl: prod.image_url, title: prod.title }))
    }
    setLoading(false)
    setImageCount(resp.data.count)
    setImageList(resp.data.images)
  }

  useEffect(() => {
    getImages()
  }, [page])

  return trendId == null ? null : <div className="w-[80vw] h-[80vh] ">
    <h2 className="text-center">Trend images</h2>
    <div className="flex justify-end"><div>
      {false && <>
        <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-400 px-4 py-1 rounded-xl text-sm text-white "
          disabled={page == 1}
          onClick={() => {
            setPage(prev => {
              if (prev > 1) {
                return prev - 1
              }
              return 1
            })
          }}
        >prev</button>
        <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-400 px-4 py-1 rounded-xl text-sm text-white "
          disabled={page * limit >= imageCount}
          onClick={() => {
            setPage(prev => {
              if (prev * limit >= imageCount) {
                return prev
              }
              return prev + 1
            })
          }}
        >next</button>
      </>}
      <p>{imageCount} images</p>
    </div></div>
    <Loadingwrapper loading={loading}>
      {imageList.length > 0 ? <div className="flex flex-wrap ">
        {imageList.map(img => <div key={img.mediaId} className="p-1 min-w-fit mb-4 ">
          <ModalImage src={img.imageUrl} className=' h-64 my-1' small={img.imageUrl} large={img.imageUrl} hideZoom={false} hideDownload={true} />
          <a
            href={indexedPostUrl(img.postUrl, img.mediaId)}
            target='_blank'
            className="text-sm text-blue-500 hover:text-blue-400 hover:underline">{img.username}</a>
          <div>{img.timeStamp ? new Date(img.timeStamp).toLocaleDateString() : ''}</div>
          {searchParams.get('trends') == 'social' && userLoggedIn.curator && <a href={`/curator-meta-tags/${img.mediaId}`} className='text-white bg-slate-800 rounded px-2' target='_blank'>Data</a>}
          {searchParams.get('trends') == 'social' ? <ImageTagsDetails mediaId={img.mediaId} /> : null}
          {img.title && <div className="flex text-[10px] "><p className=" text-wrap">{img.title.slice(0, 30)}...</p></div>}
        </div>)}

      </div> : <div className="w-full p-40">
        <p className="text-center">No Products Found</p>
      </div>

      }

    </Loadingwrapper>

  </div >
}

export default CombinationTrendImages;
