import axios from "axios";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";


const Moodboard = () => {
  const [loading, setLoading] = useState(false);
  const [trendData, setTrendData] = useState(null)
  const { trendId } = useParams()

  const getTrendData = () => {
    if (!trendId) {
      return
    }
    axios.get(`/social-trends-v2-api/combination-trends/moodboard/${trendId}`).then(res => {
      setTrendData(res.data)
      setLoading(false)
    })
  }

  useEffect(getTrendData, [trendId])

  return <div className="w-screen">
    {
      loading ? null : <>  <div className="flex w-screen">

      </div>
      </>
    }
  </div>
}

export default Moodboard
