import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Blocks } from 'react-loader-spinner'
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";

const RawImage = ({ image, curator, updateTagsPopupMediaId }) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  const handelOnLoadImage = () => {
    setIsImgLoaded(true)
  }
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleCopyLink = () => {
    setIsButtonClicked(true);
    window.navigator.clipboard.writeText(image.imageUrl)
  }
  return (
    <div className='mr-10 max-[700px]:mr-1 min-w-max text-center mb-5'>
      <img className=' h-96' src={image.imageUrl} alt="name" style={{ display: isImgLoaded ? "block" : 'none' }} onLoad={handelOnLoadImage} />
      {
        !isImgLoaded && <div className='mx-5 h-96 w-52 flex items-center justify-center'>
          <Blocks
            visible={true}
            height="50"
            width="40"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      }
      {<a className='text-blue-600 m-1 font-bold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">IG LINK</a>}
      <p>IG Date: {moment(image.timeStamp).format('DD MMM YYYY')}</p>
      <p className='font-extrabold'>Loaded: {curator ? new Date(new Date(image.insertDate).getTime()).toLocaleString('en-GB').slice(0, 14).replace('T', ' - ') + 'H' : moment(image.insertDate).format('DD MMM YYYY')}</p>
      {image.likes && <p>({image.likes}) Likes</p>}
      {
        curator && <>
          <h3>Filled Categories</h3>
          {image.filledCategories && image.filledCategories.map((cat, i) => <p key={i} className='text-sm'>{i + 1}. {cat}</p>)}
          <button className={`border-2 rounded-lg px-3 py-1 font-medium border-slate-600 ${isButtonClicked ? "bg-slate-600 text-white" : "bg-white text-black"} hover:bg-slate-700 hover:border-slate-700 hover:text-white`} onClick={handleCopyLink}>{isButtonClicked ? "Image Link Copied" : "Copy Image Link"}</button>
          <button className={`border-2 rounded-lg px-3 py-1 font-medium border-slate-700 ml-2 ${image.metaDataAdded ? 'bg-green-600 text-white border-0 hover:bg-green-600 active:bg-green-700' : 'border-slate-600 bg-white text-black hover:bg-slate-700 hover:hover:text-white '}`} onClick={() => updateTagsPopupMediaId(image.mediaId)}>Tags Data</button>
          {
            image.trends && image.trends.map(t => <div className='w-full'> <a href={`/t/${t.id}`} className='text-blue-600 font-semibold'>{t.name}</a></div>)
          }
        </>
      }
    </div >
  )
}

export default RawImage
