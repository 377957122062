import React,{ useEffect, useState } from 'react'
import { useParams , useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Axios from 'axios';
import SavedImage from './SavedImage';
import Trend from '../trendDisplayPage/Trend'
import Pagination from './Pagination';

const WishList = () => {
	const navigate = useNavigate();
	const { userLoggedIn } = useSelector(state => state)
	useEffect(() => {
	    if (!userLoggedIn.loggedIn) {
		  navigate('/login');
	    }
	    else fetchSavedData(1,tab);
	}, []);

	const [tab,setTab] = useState('images')
	const [imagesData, setImagesData] = useState([])
	const [trendsData, setTrendsData] = useState([])
	const [imageDataPages, setImagesDataPages] = useState(1)
	const [trendDataPages, setTrendDataPages] = useState(1)

	const [imageDataPageNumber, setImagesDataPageNumber] = useState(1)
	const [trendDataPageNumber, setTrendDataPageNumber] = useState(1)
	const [spinner,setSpinner] = useState(true)
	const [paginationDisabled,setPaginationDisabled] = useState(false)

	const updateTab = (changedTab)=>{
		if(tab ==  changedTab){
			return ;
		}
		setTab(changedTab);
		if((changedTab=='trends' && trendDataPages!=0) 
			||(changedTab=='images'  && imageDataPages!=0) ){

			fetchSavedData(1,changedTab)
		}
	}

	const changePage = async (newPageNumber) =>{
		if(tab=='images'){
			setImagesDataPageNumber(newPageNumber);
		} else {
			setTrendDataPageNumber(newPageNumber)
		}
		await fetchSavedData(newPageNumber,tab);
		
		
	}

	const fetchSavedData = async(pageNumber,changedTab)=>{
		setPaginationDisabled(true);
		setSpinner(true);
		Axios.get(`/save/${changedTab}?page=${pageNumber}`).then(res=>{
			setSpinner(false);
			if(changedTab=='images'){				
				setImagesData(res.data.images)
				setImagesDataPages(res.data.pages)
			} else {
				setTrendsData(res.data.trends)
				setTrendDataPages(res.data.pages)
			}
		}).catch(e=>{
			
			console.error(e)
		}).finally(()=>{
			setPaginationDisabled(false);
			setSpinner(false)
		})
	}

    	return (
      <div className='mx-5'>
      	<div className='w-full p-4 text-lg flex'>
			<div className={`w-full p-3 ${tab=='images'?'border-t-2':'border-b-2'} border-black`}>
				<button className='text-center w-full' onClick={()=>{updateTab('images')}}>SAVED IMAGES</button>
			</div>
			<div className={`w-10 ${tab=='images'?'diagonal-div-tl-br':'diagonal-div-bl-tr'} `}>

			</div>
			<div className={`w-full p-3 ${tab=='trends'?'border-t-2':'border-b-2 '} border-black`}>
				<button className='w-full text-center' onClick={()=>{updateTab('trends')}}>SAVED TRENDS</button>
			</div>
		</div>

		<div className='p-4'>
			{tab =='images' && <div>
					<Pagination totalpages={imageDataPages} currentPage={imageDataPageNumber} changePage={changePage} paginationDisabled={paginationDisabled}/>
					{imagesData.filter(img => img).map((img,index) => <SavedImage key={img.mediaId} image={img} fetch={()=>{ fetchSavedData(imageDataPageNumber,'images') }}/> )}
			</div>}

			{tab =='trends' && <div>
			<Pagination totalpages={trendDataPages} currentPage={trendDataPageNumber} changePage={changePage} paginationDisabled={paginationDisabled}/>
				{
					trendsData!=undefined && trendsData.map(trend => <Trend key={trend.id} trend={trend} toMerge={()=>{}} setFetch={()=>{ fetchSavedData(trendDataPageNumber,'trends') }} fetch={true} trendSaved={true} /> )
				}
			</div>}
		</div>
		{ spinner && <div className='w-full'>
			<div class="min-h-[10rem] flex flex-col bg-white">
				<div class="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
					<div class="flex justify-center">
						<div class="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>}

      </div>
    )
}

export default WishList