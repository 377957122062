import axios from "axios";
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Loadingwrapper from "../../component/LoadingWrapper";
import CategoryDisplay from "./CategoryDisplay"
import { IoArrowBackCircle, IoArrowBlackCircle } from 'react-icons/io5'
import { FaAngleDown, FaAngleRight, FaAngleUp, FaArrowCircleUp, FaArrowUp, FaFilter } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import Popup from '../../component/Popup';
import SocialTrendsTagsImages from './SocialTrendTagsImages'
import { indexedPostUrl } from "../../utility/helper_functions";
import { HeaderSets, HeadersRanking, metaCategoryTags, SetSorts, ToolTipText } from "../../constants";
import ModalImage from "react-modal-image";
import ImageTagsDetails from "./ImageTagsDetails";
import HeaderButton from "./HeaderSelectButton";
import CombinationTrends from './CombinationTrends';
import CombinationTrendFirstHeaders from './CombinationTrendFirstHeaders';
import { useSelector } from "react-redux";
import TrendLifeCycleTooptip from "./TrendLifeCycleTooltip";
const SocialTrendsTags = ({ gender }) => {

  const [trendCount, setTrendCount] = useState(0);
  const [trends, setTrends] = useState([])
  const [page, setPage] = useState(1);
  const initialParams = {
    category: gender == 'Female' ? 'Dresses' : 'Shirts',
    time: '7 days',
    geography: 'All',
    source: '',
    limit: 50,
  }
  const { userLoggedIn } = useSelector(state => state)
  const [searchParams, setSearchParams] = useSearchParams(initialParams);
  const [selectedTags, setSelectedTags] = useState(null);
  const [totalArticleCount, setTotalArticleCount] = useState(10000);
  const [showFilters, setShowFilters] = useState(false);
  const [hoveredCats, setHoveredCats] = useState([])
  const [breakdownTags, setBreakdownTags] = useState([])
  const [filteredImages, setFilteredImages] = useState(0);
  useEffect(() => {
    setSearchParams(prev => {
      prev.set('category', prev.get('category') ? prev.get('category') : (gender == 'Female' ? 'Dresses' : 'Tops'))
      prev.set('time', prev.get('time') ? prev.get('time') : '7 days')
      prev.set('geography', prev.get('geography') ? prev.get('geography') : 'All')
      prev.set('source', prev.get('source') ? prev.get('source') : '')
      if (prev.get('tag')) {
        prev.set('tag', prev.get('tag'))
      }
      prev.set('trends', prev.get('trends') || 'social')
      prev.set('sort', prev.get('sort') || (prev.get('combinations') == 'multiple' ? '' : 'popularity'))
      return prev
    })
  }, [gender])
  const [loading, setLoading] = useState(false)
  const getTrends = async (signal) => {
    setLoading(true)
    if (page == 1) {
      setTrends([])
    }
    try {
      const resp = await axios.get(`/social-trends-v2-api/trends?gender=${gender || 'Female'}&category=${searchParams.get('category').split('&').join('%26') || 'Dresses'}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&page=${page || 1}&limit=${searchParams.get('limit') || 50}${searchParams.get('tag') ? `&tag=${(searchParams.get('tag').includes('SET ') ? HeaderSets[searchParams.get('category')][searchParams.get('tag')].join(',') : searchParams.get('tag')).split('&').join('%26')}` : ''}&sort=${searchParams.get('sort')}${getBreakdownUrlString()}`, { signal })
      setLoading(false)
      setTrendCount(resp.data.count)
      setFilteredImages(resp.data.imageCount)
      if (page == 1) {
        setTrends(resp.data.trends)
      } else {
        setTrends(prev => ([...prev, ...resp.data.trends]))
      }
    } catch (e) {
      if (e.code != 'ERR_CANCELED') {
        console.error(e)
      }
    }
  }

  const removeTrend = async (deleted, category, tag, value) => {
    await axios[deleted ? 'patch' : 'delete'](`/social-trends-v2-api/remove-trend?gender=${gender || 'Female'}&category=${category.split('&').join('%26')}&tag=${tag.split('&').join('%26')}&value=${encodeURIComponent(value)}`)
    getTrends()
  }

  useEffect(() => {
    setSearchParams(prev => {
      if (prev.get('combinations')) {
        prev.set('sort', '')
      } else if (prev.get('sort') == '') {
        prev.set('sort', 'popularity')
      }
      return prev
    })
  }, [searchParams.get('combinations')])


  useEffect(() => {
    setSearchParams(prev => {
      if (prev.get('combinations')) {
        prev.set('time', prev.get('trends') == 'retailer' ? '90 days' : '7 days')
      } else {
        prev.set('time', prev.get('trends') == 'retailer' ? '90 days' : '30 days')
      }
      return prev
    })
  }, [searchParams.get('combinations'), searchParams.get('trends')])

  const getBreakdownUrlString = () => {
    return `&breakdownlevel=${breakdownTags.length}${breakdownTags.length > 0 ? `&${breakdownTags.map((pair, i) => (`tagName${i}=${encodeURIComponent(pair.tagName)}&valueName${i}=${encodeURIComponent(pair.valueName)}`)).join('&')}` : ''}`
  }

  const getRetailerTrends = async (signal) => {
    setLoading(true)
    if (page == 1) {
      setTrends([])
    }
    try {
      const resp = await axios.get(`/social-trends-v2-api/retailer-trends?gender=${gender || 'Female'}&category=${searchParams.get('category').split('&').join('%26') || 'Dresses'}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&page=${page || 1}&limit=${searchParams.get('limit') || 50}${searchParams.get('tag') ? `&tag=${(searchParams.get('tag').includes('SET ') ? HeaderSets[searchParams.get('category')][searchParams.get('tag')].join(',') : searchParams.get('tag')).split('&').join('%26')}` : ''}&sort=${searchParams.get('sort')}${getBreakdownUrlString()}`, { signal })
      setLoading(false)
      setTrendCount(resp.data.count)
      if (page == 1) {
        setTrends(resp.data.trends)
      } else {
        setTrends(prev => ([...prev, ...resp.data.trends]))
      }
    } catch (e) {
      if (e.code != 'ERR_CANCELED') {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    if (searchParams.get('combinations') == 'multiple') {
      return
    }
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      if (searchParams.get('trends') == 'social')
        getTrends(signal)
      else
        getRetailerTrends(signal);
    } catch (e) {
      console.log(e)
    }
    return () => {
      abortController.abort()
    }
  }, [gender, searchParams, page, breakdownTags])

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('tag', Object.keys(HeadersRanking[searchParams.get('category')]).filter(t => !breakdownTags.map(bt => bt.tagName).includes(t))[0])
      return prev
    })
    setPage(1)
  }, [breakdownTags])

  useEffect(() => {
    setPage(1)
    setTrends([])
  }, [gender, searchParams])


  return <div className="w-full min-h-screen  ">

    <div className="px-12">
      {searchParams.get('combinations') == 'multiple' ? <a className={``}
        href='/'
      ><IoArrowBackCircle size={35} className="text-gray-400 hover:text-gray-600" /></a> : null}
    </div>

    {
      userLoggedIn.curator && <a href={`/v2-leaderboard?gender=${gender}&category=${searchParams.get('category')}`} target='_blank'>Leaderboard</a>
    }
    <div className="flex p-1 px-10 text-xs ">

      <div className="inline-flex items-center" >
        <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="social">
          <input name="trends" type="radio"
            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
            checked={searchParams.get('trends') == 'social'}
            onChange={() => {
              setSearchParams(prev => {
                prev.set('trends', 'social');
                return prev;
              })
            }}
            id="social"
          />
          <span
            class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </span>
        </label>
        <label class="mt-px font-semibold text-gray-700 cursor-pointer select-none" htmlFor="social">
          Social trends
        </label>
      </div>
      < div className="inline-flex items-center">
        <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="retailer">
          <input name="trends" type="radio"
            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
            id="retailer"
            checked={searchParams.get('trends') == 'retailer'}
            onChange={() => {
              setSearchParams(prev => { prev.set('trends', 'retailer'); return prev; })
            }}
          />
          <span
            class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </span>
        </label>
        <label class="mt-px font-semibold text-gray-700 cursor-pointer select-none" htmlFor="retailer">
          Retailer trends
        </label>
      </div>

    </div>
    {breakdownTags.length == 0 ? <h1 className="text-2xl pl-10 mt-1">{searchParams.get('trends') == 'social' ? 'Social' : 'Retailer'} {searchParams.get('tag')} Trends In <span className="font-semibold">{searchParams.get('category')}</span></h1> : null}
    {
      breakdownTags.length != 0 ?
        <h2 className="text-blue-500 font-semibold text-2xl px-10">Exploring top characteristics within : {breakdownTags.map(t => (`${t.valueName}(${t.tagName})`)).join(', ')}</h2>
        : null
    }
    <div className="top-14 bg-white  mt-2">

      <div className="px-10">
        <button className={`font-medium relative group p-0.5 px-1 m-0.5 rounded border-slate-700 border-2  ${searchParams.get('combinations') != 'multiple' ? 'bg-white text-black' : 'bg-green-700 text-white'}  text-[15px] `}
          onClick={() => {
            setSearchParams(prev => {
              if (prev.get('combinations') != 'multiple') {
                prev.set('combinations', 'multiple')
              } else {
                prev.delete('combinations')
              }
              return prev;
            })
          }}
        >Design Recipes 😍😍</button>
      </div>
      {
        searchParams.get('combinations') == 'multiple' ? <CombinationTrendFirstHeaders gender={gender} category={searchParams.get('category')} searchParams={searchParams} setSearchParams={setSearchParams} setFilteredImages={setFilteredImages} /> : null
      }
      {searchParams.get('combinations') != 'multiple' && <h3 className="w-full px-10 font-semibold text-xl">Please Choose Characteristics</h3>}
      <div className="w-full bg-white ">
        <div className="flex w-full px-10 flex-wrap ">
          {
            searchParams.get('combinations') != 'multiple' &&
            ['COLOR', 'MULTICOLOR', ...(metaCategoryTags[searchParams.get('category')] || [])]
              .sort((a, b) => {
                if (HeadersRanking[searchParams.get('category')]) {
                  return HeadersRanking[searchParams.get('category')][a] - HeadersRanking[searchParams.get('category')][b]
                }
                return 0
              }).map(tag => <HeaderButton disabled={breakdownTags.map(t => t.tagName).includes(tag)} tag={tag} searchParams={searchParams} hoveredCats={hoveredCats} setSearchParams={setSearchParams} isSetButton={false} />)
          }
        </div>
        <div className="flex w-full px-10 flex-wrap ">
          {searchParams.get('combinations') != 'multiple' &&
            ['Single Characteristics', ...(HeaderSets[searchParams.get('category')] ? Object.keys(HeaderSets[searchParams.get('category')]).sort((a, b) => {
              return SetSorts[a] - SetSorts[b];
            }) : [])].map(tag => <HeaderButton tag={tag} setHoveredCats={setHoveredCats} searchParams={searchParams} setSearchParams={setSearchParams} isSetButton={true} category={searchParams.get('category')} />)
          }
        </div>
      </div>
    </div>

    <div className="flex flex-col py-2  text-sm mt-2">
      {
        searchParams.get('trends') == 'social' &&
        <div className="flex w-full justify-left px-10 ">
          {searchParams.get('trends') != 'retailer' ? <div className='mb-2 w-full z-0 '>
            <div onClick={() => setShowFilters(prev => !prev)} className=' text-sm bg-slate-50 pl-[5%] pr-[10%] py-3'>
              <div className="flex justify-between w-full">
                <div className="flex">
                  <FaFilter /> Filters
                </div>
                {showFilters ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>
            <div className={`p-1 border bg-white ${showFilters ? 'block' : 'hidden'}`}>
              <div className="mb-2 pl-1">
                Trending in {['3 days', '7 days', '15 days', '30 days', '60 days', '90 days', '180 days']
                  .map(t => <button
                    key={t}
                    className='mx-2 disabled:font-semibold disabled:underline'
                    disabled={t == searchParams.get('time')}
                    onClick={() => {
                      setSearchParams(prev => {
                        prev.set('time', t);
                        return prev
                      })
                    }}
                  >{t}</button>)}
              </div>

              <div className="mb-2 pl-1">
                Geography {['All', 'Western', 'Asian', 'Indian']
                  .map(t => <button
                    key={t}
                    className='mx-2 disabled:font-semibold disabled:underline'
                    disabled={t == searchParams.get('geography')}
                    onClick={() => {
                      setSearchParams(prev => {
                        prev.set('geography', t);
                        return prev
                      })
                    }}
                  >{t}</button>)}
              </div>

              <div className="mb-2 pl-1">
                Source {['All', 'Mega Influencers', 'Micro Influencers', 'Celebrities', 'Pages/Magazines', 'Stores/Brands']
                  .map(t => <button
                    key={t}
                    className={`mx-2 ${(searchParams.get('source') || '').split(',').includes(t) || (searchParams.get('source') == '' && t == 'All') ? 'font-semibold underline' : ''} `}
                    onClick={() => {
                      setSearchParams(prev => {
                        let newVal = prev.get('source');
                        if (t == 'All') {
                          newVal = ''
                        } else {
                          if (newVal.includes(t)) {
                            newVal = newVal.split(',').filter((item) => item != t).join(',')

                          } else {
                            newVal = newVal == '' ? t : newVal + ',' + t
                          }
                        }
                        if (newVal.split(',').length == 5) {
                          newVal = ''
                        }
                        prev.set('source', newVal);
                        return prev
                      })
                    }}
                  >{t}</button>)}
              </div>

              <div className="flex mb-2 pl-1 ">
                <div className="p-1 pr-3">Sort By</div>
                {
                  searchParams.get('combinations') == 'multiple' ? <div className="inline-flex items-center">
                    <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="">
                      <input name="sort" type="radio"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        checked={searchParams.get('sort') == ''}
                        onChange={() => {
                          setSearchParams(prev => { prev.set('sort', ''); return prev; })
                        }}
                        id=""
                      />
                      <span
                        class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                          <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                        </svg>
                      </span>
                    </label>
                    <label class="mt-px text-gray-700 cursor-pointer select-none" htmlFor="popularity">
                      (Avg of Popularity + Rate of Growth)
                    </label>
                  </div> : null
                }
                <div className="inline-flex items-center">
                  <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="popularity">
                    <input name="sort" type="radio"
                      class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      checked={searchParams.get('sort') == 'popularity'}
                      onChange={() => {
                        setSearchParams(prev => { prev.set('sort', 'popularity'); return prev; })
                      }}
                      id="popularity"
                    />
                    <span
                      class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                      </svg>
                    </span>
                  </label>
                  <label class="mt-px text-gray-700 cursor-pointer select-none" htmlFor="popularity">
                    Dominance (All time popular trends)
                  </label>
                </div>
                <div className="inline-flex items-center">
                  <label class="relative flex items-center p-1 px-3 rounded-full cursor-pointer" htmlFor="growth">
                    <input name="sort" type="radio"
                      class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      id="growth"
                      checked={searchParams.get('sort') == 'growth'}
                      onChange={() => {
                        setSearchParams(prev => { prev.set('sort', 'growth'); return prev; })
                      }}
                    />
                    <span
                      class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                      </svg>
                    </span>
                  </label>
                  <label class="mt-px text-gray-700 cursor-pointer select-none" htmlFor="growth">
                    Rate of growth (Trends picking up recently)
                  </label>
                </div>

              </div>
            </div>
          </div> : null}
        </div>
      }
      {
        userLoggedIn && userLoggedIn.curator ? <h2 className="px-10 font-semibold my-4">Images count : {filteredImages}</h2> : null
      }
      <div className="flex px-6">
        {breakdownTags.map((tag, i) => <button className=" flex  mx-2 my-1 px-3 py-1.5 rounded-xl bg-blue-600 hover:bg-blue-800 text-white" key={i}
          onClick={() => {
            setBreakdownTags(prev => (prev.filter(item => item.tagName != tag.tagName)))
          }}
        >{i + 1}. {tag.valueName} ({tag.tagName}) <div className='mx-1 pt-1'><ImCross color='white' /></div></button>)}

      </div>

      {
        searchParams.get('combinations') != 'multiple' && <div className="mt-2">
          <h3 className="w-full px-10 font-semibold text-xl">Depth Forecast</h3>
          <div className="flex px-10">
            Enter projected sales (no. of articles):
            <input className="outline-none mx-2 px-2 py-1 bg-slate-50 w-36 rounded text-slate-500 font-semibold shadow"
              value={`${totalArticleCount}`}
              onChange={(e) => {
                if (e.target.value == "") {
                  setTotalArticleCount(0)
                  return
                }
                const value = e.target.value.length > 0 ? parseInt(e.target.value) : 0

                if (isNaN(value)) {
                  return
                }
                setTotalArticleCount(parseInt(e.target.value))
              }}
            />

          </div>
        </div>
      }
      {
        searchParams.get('combinations') != 'multiple' &&
        <div className="w-full flex flex-wrap justify-center mt-2">
          {
            trends.length > 0 ?
              trends.map((trend, i) => <div className={`w-1/2 shrink-0 h-96 p-2 odd:border-r-[1.5px] border-slate-700 border-b-[1.5px]`} key={`${trend.tag}_${trend.value} `} >
                <div className="w-full h-8 flex justify-between px-6 overflow-x-hidden overflow-clip">
                  <p className="text-xl my-1 shrink-0 font-medium">{trend.value} {searchParams.get('tag') && !searchParams.get('tag').includes('SET ') ? null : <a href={window.location.pathname + window.location.search + (window.location.search.includes('?') ? '&' : '?') + `tag=${trend.tag}`} target='_blank' className="text-[#5050FF] font-medium hover:font-semibold">( {trend.tag} )</a>}</p>
                  <button className="text-[8px] py-[0.25px] px-1 bg-blue-500 hover:bg-blue-600 text-white rounded m-1 shrink-0 font-medium" onClick={() => {
                    setBreakdownTags(prev => ([...prev, { tagName: trend.tag, valueName: trend.value }]))
                  }}>Breakdown</button>
                  <TrendLifeCycleTooptip trend={trend} trendstype={searchParams.get('trends')} index={i} />
                  <p className="h-4 shrink-0 mx-2 text-xs ">Count : {trend.count} </p>
                  <p className="h-4 shrink-0 mx-1 text-xs">Depth Forecast: {parseInt(trend.share * totalArticleCount / 100)} articles </p>
                  <p className="shrink-0 text-xs">Trend Rank <span className="font-bold">{i + 1} of {trendCount}</span></p>
                </div>
                {
                  userLoggedIn.curator ? <div className="flex justify-end">
                    <button onClick={() => removeTrend(trend.deleted, searchParams.get('category'), trend.tag, trend.value)} className={`${trend.deleted ? "bg-red-500 hover:bg-red-700" : "bg-blue-600 hover:bg-blue-700"} text-white rounded-xl text-xs px-4 py-1`}>{trend.deleted ? <span>Restore Trend</span> : <span>Delete Trend</span>}</button>
                  </div> : null
                }
                <div className="relative w-full h-0">
                  <button className=" absolute right-0 h-60 top-4 px-1 hover:bg-slate-100-holder rounded-l bg-slate-300/80 hover:bg-slate-100/75 py-4 z-10" onClick={() => {
                    setSelectedTags({ tag: trend.tag, value: trend.value })
                  }}>
                    <FaAngleRight size={50} color='#666' />
                  </button>
                </div>
                <div className="h-96 flex overflow-x-hidden w-full shrink-0">
                  {
                    trend.images && trend.images.map(img => <div key={img.postUrl} className="p-1 min-w-fit">

                      <ModalImage src={img.imageUrl} className=' h-64 my-1' small={img.imageUrl} large={img.imageUrl} hideZoom={false} hideDownload={true} />
                      {/*
                    <img className="h-64 my-1" src={img.imageUrl} />
                  */}
                      <a
                        href={indexedPostUrl(img.postUrl, img.mediaId)}
                        target='_blank'
                        className="text-sm text-blue-500 hover:text-blue-400 hover:underline">{img.username}</a>
                      <div>{img.timeStamp ? new Date(img.timeStamp).toLocaleDateString() : ''}</div>
                      {searchParams.get('trends') == 'social' ? <ImageTagsDetails mediaId={img.mediaId} /> : null}
                    </div>)
                  }
                </div>

              </div>)
              : <div className="w-full my-20">
                {!loading && <p className="w-full text-center text-lg font-semibold">No trends Found</p>}
              </div>
          }
          <Loadingwrapper loading={loading}>
          </Loadingwrapper>
          <div className="flex justify-center py-3 w-full shrink-0">
            {!loading
              && (page * 50) < trendCount
              && <button className="text-white bg-blue-500 active:bg-blue-700 hover:bg-blue-600 rounded px-4 py-1 text-lg font-semibold " disabled={loading || page * parseInt(searchParams.get('limit')) >= trendCount} onClick={() => {
                setPage(prev => prev + 1)
              }}>Load More</button>}
          </div>
        </div>
      }
    </div>

    {
      searchParams.get('combinations') == 'multiple' ? <CombinationTrends gender={gender} category={searchParams.get('category')} searchParams={searchParams} setSearchParams={setSearchParams} setFilteredImages={setFilteredImages} /> : null
    }
    <div className="fixed right-4 bottom-4 w-10 h-10 z-20">
      <button className="w-full h-full rounded-full bg-white"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" })
        }}
      ><FaArrowCircleUp
          size={40}
          color='#444444'
          stroke="#fff"
        /></button>

    </div>
    <Popup isPopUp={selectedTags != null} setIsPopUp={(next) => {
      if (!next) {
        setSelectedTags(null)
      }
    }}>
      <SocialTrendsTagsImages selectedTag={selectedTags} gender={gender} searchParams={searchParams} />

    </Popup>
  </div >
}

export default SocialTrendsTags
