import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    minSimilarityScore : "50.0"
}

export const minScoreSlice = createSlice({
    name: 'minSimilarityScore',
    initialState,
    reducers: {
        setMinSimilarityScore: (state,action) => {
            state.minSimilarityScore = action.payload
        },
    },
})  
export const { setMinSimilarityScore } = minScoreSlice.actions

export default minScoreSlice.reducer
