import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

const DateRangeFilter = ({ dateFilter, setDateFilter, setFetch,apiRoute="trend"}) => {
    const [showDateFilter, setShowDateFilter] = useState(false)
    const [dateRange, setDateRange] = useState({
        maxDate: "",
        minDate: "",
    })
    useEffect(() => {
        axios.get('/'+apiRoute+'/date')
            .then(res => {
                setDateRange({
                    maxDate: moment(res.data.maxDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
                    minDate: moment(res.data.minDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
                })
            })
            .catch(e => {
                console.log(e)
            })
    }, [])
    const fillForm = (e) => {
        const { name, value } = e.target;
        setDateFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const doFocus = () => {
        setShowDateFilter(true)
    }
    const doBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowDateFilter(false)
        }
    }
    const iniDateFilter = {
        from: "",
        to: "",
        dateType: 'originating'
    }
    const handleReset = () => {
        setDateFilter(iniDateFilter)
        setFetch(prev => !prev)
    }
    const handlego = () => {
        setFetch(prev => !prev)
    }
    return (
        <div className='cursor-pointer' onFocus={doFocus} onBlur={doBlur} tabIndex={0}>
            <div className='flex items-center mr-2'><p className='mx-1'>Date-filter</p><img className='h-5' src="calendar.png" alt="calander" /></div>
            {
                showDateFilter && <div className='flex flex-col p-2 rounded-md bg-white z-50 absolute left-40 mt-2 justify-center shadow-2xl max-[750px]:left-0'>
                    <div className='flex items-center flex-wrap'>
                        <div>
                            <label htmlFor="from-date">From:</label>
                            <input className='rounded-md outline-none text-center' onChange={fillForm} value={dateFilter.from} type="date" max={dateFilter.to || dateRange.maxDate} min={dateRange.minDate} name="from" id="from-date" />
                        </div>
                        <div>
                            <label htmlFor="to-date">To:</label>
                            <input className='rounded-md outline-none text-center' onChange={fillForm} value={dateFilter.to} max={dateRange.maxDate} min={dateFilter.from || dateRange.minDate} type="date" name="to" id="to-date" />
                        </div>
                        <div>
                            <input type="radio" name="dateType" id="originating" onChange={fillForm} value='originating' checked={dateFilter.dateType === 'originating'} className='ml-2' /> <label htmlFor="originating">Originating</label>
                            <input onChange={fillForm} value='range' checked={dateFilter.dateType === 'range'} type="radio" name="dateType" className='ml-2' id="range" /><label htmlFor="range">Range</label>
                        </div>
                    </div>
                    <div className='flex'>
                        <button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 mr-2 py-2 rounded-3xl' onClick={handlego}>Go</button>
                        <button onClick={handleReset} className='ml-2 py-2 hover:bg-red-700 text-white bg-red-500 px-7 rounded-3xl'>clear</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default DateRangeFilter