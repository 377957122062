import React from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { useSelector } from 'react-redux'
const SavedImage = ({ image, fetch}) => {
	const { userLoggedIn } = useSelector(state => state)
  const handleDeleteAnImage = () => {
    const response = window.confirm(`Do You really want to delete image with id ${image.mediaId}.`)
    if (response) {
      axios.delete(`/trend/delete-image/`, {
        data: { mediaId: image.mediaId }
      })
        .then(res => {
            fetch();
          console.log(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  	const removeSavedData = async  (type,dataId) =>{
		try {
			const response = await axios.delete(`/save/${type}/${dataId}`)
			fetch();
		} catch(e){
			console.error(e)
		}
	}


  return (
    <div className='w-fit inline-block m-2 mr-10'>
      <div className='' >
		<div className='relative'>
			<img className='h-80 w-full' src={image.imageUrl} alt="name" />
			<div className='hover:opacity-95 cursor-pointer absolute top-1 right-1'><button className='  bg-blue-500 px-3 py-1 rounded-xl text-white' onClick={()=>{removeSavedData('image',image.mediaId)}}>Remove</button></div>
		</div>
      </div>
	{<a className='mx-2 flex justify-between items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
        <div>
          <div className='flex items-center'>{parseInt(image.like_count) <= 3?'Hidden':image.like_count}<span className=' text-red-600 text-xl'><AiTwotoneHeart/></span></div>
        </div>
        <div>
          <div className='flex items-center'>{image.comment_count}<span className = 'text-xl'><BiCommentDots/></span></div>
        </div>
      </a>}
      <p className='text-center'>{moment(image.timeStamp).format("DD MMM YYYY")}</p>
    </div>
  )
}

export default SavedImage