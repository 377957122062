import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    category:"",
    photo:"",
    gender:'f',
    timeStamp:"",
}

export const imageUploadSlice = createSlice({
    name: 'imageUpload',
    initialState,
    reducers: {
        setImageUploadData: (state,action) => {
            state.category = action.payload.category
            state.timeStamp = action.payload.timeStamp
            state.photo = action.payload.photo
            state.gender = action.payload.gender
        },
    },
})  
export const { setImageUploadData } = imageUploadSlice.actions

export default imageUploadSlice.reducer
