import { useEffect, useState } from "react"
import { femaleTrendCategory , mensTrendsCategory} from '../../constants'
import Popup from "../../component/Popup";

import axios from "axios";
import Swal from "sweetalert2";
import ProductsPopup from "./ProductsPopup";
import { isChrome }  from 'react-device-detect'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from 'react-js-switch';





// eslint-disable-next-line import/no-anonymous-default-export
export default  ()=>{

	const femaleCategories = femaleTrendCategory.filter(cat => cat!="All Trends")
	const maleCategories = mensTrendsCategory.filter(cat => cat!="All Trends")

	const [gender, setGender] = useState('f'); 
	const [category, setCategory] = useState(femaleCategories[0]); 
	const [urlText, setUrlText] = useState('')
	const [showPopup, setShowPopup] = useState(false);
	const [foreignEcommerce , setForeignEcommerce] = useState(false)
	const { userLoggedIn } = useSelector (state => state)
	const navigate = useNavigate();

	useEffect(()=> {
		setCategory(gender=='f'?femaleCategories[0]:maleCategories[0])
		if(gender!='f'){
			setForeignEcommerce(false)
		}
	},[gender]) 

	useEffect(()=> {
		if(!userLoggedIn.loggedIn){
			navigate('/login');
		}
	},[]) 

	const queryForProducts =  async ()=>{
		if(!urlText){
			Swal.fire('Please enter image urls.','','warning');
			return ;
		}


		setShowPopup(true)
		
	}

	const handleToggle = ()=>{
		setForeignEcommerce(!foreignEcommerce);
	}

	return (
		<div>
			<div className="flex mt-6 m-4">
				<select className='mr-1 p-2 rounded-md outline-none text-center max-[1160px]:w-24 ' value={gender} onChange={(e)=> { setGender(e.target.value) }} name="gender" >
					<option value="" hidden>--select Gender--</option>
					<option value="m">Male</option>
					<option value="f">Female</option>
				</select>
				<select className='mr-1 p-2 rounded-md outline-none text-center max-[1160px]:w-24 ' value={category} onChange={(e)=> { setCategory(e.target.value) }} name="gender" >
					<option value="" hidden>--select Category--</option>
					{
						gender == 'm'? 
						maleCategories.map( (category,i) =>{
							return (
								<option value={category} key={i} >{category}</option>
							)
						})
						:femaleCategories.map( category =>{
							return (
								<option value={category} key={category} >{category}</option>
							)
						})
					}
				</select>
				<div className="flex flex-row">
					<div className="pt-1 ml-4 mr-2">
						<Switch value={foreignEcommerce} onChange={handleToggle} size={60}
							 backgroundColor={{ on: '#888', off: '#f9f9f9' }} borderColor={{ on: '#888', off: '#e6e6e6' }}
							 color="#eee"
							 disabled={gender!='f'}
						/>
					</div>
					<span>Foreign Ecommerce</span>
				</div>
			</div>
			<div className="w-full flex h-80 ">
				<textarea id="product-image-urls" name="images"  className="w-3/4 h-4/5 max-h-[80%] min-h-[80%] my-10 m-auto shadow-[0_0px_2px_1px_rgba(0,0,0,0.3)] outline-none rounded-lg px-20 py-10 justify-center" placeholder="Line seperated image urls" value={urlText} onChange={e => setUrlText(e.target.value)}></textarea>
			</div>
			<div className="w-full grid">
				<button className="bg-slate-400 px-6 py-2 text-white rounded-xl hover:bg-slate-500 mx-auto justify-center" onClick={queryForProducts}>Submit</button>
			</div>
			{ isChrome && <p className="mt-10 ml-10 text-sm font-medium ">Note : For the best search experience, we recommend using the Mozilla Firefox browser.</p>}
				
			<Popup isPopUp={showPopup} setIsPopUp={setShowPopup}>
				<ProductsPopup gender={gender} category={category} urlText={urlText} foreignEcommerce={foreignEcommerce}/>
			</Popup>

		</div>
	)
}
