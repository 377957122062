import axios from "axios"
import { useState } from "react"

const UpdateCollectionName = ({ collectionId, collectionName, getCollections, setShowPopup }) => {

  const [newName, setNewName] = useState(collectionName);
  const updateCollection = async () => {

    const resp = await axios.patch(`/api/saved-images/collection?collectionId=${collectionId}`, { name: newName })
    getCollections(true)
    setShowPopup(false)

  }
  return <div className="px-8 py-4">
    <h3>Update collection name - {collectionName}</h3>
    <div className="mt-8 flex-wrap justify-center">
      <div className="w-full ">

        <input className="w-full shadow border-slate-200/60 outline-none border rounded bg-slate-50 text-black px-3 shrink-0 py-2"
          placeholder="New Collection Name"
          value={newName}
          onChange={e => setNewName(e.target.value)}
        />

      </div>
      <div className="flex justify-center w-full">
        <button className="bg-blue-600 px-4 mx-2  text-white rounded py-2 mt-5" onClick={() => { updateCollection() }}>Update Collection name {collectionName}</button>
        <button className="border  px-4 mx-2  text-black font-semibold rounded py-2 mt-5" onClick={() => { setShowPopup(false) }}>Cancel</button>
      </div>


    </div>


  </div>

}

export default UpdateCollectionName;
