import { Oval } from "react-loader-spinner";

const Loading = ({ children, loading }) => {


  return loading ? <div className="h-full w-full flex justify-center items-center">
    <Oval
      visible={true}
      height="80"
      width="80"
      color="#666"
      secondaryColor="#999"
      strokeWidth={3}
      ariaLabel="oval-loading"
    />
  </div> : children
}

export default Loading;
