import { CategoriesSortHomePage } from "../../constants"
import { MenCategoryThumbnails, WomenCategoryThumbnails } from "../../utility/CategoryThumbnails"


const V2CategoriesHomePage = () => {

  const setCategory = (cat, gender, type) => {
    //   if (location.pathname == '/women-social-trends' || location.pathname == '/men-social-trends') {
    //      setSearchParams(prev => {
    //        prev.set('category', cat);
    //        return prev;
    //      })
    const url = type == 'trends' ? `${gender == 'Female' ? '/women-social-trends' : 'men-social-trends'}?category=${cat.split('&').join('%26')}` :
      type == 'leaderboard' ? `v2-leaderboard?gender=${gender}&category=${encodeURIComponent(cat)}&time=30+days&geography=All&source=` :
        `design-trends-lifecycle?gender=${gender}&category=${encodeURIComponent(cat)}&time=7+days&geography=All&source=&trends=social`
    window.location = url
  }
  return <div className="flex w-screen justify-between">
    <div className="w-1/2 border-r-4 border-r-slate-400">
      <h2 className="text-center my-4 text-lg font-semibold">Women's Categories</h2>
      <div className=" flex shrink-0 flex-wrap">
        {Object.keys(WomenCategoryThumbnails).sort((a, b) => (CategoriesSortHomePage.indexOf(a) - CategoriesSortHomePage.indexOf(b))).map(cat => <div key={cat} className=' p-2 w-1/4 shrink-0 flex justify-center'>
          <div className='w-4/5 rounded-lg border hover:shadow-lg relative group'>
            <div className="flex justify-center w-full p-2">
              <img src={WomenCategoryThumbnails[cat]} alt={cat} className='w-4/5' />
            </div>
            <p className="text-lg font-medium group-hover:font-semibold">{cat}</p>
            <div className="absolute group-hover:visible invisible bottom-0 w-full py-3 h-2/3 text-[12px] bg-slate-100/90 rounded-t-xl">
              <button onClick={() => { setCategory(cat, 'Female', 'leaderboard') }} className="w-full py-1 hover:shadow font-semibold">Leaderboard</button>
              <button onClick={() => { setCategory(cat, 'Female', 'lifecycles') }} className="w-full py-1 hover:shadow font-semibold">Lifecycle</button>
              <button onClick={() => { setCategory(cat, 'Female', 'trends') }} className="w-full py-1 hover:shadow font-semibold">Deep Dive</button>
            </div>
          </div>
        </div>)}
      </div>
    </div>

    <div className="w-1/2 ">
      <h2 className="text-center my-4 text-lg font-semibold">Men's Categories</h2>
      <div className=" flex shrink-0 flex-wrap">
        {Object.keys(MenCategoryThumbnails).sort((a, b) => (CategoriesSortHomePage.indexOf(a) - CategoriesSortHomePage.indexOf(b))).map(cat => <div key={cat} className=' p-2 w-1/4 shrink-0 flex justify-center'>
          <div className='w-4/5 rounded-lg border hover:shadow-lg relative group'>
            <div className="flex justify-center w-full p-2 ">
              <img src={MenCategoryThumbnails[cat]} alt={cat} className='w-4/5' />
            </div>
            <p className="text-lg font-medium group-hover:font-semibold">{cat}</p>
            <div className="absolute group-hover:visible invisible bottom-0 w-full py-3 h-2/3 text-[12px] bg-slate-100/90 rounded-t-xl">
              <button onClick={() => { setCategory(cat, 'Male', 'leaderboard') }} className="w-full py-1 hover:shadow font-semibold">Leaderboard</button>
              <button onClick={() => { setCategory(cat, 'Male', 'lifecycles') }} className="w-full py-1 hover:shadow font-semibold">Lifecycle</button>
              <button onClick={() => { setCategory(cat, 'Male', 'trends') }} className="w-full py-1 hover:shadow font-semibold">Deep Dive</button>
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </div>
}

export default V2CategoriesHomePage
