import axios from "axios"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import LoadingWrapper from "../../component/LoadingWrapper";
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";

const SavedImages = () => {

  const { Id } = useParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [socialImages, setSocialImages] = useState([])
  const [retailerImages, setRetailerImages] = useState([])
  const [totalImages, setTotalImages] = useState(1)
  const [collectionName, setCollectionName] = useState('')
  const [collectionNotFound, setCollectionNotFound] = useState(false)


  const getCollectionImages = async () => {
    setLoading(true)
    const resp = await axios.get(`/api/saved-images/collection-images/${Id}?page=${page}`)
    setLoading(false)
    if (!resp.data.collectionFound) {
      setCollectionNotFound(true)
    } else {
      setSocialImages(resp.data.socialimages)
      setCollectionName(resp.data.collectionName)
      setRetailerImages(resp.data.retailerImages)
      setTotalImages(resp.data.count)
    }
  }



  useEffect(() => {
    getCollectionImages()
  }, [page])

  return <div className="flex ">

    <div className="w-full min-h-[80vh]">
      {
        collectionNotFound ? <div className="flex w-full h-full justify-center items-center">
          <div className="font-semibold">No Collection Found</div>

        </div> : <>
          <h3 className="text-center text-xl my-3 ">{collectionName} Collection Images </h3>

          <LoadingWrapper loading={loading}>
            <div className="flex w-full ">
              <div className="w-1/2 border-r h-full px-2">
                <h4 className="text-center font-medium text-xl">Social Images</h4>
                <div className="flex justify-center">
                  <hr className="w-3/4" />
                </div>
                <div className="flex flex-wrap w-full ">
                  {socialImages.map(img => <div key={img._id} className="w-1/3 px-1 border py-2">
                    <div className="flex justify-center">

                      <img src={img.imageUrl} className='h-40' />

                    </div>
                    <div className="px-2 text-sm">
                      <a href={`${img.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{img.username}</a>
                      <p>{new Date(img.timeStamp).toDateString()}</p>
                      <div></div>
                    </div>

                    <div className="flex text-sm my-2 justify-between px-2">
                      <ImageTagsDetails mediaId={img.mediaId} />

                    </div>


                  </div>)}
                </div>
                <div className="flex w-full h-full py-40">
                  {socialImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
                </div>

              </div>
              <div className="w-1/2 border-r h-full px-2">
                <h4 className="text-center font-medium text-xl">Retailer Images</h4>
                <div className="flex justify-center">
                  <hr className="w-3/4" />
                </div>
                <div className="flex flex-wrap w-full">
                  {retailerImages.map(img => <div key={img._id} className="w-1/3 px-1 my-2 border">
                    <div className="flex justify-center">

                      <img src={img.image_url} className='h-40' />
                    </div>
                    <div className="px-3">
                      <a href={`${img.product_url}`} className='text-blue-600 font-medium' target='_blank'>{img.retailer}</a>
                      <p>Brand : {img.brand}</p>
                    </div>

                    <div className="flex text-sm my-2 justify-between px-2">
                      <ImageTagsDetails mediaId={img.pk} isretailer={true} />

                    </div>

                  </div>)}
                </div>
                <div className="flex w-full h-full py-40">
                  {retailerImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
                </div>
              </div>
            </div>

          </LoadingWrapper>

        </>
      }
    </div>


  </div>
}

export default SavedImages
