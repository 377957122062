
import axios from "axios";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Piechart from "../ProductsAnalysis/Piechart.jsx";
import Popup from '../../component/Popup';
import ProductsPopup from "../TagsPage/ProductsPopup.jsx";
const TagsPopup = ({ trend }) => {

  const [loading, setLoading] = useState(true);
  const { userLoggedIn } = useSelector(state => state)
  const [charts, setCharts] = useState([])
  const [filters, setFilters] = useState({ geography: 'all', type: 'All', tier: 'All' })
  const [showProductsPopup, setShowProductsPopup] = useState(false)
  const [selectedTagData, setSelectedTagData] = useState(null)
  const geographies = ['all', 'asian', 'indian', 'western'];
  const types = ['All', 'Celebrity', 'Mega Influencer', 'Micro Influencer', 'Pages/Magazines', 'Stores/Brands']
  const tiers = ['All', 'Tier 1 city', 'Tier 2 city']
  const getData = () => {
    if (!trend)
      return

    axios.get(`image-analysis-api/trend-chart-data/${trend._id}?tier=${filters.tier}&geography=${filters.geography}&type=${filters.type}`)
      .then(res => {
        setCharts(res.data.charts)
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleProducts = (showPopup, tagName, tagValue) => {
    if (!showPopup) {
      setShowProductsPopup(false);
      setSelectedTagData(null);
    } else {
      setShowProductsPopup(true)
      setSelectedTagData({ tagName, tagValue })
    }
  }
  const updateFilters = (name, value) => {
    setFilters(prev => {
      const newState = { ...prev };
      newState[name] = value;
      if (name === 'geography') {
        newState.tier = 'All'
      }
      if (name === 'tier' && value !== 'All') {
        newState.geography = 'indian'
      }
      return newState;
    })
  }
  useEffect(getData, [trend, filters])
  return <div className="h-[80vh] w-[80vw]">
    <h2 className="text-2xl font-bold text-center mb-6">Social Specifications</h2>
    <div className="flex flex-row rounded-2xl ">
      {geographies.map((val, i) => <button className={`px-2 py-1 my-1 bg-slate-500 disabled:bg-slate-600 hover:bg-slate-700 font-semibold text-white ${i === 0 ? 'rounded-l-md' : 'border-l-white border-l'} ${i === geographies.length - 1 ? 'rounded-r-md' : ''}`} disabled={val === filters.geography} onClick={() => updateFilters('geography', val)}>{val.slice(0, 1).toUpperCase() + val.slice(1).toLowerCase()}</button>)}
      <span className="w-8 "> </span>
      {types.map((val, i) => <button className={`px-2 py-1 my-1 bg-slate-500 disabled:bg-slate-600 hover:bg-slate-700 font-semibold text-white ${i === 0 ? 'rounded-l-md' : 'border-l-white border-l'} ${i === types.length - 1 ? 'rounded-r-md' : ''}`} disabled={val === filters.type} onClick={() => updateFilters('type', val)}>{val}</button>)}
      <span className="w-8 "> </span>
    </div>
    <div className="flex flex-row rounded-2xl ">
      {tiers.map((val, i) => <button className={`px-2 py-1 mb-3 bg-slate-500 disabled:bg-slate-600 hover:bg-slate-700 font-semibold text-white ${i === 0 ? 'rounded-l-md ml-2' : 'border-l-white border-l'} ${i === tiers.length - 1 ? 'rounded-r-md' : ''}`} disabled={val === filters.tier} onClick={() => updateFilters('tier', val)}>{val}</button>)}
    </div>
    {
      loading && <div className=' flex justify-center items-center'>
        <TailSpin
          height="80"
          width="80"
          color="grey"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible="true"
        />
      </div>
    }
    <div className="mt-10 flex flex-row w-full flex-wrap">
      {
        charts.map(chart => <div key={chart.tag} className='w-1/3 shrink-0 '> <Piechart data={chart} handleProductsPopup={handleProducts} /> </div>)
      }
    </div>
    {/*
    <hr className="w-40 mx-auto mb-20" />
    <div className="w-4/5 m-auto mb-10">
        <ProductsPriceGraph products={productPrices} />
    </div>
    */}
    <Popup isPopUp={showProductsPopup} setIsPopUp={handleProducts}>
      <ProductsPopup tagsData={selectedTagData} category={trend.searchedCategory} trendId={trend._id} tier={filters.tier} geography={filters.geography} type={filters.type} />
    </Popup>
  </div >
}

export default TagsPopup 
