
import React, { useState } from 'react'

const RunwayImage = ({ prod, updateTagsPopupMediaId }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleCopyLink = () => {
    setIsButtonClicked(true);
    window.navigator.clipboard.writeText(prod.product_url)
  }
  return (
    <div className='mr-10 max-[700px]:mr-1 min-w-max text-center mb-5'>
      <img className=' h-60' src={prod.image_url} />
      <p>{(prod.title || '').slice(0, 40)}</p>
      {
        prod.filledCategories &&
        <h3>Filled Categories</h3>
      }
      {
        prod.filledCategories &&
        prod.filledCategories.map((cat, i) => <p key={i} className='text-sm'>{i + 1}. {cat}</p>)
      }
      {false ? <button className={`border-2 rounded-lg px-3 py-1 font-medium border-slate-600 ${isButtonClicked ? "bg-slate-600 text-white" : "bg-white text-black"} hover:bg-slate-700 hover:border-slate-700 hover:text-white`} onClick={handleCopyLink}>{isButtonClicked ? "Product Link Copied" : "Product Link"}</button> : null}
      <button className={`border-2 rounded-lg px-3 py-1 font-medium border-slate-700 ml-2 ${prod.metaDataAdded ? 'bg-green-600 text-white border-0 hover:bg-green-600 active:bg-green-700' : 'border-slate-600 bg-white text-black hover:bg-slate-700 hover:hover:text-white '}`} onClick={() => updateTagsPopupMediaId(prod._id)}>Tags Data</button>


    </div >
  )
}

export default RunwayImage;
