import axios from "axios"

const DeleteCollectionName = ({ collectionId, collectionName, getCollections, setShowPopup }) => {

  const deleteCollection = async () => {

    const resp = await axios.delete(`/api/saved-images/collection?collectionId=${collectionId}`)
    getCollections()
    setShowPopup(false)

  }
  return <div className="px-8 py-4">
    <h3>Delete the Collection {collectionName}?</h3>
    <div className="flex  mt-8">

      <button className="bg-red-600 px-4 mx-2  text-white rounded py-2 mt-5" onClick={() => { deleteCollection() }}>Delete Collection {collectionName}</button>
      <button className="border-2  px-4 mx-2  text-black font-semibold rounded py-2 mt-5" onClick={() => { setShowPopup(false) }}>Cancel</button>

    </div>


  </div>
}

export default DeleteCollectionName;

