import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CheckLoggedIn = ({ children }) => {
  const userLoggedIn = useSelector(state => state.userLoggedIn)



  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
  }, [userLoggedIn]);

  return userLoggedIn && userLoggedIn.loggedIn ? <>{children}</> : null
}

export default CheckLoggedIn;
