import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: "userLoggedIn",
  initialState: { loggedIn: false, curator: false, admin: false, updater: false },
  reducers: {
    updateLogin: (state, param) => {
      state.loggedIn = param.payload.loggedIn
      state.curator = param.payload.curator
      state.admin = param.payload.admin
      state.updater = param.payload.updater
      state.clientName = param.payload.clientName
    },
  }
});


const { actions, reducer } = loginSlice
export const { updateLogin } = actions;
export default reducer;
