import React from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import './OverTrends.css'

const OverTrendImage = ({ image, uVal, setFetch }) => {
  const handleDeleteAnImage = () => {
    const response = window.confirm(`Do You really want to delete image with id ${image.mediaId} in trend with id ${uVal}`)
    if (response) {
      axios.delete(`/trend/delete-image/`, {
        data: { mediaId: image.mediaId, pk: uVal }
      })
        .then(res => {
          if (res.data === "deleted") {
            setFetch(prev => !prev)
          }
          console.log(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  return (
    <div className='mr-10 min-w-max text-center' style={image.overLapping?{border:"2px solid blue", borderRadius:'5px'}:{}}>
      <div className='flex mb-4 flex-col items-end'>
        <img className={'-mb-8 h-80'} src={image.imageUrl} alt="name" />
        <div onClick={handleDeleteAnImage} className=' cursor-pointer opacity-40 rounded-full p-1 text-black mr-2'><RiDeleteBinLine /></div>
      </div>
      {<a className='mx-2 flex justify-between items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
        <div>
          <div className='flex items-center'>{parseInt(image.noOfLikes) <= 3?'Hidden':image.noOfLikes}<span className=' text-red-600 text-xl'><AiTwotoneHeart/></span></div>
        </div>
        <div>
          <div className='flex items-center'>{image.noOfComments}<span className = 'text-xl'><BiCommentDots/></span></div>
        </div>
      </a>}
      <p>{moment(image.timeStamp).format("DD MMM YYYY")}</p>
      {image.likes && <p>({image.likes}) Likes</p>}
    </div>
  )
}

export default OverTrendImage