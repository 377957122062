
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import './index.css'
import { useSelector } from "react-redux";
import Popup from "../../component/Popup";
import TagsUpdatePopup from "./TagsUpdatePopup";

const ImageTags = () => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { userLoggedIn } = useSelector(state => state)
  const [totalCount, setTotalCount] = useState(1)
  const [loading, setLoading] = useState(true)
  const [images, setImages] = useState([])
  const [showTagsUpdate, setShowTagsUpdate] = useState(false)
  const [mediaIdToUpdate, setmediaIdToUpdate] = useState(null)
  const [tagsType, setTagsType] = useState('primary-uncolored')
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
    if (!userLoggedIn.curator && !userLoggedIn.updater) {
      navigate('/')
    }
  }, [])

  const showTagsUpdatePopUp = (flag, mediaId) => {
    if (!flag) {
      setShowTagsUpdate(false)
      setmediaIdToUpdate(null)
    } else {
      setShowTagsUpdate(true)
      setmediaIdToUpdate(mediaId)

    }
  }
  const updateImages = () => {
    setImages([])
    axios.get(`/usedimages-api/all-images?page=${searchParams.get('page')}&tagstype=${tagsType}`).then(response => {
      setImages(response.data.images)
      setTotalCount(response.data.total)
    })
  }
  useEffect(() => {
    updateImages()
  }, [searchParams.get('page'), tagsType])

  const navigate = useNavigate();

  const updatePage = (newPage) => {
    setSearchParams(prev => { return { page: parseInt(prev.get('page') || 1) + newPage } })
  }
  return (
    <div className="mt-8 w-full px-10 relative leaderboard-page">
      <div className="flex w-full sticky top-12 bg-white py-4">
        <div className="flex flex-row w-3/4 flex-wrap">
          <select value={tagsType} onChange={(e) => { setTagsType(e.target.value) }}>
            <option value={'all'}>All (Excluding newer images)</option>
            <option value={'tagged'}>Completed</option>
            <option value={'untagged'}>Remaining</option>
            <option value={'primary-colored'}>Complete Primary Color</option>
            <option value={'primary-uncolored'}>Remaining Primary Color</option>
            <option value={'patterned'}>Complete Pattern</option>
            <option value={'unpatterned'}>Remaining Pattern</option>
          </select>
        </div>
        <div className="justify-end flex w-1/4">
          <button disabled={searchParams.get('page') <= 1} onClick={e => updatePage(-1)} className="primary-btn">prev</button>
          <span className="m-2">{searchParams.get('page') || 1}/{Math.ceil(totalCount / 100)}</span>
          <button disabled={searchParams.get('page') >= Math.ceil(totalCount / searchParams.get('limit'))} onClick={e => updatePage(1)} className="primary-btn">next</button>
          <span className="m-2">{totalCount} images</span>
        </div>
      </div>
      <table className="table-auto w-full border-2 mt-6">
        <thead className="border-2 border-slate-300 sticky top-[150px] bg-white">
          <tr>
            <th>No</th>
            {/*
            <th>Category</th>
            <th>Name</th>
            <th>Used Categories</th>
            */}
            <th>Updated Categories</th>
            <th>Updated Patterns</th>
            <th>Updated Specific Colors</th>
            <th>Copy Url</th>
            <th>Image</th>
            <th>Update</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {images.map((img, i) => <tr key={img.mediaId} className="my-2 h-40 border-2 border-slate-300">
            <td className="px-4">{i + 1}</td>
            {/*
              <td>{img.searchedCategory}</td>
              <td>{img.name}</td>
              <td>{img.usedCats?.map(cat => <p key={cat}>{cat}</p>)}</td>
            */}
            <td>{img.updatedCats?.map(cat => <p key={cat}>{cat}</p>)}</td>
            {
              /*            <td>{img.colors && Object.keys(img.colors).length > 0 ?
              Object.keys(img.colors).map(cat => <div key={img.colors['cat']} className='flex border m-1 p-3'>
                <p className="font-bold mr-4">{cat}</p>
                <div className="flex flex-col">{img['colors'][cat].map(c => <p>{c}</p>)}</div>
              </div>) : null}</td>
            */
            }
            <td>{img.pattern && Object.keys(img.pattern).length > 0 ?
              Object.keys(img.pattern).map(cat => <div key={img.pattern['cat']} className='flex border m-1 p-3'>
                <p className="font-bold mr-4">{cat}</p>
                <div className="flex flex-col">{img['pattern'][cat]}</div>
              </div>) : null}</td>
            <td>{img.specificColorPrimary && Object.keys(img.specificColorPrimary).length > 0 ?
              Object.keys(img.specificColorPrimary).map(cat => <div key={img.specificColorPrimary['cat']} className='flex border m-1 p-3'>
                <p className="font-bold mr-4">{cat}</p>
                <div className="flex flex-col">{img['specificColorPrimary'][cat]}</div>
              </div>) : null}</td>
            <td><button onClick={() => { window.navigator.clipboard.writeText(img.imageUrl) }} className="active:bg-slate-300 h-12 hover:bg-slate-200">{img.mediaId}</button></td>
            <td><img className="h-32" src={img.imageUrl} /></td>
            <td ><button className={(img.tagsExist ? 'bg-slate-500 ' : 'bg-green-500') + ' text-white px-2 py-1 rounded-lg '} onClick={() => { showTagsUpdatePopUp(true, img.mediaId) }}>{img.tagsExist ? 'Updated' : 'Remaining'}</button></td>
            <td>{img.timeStamp.slice(0, 10)}</td>
          </tr>)
          }
        </tbody>
      </table>
      <Popup isPopUp={showTagsUpdate} setIsPopUp={showTagsUpdatePopUp}>
        <TagsUpdatePopup mediaId={mediaIdToUpdate} />
      </Popup>
    </div >
  )
}


export default ImageTags;
