

export const femaleTrendCategory = [
  "All Trends",
  "Topwear Trends",
  "Co-ord Trends",
  "Dress Trends",
  "Jeans & Trouser Trends",
  "Skirt Trends",
  "Jumpsuit Trends",
  "Trendy Shorts",
  "Outerwear Trends",
  "Trendy Bags",
  "Trendy Footwear",
  "Trendy Accessories",
  "Ethnic Wear"
]

export const mensTrendsCategory = [
  "All Trends",
  "Topwear Trends",
  "Bottomwear Trends",
  "Outerwear Trends",
  "Trendy Footwear",
  "Trendy Bags",
  "Trendy Accessories",
  "Ethnic Wear"
]

export const acceptedTags = [
  "lower_body_length",
  "sleeve_length",
  "category",
  "apparel",
  "full_body_garment",
  "sleeve_style",
  "neckline",
  "product_color",
  "dress_type",
  "dress_skirt_shape",
  "product_pattern",
  "fashion_occasion",
  "fashion_style",
  "closure_type",
  "upper_body_length",
  "upper_body_garment",
  "pants_fit_type",
  "pants_type",
  "lower_body_garment",
  "rise_type",
  "coat_type",
  "outerwear",
  "coat_style",
  "jacket_type",
  "sweater_style",
  "denim_wash_color",
  "bag",
  "shoulder_bag_totes",
  "clutch_wallet",
  "footwear",
  "heel_type",
  "heel_height",
  "sandals",
  "sandal_type",
  "shoe_decoration",
  "accessory",
  "toe_type",
  "shoes",
  "toe_shape",
  "shoe_closure",
  "shoe_type",
  "boots",
  "sneakers",
  "shorts_type",
  "innerwear_nightwear"
]


export const FemaleTrendToStlCategory = [
  "Dresses",
  "Jumpsuits",
  //  "Playsuits",
  "Co-ord Sets",
  //  "Loungewear",
  //  "Suits",
  //  "Nightwear",
  "Tops",
  "T-Shirts",
  //  "Bodysuits",
  "Shirts",
  "Trousers",
  "Jeans & Jeggings",
  "Leggings",
  //  "Sweatpants",
  "Skirts",
  "Shorts",
  //  "Skorts",
  "Sweaters & Cardigans",
  "Sweatshirts & Hoodies",
  "Jackets",
  "Coats, Suits & Blazers",
  //  "Waistcoat",
  //  "Shrugs",
  "Boots",
  "Sneakers",
  "Heels",
  "Flats",
  //  "Shoes",
  "Handbags",
  "Clutches",
  "Sunglasses",
  //  "Artificial Jewellery",
  //  "Belts",
]
export const MaleTrendToStlCategory = [
  "Loungewear",
  "Suits",
  "Tops",
  "T-Shirts",
  "Shirts",
  "Trousers",
  "Jeans & Jeggings",
  "Sweatpants",
  "Shorts",
  "Sweaters & Cardigans",
  "Sweatshirts & Hoodies",
  "Jackets",
  "Coats, Suits & Blazers",
  "Waistcoat",
  "Boots",
  "Sneakers",
  "Shoes",
  "Handbags",
  "Clutches",
  "Sunglasses",
]

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]


export const patterns = [
  "SOLIDS",
  "HORIZONTAL STRIPES (all-over)",
  "VERTICAL STRIPES (all-over)",
  "DIAGNOL STRIPES (all-over)",
  "WAVY/CURVE (all-over)",
  "ZIG ZAG (all-over)",
  "PINSTRIPES (all-over)",
  "SNAKE SKIN (all-over)",
  "TIGER SKIN (all-over)",
  "COW SKIN (all-over)",
  "LEOPARD SKIN (all-over)",
  "CROCODILE SKIN (all-over)",
  "ZEBRA SKIN (all-over)",
  "CHARACTER (front/back)",
  "SCENERY (front/back)",
  "OUTER SPACE (front/back)",
  "OCEAN (front/back)",
  "COLLAGE (front/back)",
  "GOTHIC (front/back)",
  "BAND/MOVIE (front/back)",
  "EMOJI (front/back)",
  "TEXT (front/back)",
  "ILLUSTRATION PAINTING (front/back)",
  "WILDLIFE CHARACTERS (front/back)",
  "CHARACTER (all-over)",
  "SCENERY (all-over)",
  "OUTER SPACE (all-over)",
  "OCEAN (all-over)",
  "COLLAGE (all-over)",
  "BAND/MOVIE (all-over)",
  "BONES, SKULL, SKELTON",
  "EMOJI (all-over)",
  "TEXT (all-over)",
  "ILLUSTRATION PAINTING (all-over)",
  "WILDLIFE CHARACTERS (all-over)",
  "DRAGON BIG & SMALL",
  "LEAF PRINT (all-over)",
  "FRUIT PRINT (all-over)",
  "FEATHER PRINT (all-over)",
  "HEART PATTERN (all-over)",
  "INSECT PRINT (all-over)",
  "FIRE PRINT (all-over)",
  "PAISLEY (all-over)",
  "ANCHOR PRINT (all-over)",
  "CHAIN LINK (all-over)",
  "PATCHWORK",
  "CAMOFLOUGE (all-over)",
  "MARBLE/WATERCOLOR (all-over)",
  "CHECKBOARD (all-over)",
  "DIAMOND (all-over)",
  "HOUNDSTOOTH (all-over)",
  "GEOMETRIC (all-over)",
  "ABSTRACT (all-over)",
  "COLOR BLOCK (all-over)",
  "GRADIENT / OMBRE (all-over)",
  "TIE DYE (all-over)",
  "DOTTED (all-over)",
  "POLKA (all-over)",
  "LARGE CHECK (all-over)",
  "SMALL CHECK (all-over)",
  "MEDIUM CHECKS (all-over)",
  "MEDIUM FLORAL (all-over)",
  "SMALL FLORAL (all-over)",
  "LARGE FLORAL (all-over)",
  "Logo (front/back)",
  "Logo (all-over)",
  "Gingham (all-over)"
]

export const textures = [
  "LACE",
  "LASER CUT",
  "RIBBED",
  "NET",
  "CROCHET",
  "LEATHER",
  "DENIM",
  "CANVAS",
  "FUR/SHEARLING",
  "MESH/TULLE",
  "VELVET",
  "SATIN/POPLIN",
  "STRAW",
  "CORDUROY",
  "STUDDED",
  "EMBELLISHED",
  "SEQUIN/SHIMMERY",
  "EMBROIDERED",
  "FEATHER",
  "APPLIQUE (flower, knot, bow, etc.)",
  "3-D DETAIL (geometric protude, pompom, tassel etc.)",
  "METALLIC SHINE (SMOOTH)",
  "REFLECTIVE & HIGH SHINE",
  "PLASTIC TRANSPARENT",
  "Interwoven/braided/knot",
  "Suede",
  "Quilted"
]

export const ColorMapping = {
  Black: 'Black',
  Onyx: 'Black',
  'Smoky black': 'Black',
  'Dark jungle green': 'Dark jungle green',
  Cyan: 'Cyan',
  Waterspout: 'Cyan',
  'Electric cyan': 'Cyan',
  Aqua: 'Cyan',
  'Turquoise blue': 'Cyan',
  'Bright turquoise': 'Cyan',
  'Electric blue': 'Cyan',
  Turquoise: 'Turquoise',
  'Dark turquoise': 'Turquoise',
  "Robin's Egg Blue": 'Turquoise',
  'Medium turquoise': 'Turquoise',
  Aquamarine: 'Aquamarine',
  'Cadet blue': 'Cadet blue',
  'Blue Bell': 'Blue Bell',
  'Wild blue yonder': 'Blue Bell',
  'Blue Gray': 'Blue Gray',
  'Dark pastel blue': 'Blue Gray',
  Celeste: 'Celeste',
  'Light cyan': 'Light cyan',
  Bubbles: 'Light cyan',
  'Azure mist/web': 'Light cyan',
  'Light blue': 'Powder blue',
  'Powder blue': 'Powder blue',
  'Blizzard Blue': 'Powder blue',
  'Pale blue': 'Powder blue',
  'Non-photo blue': 'Powder blue',
  'Columbia blue': 'Baby blue',
  'Light sky blue': 'Baby blue',
  'Baby blue': 'Baby blue',
  'Sky blue': 'Baby blue',
  'Maya blue': 'Baby blue',
  'Carolina blue': 'Pale cerulean',
  'Pale cerulean': 'Pale cerulean',
  'Light cornflower blue': 'Pale cerulean',
  Cornflower: 'Pale cerulean',
  'Baby blue eyes': 'Pale cerulean',
  'Pale cornflower blue': 'Pale cerulean',
  'Beau blue': 'Pale cerulean',
  'Pale aqua': 'Pale cerulean',
  'Cornflower blue': 'Cornflower blue',
  'United Nations blue': 'Cornflower blue',
  'Celestial blue': 'Cornflower blue',
  'Moonstone blue': 'Moonstone blue',
  Cerulean: 'Cerulean',
  'CG Blue': 'Cerulean',
  'Sea blue': 'Cerulean',
  'Teal blue': 'Teal blue',
  'Bondi blue': 'Pacific Blue',
  'Blue green': 'Pacific Blue',
  'Bright cerulean': 'Pacific Blue',
  'Ball Blue': 'Pacific Blue',
  'Pacific Blue': 'Pacific Blue',
  'Deep sky blue': 'Deep sky blue',
  Capri: 'Deep sky blue',
  'Spiro Disco Ball': 'Deep sky blue',
  'Tufts Blue': 'Tufts Blue',
  'Steel blue': 'Tufts Blue',
  Glaucous: 'Tufts Blue',
  Rackley: 'Tufts Blue',
  'Air Force blue': 'Tufts Blue',
  'Rich electric blue': 'Rich electric blue',
  'True Blue': 'True Blue',
  'Ocean Boat Blue': 'True Blue',
  'French blue': 'True Blue',
  'Medium Persian blue': 'True Blue',
  'Green Blue': 'True Blue',
  Denim: 'True Blue',
  'Dark cerulean': 'Dark cerulean',
  'Yale Blue': 'Dark cerulean',
  'Medium electric blue': 'Dark cerulean',
  'Lapis lazuli': 'Dark cerulean',
  'Brandeis blue': 'Azure',
  Azure: 'Azure',
  'Dodger blue': 'Azure',
  'Bleu de France': 'Azure',
  'Ultramarine blue': 'Ultramarine blue',
  'Royal blue': 'Ultramarine blue',
  'Han blue': 'Ultramarine blue',
  'Violet Blue': 'Cobalt',
  'Cerulean blue': 'Cobalt',
  Sapphire: 'Cobalt',
  'Medium teal blue': 'Cobalt',
  Cobalt: 'Cobalt',
  'Royal azure': 'Cobalt',
  'UA blue': 'Cobalt',
  'Persian blue': 'Cobalt',
  'Palatinate blue': 'Cobalt',
  'Egyptian blue': 'Cobalt',
  'Navy blue': 'Navy blue',
  Ultramarine: 'Navy blue',
  'Phthalo blue': 'Navy blue',
  'Dark blue': 'Navy blue',
  'Duke blue': 'Navy blue',
  Zaffre: 'Navy blue',
  'Medium blue': 'Navy blue',
  'Dark powder blue': 'Navy blue',
  'International Klein Blue': 'Navy blue',
  Smalt: 'Navy blue',
  'Oxford Blue': 'Dark midnight blue',
  'Prussian blue': 'Dark midnight blue',
  'Cool black': 'Dark midnight blue',
  'Dark midnight blue': 'Dark midnight blue',
  Blue: 'Blue',
  'Electric ultramarine': 'Blue',
  'Han purple': 'Blue',
  'Saint Patrick Blue': 'Midnight blue',
  'Midnight blue': 'Midnight blue',
  'Electric indigo': 'Indigo',
  Indigo: 'Indigo',
  'Persian indigo': 'Indigo',
  'Dark slate blue': 'Dark slate blue',
  Toolbox: 'Iris',
  'Slate blue': 'Iris',
  Iris: 'Iris',
  'Majorelle Blue': 'Iris',
  'Medium slate blue': 'Iris',
  'UCLA Blue': 'UCLA Blue',
  Bistre: 'Bistre',
  'Café noir': 'Café noir',
  'Dark brown': 'Dark brown',
  'Otter brown': 'Dark brown',
  Coffee: 'Dark brown',
  Sepia: 'Dark brown',
  'Old rose': 'Rosy brown',
  'Rosy brown': 'Rosy brown',
  'Rose vale': 'Deep chestnut',
  'Deep chestnut': 'Deep chestnut',
  'Dark chestnut': 'Rose taupe',
  'Copper rose': 'Rose taupe',
  'Rose taupe': 'Rose taupe',
  'Mauve taupe': 'Rose taupe',
  'Deep coffee': 'Deep coffee',
  Bole: 'Deep coffee',
  'Dark lava': 'Taupe',
  'Dark taupe': 'Taupe',
  Taupe: 'Taupe',
  'Pastel brown': 'Beaver',
  Beaver: 'Beaver',
  'Light brown': 'Ruddy brown',
  'Ruddy brown': 'Ruddy brown',
  Copper: 'Ruddy brown',
  Russet: 'Saddle brown',
  'Saddle brown': 'Saddle brown',
  Cinereous: 'Cinereous',
  'Light taupe': 'Light taupe',
  'Pale taupe': 'Light taupe',
  'Antique brass': 'Light taupe',
  Lion: 'Camel',
  Fallow: 'Camel',
  Desert: 'Camel',
  Camel: 'Camel',
  Tan: 'Tan',
  Burlywood: 'Tan',
  'Pale gold': 'Tan',
  Tumbleweed: 'Tan',
  'Pale brown': 'Pale brown',
  Chamoisee: 'Pale brown',
  'Café au lait': 'Pale brown',
  'French beige': 'Pale brown',
  Wenge: 'Medium taupe',
  Umber: 'Medium taupe',
  'Medium taupe': 'Medium taupe',
  'Rose ebony': 'Medium taupe',
  'Red brown': 'Auburn',
  Brown: 'Auburn',
  Auburn: 'Auburn',
  'Vivid auburn': 'Auburn',
  'Smokey topaz': 'Wine',
  Cordovan: 'Wine',
  Wine: 'Wine',
  'Burnt umber': 'Burnt umber',
  Sienna: 'Burnt umber',
  Shadow: 'Shadow',
  'Dark tan': 'Shadow',
  'Caput mortuum': 'Caput mortuum',
  'Zinnwaldite brown': 'Seal brown',
  'Seal brown': 'Seal brown',
  'Dark sienna': 'Seal brown',
  'Mint cream': 'Mint cream',
  Cream: 'Cream',
  Cornsilk: 'Cream',
  'Cosmic latte': 'Cosmic latte',
  'Old lace': 'Cosmic latte',
  Linen: 'Cosmic latte',
  Beige: 'Beige',
  'Light goldenrod yellow': 'Beige',
  'Lemon chiffon': 'Beige',
  Blond: 'Blond',
  Pearl: 'Almond',
  Almond: 'Almond',
  Bone: 'Almond',
  'Dark electric blue': 'Payne grey',
  Cadet: 'Payne grey',
  'Payne grey': 'Payne grey',
  Grullo: 'Grullo',
  'Pastel gray': 'Pastel gray',
  'Light gray': 'Light gray',
  Timberwolf: 'Light gray',
  Gainsboro: 'Light gray',
  Platinum: 'Light gray',
  Silver: 'Silver',
  'Pale silver': 'Silver',
  'Lavender gray': 'Silver',
  'Dark gray': 'Dark gray',
  'Pastel blue': 'Pastel blue',
  'Cadet grey': 'Cool grey',
  Manatee: 'Cool grey',
  'Cool grey': 'Cool grey',
  'Light slate gray': 'Slate gray',
  'Slate gray': 'Slate gray',
  'Outer Space': 'Dark slate gray',
  Charcoal: 'Dark slate gray',
  'Dark slate gray': 'Dark slate gray',
  Liver: 'Davy grey',
  'Davy grey': 'Davy grey',
  'Dim gray': 'Davy grey',
  'Trolley Grey': 'Gray',
  Gray: 'Gray',
  'Battleship grey': 'Gray',
  'Taupe gray': 'Gray',
  Verdigris: 'Light sea green',
  'Light sea green': 'Light sea green',
  'Tiffany Blue': 'Light sea green',
  'Pale robin egg blue': 'Pearl Aqua',
  'Pearl Aqua': 'Pearl Aqua',
  'Magic mint': 'Pearl Aqua',
  'Jungle green': 'Mint',
  Mint: 'Mint',
  'Mountain Meadow': 'Mint',
  Shamrock: 'Mint',
  'Medium aquamarine': 'Mint',
  'Caribbean green': 'Mint',
  'Dark cyan': 'Teal',
  Teal: 'Teal',
  Stormcloud: 'Teal',
  Skobeloff: 'Teal',
  'Pine green': 'Teal',
  'Fern green': 'Fern green',
  'Dark olive green': 'Fern green',
  'Sap green': 'Fern green',
  'Olive Drab': 'Fern green',
  'Heart Gold': 'Olive',
  Olive: 'Olive',
  'Army green': 'Army green',
  Feldgrau: 'Camouflage green',
  'Gray asparagus': 'Camouflage green',
  AuroMetalSaurus: 'Camouflage green',
  Xanadu: 'Camouflage green',
  'Camouflage green': 'Camouflage green',
  'Laurel green': 'Laurel green',
  'Cambridge Blue': 'Laurel green',
  'Ash grey': 'Laurel green',
  'Lawn green': 'Neon green',
  Chartreuse: 'Neon green',
  'Bright green': 'Neon green',
  Harlequin: 'Neon green',
  'Neon green': 'Neon green',
  'Electric green': 'Neon green',
  Green: 'Neon green',
  'Lime green': 'Lime green',
  'Kelly green': 'Lime green',
  'Dark pastel green': 'Lime green',
  Malachite: 'Lime green',
  Mantis: 'Pistachio',
  'Dollar bill': 'Pistachio',
  Pistachio: 'Pistachio',
  Olivine: 'Pistachio',
  Asparagus: 'Pistachio',
  'Medium spring bud': 'Medium spring bud',
  'Pale spring bud': 'Pale spring bud',
  'Vegas gold': 'Olive Green',
  'Dark khaki': 'Olive Green',
  'Olive Green': 'Olive Green',
  'Satin sheen gold': 'Satin sheen gold',
  'Midnight green': 'Deep jungle green',
  'Deep jungle green': 'Deep jungle green',
  'Warm black': 'Deep jungle green',
  'Rich black': 'Deep jungle green',
  'MSU Green': 'Deep jungle green',
  'Sacramento State green': 'Deep jungle green',
  'North Texas Green': 'Forest green',
  'Forest green': 'Forest green',
  'Napier green': 'Forest green',
  'India green': 'Forest green',
  'Islamic green': 'Forest green',
  'Office green': 'Forest green',
  Ao: 'Forest green',
  'La Salle Green': 'Forest green',
  'Sea green': 'Forest green',
  'Shamrock green': 'Forest green',
  'Cadmium green': 'Teal green',
  'Dartmouth green': 'Teal green',
  'Tropical rain forest': 'Teal green',
  'Teal green': 'Teal green',
  'Bottle green': 'Teal green',
  'Dark spring green': 'Teal green',
  'Hooker green': 'Hooker green',
  Viridian: 'Hooker green',
  'UP Forest green': 'Dark green',
  'British racing green': 'Dark green',
  'Dark green': 'Dark green',
  'Medium jungle green': 'Dark green',
  'Phthalo green': 'Dark green',
  'Cal Poly Pomona green': 'Dark green',
  Myrtle: 'Dark green',
  'Hunter green': 'Dark green',
  'Pakistan green': 'Dark green',
  'Lincoln green': 'Dark green',
  'Rifle green': 'Rifle green',
  'Yellow green': 'Yellow green',
  'Android Green': 'Yellow green',
  'June bud': 'Yellow green',
  'Apple green': 'Yellow green',
  Pear: 'Yellow green',
  'Spring bud': 'Green yellow',
  'Green yellow': 'Green yellow',
  Inchworm: 'Green yellow',
  'Dark sea green': 'Fern',
  Fern: 'Fern',
  'Eton blue': 'Fern',
  'Turquoise green': 'Tea green',
  'Moss green': 'Tea green',
  Celadon: 'Tea green',
  'Tea green': 'Tea green',
  'Granny Smith Apple': 'Light green',
  'Light green': 'Light green',
  'Pale green': 'Light green',
  'Mint green': 'Light green',
  'Screamin Green': 'Light green',
  'Pastel green': 'Light green',
  'Spring green': 'Spring green',
  'Guppie green': 'Spring green',
  'Medium spring green': 'Spring green',
  'UFO Green': 'Emerald',
  'Paris Green': 'Emerald',
  Emerald: 'Emerald',
  'Medium sea green': 'Emerald',
  Jade: 'Emerald',
  'Field drab': 'Field drab',
  'Antique fuchsia': 'Twilight lavender',
  'Raspberry glace': 'Twilight lavender',
  'Twilight lavender': 'Twilight lavender',
  'Bright lavender': 'Bright lavender',
  'Light pastel purple': 'Bright lavender',
  Mauve: 'Mauve',
  'Bright ube': 'Mauve',
  Wisteria: 'Mauve',
  Lilac: 'Mauve',
  'Medium lavender magenta': 'Medium lavender magenta',
  'Pale plum': 'Medium lavender magenta',
  Plum: 'Medium lavender magenta',
  'Pastel violet': 'Medium lavender magenta',
  'Brilliant lavender': 'Electric lavender',
  'Electric lavender': 'Electric lavender',
  'Languid lavender': 'Languid lavender',
  Thistle: 'Languid lavender',
  Lavender: 'Lavender mist',
  'Lavender mist': 'Lavender mist',
  Glitter: 'Lavender mist',
  'Lavender blue': 'Lavender blue',
  Periwinkle: 'Lavender blue',
  'Pale lavender': 'Lavender blue',
  'Lavender indigo': 'Lavender indigo',
  'Royal purple': 'Dark lavender',
  'Dark lavender': 'Dark lavender',
  'Old lavender': 'Old lavender',
  "Purple Mountain's Majesty": 'Lavender purple',
  'Lavender purple': 'Lavender purple',
  'Purple mountain majesty': 'Lavender purple',
  Ube: 'Lavender purple',
  Patriarch: 'Dark magenta',
  Purple: 'Dark magenta',
  'Dark magenta': 'Dark magenta',
  'Rich lilac': 'Medium orchid',
  'Medium orchid': 'Medium orchid',
  'Deep fuchsia': 'Medium orchid',
  'Deep magenta': 'Deep magenta',
  'Sky magenta': 'Sky magenta',
  Flame: 'Flame',
  'Portland Orange': 'Flame',
  Tomato: 'Flame',
  'Deep carrot orange': 'Flame',
  'International orange': 'Orange red',
  Tangelo: 'Orange red',
  'Orange red': 'Orange red',
  'Burnt sienna': 'Burnt sienna',
  'Terra cotta': 'Burnt sienna',
  'Outrageous Orange': 'Outrageous Orange',
  Coral: 'Outrageous Orange',
  'Mango Tango': 'Outrageous Orange',
  Pumpkin: 'Safety orange',
  'Safety orange': 'Safety orange',
  'Burnt orange': 'Burnt orange',
  Tawny: 'Burnt orange',
  'Fuzzy Wuzzy': 'Chestnut',
  Chestnut: 'Chestnut',
  'Indian red': 'Chestnut',
  'Pale copper': 'Persian orange',
  'Raw Sienna': 'Persian orange',
  'Persian orange': 'Persian orange',
  'Orange peel': 'Yellow Orange',
  Orange: 'Yellow Orange',
  'Chrome yellow': 'Yellow Orange',
  'Pastel orange': 'Yellow Orange',
  'Yellow Orange': 'Yellow Orange',
  'Neon Carrot': 'Yellow Orange',
  'Deep saffron': 'Yellow Orange',
  Rust: 'Rust',
  Mahogany: 'Rust',
  Sinopia: 'Rust',
  'Sunset Orange': 'Sunset Orange',
  'Red Orange': 'Sunset Orange',
  'Carmine pink': 'Sunset Orange',
  'Coral pink': 'Coral pink',
  'Light coral': 'Coral pink',
  'Light carmine pink': 'Coral pink',
  'Candy pink': 'Coral pink',
  'Pale violet red': 'Coral pink',
  'Pale red violet': 'Coral pink',
  'French rose': 'Wild Strawberry',
  'Violet Red': 'Wild Strawberry',
  'Brilliant rose': 'Wild Strawberry',
  'Wild Strawberry': 'Wild Strawberry',
  'Rose bonbon': 'Wild Strawberry',
  'Cerise pink': 'Wild Strawberry',
  Mulberry: 'Wild Strawberry',
  'Raspberry pink': 'Wild Strawberry',
  'Thulian pink': 'Wild Strawberry',
  Famous: 'Magenta',
  Fuchsia: 'Magenta',
  Magenta: 'Magenta',
  'Shocking pink': 'Magenta',
  'Hot magenta': 'Magenta',
  'Razzle dazzle rose': 'Magenta',
  'Persian rose': 'Deep pink',
  'Fluorescent pink': 'Deep pink',
  'Deep pink': 'Deep pink',
  Rose: 'Deep pink',
  'Bright pink': 'Deep pink',
  'Hollywood cerise': 'Deep pink',
  'Fashion fuchsia': 'Deep pink',
  'Deep cerise': 'Deep pink',
  'Royal fuchsia': 'Royal fuchsia',
  'Medium red violet': 'Royal fuchsia',
  Fandango: 'Royal fuchsia',
  'Ultra pink': 'Fuchsia pink',
  'Pink Flamingo': 'Fuchsia pink',
  'Fuchsia pink': 'Fuchsia pink',
  'Light fuchsia pink': 'Fuchsia pink',
  'Pale magenta': 'Fuchsia pink',
  'Rose pink': 'Neon fuchsia',
  'Neon fuchsia': 'Neon fuchsia',
  'Purple pizzazz': 'Neon fuchsia',
  'Hot pink': 'Neon fuchsia',
  'Persian pink': 'Neon fuchsia',
  Heliotrope: 'Orchid',
  Orchid: 'Orchid',
  'Rose quartz': 'Rose quartz',
  'Pastel purple': 'Rose quartz',
  'Opera mauve': 'Opera mauve',
  'Peach puff': 'Peach puff',
  'Light apricot': 'Peach puff',
  Apricot: 'Peach puff',
  'Deep peach': 'Peach puff',
  Melon: 'Baby pink',
  'Baby pink': 'Baby pink',
  'Tea rose': 'Baby pink',
  'Pale chestnut': 'Baby pink',
  'Cherry blossom pink': 'Light pink',
  'Light pink': 'Light pink',
  Pink: 'Light pink',
  'Bubble gum': 'Light pink',
  'Pastel pink': 'Light pink',
  'Pale pink': 'Light pink',
  'Misty rose': 'Light pink',
  'Piggy pink': 'Light pink',
  'Nadeshiko pink': 'Carnation pink',
  'Lavender pink': 'Carnation pink',
  'Carnation pink': 'Carnation pink',
  'Pastel magenta': 'Carnation pink',
  'Cotton candy': 'Carnation pink',
  'Classic rose': 'Carnation pink',
  'Pink pearl': 'Carnation pink',
  Mauvelous: 'Salmon pink',
  'Pink Sherbet': 'Salmon pink',
  'Flamingo pink': 'Salmon pink',
  'Tickle Me Pink': 'Salmon pink',
  'Salmon pink': 'Salmon pink',
  'Light salmon pink': 'Salmon pink',
  'Light Thulian pink': 'Salmon pink',
  Blush: 'Brink pink',
  'Dark pink': 'Brink pink',
  'Light Crimson': 'Brink pink',
  'Wild Watermelon': 'Brink pink',
  'Brink pink': 'Brink pink',
  'Dark salmon': 'Dark salmon',
  Bazaar: 'Mountbatten pink',
  'Mountbatten pink': 'Mountbatten pink',
  'Rose gold': 'Rose gold',
  'Turkish rose': 'Rose gold',
  Puce: 'Rose gold',
  'Ruddy pink': 'Rose gold',
  Byzantine: 'Byzantine',
  'Blue purple': 'Blue violet',
  'Blue violet': 'Blue violet',
  Veronica: 'Blue violet',
  'Dark orchid': 'Blue violet',
  'Purple Heart': 'Purple Heart',
  Byzantium: 'Byzantium',
  'Palatinate purple': 'Byzantium',
  'Dark byzantium': 'Old mauve',
  'Halayà úbe': 'Old mauve',
  'Old mauve': 'Old mauve',
  'Pansy purple': 'Dark raspberry',
  'Dark raspberry': 'Dark raspberry',
  Boysenberry: 'Dark raspberry',
  'Electric purple': 'Electric purple',
  Phlox: 'Electric purple',
  'Psychedelic purple': 'Electric purple',
  'Medium purple': 'Medium purple',
  'Dark pastel purple': 'Medium purple',
  'Tyrian purple': 'Tyrian purple',
  'French lilac': 'French lilac',
  'Pastel red': 'Pastel red',
  Bittersweet: 'Pastel red',
  'Pale carmine': 'Medium carmine',
  'Medium carmine': 'Medium carmine',
  'Dark coral': 'Medium carmine',
  Coquelicot: 'Scarlet',
  'Ferrari Red': 'Scarlet',
  Scarlet: 'Scarlet',
  Red: 'Scarlet',
  'Candy apple red': 'Scarlet',
  Cardinal: 'Cardinal',
  'Bright maroon': 'Cardinal',
  'Utah Crimson': 'Cardinal',
  'Rich carmine': 'Cardinal',
  Crimson: 'Cardinal',
  Amaranth: 'Amaranth',
  Cherry: 'Amaranth',
  Cerise: 'Amaranth',
  Razzmatazz: 'Amaranth',
  'Dogwood rose': 'Amaranth',
  Ruby: 'Amaranth',
  Raspberry: 'Amaranth',
  'Debian red': 'Amaranth',
  'UA red': 'Amaranth',
  Cinnabar: 'Persian red',
  Vermilion: 'Persian red',
  'CG Red': 'Persian red',
  Jasper: 'Persian red',
  'Persian red': 'Persian red',
  'Dark pastel red': 'Persian red',
  Folly: 'Carmine red',
  Ruddy: 'Carmine red',
  'Carmine red': 'Carmine red',
  Carmine: 'Carmine red',
  'Electric crimson': 'Carmine red',
  'American rose': 'Carmine red',
  'Tractor red': 'Carmine red',
  Awesome: 'Carmine red',
  'Radical Red': 'Carmine red',
  'Coral red': 'Carmine red',
  'Deep carmine pink': 'Carmine red',
  'Rose madder': 'Carmine red',
  'Alizarin crimson': 'Carmine red',
  'Rosso corsa': 'Venetian red',
  'Boston University Red': 'Venetian red',
  'Harvard crimson': 'Venetian red',
  'Venetian red': 'Venetian red',
  Lava: 'Venetian red',
  'Fire engine red': 'Venetian red',
  'Cadmium red': 'Venetian red',
  'KU Crimson': 'Venetian red',
  Lust: 'Venetian red',
  'Medium candy apple red': 'Venetian red',
  'Dark candy apple red': 'Firebrick',
  'Mordant red 19': 'Firebrick',
  Rufous: 'Firebrick',
  'Cornell Red': 'Firebrick',
  Carnelian: 'Firebrick',
  Firebrick: 'Firebrick',
  'Upsdell red': 'Firebrick',
  'Crimson glory': 'Firebrick',
  Maroon: 'Maroon',
  'Dark red': 'Maroon',
  'USC Cardinal': 'Maroon',
  Stizza: 'Maroon',
  'Crimson Red': 'Maroon',
  'Bulgarian rose': 'Rosewood',
  'Dark scarlet': 'Rosewood',
  Rosewood: 'Rosewood',
  'Persian plum': 'Rosewood',
  'UP Maroon': 'Rosewood',
  Burgundy: 'Rosewood',
  'Falu red': 'Rosewood',
  'Dark terra cotta': 'Brick red',
  'Brick red': 'Brick red',
  'Rich maroon': 'Rich maroon',
  'Raspberry rose': 'Rich maroon',
  'Jazzberry jam': 'Jazzberry jam',
  'Vivid burgundy': 'Vivid burgundy',
  'Deep carmine': 'Vivid burgundy',
  'Dark violet': 'Dark violet',
  'Vivid violet': 'Dark violet',
  'Electric violet': 'Dark violet',
  'Deep lilac': 'Deep lilac',
  Amethyst: 'Deep lilac',
  'Lavender magenta': 'Violet',
  Violet: 'Violet',
  'Lavender rose': 'Lavender rose',
  'Red violet': 'Red violet',
  'Medium violet red': 'Red violet',
  'Vivid cerise': 'Red violet',
  'Tuscan red': 'Eggplant',
  Eggplant: 'Eggplant',
  'Purple taupe': 'Eggplant',
  Honeydew: 'Honeydew',
  Seashell: 'Lavender blush',
  Isabelline: 'Lavender blush',
  'Lavender blush': 'Lavender blush',
  Ivory: 'Floral white',
  'Light yellow': 'Floral white',
  'Floral white': 'Floral white',
  Munsell: 'Ghost white',
  'White smoke': 'Ghost white',
  'Anti-flash white': 'Ghost white',
  Magnolia: 'Ghost white',
  'Ghost white': 'Ghost white',
  'Alice blue': 'Ghost white',
  White: 'White',
  Snow: 'White',
  Canary: 'Canary',
  'Pastel yellow': 'Canary',
  Champagne: 'Sunset',
  'Deep champagne': 'Sunset',
  Sunset: 'Sunset',
  Gold: 'Orange Yellow',
  'School bus yellow': 'Orange Yellow',
  'Golden yellow': 'Orange Yellow',
  Mustard: 'Orange Yellow',
  Jonquil: 'Orange Yellow',
  'Naples yellow': 'Orange Yellow',
  'Stil de grain yellow': 'Orange Yellow',
  'Orange Yellow': 'Orange Yellow',
  Aureolin: 'Lemon',
  'Canary yellow': 'Lemon',
  'Cadmium yellow': 'Lemon',
  Lemon: 'Lemon',
  'Electric yellow': 'Lemon',
  Yellow: 'Lemon',
  'Laser Lemon': 'Lemon',
  Daffodil: 'Lemon',
  'Banana yellow': 'Dandelion',
  Dandelion: 'Dandelion',
  Sandstorm: 'Dandelion',
  'Titanium yellow': 'Dandelion',
  Peridot: 'Dandelion',
  Citrine: 'Dandelion',
  Corn: 'Dandelion',
  Maize: 'Dandelion',
  'Arylide yellow': 'Hansa yellow',
  'Hansa yellow': 'Hansa yellow',
  Straw: 'Hansa yellow',
  Flax: 'Hansa yellow',
  Buff: 'Hansa yellow',
  Jasmine: 'Hansa yellow',
  'Light khaki': 'Hansa yellow',
  Flavescent: 'Hansa yellow',
  'Cadmium orange': 'Tangerine',
  'Carrot orange': 'Tangerine',
  Gamboge: 'Tangerine',
  'Harvest Gold': 'Tangerine',
  Fulvous: 'Tangerine',
  Tangerine: 'Tangerine',
  'Dark orange': 'Tangerine',
  'Princeton orange': 'Tangerine',
  'UCLA Gold': 'Amber',
  'Selective yellow': 'Amber',
  Amber: 'Amber',
  'Fluorescent orange': 'Amber',
  'Golden poppy': 'Amber',
  'Mikado yellow': 'Amber',
  'Tangerine yellow': 'Amber',
  'USC Gold': 'Amber',
  Sunglow: 'Amber',
  Saffron: 'Amber',
  'Dark tangerine': 'Dark tangerine',
  Bronze: 'Chocolate',
  Ochre: 'Chocolate',
  Chocolate: 'Chocolate',
  Cinnamon: 'Chocolate',
  'Cocoa brown': 'Chocolate',
  'Dark goldenrod': 'Dark goldenrod',
  'University of California Gold': 'Dark goldenrod',
  Brass: 'Brass',
  Drab: 'Sandy taupe',
  'Mode beige': 'Sandy taupe',
  'Sand dune': 'Sandy taupe',
  'Sandy taupe': 'Sandy taupe',
  'Golden brown': 'Golden brown',
  Ginger: 'Ginger',
  Ecru: 'Ecru',
  Sand: 'Ecru',
  Khaki: 'Ecru',
  'Desert sand': 'Desert sand',
  Eggshell: 'Blanched Almond',
  'Antique white': 'Blanched Almond',
  Moccasin: 'Blanched Almond',
  'Papaya whip': 'Blanched Almond',
  'Blanched Almond': 'Blanched Almond',
  Bisque: 'Blanched Almond',
  'Medium champagne': 'Peach',
  Vanilla: 'Peach',
  'Pale goldenrod': 'Peach',
  'Banana Mania': 'Peach',
  Peach: 'Peach',
  'Navajo white': 'Peach',
  'Peach yellow': 'Peach',
  Wheat: 'Peach',
  Fawn: 'Earth yellow',
  'Earth yellow': 'Earth yellow',
  'Indian yellow': 'Earth yellow',
  'Fluorescent yellow': 'Fluorescent yellow',
  'Electric lime': 'Fluorescent yellow',
  Lime: 'Fluorescent yellow',
  'Lemon lime': 'Fluorescent yellow',
  Goldenrod: 'Goldenrod',
  Urobilin: 'Goldenrod',
  'Meat brown': 'Goldenrod',
  'Old gold': 'Old gold',
  'Lemon Yellow': 'Lemon Yellow',
  Icterine: 'Lemon Yellow',
  'Unmellow Yellow': 'Lemon Yellow',
  'Sandy brown': 'Sandy brown',
  'Tiger eye': 'Tiger eye',
  Topaz: 'Macaroni and Cheese',
  'Macaroni and Cheese': 'Macaroni and Cheese',
  'Vivid tangerine': 'Vivid tangerine',
  'Light salmon': 'Vivid tangerine',
  'Atomic tangerine': 'Vivid tangerine',
  Salmon: 'Vivid tangerine'
}

export const MajorColorArrays = {
  Black: ['Black', 'Onyx', 'Smoky black'],
  'Dark jungle green': ['Dark jungle green'],
  Cyan: [
    'Cyan',
    'Waterspout',
    'Electric cyan',
    'Aqua',
    'Turquoise blue',
    'Bright turquoise',
    'Electric blue'
  ],
  Turquoise: [
    'Turquoise',
    'Dark turquoise',
    "Robin's Egg Blue",
    'Medium turquoise'
  ],
  Aquamarine: ['Aquamarine'],
  'Cadet blue': ['Cadet blue'],
  'Blue Bell': ['Blue Bell', 'Wild blue yonder'],
  'Blue Gray': ['Blue Gray', 'Dark pastel blue'],
  Celeste: ['Celeste'],
  'Light cyan': ['Light cyan', 'Bubbles', 'Azure mist/web'],
  'Powder blue': [
    'Light blue',
    'Powder blue',
    'Blizzard Blue',
    'Pale blue',
    'Non-photo blue'
  ],
  'Baby blue': [
    'Columbia blue',
    'Light sky blue',
    'Baby blue',
    'Sky blue',
    'Maya blue'
  ],
  'Pale cerulean': [
    'Carolina blue',
    'Pale cerulean',
    'Light cornflower blue',
    'Cornflower',
    'Baby blue eyes',
    'Pale cornflower blue',
    'Beau blue',
    'Pale aqua'
  ],
  'Cornflower blue': ['Cornflower blue', 'United Nations blue', 'Celestial blue'],
  'Moonstone blue': ['Moonstone blue'],
  Cerulean: ['Cerulean', 'CG Blue', 'Sea blue'],
  'Teal blue': ['Teal blue'],
  'Pacific Blue': [
    'Bondi blue',
    'Blue green',
    'Bright cerulean',
    'Ball Blue',
    'Pacific Blue'
  ],
  'Deep sky blue': ['Deep sky blue', 'Capri', 'Spiro Disco Ball'],
  'Tufts Blue': [
    'Tufts Blue',
    'Steel blue',
    'Glaucous',
    'Rackley',
    'Air Force blue'
  ],
  'Rich electric blue': ['Rich electric blue'],
  'True Blue': [
    'True Blue',
    'Ocean Boat Blue',
    'French blue',
    'Medium Persian blue',
    'Green Blue',
    'Denim'
  ],
  'Dark cerulean': [
    'Dark cerulean',
    'Yale Blue',
    'Medium electric blue',
    'Lapis lazuli'
  ],
  Azure: ['Brandeis blue', 'Azure', 'Dodger blue', 'Bleu de France'],
  'Ultramarine blue': ['Ultramarine blue', 'Royal blue', 'Han blue'],
  Cobalt: [
    'Violet Blue',
    'Cerulean blue',
    'Sapphire',
    'Medium teal blue',
    'Cobalt',
    'Royal azure',
    'UA blue',
    'Persian blue',
    'Palatinate blue',
    'Egyptian blue'
  ],
  'Navy blue': [
    'Navy blue',
    'Ultramarine',
    'Phthalo blue',
    'Dark blue',
    'Duke blue',
    'Zaffre',
    'Medium blue',
    'Dark powder blue',
    'International Klein Blue',
    'Smalt'
  ],
  'Dark midnight blue': [
    'Oxford Blue',
    'Prussian blue',
    'Cool black',
    'Dark midnight blue'
  ],
  Blue: ['Blue', 'Electric ultramarine', 'Han purple'],
  'Midnight blue': ['Saint Patrick Blue', 'Midnight blue'],
  Indigo: ['Electric indigo', 'Indigo', 'Persian indigo'],
  'Dark slate blue': ['Dark slate blue'],
  Iris: [
    'Toolbox',
    'Slate blue',
    'Iris',
    'Majorelle Blue',
    'Medium slate blue'
  ],
  'UCLA Blue': ['UCLA Blue'],
  Bistre: ['Bistre'],
  'Café noir': ['Café noir'],
  'Dark brown': ['Dark brown', 'Otter brown', 'Coffee', 'Sepia'],
  'Rosy brown': ['Old rose', 'Rosy brown'],
  'Deep chestnut': ['Rose vale', 'Deep chestnut'],
  'Rose taupe': ['Dark chestnut', 'Copper rose', 'Rose taupe', 'Mauve taupe'],
  'Deep coffee': ['Deep coffee', 'Bole'],
  Taupe: ['Dark lava', 'Dark taupe', 'Taupe'],
  Beaver: ['Pastel brown', 'Beaver'],
  'Ruddy brown': ['Light brown', 'Ruddy brown', 'Copper'],
  'Saddle brown': ['Russet', 'Saddle brown'],
  Cinereous: ['Cinereous'],
  'Light taupe': ['Light taupe', 'Pale taupe', 'Antique brass'],
  Camel: ['Lion', 'Fallow', 'Desert', 'Camel'],
  Tan: ['Tan', 'Burlywood', 'Pale gold', 'Tumbleweed'],
  'Pale brown': ['Pale brown', 'Chamoisee', 'Café au lait', 'French beige'],
  'Medium taupe': ['Wenge', 'Umber', 'Medium taupe', 'Rose ebony'],
  Auburn: ['Red brown', 'Brown', 'Auburn', 'Vivid auburn'],
  Wine: ['Smokey topaz', 'Cordovan', 'Wine'],
  'Burnt umber': ['Burnt umber', 'Sienna'],
  Shadow: ['Shadow', 'Dark tan'],
  'Caput mortuum': ['Caput mortuum'],
  'Seal brown': ['Zinnwaldite brown', 'Seal brown', 'Dark sienna'],
  'Mint cream': ['Mint cream'],
  Cream: ['Cream', 'Cornsilk'],
  'Cosmic latte': ['Cosmic latte', 'Old lace', 'Linen'],
  Beige: ['Beige', 'Light goldenrod yellow', 'Lemon chiffon'],
  Blond: ['Blond'],
  Almond: ['Pearl', 'Almond', 'Bone'],
  'Payne grey': ['Dark electric blue', 'Cadet', 'Payne grey'],
  Grullo: ['Grullo'],
  'Pastel gray': ['Pastel gray'],
  'Light gray': ['Light gray', 'Timberwolf', 'Gainsboro', 'Platinum'],
  Silver: ['Silver', 'Pale silver', 'Lavender gray'],
  'Dark gray': ['Dark gray'],
  'Pastel blue': ['Pastel blue'],
  'Cool grey': ['Cadet grey', 'Manatee', 'Cool grey'],
  'Slate gray': ['Light slate gray', 'Slate gray'],
  'Dark slate gray': ['Outer Space', 'Charcoal', 'Dark slate gray'],
  'Davy grey': ['Liver', 'Davy grey', 'Dim gray'],
  Gray: ['Trolley Grey', 'Gray', 'Battleship grey', 'Taupe gray'],
  'Light sea green': ['Verdigris', 'Light sea green', 'Tiffany Blue'],
  'Pearl Aqua': ['Pale robin egg blue', 'Pearl Aqua', 'Magic mint'],
  Mint: [
    'Jungle green',
    'Mint',
    'Mountain Meadow',
    'Shamrock',
    'Medium aquamarine',
    'Caribbean green'
  ],
  Teal: ['Dark cyan', 'Teal', 'Stormcloud', 'Skobeloff', 'Pine green'],
  'Fern green': ['Fern green', 'Dark olive green', 'Sap green', 'Olive Drab'],
  Olive: ['Heart Gold', 'Olive'],
  'Army green': ['Army green'],
  'Camouflage green': [
    'Feldgrau',
    'Gray asparagus',
    'AuroMetalSaurus',
    'Xanadu',
    'Camouflage green'
  ],
  'Laurel green': ['Laurel green', 'Cambridge Blue', 'Ash grey'],
  'Neon green': [
    'Lawn green',
    'Chartreuse',
    'Bright green',
    'Harlequin',
    'Neon green',
    'Electric green',
    'Green'
  ],
  'Lime green': ['Lime green', 'Kelly green', 'Dark pastel green', 'Malachite'],
  Pistachio: ['Mantis', 'Dollar bill', 'Pistachio', 'Olivine', 'Asparagus'],
  'Medium spring bud': ['Medium spring bud'],
  'Pale spring bud': ['Pale spring bud'],
  'Olive Green': ['Vegas gold', 'Dark khaki', 'Olive Green'],
  'Satin sheen gold': ['Satin sheen gold'],
  'Deep jungle green': [
    'Midnight green',
    'Deep jungle green',
    'Warm black',
    'Rich black',
    'MSU Green',
    'Sacramento State green'
  ],
  'Forest green': [
    'North Texas Green',
    'Forest green',
    'Napier green',
    'India green',
    'Islamic green',
    'Office green',
    'Ao',
    'La Salle Green',
    'Sea green',
    'Shamrock green'
  ],
  'Teal green': [
    'Cadmium green',
    'Dartmouth green',
    'Tropical rain forest',
    'Teal green',
    'Bottle green',
    'Dark spring green'
  ],
  'Hooker green': ['Hooker green', 'Viridian'],
  'Dark green': [
    'UP Forest green',
    'British racing green',
    'Dark green',
    'Medium jungle green',
    'Phthalo green',
    'Cal Poly Pomona green',
    'Myrtle',
    'Hunter green',
    'Pakistan green',
    'Lincoln green'
  ],
  'Rifle green': ['Rifle green'],
  'Yellow green': [
    'Yellow green',
    'Android Green',
    'June bud',
    'Apple green',
    'Pear'
  ],
  'Green yellow': ['Spring bud', 'Green yellow', 'Inchworm'],
  Fern: ['Dark sea green', 'Fern', 'Eton blue'],
  'Tea green': ['Turquoise green', 'Moss green', 'Celadon', 'Tea green'],
  'Light green': [
    'Granny Smith Apple',
    'Light green',
    'Pale green',
    'Mint green',
    'Screamin Green',
    'Pastel green'
  ],
  'Spring green': ['Spring green', 'Guppie green', 'Medium spring green'],
  Emerald: ['UFO Green', 'Paris Green', 'Emerald', 'Medium sea green', 'Jade'],
  'Field drab': ['Field drab'],
  'Twilight lavender': ['Antique fuchsia', 'Raspberry glace', 'Twilight lavender'],
  'Bright lavender': ['Bright lavender', 'Light pastel purple'],
  Mauve: ['Mauve', 'Bright ube', 'Wisteria', 'Lilac'],
  'Medium lavender magenta': ['Medium lavender magenta', 'Pale plum', 'Plum', 'Pastel violet'],
  'Electric lavender': ['Brilliant lavender', 'Electric lavender'],
  'Languid lavender': ['Languid lavender', 'Thistle'],
  'Lavender mist': ['Lavender', 'Lavender mist', 'Glitter'],
  'Lavender blue': ['Lavender blue', 'Periwinkle', 'Pale lavender'],
  'Lavender indigo': ['Lavender indigo'],
  'Dark lavender': ['Royal purple', 'Dark lavender'],
  'Old lavender': ['Old lavender'],
  'Lavender purple': [
    "Purple Mountain's Majesty",
    'Lavender purple',
    'Purple mountain majesty',
    'Ube'
  ],
  'Dark magenta': ['Patriarch', 'Purple', 'Dark magenta'],
  'Medium orchid': ['Rich lilac', 'Medium orchid', 'Deep fuchsia'],
  'Deep magenta': ['Deep magenta'],
  'Sky magenta': ['Sky magenta'],
  Flame: ['Flame', 'Portland Orange', 'Tomato', 'Deep carrot orange'],
  'Orange red': ['International orange', 'Tangelo', 'Orange red'],
  'Burnt sienna': ['Burnt sienna', 'Terra cotta'],
  'Outrageous Orange': ['Outrageous Orange', 'Coral', 'Mango Tango'],
  'Safety orange': ['Pumpkin', 'Safety orange'],
  'Burnt orange': ['Burnt orange', 'Tawny'],
  Chestnut: ['Fuzzy Wuzzy', 'Chestnut', 'Indian red'],
  'Persian orange': ['Pale copper', 'Raw Sienna', 'Persian orange'],
  'Yellow Orange': [
    'Orange peel',
    'Orange',
    'Chrome yellow',
    'Pastel orange',
    'Yellow Orange',
    'Neon Carrot',
    'Deep saffron'
  ],
  Rust: ['Rust', 'Mahogany', 'Sinopia'],
  'Sunset Orange': ['Sunset Orange', 'Red Orange', 'Carmine pink'],
  'Coral pink': [
    'Coral pink',
    'Light coral',
    'Light carmine pink',
    'Candy pink',
    'Pale violet red',
    'Pale red violet'
  ],
  'Wild Strawberry': [
    'French rose',
    'Violet Red',
    'Brilliant rose',
    'Wild Strawberry',
    'Rose bonbon',
    'Cerise pink',
    'Mulberry',
    'Raspberry pink',
    'Thulian pink'
  ],
  Magenta: [
    'Famous',
    'Fuchsia',
    'Magenta',
    'Shocking pink',
    'Hot magenta',
    'Razzle dazzle rose'
  ],
  'Deep pink': [
    'Persian rose',
    'Fluorescent pink',
    'Deep pink',
    'Rose',
    'Bright pink',
    'Hollywood cerise',
    'Fashion fuchsia',
    'Deep cerise'
  ],
  'Royal fuchsia': ['Royal fuchsia', 'Medium red violet', 'Fandango'],
  'Fuchsia pink': [
    'Ultra pink',
    'Pink Flamingo',
    'Fuchsia pink',
    'Light fuchsia pink',
    'Pale magenta'
  ],
  'Neon fuchsia': [
    'Rose pink',
    'Neon fuchsia',
    'Purple pizzazz',
    'Hot pink',
    'Persian pink'
  ],
  Orchid: ['Heliotrope', 'Orchid'],
  'Rose quartz': ['Rose quartz', 'Pastel purple'],
  'Opera mauve': ['Opera mauve'],
  'Peach puff': ['Peach puff', 'Light apricot', 'Apricot', 'Deep peach'],
  'Baby pink': ['Melon', 'Baby pink', 'Tea rose', 'Pale chestnut'],
  'Light pink': [
    'Cherry blossom pink',
    'Light pink',
    'Pink',
    'Bubble gum',
    'Pastel pink',
    'Pale pink',
    'Misty rose',
    'Piggy pink'
  ],
  'Carnation pink': [
    'Nadeshiko pink',
    'Lavender pink',
    'Carnation pink',
    'Pastel magenta',
    'Cotton candy',
    'Classic rose',
    'Pink pearl'
  ],
  'Salmon pink': [
    'Mauvelous',
    'Pink Sherbet',
    'Flamingo pink',
    'Tickle Me Pink',
    'Salmon pink',
    'Light salmon pink',
    'Light Thulian pink'
  ],
  'Brink pink': [
    'Blush',
    'Dark pink',
    'Light Crimson',
    'Wild Watermelon',
    'Brink pink'
  ],
  'Dark salmon': ['Dark salmon'],
  'Mountbatten pink': ['Bazaar', 'Mountbatten pink'],
  'Rose gold': ['Rose gold', 'Turkish rose', 'Puce', 'Ruddy pink'],
  Byzantine: ['Byzantine'],
  'Blue violet': ['Blue purple', 'Blue violet', 'Veronica', 'Dark orchid'],
  'Purple Heart': ['Purple Heart'],
  Byzantium: ['Byzantium', 'Palatinate purple'],
  'Old mauve': ['Dark byzantium', 'Halayà úbe', 'Old mauve'],
  'Dark raspberry': ['Pansy purple', 'Dark raspberry', 'Boysenberry'],
  'Electric purple': ['Electric purple', 'Phlox', 'Psychedelic purple'],
  'Medium purple': ['Medium purple', 'Dark pastel purple'],
  'Tyrian purple': ['Tyrian purple'],
  'French lilac': ['French lilac'],
  'Pastel red': ['Pastel red', 'Bittersweet'],
  'Medium carmine': ['Pale carmine', 'Medium carmine', 'Dark coral'],
  Scarlet: ['Coquelicot', 'Ferrari Red', 'Scarlet', 'Red', 'Candy apple red'],
  Cardinal: [
    'Cardinal',
    'Bright maroon',
    'Utah Crimson',
    'Rich carmine',
    'Crimson'
  ],
  Amaranth: [
    'Amaranth',
    'Cherry',
    'Cerise',
    'Razzmatazz',
    'Dogwood rose',
    'Ruby',
    'Raspberry',
    'Debian red',
    'UA red'
  ],
  'Persian red': [
    'Cinnabar',
    'Vermilion',
    'CG Red',
    'Jasper',
    'Persian red',
    'Dark pastel red'
  ],
  'Carmine red': [
    'Folly',
    'Ruddy',
    'Carmine red',
    'Carmine',
    'Electric crimson',
    'American rose',
    'Tractor red',
    'Awesome',
    'Radical Red',
    'Coral red',
    'Deep carmine pink',
    'Rose madder',
    'Alizarin crimson'
  ],
  'Venetian red': [
    'Rosso corsa',
    'Boston University Red',
    'Harvard crimson',
    'Venetian red',
    'Lava',
    'Fire engine red',
    'Cadmium red',
    'KU Crimson',
    'Lust',
    'Medium candy apple red'
  ],
  Firebrick: [
    'Dark candy apple red',
    'Mordant red 19',
    'Rufous',
    'Cornell Red',
    'Carnelian',
    'Firebrick',
    'Upsdell red',
    'Crimson glory'
  ],
  Maroon: ['Maroon', 'Dark red', 'USC Cardinal', 'Stizza', 'Crimson Red'],
  Rosewood: [
    'Bulgarian rose',
    'Dark scarlet',
    'Rosewood',
    'Persian plum',
    'UP Maroon',
    'Burgundy',
    'Falu red'
  ],
  'Brick red': ['Dark terra cotta', 'Brick red'],
  'Rich maroon': ['Rich maroon', 'Raspberry rose'],
  'Jazzberry jam': ['Jazzberry jam'],
  'Vivid burgundy': ['Vivid burgundy', 'Deep carmine'],
  'Dark violet': ['Dark violet', 'Vivid violet', 'Electric violet'],
  'Deep lilac': ['Deep lilac', 'Amethyst'],
  Violet: ['Lavender magenta', 'Violet'],
  'Lavender rose': ['Lavender rose'],
  'Red violet': ['Red violet', 'Medium violet red', 'Vivid cerise'],
  Eggplant: ['Tuscan red', 'Eggplant', 'Purple taupe'],
  Honeydew: ['Honeydew'],
  'Lavender blush': ['Seashell', 'Isabelline', 'Lavender blush'],
  'Floral white': ['Ivory', 'Light yellow', 'Floral white'],
  'Ghost white': [
    'Munsell',
    'White smoke',
    'Anti-flash white',
    'Magnolia',
    'Ghost white',
    'Alice blue'
  ],
  White: ['White', 'Snow'],
  Canary: ['Canary', 'Pastel yellow'],
  Sunset: ['Champagne', 'Deep champagne', 'Sunset'],
  'Orange Yellow': [
    'Gold',
    'School bus yellow',
    'Golden yellow',
    'Mustard',
    'Jonquil',
    'Naples yellow',
    'Stil de grain yellow',
    'Orange Yellow'
  ],
  Lemon: [
    'Aureolin',
    'Canary yellow',
    'Cadmium yellow',
    'Lemon',
    'Electric yellow',
    'Yellow',
    'Laser Lemon',
    'Daffodil'
  ],
  Dandelion: [
    'Banana yellow',
    'Dandelion',
    'Sandstorm',
    'Titanium yellow',
    'Peridot',
    'Citrine',
    'Corn',
    'Maize'
  ],
  'Hansa yellow': [
    'Arylide yellow',
    'Hansa yellow',
    'Straw',
    'Flax',
    'Buff',
    'Jasmine',
    'Light khaki',
    'Flavescent'
  ],
  Tangerine: [
    'Cadmium orange',
    'Carrot orange',
    'Gamboge',
    'Harvest Gold',
    'Fulvous',
    'Tangerine',
    'Dark orange',
    'Princeton orange'
  ],
  Amber: [
    'UCLA Gold',
    'Selective yellow',
    'Amber',
    'Fluorescent orange',
    'Golden poppy',
    'Mikado yellow',
    'Tangerine yellow',
    'USC Gold',
    'Sunglow',
    'Saffron'
  ],
  'Dark tangerine': ['Dark tangerine'],
  Chocolate: ['Bronze', 'Ochre', 'Chocolate', 'Cinnamon', 'Cocoa brown'],
  'Dark goldenrod': ['Dark goldenrod', 'University of California Gold'],
  Brass: ['Brass'],
  'Sandy taupe': ['Drab', 'Mode beige', 'Sand dune', 'Sandy taupe'],
  'Golden brown': ['Golden brown'],
  Ginger: ['Ginger'],
  Ecru: ['Ecru', 'Sand', 'Khaki'],
  'Desert sand': ['Desert sand'],
  'Blanched Almond': [
    'Eggshell',
    'Antique white',
    'Moccasin',
    'Papaya whip',
    'Blanched Almond',
    'Bisque'
  ],
  Peach: [
    'Medium champagne',
    'Vanilla',
    'Pale goldenrod',
    'Banana Mania',
    'Peach',
    'Navajo white',
    'Peach yellow',
    'Wheat'
  ],
  'Earth yellow': ['Fawn', 'Earth yellow', 'Indian yellow'],
  'Fluorescent yellow': ['Fluorescent yellow', 'Electric lime', 'Lime', 'Lemon lime'],
  Goldenrod: ['Goldenrod', 'Urobilin', 'Meat brown'],
  'Old gold': ['Old gold'],
  'Lemon Yellow': ['Lemon Yellow', 'Icterine', 'Unmellow Yellow'],
  'Sandy brown': ['Sandy brown'],
  'Tiger eye': ['Tiger eye'],
  'Macaroni and Cheese': ['Topaz', 'Macaroni and Cheese'],
  'Vivid tangerine': ['Vivid tangerine', 'Light salmon', 'Atomic tangerine', 'Salmon']
}


export const metaCategories = [
  "Bags",
  "Caps & Hats",
  "Coats, Suits, Blazers",
  "Dresses",
  "Dungaree",
  "Ethinic Wear",
  "Footwear",
  "Jackets",
  "Jeans",
  "Jumpsuits",
  "Leggings",
  "Lingerie & Swimwear",
  "Lounge Coords",
  "Other Outerwear",
  "Shirts",
  "Shorts",
  "Skirts",
  "Skorts",
  "Socks & Stockings",
  "Sunglasses",
  "Sweaters & Cardigans",
  "Sweatshirts & Hoodies",
  "Topwear",
  "Trousers",
  "Tshirts",
  "Track/ Sweat/ Pyjama Pants",
  "Belt",
  "Glove"
]


export const metaCategoriesMen = [
  "Bags",
  "Caps & Hats",
  "Coats, Suits, Blazers",
  "Ethinic Wear",
  "Footwear",
  "Jackets",
  "Jeans",
  "Jumpsuits",
  "Lounge Coords",
  "Other Outerwear",
  "Shirts",
  "Shorts",
  "Sunglasses",
  "Sweaters & Cardigans",
  "Sweatshirts & Hoodies",
  "Track/ Sweat/ Pyjama Pants",
  "Trousers",
  "Tshirts"
]


export const metaCategoryTags = {
  Topwear: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TOPWEAR SILHOUETTE',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Tshirts: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TSHIRT SILHOUETTE',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Shirts: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'SHIRT SILHOUETTE',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Jeans: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'PANT/ JEANS FIT',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Track/ Sweat/ Pyjama Pants': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'PANT/ JEANS FIT',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Leggings: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE',
    'PANT/ JEANS FIT'
  ],
  Shorts: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'SHORTS SILHOUETTE',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Skirts: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'SKIRT SILHOUETTE',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Skorts: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Trousers: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'PANT/ JEANS FIT',
    'BOTTOM LENGTH',
    'WAIST STYLING',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Dresses: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'DRESS SILHOUETTE',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Dungaree: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Jumpsuits: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Sweaters & Cardigans': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'SWEATER & CARDIGAN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Coats, Suits, Blazers': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'COATS, SUITS TYPE',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Sweatshirts & Hoodies': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Jackets: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'JACKETS',
    'TOP LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Other Outerwear': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'OTHER OUTERWEAR',
    'TOP LENGTH',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Ethinic Wear': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'ETHINIC WEAR',
    'TOP LENGTH',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'BACK',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'KURTA SILHOUETTE',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Lounge Coords': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TOPWEAR SILHOUETTE',
    'PANT/ JEANS FIT',
    'SKIRT SILHOUETTE',
    'SHORTS SILHOUETTE',
    'CASUAL COORDS',
    'TOP LENGTH',
    'BOTTOM LENGTH',
    'NECKLINE',
    'SHOULDER STYLE',
    'SLEEVE LENGTH',
    'SLEEVE STYLE',
    'WAIST STYLING',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Bags: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'BAG TYPE',
    'BAG SHAPE',
    'BAG HANDLE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  Footwear: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'FOOTWEAR SILHOUETTE',
    'FOOTWEAR NAME',
    'FOOTWEAR HEEL/ SOLE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'UNIQUE SHAPES & DETAILS',
    'TOE SHAPE'
  ],
  'Caps & Hats': ['PATTERN', 'TEXTURES', 'CAPS/ HATS'],
  Sunglasses: ['SUNGLASS FRAME', 'SUNGLASS LENS', 'PATTERN'],
  'Lingerie & Swimwear': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'NECKLINE',
    'SHOULDER STYLE',
    'WAIST STYLING',
    'FRONT CLOSURE',
    'HEMLINE SHAPE',
    'TRANSPARENCY',
    'DENIM EFFECT',
    'TRIM/ EDGE/ HEM',
    'CHEST DESIGN',
    'UNIQUE SHAPES & DETAILS',
    'PATTERN SIZE'
  ],
  'Socks & Stockings': [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TRANSPARENCY',
    'SOCKS & STOCKINGS',
    'UNIQUE SHAPES & DETAILS'
  ],
  Glove: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TRANSPARENCY',
    'GLOVES',
    'UNIQUE SHAPES & DETAILS'
  ],
  Belt: [
    'DESIGN/ THEME',
    'SURFACE ADDONS',
    'PATTERN',
    'TEXTURES',
    'TRANSPARENCY',
    'BELTS',
    'UNIQUE SHAPES & DETAILS'
  ]
}

export const metaTags = {
  BACK: [
    'Backless',
    'Keyhole Back',
    'Racer Back',
    'Tie Detail Back',
    '#N/A'
  ],
  'BAG HANDLE': [
    'Beaded Handle',
    'Chain Handle',
    'Cutout In Bag Handle',
    'Knot strap handle',
    'Metallic Handle',
    'Regular Strap Handle',
    'Round Handle',
    'Scrunched bag handle',
    'Wider Belt/ Handle',
    'Wooden Handle',
    'Wrist Strap',
    '#N/A'
  ],
  'BAG SHAPE': [
    'Animal', 'Bucket',
    'Capsule', 'Circular',
    'Collapsing Middle', 'Curve Edge',
    'Cylindrical Wide', 'Envelope',
    'Heart', 'Oval',
    'Polygon shape bag', 'Potli',
    'Rectangle Long', 'Rectangle Wide',
    'Square', 'Trapeze',
    'Triangle', '#N/A'
  ],
  'BAG TYPE': [
    'Backpack',
    'Baquette',
    'Boston/ Barrel/ Duffel Bag',
    'Box Bag',
    'Briefcase bag trend',
    'Clutches',
    'Coin Purse bags',
    'Drawstring Bag',
    'Fanny/ Waist Bag',
    'Handheld Bag',
    'Hobo, Bindle bags',
    'Jodie Bag',
    'Messenger/ Satchel bags',
    'Mini Bags',
    'Pillow Bag',
    'Saddle Bag',
    'Shoulder Bag',
    'Sling/ Cross Body Bag',
    'Squeeze Bag',
    'Tote Bag',
    'Vanity bag',
    '#N/A'
  ],
  BELTS: [
    'Chain Belt',
    'Corset Belt',
    'Narrow Belt',
    'Rope Belt',
    'Wide Belt'
  ],
  'BOTTOM LENGTH': [
    'Ankle/ Maxi Length',
    'Calf/ Midi Length',
    'Floor Length',
    'Knee Length',
    'Micro (Above Thigh Length)',
    'Mini (Thigh Length)',
    'Slight Above Ankle Length'
  ],
  'CAPS/ HATS': [
    'Bandana',
    'Baseball & Basketball Caps',
    'Beanie',
    'Beret Hat',
    'Breton Cap',
    'Bucket Hat',
    'Cowboy Hat',
    'Fedora Hat',
    'Gaucho Hat',
    'Large Brim Hat',
    'Newsboy Cap/ Ascot Cap'
  ],
  'CASUAL COORDS': ['Loungewear Coord', 'Pyjama Set', 'Track Suit'],
  'CHEST DESIGN': [
    'Balconette Style',
    'Cone Cup Dress/ Top',
    'Embellished/ Studded Cup Dress/ Top',
    'Floral Cup Dress/ Top',
    'Folded At Chest Detail',
    'Heart Cup Dress/ Top',
    'Laced Cup Dress/ Top',
    'Milkmaid Detail',
    'Strappy Cup Dress/ Top',
    'Triangle Cup Top/ Dress',
    'Underwired Dress/ Top',
    '#N/A'
  ],
  'COATS, SUITS TYPE': [
    'Cropped Blazer',
    'Double Breasted Blazer',
    'Overcoat',
    'Pea Coat',
    'Single Breated Blazer',
    'Suits',
    'Trench Coat',
    'Waistcoat'
  ],
  'DENIM EFFECT': [
    'All Over Distress',
    'No Distress/ Slash',
    'Only Slash',
    'Patched Denim',
    'Small-Medium Distress',
    'Washed',
    '#N/A'
  ],
  'DESIGN/ THEME': [
    'Asymmetric/ High Low',
    'Athleisure/ Jersey/ Gym/ Sports',
    'Blazer Style (Dress/ Jumpsuit)',
    'Cargo Style, Utility/ Technical',
    'Corset Style (Dress/ Top/ Jumpsuit)',
    'Cut Out Trend',
    'Draped/ Layered',
    'Exposed Pockets',
    'Inside Out Trend',
    'Large/ Box Pleats',
    'Oversize',
    'Padded (Flat Padded)',
    'Panelled',
    'Puffer/ Puffed Design (Bag/ Jacket)',
    'Ripped/ Shredded',
    'Ruched/ Gathered/ Scrunched',
    'Ruffled',
    'Small/ Regular Pleats',
    'Smocked',
    'Tailored Cut/ Formal',
    'Tiered Dress/ Skirt',
    '#N/A'
  ],
  'DRESS SILHOUETTE': [
    'A Line Dress',
    'Blazer Dress',
    'Bodycon Dress / Pencil Dress',
    'Cape Dress',
    'Chinese Dress',
    'Column Dress',
    'Corset Dress',
    'Deconstructed Dress',
    'Empire Dress',
    'Fit Flare Dress',
    'Kaftan Dress',
    'Mermaid Dress',
    'Peplum Dress',
    'Pinafore/ Suspender Dress',
    'Sheath Dress',
    'Shift Dress',
    'Shirt Dress',
    'Skater Skirt Dress',
    'Slip Dress',
    'Tennis Dress',
    'Trench Dress',
    'Tshirt Dress',
    'Tube Dress',
    'Wrap Dress'
  ],
  'ETHINIC WEAR': [
    'Anarkali',
    'Dhoti Salwar',
    'Kaftan Set',
    'Kurta',
    'Lehenga',
    'Pants Top Coord',
    'Salwar Suit',
    'Saree',
    'Sharara Set',
    'Sherwani, and Nawabi Set'
  ],
  'The sherwani is typically worn over a pair of trousers and can be paired up with a scarf or jacket"': [undefined],
  'FOOTWEAR HEEL/ SOLE': [
    'Artistic Heels',
    'Block Heels',
    'Chunky Soles',
    'Kitten Heels',
    'No Heels (Flats)',
    'Pointed/ Stiletto Heels',
    'Regular Soles',
    'Wedge Heels',
    'Zig Zag'
  ],
  'FOOTWEAR NAME': [
    'Adidas Sneakers',
    'Ballet Pumps',
    'Brogues/ Oxfords/ Formal Shoes',
    'Chelsea Boots',
    'Clear Strap Sandals',
    'Clogs',
    'Combat Boots',
    'Converse & canvas shoes',
    'Cowboy Boots',
    'Folded Shaft Boot',
    'Gladiator/ Caged Sandals',
    'Gum Boots',
    'Jutti',
    'Loafers',
    'Mary Jane',
    'Mules',
    'New Balance Sneakers',
    'Nike Sneakers',
    'Onitsuka Sneakers',
    'Regular Ankle Length Boots',
    'Regular Calf Length Boots',
    'Regular Knee Length Boots',
    'Regular Thigh Length Boots',
    'Scrunched Boots',
    'Slides',
    'Snow Boots',
    'Sock Style Boot',
    'Thong Sandals',
    'Tieup Ballet Flats',
    'Uggs',
    '#N/A'
  ],
  'FOOTWEAR SILHOUETTE': [
    'Ankle Strap/ Sling Back Sandals (open front)',
    'HighTop Sneakers',
    'Home Slippers',
    'Lace Up Boots',
    'Lace Up Casual Shoes',
    'Lace Up Formal Shoes',
    'No Lace Boots',
    'Pumps/ Court Shoes (closed front)',
    'Regular Sneakers',
    'Slip On Sandals (open front)',
    'Slip On Shoes'
  ],
  'FRONT CLOSURE': [
    'Buttoned (Decorative/ Functional)',
    'Concealed Buttons',
    'Drawstring Closure',
    'Halfway Zip/Button',
    'Knot Closure',
    'Off Center Button/ Zip',
    'Open/Closed without zip/buttons',
    'Pull Over Style',
    'Wrap Style Closure',
    'Zip Style Closure'
  ],
  GLOVES: ['Fingerless Gloves', 'Full Finger Gloves'],
  'HEMLINE SHAPE': [
    'Asymmetric Hem',
    'Curved Hem',
    'Double Hem',
    'Drawstring Hem',
    'Flared at the hem',
    'Split Hem',
    'Straight/ Regular Hem'
  ],
  JACKETS: [
    'Bolero Jacket',
    'Bomber Jacket',
    'Chanel Inspired Jacket',
    'Deconstructued Jacket',
    'Denim Jacket',
    'Ethinic Coat/ Jacket',
    'Gilet',
    'Leather Jacket',
    'Nehru Blazer',
    'Parka Jacket',
    'Puffer Jacket',
    'Pullover Jacket',
    'Racing/ Moto Jacket',
    'Shacket',
    'Teddy Jacket',
    'Teddy Lined/ Pile Jacket',
    'Utility/ Cargo Jacket',
    'Varsity Jacket',
    'Windcheater Jackets'
  ],
  'KURTA SILHOUETTE': [
    'A Line Kurta',
    'Fit & Flare Kurta',
    'Short Thigh Length Kurta',
    'Slip Kurta',
    '#N/A'
  ],
  NECKLINE: [
    'Asymmetric Neck',
    'Boat/ Wide Neck',
    'Chinese / Short Collar',
    'Choker',
    'Coat Lapel',
    'Contrasting collar',
    'Cowl Neck/ Swing Neck',
    'Cross Neck (Halterneck)',
    'Embellished collar',
    'Henley/ Button Neck',
    'High/ Turtle Neck',
    'Highneck (Halterneck)',
    'Large Collar',
    'Medium/ Resort Collar',
    'Necktie Detail',
    'Peter Pan Collar (Small)',
    'Plunging/ Deep Neck',
    'Racer Front/ Back',
    'Regular/ Classic Collar',
    'Round/ Crew Neck',
    'Scoop Neck',
    'Spaghetti Straps',
    'Square Neck',
    'Standing Collar',
    'Strapless/ Tube',
    'Sweetheart Neck',
    'Tie/ Knot/ Scarf Neck',
    'Tieback (Halterneck)',
    'Trapeze (non curve) Neckline',
    'U Neck',
    'V Neck',
    'WW2 Neckline',
    '#N/A'
  ],
  'OTHER OUTERWEAR': ['Kimonos', 'Shrugs'],
  'PANT/ JEANS FIT': [
    'Baggy/ Loose Fit',
    'Baloon/ Aladdin',
    'Barrel Jeans',
    'Bootcut/ Flared',
    'Capris',
    'Cargo Pants/ Jeans',
    'Chino Pants',
    'Cigarette Trousers',
    'Drop Crotch',
    'Gurkha Fit / Buckle Pants',
    'Kilt Pants',
    'Loose Chino Pants',
    'Mom Fit Jeans',
    'Palazzo/ Culottes Design',
    'Parachute Pants',
    'Pyjamas',
    'Skinny/ Narrow Leg',
    'Straight Leg',
    'Sweat Pants/ Joggers',
    'Track Pants',
    'Wide Leg',
    'Wrap Pants'
  ],
  PATTERN: [
    'Anchor Print (All Over)',
    'Band/ Movie',
    'Beach, Ocean, Surfing, Sea, Aquatic Life, Ship',
    'Bones, Skull, Skelton',
    'Butterfly, Bee, Insect Print (All Over)',
    'Camoflouge (All Over)',
    'Chain Link (All Over)',
    'Character/ Illustration (All Over)',
    'Character/ Illustration (Front/ Back)',
    'Checkboard (All Over)',
    'Checks (All)',
    'City View Print',
    'Cloud print',
    'Collage',
    'Color Block (All Over)',
    'Cow Skin (All Over)',
    'Diagnol Stripes (All Over)',
    'Diamond (All Over)',
    'Dotted/ Spotted (All Over)',
    'Dragon Big & Small',
    'Embosed Logo/ Text',
    'Emoji (All Over)',
    'Emoji (Front/ Back)',
    'Eye Print (All Over)',
    'Face Print',
    'Feather Print',
    'Fire/ Flame Print (All Over)',
    'Floral (All)',
    'Fruit Print (All Over)',
    'Geometric, Aztec (All Over)',
    'Gingham (All Over)',
    'Gothic/ Angel/ Demon (Front/ Back)',
    'Gradient / Ombre (All Over)',
    'Heart Pattern (All Over)',
    'Honeycomb pattern',
    'Horizontal Stripes (All Over)',
    'Houndstooth (All Over)',
    'Kiss/ Lipstick Print (All Over)',
    'Leaf Print (All Over)',
    'Leopard Skin (All Over)',
    'Line Drawing',
    'Logo (All Over)',
    'Logo (Front, Back, Chest)',
    'Logo on left chest',
    'Marble/ Watercolor (All Over)',
    'Maze pattern',
    'Melange Effect',
    'Mirrored Print (All Over)',
    'Nature View Print',
    'Painterly Print (All Over)',
    'Paisley (All Over)',
    'Panelled Multiple Prints (All Over)',
    'Patchwork',
    'Pinstripes (All Over)',
    'Polka (All Over)',
    'Print Only On Side/ Top/ Bottom',
    'Snake Skin (All Over)',
    'Solids',
    'Space, Sun, Moon, Celestial etc.',
    'Swirl (All Over)',
    'Text (All Over)',
    'Text (Front/ Back)',
    'Tie Dye',
    'Tiger Skin (All Over)',
    'Tile Print (All Over)',
    'Tree Print (All Over)',
    'Vertical Stripes (All Over)',
    'Wavy/ Curve (All Over)',
    'Zebra Skin (All Over)',
    'Zig Zag (All Over)'
  ],
  'PATTERN SIZE': ['Large', 'Medium', 'Small', '#N/A'],
  'SHIRT SILHOUETTE': [
    'Button Down Shirt (Formal, Casual, Half, Full, All)',
    'Resort Shirt'
  ],
  'SHORTS SILHOUETTE': [
    'Bicycle Shorts',
    'Boat Shorts',
    'Cargo Shorts',
    'Denim Jorts (Jean+shorts)',
    'Denim Shorts',
    'Formal Shorts',
    'Highcut Shorts',
    'Jersey Shorts',
    'Mini Shorts',
    'Pyjama Shorts'
  ],
  'SHOULDER STYLE': [
    'Chain strap shoulder',
    'Cold Shoulder',
    'Drop Shoulder',
    'Flower Applique Strap',
    'Knotted Shoulder Strap',
    'Lace Shoulder Strap',
    'Off Shoulder',
    'One Shoulder',
    'Puffed Shoulder',
    'Regular Shoulders',
    'Ruffled Shoulder Strap',
    'Scrunched Shoulder Strap',
    'Shoulder Pads',
    'Shoulder Tabs',
    'Thin Shoulder Straps',
    'Wide Shoulder Straps',
    '#N/A'
  ],
  'SKIRT SILHOUETTE': [
    'A Line Skirt',
    'Bandeau Skirt',
    'Bodycon & Pencil Skirts',
    'Cargo Skirt',
    'Fit & Flare Skirt',
    'Flared Skirt',
    'Mermaid Skirt',
    'Pencil/ Column/ Straight Skirt',
    'Peplum Skirt',
    'Skater Skirt',
    'Wrap Skirt'
  ],
  'SLEEVE LENGTH': [
    '1/4 sleeve',
    '2/4 sleeve',
    '3/4 sleeve',
    'Extra Long Sleeve',
    'Full Sleeve',
    'Sleeveless'
  ],
  'SLEEVE STYLE': [
    'Basic Sleeves',
    'Bell/ Flounce Sleeves',
    'Bishop / Baloon Sleeves',
    'Cap Sleeves',
    'Detached Sleeves',
    'Double Sleeves',
    'Fitted Sleeves',
    'Flutter Sleeves',
    'Juliet Sleeves',
    'Puff Sleeves',
    'Raglan Sleeves',
    'Regular Cuffed Sleeves',
    'Single Sleeve',
    'Ultra Wide Sleeves',
    'Winged Sleeves',
    '#N/A'
  ],
  'SOCKS & STOCKINGS': ['Leg Warmer', 'Socks', 'Stockings'],
  'SUNGLASS FRAME': [
    'Aviator',
    'Cat Eye',
    'Chunky Frame',
    'Narrow Cateye',
    'Narrow Sunglasses',
    'Oval',
    'Polygon',
    'Rectangular Sunglass',
    'Round',
    'Round Retro',
    'Slim Frame',
    'Square',
    'Wasp Eye (Wide Cateye)',
    'Wayfarer Sunglasses',
    'Wide/ Oversize/ Shield',
    'Wrap Around'
  ],
  'SUNGLASS LENS': [
    'Black Tint',
    'Brown Tint',
    'Mirror/ Reflective',
    'Pink Tint',
    'Red Tint',
    'White Transparent',
    'Yellow Tint'
  ],
  'SURFACE ADDONS': [
    'Back pocket in front skirt jeans',
    'Belted/ Strap Detail',
    'Big Shoelaces',
    'Brooch Detail',
    'Buckle Detail',
    'Button Detail',
    'Carpenter Strap/ Double Knee',
    'Chain Detail (Not Chain Print)',
    'Drawstring Tightning Detail',
    'Fringed/ Tassel/ Frill',
    'Hooded',
    'Keyhole Detail',
    'Knee Patch Detail',
    'Knot/ Twist/ Pinch Detail',
    'Logo Tape Detail',
    'Piping in front',
    'Pleated Pants, Shirts etc.',
    'Pocket Detail',
    'Ring Detail',
    'Safety Pin Detail',
    'Side Stripes',
    'Slits',
    'Star Patch',
    'Tail Attached',
    'Thin Laces Criscross Detail',
    'Wide Straps Crisscross Detail',
    'Zip Detail',
    '#N/A'
  ],
  'SWEATER & CARDIGAN': [
    'Bolero Sweater',
    'Cardigan Sweater',
    'Cropped Sweater',
    'Ponchos',
    'Regular Sweater',
    'Sweater Dress',
    'Sweater Vest'
  ],
  TEXTURES: [
    'Canvas',
    'Chainmail',
    'Chunky Knit',
    'Corduroy',
    'Crochet',
    'Crushed/ Crinkled (Not Plisse)',
    'Denim',
    'Embellished',
    'Embroidered',
    'Feather',
    'Fine Knit/ Smooth Grain (#Default)',
    'Fur/ Shearling',
    'Glossy Patent Leather',
    'Lace',
    'Laser Cut',
    'Matte Non Shiny Leather',
    'Medium Knit',
    'Mesh/ Tulle/ Chiffon/ Organza',
    'Metallic Shine (Smooth)',
    'Net/ Fishnet',
    'Pearls',
    'Plastic Transparent',
    'Plisse',
    'Quilted',
    'Reflective & High Shine',
    'Ribbed',
    'Satin/ Poplin',
    'Sequin/ Shimmery',
    'Straw',
    'Studded',
    'Suede',
    'Textured Leather',
    'Tweed',
    'Velvet',
    'Windcheater / Parachute',
    'Woven'
  ],
  'TOE SHAPE': [
    'Close toe',
    'Open toe',
    'Peep toe',
    'Pointed toe',
    'Round toe',
    'Split toe',
    'Square toe'
  ],
  'TOP LENGTH': [
    'Crop (Above Waist)',
    'Extra Crop (Just below bust)',
    'Long (Below Waist)',
    'Regular (Till Waist)'
  ],
  'TOPWEAR SILHOUETTE': [
    'Bandeau Top / Tube Top',
    'Basic Top (non fitted)',
    'Bodysuit',
    'Bolero Top',
    'Bralette',
    'Cami Top',
    'Cape Top',
    'Cardigan Top',
    'Corset Top',
    'Deconstructed Top',
    'Detached Sleeve Top',
    'Ethnic Blouse',
    'Fitted Top',
    'Gym/Sports Bra Top',
    'Halterneck Top',
    'Kaftan',
    'Long length Tops',
    'Micro/ Mini Top',
    'Milkmaid Top',
    'Off Shoulder Top',
    'One Shoulder Top',
    'Peplum Top',
    'Scarf Top',
    'Strappy Top',
    'Sweater Tops',
    'Tank Top',
    'Tube Flared Top',
    'Tube off shoulder top',
    'Tunics',
    'Two Piece Top',
    'Vest Blazer Top',
    'Wrap Top'
  ],
  TRANSPARENCY: [
    'Full Sheer/ Sheer Overlay',
    'Low Sheer',
    'Sheer Panels',
    'Sheer Sleeves',
    '#N/A'
  ],
  'TRIM/ EDGE/ HEM': [
    'Applique trim & edge (flower, bow etc.)',
    'Broader Fringes/ Cuts',
    'Contrasting Stiching',
    'Contrasting Striped Edge/ Hem',
    'Drawstring Edge/ Hem',
    'Embellished/ Sequin/ Shimmery Edge/ Hem',
    'Embroidered Edge/ Hem',
    'Feather Edge/ Hem',
    'Fringed / Tassel Edge/ Hem',
    'Laced/ Laser Cut Edge/ Hem',
    'Meshed Edge/ Hem',
    'Peplum Edge/ Hem',
    'Piped Edge/ Hem',
    'Pleated Edge/ Hem',
    'Raw Cut/ Frayed Edge/ Hem',
    'Ribbed/ Elastic Cuffs/ Hem',
    'Ruffled Edge/ Hem',
    'Scallop Hem',
    'Turned Up Hem/ Edge',
    'Whipstiched Edge/ Hem',
    '#N/A'
  ],
  'TSHIRT SILHOUETTE': ['Basic Tshirt', 'Fitted Tshirt', 'Jersey Top', 'Polo Tshirt'],
  'UNIQUE SHAPES & DETAILS': [
    'Big Size Buttons',
    'Bow Attachments',
    'Bow Shape Product',
    'Butterfly Attachments',
    'Butterfly Shape Product',
    'Flower Attachments',
    'Flower Shape Product',
    'Heart Attachments',
    'Heart Shape Product',
    'Selectively Balooned',
    'Selectively Slouched',
    'Small 3d detailings (bow, flower, butterfly, heart etc.)',
    'Unique Shaped Buttons',
    'Voluminous Skirt/ Dress',
    '#N/A'
  ],
  'WAIST STYLING': [
    'Asymetric Waist',
    'Belted Waist',
    'Chain Waist',
    'Cinched Waist',
    'Double Waist',
    'Drawstring Waist',
    'Drop Waist',
    'Empire Waist',
    'Fitted/ Structured Waist',
    'Folded/ Inverted Waist',
    'High Cut Waist',
    'High Waisted',
    'Logo Waistband',
    'Paperbag Waist',
    'V Waistline',
    '#N/A'
  ]
}

export const valuesThumbnails = {
  BACK: {
    Backless: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BACK-Backless.png',
      description: 'Back is completely or almost completely exposed'
    },
    'Keyhole Back': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BACK-Keyhole_Back.png',
      description: 'Only a cutout or keyhole portion of back is exposed'
    },
    'Racer Back': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BACK-Racer_Back.png',
      description: 'Racerback'
    },
    'Tie Detail Back': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BACK-Tie_Detail_Back.png',
      description: 'Back has a laceup or tieup detail'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'BAG HANDLE': {
    'Beaded Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Beaded_Handle.png',
      description: 'Beaded strap or handle'
    },
    'Chain Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Chain_Handle.png',
      description: 'Chain handle or strap'
    },
    'Cutout In Bag Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Cutout_In_Bag_Handle.png',
      description: 'Handle cut out in bag'
    },
    'Knot strap handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE_Knot_strap_handle.png',
      description: 'A bag handle that has knots '
    },
    'Metallic Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE_Metallic_Handle.png',
      description: 'Bags that have metallic handle, of any shape'
    },
    'Regular Strap Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Strap_Handle.png',
      description: 'Bag with basic fabric / leather strap handles'
    },
    'Round Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Round_Handle.png',
      description: 'Round shape handle'
    },
    'Scrunched bag handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE_Scrunched_bag_handle.png',
      description: 'Bag handle that is scrunched'
    },
    'Wider Belt/ Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE_Wider_Belt-_Handle.png',
      description: 'A wider belt bags'
    },
    'Wooden Handle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Wooden_Handle.png',
      description: ''
    },
    'Wrist Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_HANDLE-Wrist_Strap.png',
      description: 'Held by wrist strap'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'BAG SHAPE': {
    Animal: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Animal.png',
      description: 'Bags that are shaped like animlas'
    },
    Bucket: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Bucket.png',
      description: 'Bags that have somewhat shape of a bucket'
    },
    Capsule: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Capsule.png',
      description: 'Capsule shaped bags, rectangle with curved edges'
    },
    Circular: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Circular.png',
      description: ''
    },
    'Collapsing Middle': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Collapsing_Middle.png',
      description: 'Bags that collapse right in the middle making it two fold'
    },
    'Curve Edge': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Curve_Edge.png',
      description: 'Bag whose one side (not just corners, but sides) are curved'
    },
    'Cylindrical Wide': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Cylindrical_Wide.png',
      description: ''
    },
    Envelope: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Envelope.png',
      description: 'Bags that has a shape of an envelope'
    },
    Heart: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Heart.png',
      description: ''
    },
    Oval: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Oval.png',
      description: 'Shaped oval or egg'
    },
    'Polygon shape bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE_Polygon_shape_bag.png',
      description: 'Any bag which has 5 or more sides, not less than 5.'
    },
    Potli: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Potli.png',
      description: 'Ethinic bags, mostly for parties'
    },
    'Rectangle Long': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Rectangle_Long.png',
      description: 'A vertically long rectangle bag'
    },
    'Rectangle Wide': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Rectangle_Wide.png',
      description: 'A horizontally wider rectangle bag'
    },
    Square: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Square.png',
      description: 'Having 4 sides equal at right angles'
    },
    Trapeze: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Trapeze.png',
      description: 'A trapeze shape bag where corners are not at right angle'
    },
    Triangle: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_SHAPE-Triangle.png',
      description: ''
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'BAG TYPE': {
    Backpack: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Backpack.png',
      description: ''
    },
    Baquette: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Baquette.png',
      description: 'Small bag with small handheld strap and a curved top'
    },
    'Boston/ Barrel/ Duffel Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Boston__Barrel__Duffel_Bag.png',
      description: 'Flat bottom, round handle, spacious semi cylindrical bag'
    },
    'Box Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Box_Bag.png',
      description: 'A box like shape bag '
    },
    'Briefcase bag trend': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Briefcase_bag_trend.png',
      description: 'A briefcase shaped bag, not necessarily a solid rigid briefcase'
    },
    Clutches: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Clutches.png',
      description: ''
    },
    'Coin Purse bags': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Coin_Purse_bags.png',
      description: 'A small coin purse attachment to the bag'
    },
    'Drawstring Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Drawstring_Bag.png',
      description: ''
    },
    'Fanny/ Waist Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Fanny_Bag.png',
      description: ''
    },
    'Handheld Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Handheld_Bag.png',
      description: 'A small/ medium bag with handle and strap to carry'
    },
    'Hobo, Bindle bags': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Hobo,_Bindle_bags.png',
      description: 'A unique shaped bag that has one shoulder strap and a big space inside. Best understood from image'
    },
    'Jodie Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Jodie_Bag.png',
      description: 'Identified by the knot on the handle and one continious shape'
    },
    'Messenger/ Satchel bags': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Messenger-_Satchel_bags.png',
      description: 'A messenger bag, see imaes'
    },
    'Mini Bags': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Mini_Bags.png',
      description: 'A very small bag '
    },
    'Pillow Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Pillow_Bag.png',
      description: 'A wide rectangle bag that appears slouchy, puffed, and spacious '
    },
    'Saddle Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Saddle_Bag.png',
      description: ''
    },
    'Shoulder Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Shoulder_Bag.png',
      description: 'Any bag that can be carried on shoulder. But not like bauquette shape curved bag'
    },
    'Sling/ Cross Body Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Sling__Cross_Body_Bag.png',
      description: 'Consist of a long strap that can be carried across your body'
    },
    'Squeeze Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Squeeze_Bag.png',
      description: 'Handle made from from folding top portion of bag, has chain detail, has gathered detail'
    },
    'Tote Bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE-Tote_Bag.png',
      description: 'A medium size open bag with two handles,often made of strong fabric like canvas or leather'
    },
    'Vanity bag': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BAG_TYPE_Vanity_bag.png',
      description: 'A vanity bag shaped bag'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  BELTS: {
    'Corset Belt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BELTS_Corset_Belt.png',
      description: 'Corset belts styled on top of garment. Its a belt, not corset top, or corset dress'
    },
    'Narrow Belt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BELTS_Narrow_Belt.png',
      description: 'A narrow belt, that can have any buckle'
    },
    'Wide Belt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BELTS_Wide_Belt.png',
      description: 'A wider belt can be of any shape buckle'
    }
  },
  'BOTTOM LENGTH': {
    'Ankle/ Maxi Length': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Ankle__Maxi_Length.png',
      description: 'Maxi, ankle length'
    },
    'Calf/ Midi Length': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Calf__Midi_Length.png',
      description: 'Calf length'
    },
    'Floor Length': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Floor_Length.png',
      description: 'Ending at the floor, slightly below maxi'
    },
    'Knee Length': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Knee_Length.png',
      description: 'Knee length'
    },
    'Micro (Above Thigh Length)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Micro_(Above_Thigh_Length).png',
      description: 'Very short, micro'
    },
    'Mini (Thigh Length)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Mini_(Thigh_Length).png',
      description: 'Mini, thigh length'
    },
    'Slight Above Ankle Length': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/BOTTOM_LENGTH-Slight_Above_Ankle_Length.png',
      description: 'Ending slightly above the ankle, shorter than maxi'
    }
  },
  'CAPS/ HATS': {
    Bandana: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Bandana.png',
      description: 'A  triangular or square piece of cloth tied around the head'
    },
    'Baseball & Basketball Caps': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Baseball_&_Basketball_Caps.png',
      description: ''
    },
    Beanie: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Beanie.png',
      description: 'A small round tight-fitting skull cap'
    },
    'Beret Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Beret_Hat.png',
      description: 'A round flat hat that is made of soft material and has no brim,and can also be seen wearing by the army'
    },
    'Breton Cap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Breton_Cap.png',
      description: 'A breton cap has a soft and flat crown'
    },
    'Bucket Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Bucket_Hat.png',
      description: 'A bucket hat is a hat with a narrow, downward sloping brim,the hat is made from heavy-duty cotton fabric such as denim or canvas'
    },
    'Cowboy Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Cowboy_Hat.png',
      description: 'A curved  brimmed hat,with  a high crown'
    },
    'Fedora Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Fedora_Hat.png',
      description: 'Small version of cowboy hat where the brim is not that curved and the crown is pointed'
    },
    'Gaucho Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Gaucho_Hat.png',
      description: 'It is characterized by a flat top and wide curved brim '
    },
    'Large Brim Hat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Large_Brim_Hat.png',
      description: 'Unusally a very big size hat protecting the entire face'
    },
    'Newsboy Cap/ Ascot Cap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CAPS__HATS-Newsboy_Cap__Ascot_Cap.png',
      description: 'Also known as peaky blinder cap, where the crown and front brim is merged'
    }
  },
  'CASUAL COORDS': {
    'Loungewear Coord': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CASUAL_COORDS-Loungewear_Coord.png',
      description: 'Matching comfortable top and bottoms'
    },
    'Pyjama Set': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CASUAL_COORDS-Pyjama_Set.png',
      description: 'Payjama satin material matching sleepwear coord'
    },
    'Track Suit': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CASUAL_COORDS-Track_Suit.png',
      description: 'Athleisure coord'
    }
  },
  'CHEST DESIGN': {
    'Balconette Style': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Balconette_Style.png',
      description: "A low-cut cups that is designed to lift and enhance the appearance of a women's bust"
    },
    'Cone Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Cone_Cup__Bralette.png',
      description: 'Pointed cone shaped at the chest area'
    },
    'Embellished/ Studded Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Embellished__Studded_Cup_Dress__Top.png',
      description: 'Embellishment around the cup area'
    },
    'Floral Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Floral_Cup_Dress__Top.png',
      description: 'Floral detail on the cup'
    },
    'Folded At Chest Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Folded__Inverted_Edge.png',
      description: 'Where the edge of the garment is folded inside out'
    },
    'Heart Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Heart_Cup_Dress__Top.png',
      description: 'Heart details around the cup area'
    },
    'Laced Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Laced_Cup_Dress__Top.png',
      description: 'Lace details around the cup area'
    },
    'Milkmaid Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Milkmaid_Detail.png',
      description: 'Gathered look around the bust'
    },
    'Strappy Cup Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Strappy_Cup_Dress__Top.png',
      description: 'Strap details around the cup area'
    },
    'Triangle Cup Top/ Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Triangle_Cup__Bralette.png',
      description: 'Triangle shaped cup it creates a v-shaped cleavge. It can be a part of top, dress or a bralette'
    },
    'Underwired Dress/ Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/CHEST_DESIGN-Underwired_Dress__Top.png',
      description: 'Dress or top that has underwire detail under the cup area'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'COATS, SUITS TYPE': {
    'Cropped Blazer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Cropped_Blazer.png',
      description: ''
    },
    'Double Breasted Blazer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Double_Breasted_Blazer.png',
      description: ''
    },
    Overcoat: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Overcoat.png',
      description: 'More formal than trench coat, equally long till thigh or below knee'
    },
    'Pea Coat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Pea_Coat.png',
      description: 'Heavy jacket, double breasted, buttons from top'
    },
    'Single Breated Blazer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Single_Breated_Blazer.png',
      description: ''
    },
    Suits: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Suits.png',
      description: 'A proper matching coat and pant set'
    },
    'Trench Coat': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Trench_Coat.png',
      description: 'Double-breasted coat with deep pockets,wide belt and often straps on the shoulders'
    },
    Waistcoat: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/COATS,_SUITS_TYPE-Waistcoat.png',
      description: 'Not waistcoat top (which is worn directly withou shirt) but proper waistcoat'
    }
  },
  'DENIM EFFECT': {
    'All Over Distress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-All_Over_Distress.png',
      description: 'Heavy distress all over'
    },
    'No Distress/ Slash': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-No_Distress__Slash.png',
      description: 'No slash, or any distress. Whiskering or wash may be there.'
    },
    'Only Slash': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-Only_Slash.png',
      description: 'Only one or more slashes on the denim'
    },
    'Patched Denim': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-Patched_Denim.png',
      description: 'Denim with different patches'
    },
    'Small-Medium Distress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-Small-Medium_Distress.png',
      description: 'Distress confined to a small/ medium area'
    },
    Washed: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DENIM_EFFECT-Washed.png',
      description: 'A denim which has patches of different color because of being washed'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'DESIGN/ THEME': {
    'Asymmetric/ High Low': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Asymmetric__High_Low.png',
      description: ''
    },
    'Athleisure/ Jersey/ Gym/ Sports': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Athleisure__Jersey__Gym__Sports.png',
      description: 'Gym, jersey, or sports clothing'
    },
    'Blazer Style (Dress/ Jumpsuit)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Blazer_Style_(Dress__Jumpsuit).png',
      description: 'Blazer style suit, dress, jumpsuit etc.'
    },
    'Cargo Style, Utility/ Technical': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Cargo_Style.png',
      description: 'Cargo styled any garment'
    },
    'Corset Style (Dress/ Top/ Jumpsuit)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Corset_Style_(Dress__Top__Jumpsuit).png',
      description: 'Any garment that has an element of corset in it'
    },
    'Cut Out Trend': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Cut_Out_Trend.png',
      description: 'Cutouts in a garment'
    },
    'Draped/ Layered': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Draped__Layered.png',
      description: 'Multiple layers of fabric draped on top of each other, or a single layer folded to give a draped look'
    },
    Inserts: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN-_THEME_Inserts.png',
      description: 'Smaller than panels, inserts are a smaller piece of another fabric inserted into teh garment. '
    },
    'Large/ Box Pleats': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Large__Box_Pleats.png',
      description: 'Big size pleats'
    },
    Oversize: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Oversize.png',
      description: 'A very loose fitted clothing worn as a fashionable statement'
    },
    'Padded (Flat Padded)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Padded_(Flat_Padded).png',
      description: 'A very light thin cushioned (padded) added to the clothes'
    },
    Panelled: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Panelled.png',
      description: 'Garment made up of different panels combined together'
    },
    'Puffer/ Puffed Design (Bag/ Jacket)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Puffer__Puffed_Design_(Bag__Jacket).png',
      description: 'A warm thick soft material sewn into sections, so the sections look as they are puffed'
    },
    'Ripped/ Shredded': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN-_THEME_Ripped-_Shredded.png',
      description: 'Ripped/ shredded detail on clothes'
    },
    'Ruched/ Gathered/ Scrunched': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Ruched__Gathered__Scrunched.png',
      description: 'Fabric gathered closely creating a folded effect'
    },
    Ruffled: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Ruffled.png',
      description: 'Fabric gathered loosely creating a frilled effect'
    },
    'Small/ Regular Pleats': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Small__Regular_Pleats.png',
      description: 'Smaller folded pleats'
    },
    Smocked: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Smocked.png',
      description: 'A  streachable clothing swen in with elastic looking closely gathered and strechy'
    },
    'Tailored Cut/ Formal': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Tailored_Cut__Formal.png',
      description: 'Tailored fit formal clothes'
    },
    'Tiered Dress/ Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Tiered_Dress__Skirt.png',
      description: 'Clothing  that has overlapping layers creating a tiered or layered effects'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'DRESS SILHOUETTE': {
    'A Line Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-A_Line_Dress.png',
      description: 'Very similar to fit & flare, but have to make sure that its not scrunched, fitted, belted, tightend around the waist before flaring'
    },
    'Blazer Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Blazer_Dress.png',
      description: 'A dress shaped like a blazer, can be of any length'
    },
    'Bodycon Dress / Pencil Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Bodycon_Dress___Pencil_Dress.png',
      description: "Fits very closely to the body and has figure-hugging fabric designed to show off a women's curve"
    },
    'Cape Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Cape_Dress.png',
      description: 'A dress with cape details'
    },
    'Chinese Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Chinese_Dress.png',
      description: ''
    },
    'Column Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Column_Dress.png',
      description: 'A very straight,close fitting dress'
    },
    'Corset Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Corset_Dress.png',
      description: ''
    },
    'Deconstructed Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE_Deconstructed_Dress.png',
      description: 'A dress whose structure has been altered, but is still a dress'
    },
    'Empire Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Empire_Dress.png',
      description: 'Fitted bodice ending just below the bust, giving a high waisted appearance, and creates a long line from the waist down'
    },
    'Fit Flare Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Fit_Flare_Dress.png',
      description: 'Like a line, but scrunched/ tied/ belted around the waist before it starts flaring'
    },
    'Kaftan Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Kaftan_Dress.png',
      description: ''
    },
    'Mermaid Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Mermaid_Dress.png',
      description: 'A fitted bodycon through the waist and hips and it flares out at the bottom'
    },
    'Peplum Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Peplum_Dress.png',
      description: 'A flared ruffle sewn into the waistline or at the hemline'
    },
    'Pinafore/ Suspender Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Pinafore__Suspender_Dress.png',
      description: ''
    },
    'Sheath Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Sheath_Dress.png',
      description: 'A fitted straight cut dress,that has a slightly defined waistline,and often fall just above or below the knee'
    },
    'Shift Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Shift_Dress.png',
      description: 'A simple dress that hangs loosely from the shoulder'
    },
    'Shirt Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Shirt_Dress.png',
      description: ''
    },
    'Skater Skirt Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Skater_Skirt_Dress.png',
      description: 'A type of mini dress that is fitting at the top and flares out at the waist'
    },
    'Slip Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Slip_Dress.png',
      description: ''
    },
    'Tennis Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Tennis_Dress.png',
      description: 'Mini length, fit flare, can have any neckline, sporty look'
    },
    'Trench Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Trench_Dress.png',
      description: ''
    },
    'Tshirt Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Tshirt_Dress.png',
      description: ''
    },
    'Tube Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Tube_Dress.png',
      description: 'A dress that covers the front and back but not the shoulder or arms'
    },
    'Wrap Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DRESS_SILHOUETTE-Wrap_Dress.png',
      description: ''
    }
  },
  'ETHINIC WEAR': {
    Anarkali: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Anarkali.png',
      description: 'The anarkali suit has a long,flowy shape with a fitted bodice and flared skirt, the skirt straches out from the waist to ankle length, and is paired with with  a churidar pants, and dupatta'
    },
    'Dhoti Salwar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Dhoti_Salwar.png',
      description: 'Ethinic outfit for women, loose aladin pants, short kurta, with dupatta'
    },
    'Kaftan Set': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Kaftan_Set.png',
      description: 'Long/short kaftan top, paired with matching pants. Both with ethinic touch'
    },
    Kurta: {
      thumbnail: '',
      description: 'All kurta. After selecting this select kurta silhoutte'
    },
    Lehenga: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Lehenga.png',
      description: 'Lehenga is a three piece,consist of a long ankle-length skirt,well-fitted blouse known as the choli and a scarf to drap around the outfit known as the dupatta'
    },
    'Pants Top Coord': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Pants_Top_Coord.png',
      description: ''
    },
    'Salwar Suit': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Salwar_Suit.png',
      description: "A kurta set, paired with straight or narrow trousers and dupatta (not dhoti/aladin trousers, that's dhoti salwar)"
    },
    Saree: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Saree.png',
      description: ''
    },
    'Sharara Set': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Sharara_Set.png',
      description: 'Ultra wide legged pants, paired with short kurta'
    },
    'Sherwani, and Nawabi Set': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/ETHINIC_WEAR-Sherwani,_and_Nawabi_Set.png',
      description: '"Sherwani is a long sleeved knee length coat with a stand-up collor and has a buttons up to the neck.'
    }
  },
  'The sherwani is typically worn over a pair of trousers and can be paired up with a scarf or jacket"': { undefined: { thumbnail: undefined, description: undefined } },
  'FOOTWEAR HEEL/ SOLE': {
    'Artistic Heels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Artistic_Heels.png',
      description: 'Artistic heel with either letter or unique design in it'
    },
    'Block Heels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Block_Heels.png',
      description: 'Any footwear with block heel'
    },
    'Chunky Soles': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Chunky_Soles.png',
      description: 'Any footwear with chunky soles'
    },
    'Kitten Heels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Kitten_Heels.png',
      description: 'Any footwear with kitten (small pointed heel)'
    },
    'No Heels (Flats)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-No_Heels_(Flats).png',
      description: 'All flat footwear'
    },
    'Pointed/ Stiletto Heels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Pointed__Stiletto_Heels.png',
      description: 'Pointed/ pencil heels'
    },
    'Regular Soles': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Regular_Soles.png',
      description: 'Regular footwear soles, not flats, not heels'
    },
    'Wedge Heels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_HEEL__SOLE-Wedge_Heels.png',
      description: 'Any footwear with wedge heels'
    }
  },
  'FOOTWEAR NAME': {
    'Adidas Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Adidas_Sneakers.png',
      description: 'Identify by its 3 striped(adidas) shoes'
    },
    'Ballet Pumps': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Ballet_Pumps.png',
      description: 'Flat pumps, where the top cover is further from the ankle'
    },
    'Brogues/ Oxfords/ Formal Shoes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME_Brogues-_Oxfords-_Formal_Shoes.png',
      description: 'A mix of all formal shoes with laces'
    },
    'Chelsea Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Chelsea_Boots.png',
      description: 'That has elastic on both side of the boots, and has no lace'
    },
    'Clear Strap Sandals': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Clear_Strap_Sandals.png',
      description: 'With transparent strap'
    },
    Clogs: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Clogs.png',
      description: 'Covered front, slip ons'
    },
    'Combat Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/footwearname_combatboots.png',
      description: 'Lacedup leather boots'
    },
    'Converse & canvas shoes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Converse_&_canvas_shoes.png',
      description: ''
    },
    'Cowboy Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Cowboy_Boots.png',
      description: 'Boots that have american cowboy styling on them'
    },
    'Folded Shaft Boot': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Folded_Shaft_Boot.png',
      description: 'The shaft of the boot drops down and looks like 2 piece'
    },
    'Gladiator/ Caged Sandals': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Gladiator__Caged_Sandals.png',
      description: 'With straps all over binding the foot'
    },
    'Gum Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Gum_Boots.png',
      description: 'Rubber boots worn mostly in rain '
    },
    Jutti: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Jutti.png',
      description: 'Ethinic slip on shoes, with embellishments'
    },
    Loafers: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Loafers.png',
      description: 'Slip on formal shoes, low/no heel'
    },
    'Mary Jane': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Mary_Jane.png',
      description: 'Round covered toe, block heel, ankle strap, unique shape'
    },
    Mules: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Mules.png',
      description: 'Flat slip ons without the back'
    },
    'New Balance Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-New_Balance_Sneakers.png',
      description: 'New balance brand sneakers, indicated by letter n'
    },
    'Nike Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Nike_Sneakers.png',
      description: 'All nike shoes'
    },
    'Onitsuka Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Onitsuka_Sneakers.png',
      description: 'Onitsuka tiger brand, known by its signature lines on side'
    },
    'Regular Ankle Length Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Regular_Ankle_Length_Boots.png',
      description: 'Not chelsea, not uggs, not snow, not snow, not sock, but other basic ankle length boot'
    },
    'Regular Calf Length Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Regular_Calf_Length_Boots.png',
      description: 'Not cowboy, not snow, but basic calf length boot'
    },
    'Regular Knee Length Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Regular_Knee_Length_Boots.png',
      description: 'Any knee length boot'
    },
    'Regular Thigh Length Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Regular_Thigh_Length_Boots.png',
      description: 'Any thigh length boot'
    },
    'Scrunched Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME_Scrunched_Boots.png',
      description: 'Boots that are scrunched/slouched'
    },
    Slides: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Slides.png',
      description: 'Women slipon slides'
    },
    'Snow Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Snow_Boots.png',
      description: 'Heavy padded waterproof ankle length or above boots'
    },
    'Sock Style Boot': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Sock_Style_Boot.png',
      description: 'Ankle boots that have the appearence of the sock on the top part'
    },
    'Thong Sandals': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Thong_Sandals.png',
      description: 'Open foot, very light thin strap sandals'
    },
    'Tieup Ballet Flats': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME_Tieup_Ballet_Flats.png',
      description: 'Ballet flats that have lace tied up around ankle'
    },
    Uggs: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_NAME-Uggs.png',
      description: 'Not snow boots, but think material boots, without lace'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'FOOTWEAR SILHOUETTE': {
    'Ankle Strap/ Sling Back Sandals (open front)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Ankle_Strap__Sling_Back_Sandals_(open_front).png',
      description: 'Not a slipon, there should be se straps around the ankle'
    },
    'HighTop Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HighTop_Sneakers.png',
      description: 'high top sneakers'
    },
    'Home Slippers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Home_Slippers.png',
      description: 'Casual homewear slipeers'
    },
    'Lace Up Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Lace_Up_Boots.png',
      description: 'Boots that are laced up'
    },
    'Lace Up Casual Shoes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Lace_Up_Casual_Shoes.png',
      description: 'Casual shoes with a lace (not sneakers)'
    },
    'Lace Up Formal Shoes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Lace_Up_Formal_Shoes.png',
      description: 'Any kind of laceup formal shoes. Can of of any color or pattern'
    },
    'No Lace Boots': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-No_Lace_Boots.png',
      description: 'Boots that has no lace'
    },
    'Pumps/ Court Shoes (closed front)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Pumps__Court_Shoes_(closed_front).png',
      description: 'Women shoes/heels with closed front'
    },
    'Regular Sneakers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Sneakers.png',
      description: 'regular sneakers'
    },
    'Slip On Sandals (open front)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Slip_On_Sandals_(open_front).png',
      description: 'Not tied by ankle strap, its a slipon'
    },
    'Slip On Shoes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FOOTWEAR_SILHOUETTE-Slip_On_Shoes.png',
      description: 'Formal/ casual slipon shoes, with back support'
    }
  },
  'FRONT CLOSURE': {
    'Buttoned (Decorative/ Functional)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Buttoned_(Decorative__Functional).png',
      description: 'Button detail on front, may not be necessarily used for closure'
    },
    'Concealed Buttons': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE_Concealed_Buttons.png',
      description: 'Topwear in which the front buttons are hidden'
    },
    'Drawstring Closure': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/drawstring_front_closure.png',
      description: 'drawstring closure in the front'
    },
    'Halfway Zip/Button': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/half_way_closure.png',
      description: 'zip/buttons half way till check from top'
    },
    'Off Center Button/ Zip': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Off_Center_Button__Zip.png',
      description: ''
    },
    'Open/Closed without zip/buttons': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Open_Closed_without_zip_buttons.png',
      description: 'Where buttons/ zips are not used for front closure'
    },
    'Pull Over Style': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Pull_Over_Style.png',
      description: 'Not pullover sweaters. It means there is not front closure (decorative or functional) instead the garment is pullover'
    },
    'Wrap Style Closure': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Wrap_Style_Closure.png',
      description: ''
    },
    'Zip Style Closure': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/FRONT_CLOSURE-Zip_Style_Closure.png',
      description: 'That has a zipline at the front'
    }
  },
  GLOVES: {
    'Fingerless Gloves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/GLOVES_Fingerless_Gloves.png',
      description: 'All length gloves, in which the fingers are cut'
    },
    'Full Finger Gloves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/GLOVES_Full_Finger_Gloves.png',
      description: 'All length gloves, which have fingers covered'
    }
  },
  'HEMLINE SHAPE': {
    'Asymmetric Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE-Asymmetric_Hem.png',
      description: ''
    },
    'Curved Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE-Curved_Hem.png',
      description: ''
    },
    'Drawstring Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/hemlineshape_drawstringhem.png',
      description: 'Drawstring at the hemline of bottomwear or topwear'
    },
    'Flared at the hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE_Flared_at_the_hem.png',
      description: 'Not peplum at hem. A flare seen at teh bottom of skirt/dress only towards the hem.'
    },
    'Split Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE-Split_Hem.png',
      description: 'That has a slit cut '
    },
    'Straight/ Regular Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE-Straight__Regular_Hem.png',
      description: 'A straight regular hem'
    }
  },
  JACKETS: {
    'Bolero Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Bolero_Jacket.png',
      description: 'Bolero style jacket (not a bolero top)'
    },
    'Bomber Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Bomber_Jacket.png',
      description: 'Tight cuffs,a tight waistband and a wraparound collar. Not like varsity which has same shape but a cooler appeal.'
    },
    'Chanel Inspired Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Chanel_Inspired_Jacket.png',
      description: ''
    },
    'Deconstructued Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS_Deconstructued_Jacket.png',
      description: 'A jacket whose structure has been altered.'
    },
    'Denim Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Denim_Jacket.png',
      description: 'Denim material jacket of any color'
    },
    'Ethinic Coat/ Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Ethinic_Coat__Jacket.png',
      description: 'An outwear with traditional ethnic prints and patterns. Dont add nehru jacket here'
    },
    Gilet: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Gilet.png',
      description: 'A vest jacket'
    },
    'Leather Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Leather_Jacket.png',
      description: 'Leather jacket made of natural or faux leather'
    },
    'Nehru Blazer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Nehru_Blazer.png',
      description: 'Can be half or full sleeved'
    },
    'Parka Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Parka_Jacket.png',
      description: 'Parka  jacket are longer and extend below the waist, its a type of a hood lined with fur'
    },
    'Puffer Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Puffer_Jacket.png',
      description: 'A warm coat filled with thick soft material sewn into sections providing a high level of warmth'
    },
    'Pullover Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS_Pullover_Jacket.png',
      description: 'A jacket with pullover front closure'
    },
    'Racing/ Moto Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Racing__Moto_Jacket.png',
      description: 'Jacket with brand, automobile, sports, or racing logos and names'
    },
    Shacket: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Shacket.png',
      description: 'A heavy oversize thick shirt that acts as a jacet too.'
    },
    'Teddy Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Teddy_Jacket.png',
      description: 'Light jacket made of faux shearling material'
    },
    'Teddy Lined/ Pile Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Teddy_Lined__Pile_Jacket.png',
      description: 'A mix of (leather/corduroy/denim) and shearling jacket, where the shearling are mostly at lapels or edges'
    },
    'Utility/ Cargo Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Utility__Cargo_Jacket.png',
      description: 'Multi pocket,untility or cargo style jacket'
    },
    'Varsity Jacket': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/JACKETS-Varsity_Jacket.png',
      description: 'Baseball styled jackets with large fonts and numbers'
    }
  },
  'KURTA SILHOUETTE': {
    'A Line Kurta': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/KURTA_SILHOUETTE-A_Line_Kurta.png',
      description: 'Can be identified as straight with no division or tie in between'
    },
    'Fit & Flare Kurta': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/KURTA_SILHOUETTE-Fit_&_Flare_Kurta.png',
      description: 'Fitted till the waist and flares out below that'
    },
    'Short Thigh Length Kurta': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/KURTA_SILHOUETTE-Short_Thigh_Length_Kurta.png',
      description: 'A short kurta that comes till thigh length or above'
    },
    'Slip Kurta': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/KURTA_SILHOUETTE-Slip_Kurta.png',
      description: 'Kurta with thin/wide spaghetti strap neckline, and slip dress kind of sillouhette'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  NECKLINE: {
    'Asymmetric Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Asymmetric_Neck.png',
      description: ''
    },
    'Boat/ Wide Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Boat__Wide_Neck.png',
      description: 'A wide neckline that extends towards the tips of the shoulders'
    },
    'Chinese / Short Collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Chinese___Short_Collar.png',
      description: ''
    },
    'Coat Lapel': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Coat_Lapel.png',
      description: ''
    },
    'Contrasting collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE_Contrasting_collar.png',
      description: 'Collar which has contrasting color to the rest of the garment'
    },
    'Cowl Neck/ Swing Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Cowl_Neck__Swing_Neck.png',
      description: 'Hanging, or draped neckline'
    },
    'Cross Neck (Halterneck)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Cross_Neck_(Halterneck).png',
      description: 'Subset of halterneck where 2 straps cross each other at neck'
    },
    'Embellished collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE_Embellished_collar.png',
      description: 'A collar that has embellishment on it'
    },
    'Henley/ Button Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Henley__Button_Neck.png',
      description: 'Opening at the front with a few buttons,but has no collor to it'
    },
    'High/ Turtle Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-High__Turtle_Neck.png',
      description: 'A close-fitting collar ,that covers most of the neck,often folded over  and sometimes extended into a roll-neck.'
    },
    'Highneck (Halterneck)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Highneck_(Halterneck).png',
      description: 'Subset of halterneck where with a high neck banded collar'
    },
    'Large Collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Large_Collar.png',
      description: 'Larger and big in size collar, than the usual normal medium collar'
    },
    'Medium/ Resort Collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Medium__Resort_Collar.png',
      description: 'Medium size collar, slightly bigger than the regular'
    },
    'Necktie Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Necktie_Detail.png',
      description: 'Tie detail around the neck'
    },
    'Peter Pan Collar (Small)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Peter_Pan_Collar_(Small).png',
      description: ''
    },
    'Plunging/ Deep Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Plunging__Deep_Neck.png',
      description: 'Deep neckline goes way till end of bust area of below'
    },
    'Racer Front/ Back': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Racer_Front__Back.png',
      description: 'Racer is characterized by a unique shoulder cut in the back or front of a garment'
    },
    'Regular/ Classic Collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Regular__Classic_Collar.png',
      description: ''
    },
    'Round/ Crew Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Round__Crew_Neck.png',
      description: ''
    },
    'Scoop Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Scoop_Neck.png',
      description: 'Low neckline  that show more of the chest. Slightly lesser than plunging neck.'
    },
    'Spaghetti Straps': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Spaghetti_Straps.png',
      description: 'A very thin shoulder shoulder strap neckline'
    },
    'Square Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Square_Neck.png',
      description: ''
    },
    'Standing Collar': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE_Standing_Collar.png',
      description: 'A collar more longer than the chinese collar, usually found in jackets'
    },
    'Strapless/ Tube': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Strapless__Tube.png',
      description: ''
    },
    'Sweetheart Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Sweetheart_Neck.png',
      description: 'A neckline that is low at the front and shaped like the top of a heart'
    },
    'Tie/ Knot/ Scarf Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Tie__Knot__Scarf_Neck.png',
      description: ''
    },
    'Tieback (Halterneck)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Tieback_(Halterneck).png',
      description: 'Subset of halterneck with straps going behind the neck'
    },
    'Trapeze (non curve) Neckline': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-Trapeze_(non_curve)_Neckline.png',
      description: 'A straight cut trapeze neckline. Make sure its not a sweetheart shaped curved neckline.'
    },
    'U Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-U_Neck.png',
      description: ''
    },
    'V Neck': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE-V_Neck.png',
      description: ''
    },
    'WW2 Neckline': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/NECKLINE_WW2_Neckline.png',
      description: 'A special world war era neckline which has a patch in front of neck'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'OTHER OUTERWEAR': {
    Kimonos: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/OTHER_OUTERWEAR-Kimonos.png',
      description: 'A japanese kimono inspired jacket'
    },
    Shrugs: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/OTHER_OUTERWEAR-Shrugs.png',
      description: 'A light outwear, can be of any length'
    }
  },
  'PANT/ JEANS FIT': {
    'Baggy/ Loose Fit': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Baggy__Loose_Fit.png',
      description: ''
    },
    'Baloon/ Aladdin': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Baloon__Aladdin.png',
      description: ''
    },
    'Barrel Jeans': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/barrel_jeans.png',
      description: 'inflated mom jeans, with slight curve shape'
    },
    'Bootcut/ Flared': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Bootcut__Flared.png',
      description: 'Slightly wider at the ankle than at the knee'
    },
    Capris: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Capris.png',
      description: 'Close fitting pants till the length of calf'
    },
    'Cargo Pants/ Jeans': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Cargo_Pants__Jeans.png',
      description: ''
    },
    'Chino Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Chino_Pants.png',
      description: 'A cotton trousers,which can be worn both as a casual and formal wear'
    },
    'Cigarette Trousers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Cigarette_Trousers.png',
      description: 'Fitted thigh, slim fitted bottom, ends above the ankle'
    },
    'Drop Crotch': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Drop_Crotch.png',
      description: 'A type of a pants designed to sag down loosely towards the knee'
    },
    'Gurkha Fit / Buckle Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Gurkha_Fit___Buckle_Pants.png',
      description: ''
    },
    'Kilt Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Kilt_Pants.png',
      description: 'Knee-length pleated skirit, which can be worn with or without a pants, by men'
    },
    'Loose Chino Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Loose_Chino_Pants.png',
      description: 'A loose and comfortable cotton pants. Note that chinos dont have pleats.'
    },
    'Mom Fit Jeans': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Mom_Fit_Jeans.png',
      description: 'High waisted jeans, has a loose fit all around, and are generally ankle length.'
    },
    'Palazzo/ Culottes Design': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Palazzo__Culottes_Design.png',
      description: 'Loose pants  extremely wide leg that flares out from the waist,and are short slightly above the ankle'
    },
    'Parachute Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Parachute_Pants.png',
      description: 'Parachute material, more relaxed than cargo, drawstring at waist and ankle'
    },
    Pyjamas: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Pyjamas.png',
      description: ''
    },
    'Skinny/ Narrow Leg': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Skinny__Narrow_Leg.png',
      description: ''
    },
    'Straight Leg': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Straight_Leg.png',
      description: 'Straight  fit pants have straight line from your waist down to your ankle (from top to bottom)'
    },
    'Sweat Pants/ Joggers': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Sweat_Pants__Joggers.png',
      description: 'Loose warm bottoms with an elasticated or drawstring waist, and ankle'
    },
    'Track Pants': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Track_Pants.png',
      description: 'Sporty look'
    },
    'Wide Leg': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PANT__JEANS_FIT-Wide_Leg.png',
      description: 'Loose fitting bottoms, they are fitted around the waist and then flare out at the hips,  remaining straight and wide all along the way down to the leg'
    }
  },
  PATTERN: {
    'Anchor Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Anchor_Print_(All_Over).png',
      description: ''
    },
    'Band/ Movie': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Band__Movie.png',
      description: ''
    },
    'Beach, Ocean, Surfing, Sea, Aquatic Life, Ship': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Beach,_Ocean,_Surfing,_Sea,_Aquatic_Life,_Ship.png',
      description: 'Clothing or material with ocean images printed on it all over'
    },
    'Bones, Skull, Skelton': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Bones,_Skull,_Skelton.png',
      description: ''
    },
    'Butterfly, Bee, Insect Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Butterfly,_Bee,_Insect_Print_(All_Over).png',
      description: 'Butterfly, bee, insect etc. All over'
    },
    'Camoflouge (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Camoflouge_(All_Over).png',
      description: ''
    },
    'Chain Link (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Chain_Link_(All_Over).png',
      description: ''
    },
    'Character/ Illustration (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Character__Illustration_(All_Over).png',
      description: 'Character or cartoon printed all over the garment'
    },
    'Character/ Illustration (Front/ Back)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Character__Illustration_(Front__Back).png',
      description: 'Character or cartoon printed only at the front or back of the garment'
    },
    'Checkboard (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Checkboard_(All_Over).png',
      description: ''
    },
    'Checks (All)': { thumbnail: '', description: 'All check patterns come here' },
    'City View Print': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-City_View_Print.png',
      description: 'Building, or city view print'
    },
    'Cloud print': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Cloud_print.png',
      description: ''
    },
    Collage: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Collage.png',
      description: 'A collage of images/text all over, or at front/back'
    },
    'Color Block (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Color_Block_(All_Over).png',
      description: 'When 2 or more colors / pattern are seen on a garment (not entire outfit) and their area coverage are in somewhat propotional ratio'
    },
    'Cow Skin (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Cow_Skin_(All_Over).png',
      description: ''
    },
    'Diagnol Stripes (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Diagnol_Stripes_(All_Over).png',
      description: ''
    },
    'Diamond (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Diamond_(All_Over).png',
      description: 'Diamond shape prints'
    },
    'Dotted/ Spotted (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Dotted__Spotted_(All_Over).png',
      description: 'Not polka dot. Polka dot is evenly spread, dotted has irregular sports and random distances'
    },
    'Dragon Big & Small': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Dragon_Big_&_Small.png',
      description: ''
    },
    'Embosed Logo/ Text': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Embosed_Logo-_Text.png',
      description: 'Any text or graphic embosed into a product'
    },
    'Emoji (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Emoji_(All_Over).png',
      description: 'Emoji print overall in a garment'
    },
    'Emoji (Front/ Back)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Emoji_(Front__Back).png',
      description: 'Emoji print on certain part of a garment'
    },
    'Eye Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Eye_Print_(All_Over).png',
      description: ''
    },
    'Face Print': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Face_Print.png',
      description: 'Face print on a garment'
    },
    'Feather Print': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Feather_Print.png',
      description: ''
    },
    'Fire/ Flame Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Fire__Flame_Print_(All_Over).png',
      description: ''
    },
    'Floral (All)': { thumbnail: '', description: 'All floral patterns come here' },
    'Fruit Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Fruit_Print_(All_Over).png',
      description: ''
    },
    'Geometric, Aztec (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Geometric,_Aztec_(All_Over).png',
      description: 'Any kind of abstract shapes such as lines,circles,triangle,rectangle etc. Also includes aztec print'
    },
    'Gingham (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Gingham_(All_Over).png',
      description: ''
    },
    'Gothic/ Angel/ Demon (Front/ Back)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Gothic__Angel__Demon_(Front__Back).png',
      description: 'Print of gothic/angel/demon on a garment'
    },
    'Gradient / Ombre (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Gradient___Ombre_(All_Over).png',
      description: 'Ombre/gradient is the blending of one colour to another,gradually going from light to dark'
    },
    'Heart Pattern (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Heart_Pattern_(All_Over).png',
      description: 'Heart pattern all over the garment'
    },
    'Honeycomb pattern': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Honeycomb_pattern.png',
      description: 'Hexagon shaped honeycomb pattern'
    },
    'Horizontal Stripes (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Horizontal_Stripes_(All_Over).png',
      description: ''
    },
    'Houndstooth (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Houndstooth_(All_Over).png',
      description: ''
    },
    'Kiss/ Lipstick Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Kiss__Lipstick_Print_(All_Over).png',
      description: ''
    },
    'Leaf Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Leaf_Print_(All_Over).png',
      description: ''
    },
    'Leopard Skin (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Leopard_Skin_(All_Over).png',
      description: ''
    },
    'Line Drawing': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Line_Drawing.png',
      description: 'Thin lines, lines can be colored but there is no filling of shapes with colors'
    },
    'Logo (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Logo_(All_Over).png',
      description: 'Logo print all over the garment'
    },
    'Logo (Front, Back, Chest)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Logo_(Front,_Back,_Chest).png',
      description: 'Logo print to front, back, or chest'
    },
    'Logo on left chest': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Logo_on_left_chest.png',
      description: 'A logo/text that is confined to a small area on the top left near chest'
    },
    'Marble/ Watercolor (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Marble__Watercolor_(All_Over).png',
      description: ''
    },
    'Maze pattern': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_Maze_pattern.png',
      description: 'A maze pattern'
    },
    'Mirrored Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Mirrored_Print_(All_Over).png',
      description: 'Is print that is mirroreed either vertically or horizontally'
    },
    'Nature View Print': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Nature_View_Print.png',
      description: 'Hills, streams, village, huts, lakes etc.'
    },
    'Painterly Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Painterly_Print_(All_Over).png',
      description: 'Clothes that have painting printed on them or that seem hand painted by the artist'
    },
    'Paisley (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Paisley_(All_Over).png',
      description: 'A distinctive pattern of curved feather-shaped figure'
    },
    'Panelled Multiple Prints (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Panelled_Multiple_Prints_(All_Over).png',
      description: 'Sections with panelled mulitipe print types on the garment'
    },
    Patchwork: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Patchwork.png',
      description: 'Any kind of any size of patchwork on garment'
    },
    'Pinstripes (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Pinstripes_(All_Over).png',
      description: 'Pattern of very thin strips of any colour running in parallel'
    },
    'Polka (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Polka_(All_Over).png',
      description: 'Large or small pollka dot on a garment'
    },
    'Print Only On Side/ Top/ Bottom': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Print_Only_On_Side__Top__Bottom.png',
      description: 'Prints gathered only at the certain part of the garment'
    },
    'Snake Skin (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Snake_Skin_(All_Over).png',
      description: ''
    },
    Solids: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Solids.png',
      description: 'Mostly solid print (maximum solid coverage)'
    },
    'Space, Sun, Moon, Celestial etc.': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Space,_Sun,_Moon,_Celestial_etc..png',
      description: ''
    },
    'Swirl (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Swirl_(All_Over).png',
      description: 'Swril-patterns having a whirling motion'
    },
    'Text (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Text_(All_Over).png',
      description: ''
    },
    'Text (Front/ Back)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Text_(Front__Back).png',
      description: 'Text print on certain parts of a garment'
    },
    'Tie Dye': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Tie_Dye.png',
      description: ''
    },
    'Tiger Skin (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Tiger_Skin_(All_Over).png',
      description: ''
    },
    'Tile Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Tile_Print_(All_Over).png',
      description: 'Prints confined in tiles, and the tiles are repeating'
    },
    'Tree Print (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Tree_Print_(All_Over).png',
      description: ''
    },
    'Vertical Stripes (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Vertical_Stripes_(All_Over).png',
      description: ''
    },
    'Wavy/ Curve (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Wavy__Curve_(All_Over).png',
      description: 'Wavy, curvy lines and patterns'
    },
    'Zebra Skin (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Zebra_Skin_(All_Over).png',
      description: ''
    },
    'Zig Zag (All Over)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN-Zig_Zag_(All_Over).png',
      description: ''
    }
  },
  'PATTERN SIZE': {
    Large: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_SIZE_Large.png',
      description: 'Any pattern size, which is large'
    },
    Medium: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_SIZE_Medium.png',
      description: 'Any pattern size, which is medium'
    },
    Small: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/PATTERN_SIZE_Small.png',
      description: 'Any pattern size, which is small'
    },
    '#N/A': {
      thumbnail: '',
      description: 'Select only when the pattern is solid'
    }
  },
  'SHIRT SILHOUETTE': {
    'Button Down Shirt (Formal, Casual, Half, Full, All)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Button_Down_Shirt_(Formal,_Casual,_Half,_Full,_All).png',
      description: ''
    },
    'Resort Shirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Resort_Shirt.png',
      description: 'To be identified by its resort colar only.'
    }
  },
  'SHORTS SILHOUETTE': {
    'Bicycle Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Bicycle_Shorts.png',
      description: ''
    },
    'Boat Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Boat_Shorts.png',
      description: 'Formal looking shorts. More importantly it becomes wide towards the end.'
    },
    'Cargo Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Cargo_Shorts.png',
      description: 'Shorts with cargo charactersticks'
    },
    'Denim Jorts (Jean+shorts)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Denim_Jorts_(Jean+shorts).png',
      description: 'Knee length, denim shorts, loose fit'
    },
    'Denim Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Denim_Shorts.png',
      description: 'Denim shorts (unlike jorts) are above knee, and dont have loose fit'
    },
    'Formal Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Formal_Shorts.png',
      description: 'Unlike boat shorts, they are formal, but dont widen at the bottom.'
    },
    'Highcut Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE_Highcut_Shorts.png',
      description: 'Shorts that have a very high cut upto the waist, and are assymetrical'
    },
    'Jersey Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Jersey_Shorts.png',
      description: 'Sporty looking, or jersey shorts'
    },
    'Mini Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Mini_Shorts.png',
      description: 'Consider as micro mini shorts'
    },
    'Pyjama Shorts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHORTS_SILHOUETTE-Pyjama_Shorts.png',
      description: 'Shorts that can be worn to bed'
    }
  },
  'SHOULDER STYLE': {
    'Chain strap shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Chain_strap_shoulder.png',
      description: 'Shoulder straps that have chains'
    },
    'Cold Shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Cold_Shoulder.png',
      description: ''
    },
    'Drop Shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Drop_Shoulder.png',
      description: 'Oversize, with shoulders dropped from their regular positon'
    },
    'Flower Applique Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Flower_Applique_Strap.png',
      description: 'Shoulder strap that has applique details on it'
    },
    'Knotted Shoulder Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Knotted_Shoulder_Strap.png',
      description: 'Knot detail in shoulder straps'
    },
    'Lace Shoulder Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Lace_Shoulder_Strap.png',
      description: 'Shoulder straps with lace'
    },
    'Off Shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Off_Shoulder.png',
      description: ''
    },
    'One Shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-One_Shoulder.png',
      description: ''
    },
    'Puffed Shoulder': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Puffed_Shoulder.png',
      description: 'Just the  shoulder area is puffed'
    },
    'Regular Shoulders': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Regular_Shoulders.png',
      description: 'A regular style shoulder'
    },
    'Ruffled Shoulder Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Ruffled_Shoulder_Strap.png',
      description: 'Shoulder straps are ruffled'
    },
    'Scrunched Shoulder Strap': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE_Scrunched_Shoulder_Strap.png',
      description: 'Shoulder straps are scrunched'
    },
    'Shoulder Pads': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Shoulder_Pads.png',
      description: 'Shoulder area has shoulder pads'
    },
    'Shoulder Tabs': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Shoulder_Tabs.png',
      description: 'Flap with buttons at shoulder'
    },
    'Thin Shoulder Straps': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Thin_Shoulder_Straps.png',
      description: 'Thin shoulder straps'
    },
    'Wide Shoulder Straps': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SHOULDER_STYLE-Wide_Shoulder_Straps.png',
      description: 'Wide shoulder straps'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'SKIRT SILHOUETTE': {
    'A Line Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-A_Line_Skirt.png',
      description: 'A skirt that is fitted at the hips and gradually widens towards the hem'
    },
    'Bandeau Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Bandeau_Skirt.png',
      description: 'A strechy fabric, with a fitted bodice'
    },
    'Bodycon & Pencil Skirts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Bodycon_&_Pencil_Skirts.png',
      description: 'A slim fitting skirt with a straight narrow cut'
    },
    'Cargo Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Cargo_Skirt.png',
      description: 'Skirt with cargo charactersticks'
    },
    'Fit & Flare Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Fit_&_Flare_Skirt.png',
      description: 'Fitted till waist, and half the hip and flaring wider at the bottom'
    },
    'Flared Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Flared_Skirt.png',
      description: 'Much more flared than an a-line, fitted at waist.'
    },
    'Mermaid Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Mermaid_Skirt.png',
      description: 'Calf, or full length. Fitted till the thighs and flares out from the bottom'
    },
    'Pencil/ Column/ Straight Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Pencil__Column__Straight_Skirt.png',
      description: 'Straight fit from waist down'
    },
    'Peplum Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Peplum_Skirt.png',
      description: 'Peplum either at waist of hem'
    },
    'Skater Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Skater_Skirt.png',
      description: 'Mini or knee length skirt. A lot flared (more than a-line) from waist down.'
    },
    'Wrap Skirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SKIRT_SILHOUETTE-Wrap_Skirt.png',
      description: 'Wrap style skirt'
    }
  },
  'SLEEVE LENGTH': {
    '1/4 sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-1_4_sleeve.png',
      description: ''
    },
    '2/4 sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-2_4_sleeve.png',
      description: 'Sleeves till elbow length'
    },
    '3/4 sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-3_4_sleeve.png',
      description: ''
    },
    'Extra Long Sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-Extra_Long_Sleeve.png',
      description: 'Much longer than full sleeves'
    },
    'Full Sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-Full_Sleeve.png',
      description: 'A sleeve extending from shoulder to wrist'
    },
    Sleeveless: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_LENGTH-Sleeveless.png',
      description: ''
    }
  },
  'SLEEVE STYLE': {
    'Bell/ Flounce Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Bell__Flounce_Sleeves.png',
      description: 'Has a bell like flare after the elbow'
    },
    'Bishop / Baloon Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Bishop___Baloon_Sleeves.png',
      description: 'Fitted shoulder and wrist, and everything in between is balooned'
    },
    'Cap Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Cap_Sleeves.png',
      description: 'Simple sleeves which slightly caps till below the shoulder'
    },
    'Double Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Double_Sleeves.png',
      description: 'Having  two-layered sleeves'
    },
    'Fitted Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Fitted_Sleeves.png',
      description: 'Regular fitting sleeve'
    },
    'Flutter Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Flutter_Sleeves.png',
      description: 'Loose flared sleeves, extending slightly below th e shoulder'
    },
    'Juliet Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Juliet_Sleeves.png',
      description: 'Fitted at shoulder, and from elbow down. Balooned between shoulder and elbow.'
    },
    'Puff Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Puff_Sleeves.png',
      description: 'Half sleeves which are puffed'
    },
    'Raglan Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Raglan_Sleeves.png',
      description: 'Sleeve starts from side of neckline, and there is no clear distinction of it at shoulder'
    },
    'Regular Cuffed Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Regular_Cuffed_Sleeves.png',
      description: 'Classic cuffed sleeves'
    },
    'Single Sleeve': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Single_Sleeve.png',
      description: 'Only one arm covered with sleeves, the other is bare'
    },
    'Ultra Wide Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Ultra_Wide_Sleeves.png',
      description: 'A very wide sleeves all the way down from shoulder'
    },
    'Winged Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SLEEVE_STYLE-Winged_Sleeves.png',
      description: 'Sleeves having a wing like appearance '
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'SOCKS & STOCKINGS': {
    'Leg Warmer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SOCKS_&_STOCKINGS-Leg_Warmer.png',
      description: 'Leg warmer worn above the footwear'
    },
    Socks: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SOCKS_&_STOCKINGS-Socks.png',
      description: ''
    },
    Stockings: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SOCKS_&_STOCKINGS-Stockings.png',
      description: ''
    }
  },
  'SUNGLASS FRAME': {
    Aviator: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Aviator.png',
      description: 'A style of sunglasses with thin metal frames and large lenses, usually wider on the outside and narrow  next to the nose'
    },
    'Cat Eye': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Cat_Eye.png',
      description: ''
    },
    'Chunky Frame': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Chunky_Frame.png',
      description: ''
    },
    'Narrow Cateye': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME_Narrow_Cateye.png',
      description: 'A narrower version of regular cateye glasses'
    },
    'Narrow Sunglasses': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Narrow_Rectangle.png',
      description: 'All Narrow Sunglasses, of any shape'
    },
    Oval: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Oval.png',
      description: ''
    },
    Polygon: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Polygon.png',
      description: 'Glasses that have 5 or more sides'
    },
    Round: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Round.png',
      description: ''
    },
    'Round Retro': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Round_Retro.png',
      description: 'A vintage inspired round sunglasses'
    },
    'Slim Frame': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Slim_Frame.png',
      description: ''
    },
    Square: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Square.png',
      description: ''
    },
    'Wasp Eye (Wide Cateye)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME_Wasp_Eye_(Wide_Cateye).png',
      description: 'A much broader/wider cateye glasses'
    },
    'Wayfarer Sunglasses': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Wayfarer_Sunglasses.png',
      description: 'Has the top corners of the frame just out a bit'
    },
    'Wide/ Oversize/ Shield': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Wide__Oversize__Shield.png',
      description: 'Oversize frames'
    },
    'Wrap Around': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_FRAME-Wrap_Around.png',
      description: 'Sunglasses that wrap and completely covers the eyes from side'
    }
  },
  'SUNGLASS LENS': {
    'Black Tint': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Black_Tint.png',
      description: ''
    },
    'Brown Tint': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Brown_Tint.png',
      description: ''
    },
    'Mirror/ Reflective': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Mirror__Reflective.png',
      description: ''
    },
    'Pink Tint': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Pink_Tint.png',
      description: ''
    },
    'Red Tint': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Red_Tint.png',
      description: ''
    },
    'White Transparent': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-White_Transparent.png',
      description: ''
    },
    'Yellow Tint': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SUNGLASS_LENS-Yellow_Tint.png',
      description: ''
    }
  },
  'SURFACE ADDONS': {
    'Back pocket in front skirt jeans': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_ADDONS_Back_pocket_in_front_skirt_jeans.png',
      description: 'Back pocket is sewed in the front of skirt, trouser, or jeans'
    },
    'Belted/ Strap Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Belted__Strap_Detail.png',
      description: 'Belt/strap detail added to outfit'
    },
    'Big Shoelaces': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Big_Shoelaces.png',
      description: 'Chunky shoelaces'
    },
    'Brooch Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Brooch_Detail.png',
      description: 'An ornamental pin usually attach to a garments'
    },
    'Buckle Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Buckle_Detail.png',
      description: 'Buckle used as a decorative item in  a clothing or a bag'
    },
    'Button Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Button_Detail.png',
      description: 'Its not about button closure, but about decorative buttons'
    },
    'Carpenter Strap/ Double Knee': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Carpenter_Strap__Double_Knee.png',
      description: 'Strap / panel / pockets around the knee on front of the trouser / jeans'
    },
    'Chain Detail (Not Chain Print)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Chain_Detail_(Not_Chain_Print).png',
      description: 'Chain details on a garment'
    },
    'Drawstring Tightning Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Drawstring__Tie_Up_Detail.png',
      description: 'A string on a garment or a bag,which can be pulled to tighten or close it'
    },
    'Fringed/ Tassel/ Frill': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Fringed__Tassel__Frill.png',
      description: 'Fringes at any part of the garment'
    },
    Hooded: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Hooded.png',
      description: 'Any garment with a hood to cover head'
    },
    'Keyhole Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Keyhole_Detail.png',
      description: 'A keyhole opening at front or back (smaller than cut out trend)'
    },
    'Knee Patch Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Knee_Patch_Detail.png',
      description: "A pannel of fabric/patchwork that's sewn into the knee part of a pants"
    },
    'Knot/ Twist/ Pinch Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_ADDONS_Knot-_Twist-_Pinch_Detail.png',
      description: 'Any pinched, knot, or twist detail on the garment'
    },
    'Logo Tape Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Logo_Tape_Detail.png',
      description: 'Having tags or logo running anywhere on the  garments'
    },
    'Piping in front': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_ADDONS_Piping_in_front.png',
      description: 'A trouser/jeans that have a piping in the front to enhance its looks'
    },
    'Pleated Pants, Shirts etc.': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Pleated_Pants,_Shirts_etc..png',
      description: ''
    },
    'Pocket Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Pocket_Detail.png',
      description: 'Have sevaral pockets as a part of styling'
    },
    'Ring Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Ring_Detail.png',
      description: 'A ring added to the garment for style'
    },
    'Safety Pin Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Safety_Pin_Detail.png',
      description: 'Safety pins added as stying element'
    },
    'Side Stripes': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_ADDONS_Side_Stripes.png',
      description: 'Any garment that has stripes on the side of the arm and legs'
    },
    Slits: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Slits.png',
      description: 'Slits cut at front, back, side, top, bottom of the garment'
    },
    'Star Patch': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Star_Patch.png',
      description: 'Star patch on garment'
    },
    'Tail Attached': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_ADDONS_Tail_Attached.png',
      description: 'Any product in which a tale is attached, could be bag, dress, top, coat etc.'
    },
    'Thin Laces Criscross Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Tieup_Lace_Detail.png',
      description: 'Lace/strings detail as tie ups'
    },
    'Wide Straps Crisscross Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Crossover_Straps__Criss_Cross_Tie_Detail.png',
      description: 'A criss-cross or crossover design consist of straps crossing each other'
    },
    'Zip Detail': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Zip_Detail.png',
      description: 'Not a zip closure, but zip as a decorative element'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'SWEATER & CARDIGAN': {
    'Bolero Sweater': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Bolero_Sweater.png',
      description: 'A sweater which covers only the hands and neck'
    },
    'Cardigan Sweater': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Cardigan_Sweater.png',
      description: ''
    },
    'Cropped Sweater': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Cropped_Sweater.png',
      description: 'A cropped sweater'
    },
    Ponchos: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Ponchos.png',
      description: 'A kaftan like outerwear, protection from cold'
    },
    'Regular Sweater': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_CARDIGAN_regular_sweater.png',
      description: 'Just a regular pullover sweater'
    },
    'Sweater Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Sweater_Dress.png',
      description: 'A dress made out of sweater'
    },
    'Sweater Vest': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SWEATER_&_CARDIGAN-Sweater_Vest.png',
      description: 'A vest sweater used as a top.'
    }
  },
  TEXTURES: {
    Canvas: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Canvas.png',
      description: 'Canvas shoe, bag, jacket etc.'
    },
    Chainmail: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Chainmail.png',
      description: 'A chainmail texture that can be used in top, dress, etc.'
    },
    'Chunky Knit': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Chunky_Knit__Braided__Knot__Interwoven.png',
      description: 'Thick and bulky knitted with yarn'
    },
    Corduroy: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Corduroy.png',
      description: 'A thick cotton material woven with parallel lines on the outside'
    },
    Crochet: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Crochet.png',
      description: ''
    },
    'Crushed/ Crinkled (Not Plisse)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Crushed-_Crinkled_(Not_Plisse).png',
      description: 'Texture is more non uniform then plisse, the fabric appears crushed, or crinkled'
    },
    Denim: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Denim.png',
      description: ''
    },
    Embellished: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Embellished.png',
      description: 'Adding ornaments like beads, mirrors etc.'
    },
    Embroidered: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Embroidered.png',
      description: ''
    },
    Feather: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Feather.png',
      description: 'Feather detail anywhere on garment'
    },
    'Fine Knit/ Smooth Grain (#Default)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Fine_Knit__Smooth_Grain_(%23Default).png',
      description: 'A basic default garment that doesnt have any texture on it. Is smooth and fine.'
    },
    'Fur/ Shearling': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Fur__Shearling.png',
      description: 'Coated with fur on the collar and cuffs  or covered with fur everywhere'
    },
    'Glossy Patent Leather': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Glossy_Patent_Leather.png',
      description: 'Shiny leather can be used in anything'
    },
    Lace: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Lace.png',
      description: 'Either lace in a small section, or lace all over'
    },
    'Laser Cut': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Laser_Cut.png',
      description: 'Fabric cut by laser, and cuts more prominent than lace'
    },
    'Matte Non Shiny Leather': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Matte_Non_Shiny_Leather.png',
      description: 'Non shiny leather'
    },
    'Medium Knit': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Medium_Knit.png',
      description: 'Medium size knits, which are between chunky and fine. Not necessarily a sweater'
    },
    'Mesh/ Tulle/ Chiffon/ Organza': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Mesh__Tulle.png',
      description: 'A  lightweight fine, stiff, netlike fabric'
    },
    'Metallic Shine (Smooth)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Metallic_Shine_(Smooth).png',
      description: 'Has a metallic shine, can be of any color'
    },
    'Net/ Fishnet': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Net__Fishnet.png',
      description: 'A see through net '
    },
    Pearls: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Pearls.png',
      description: ''
    },
    'Plastic Transparent': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Plastic_Transparent.png',
      description: 'Plastic/transpaent see-through'
    },
    Plisse: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Plisse.png',
      description: 'Is like micro pleats.'
    },
    Quilted: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Quilted.png',
      description: 'Not too blown like puffed. Filled with thick soft material'
    },
    'Reflective & High Shine': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Reflective_&_High_Shine.png',
      description: "Shiny beyond metallic, shine that's relfective"
    },
    Ribbed: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Ribbed.png',
      description: 'Fabric knit into thick long lines'
    },
    'Satin/ Poplin': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Satin__Poplin.png',
      description: 'A silk, smooth, and glossy finish'
    },
    'Sequin/ Shimmery': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Sequin__Shimmery.png',
      description: 'Not embellished anything. But the garment itslef is shiny.'
    },
    Straw: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Straw.png',
      description: 'Any item made out of thin or thick straws'
    },
    Studded: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Studded.png',
      description: 'Studded belt,bags or clothes are decorated with studs or spikes of any shape or kind'
    },
    Suede: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Suede.png',
      description: 'Suede is a kind of a leather but has a very smooth and velvet finish'
    },
    'Textured Leather': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Crocodile.png',
      description: 'All textures including crocodile etc.'
    },
    Tweed: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Tweed.png',
      description: 'Thick solid compressed wool. Giving rough texture.'
    },
    Velvet: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Velvet.png',
      description: 'Smooth and silky texture ,delicate in appearance or feels soft '
    },
    'Windcheater / Parachute': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES-Windcheater___Parachute.png',
      description: 'Thin lightweight fabric, often used for rain protection. Now used in different styles.'
    },
    Woven: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TEXTURES_Woven.png',
      description: 'A texture which appears woven, can be of plastic, leather, fabric or anything'
    }
  },
  'TOE SHAPE': {
    'Close toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Close_toe.png',
      description: 'Footwear that is entirely covered from the top'
    },
    'Open toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Open_toe.png',
      description: 'Footwear whose top is not covered'
    },
    'Peep toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Peep_toe.png',
      description: 'Footwear in which the toe or front fingers are peeping out'
    },
    'Pointed toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Pointed_toe.png',
      description: 'Pointed toe shaped footwear'
    },
    'Round toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Round_toe.png',
      description: 'Round shaped toe footwear'
    },
    'Split toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Split_toe.png',
      description: 'Front of the footwear where the it is split into two'
    },
    'Square toe': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOE_SHAPE_Square_toe.png',
      description: 'Square shaped toe footwear'
    }
  },
  'TOP LENGTH': {
    'Crop (Above Waist)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOP_LENGTH-Crop_(Above_Waist).png',
      description: 'Regular crop length'
    },
    'Extra Crop (Just below bust)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOP_LENGTH-Extra_Crop_(Just_below_bust).png',
      description: 'Ending just below the bust area'
    },
    'Long (Below Waist)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOP_LENGTH-Long_(Below_Waist).png',
      description: 'All lengths below waist'
    },
    'Regular (Till Waist)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOP_LENGTH-Regular_(Till_Waist).png',
      description: 'Till waist length'
    }
  },
  'TOPWEAR SILHOUETTE': {
    'Bandeau Top / Tube Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Bandeau_Top___Tube_Top.png',
      description: 'A strechy top that covers the chest part but leaves the shoulder bare'
    },
    'Basic Top (non fitted)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/Basic_top,_not_fitted.png',
      description: 'Basic top, not fitted'
    },
    Bodysuit: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Bodysuit.png',
      description: ''
    },
    'Bolero Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Bolero_Top.png',
      description: ''
    },
    Bralette: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Bralette.png',
      description: 'Not to be confused with strappy top.'
    },
    'Cami Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Cami_Top.png',
      description: 'A very light piece of clothing with thin strap, usually worn under garments or can be worn as it is'
    },
    'Cape Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Cape_Top.png',
      description: 'A cape like coverup, or top designed like loose cape'
    },
    'Cardigan Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Cardigan_Top.png',
      description: ''
    },
    'Corset Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Corset_Top.png',
      description: ''
    },
    'Deconstructed Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Deconstructed_Top.png',
      description: 'A top whose structure has been altered, but is still a topwear'
    },
    'Detached Sleeve Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Detached_Sleeve_Top.png',
      description: 'Not an off sholder, but where the sleeves are detached and worn separately'
    },
    'Fitted Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Fitted_Top.png',
      description: 'A tight-fitting small top'
    },
    'Halterneck Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Halterneck_Top.png',
      description: 'Add all halternecks, subclassification of halterneck to be done via neckline tab'
    },
    Kaftan: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Kaftan.png',
      description: ''
    },
    'Long length Tops': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Long_length_Tops.png',
      description: 'Tops which are much longer than usual, crossing knee'
    },
    'Micro/ Mini Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Micro__Mini_Top.png',
      description: ''
    },
    'Milkmaid Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Milkmaid_Top.png',
      description: 'Gathered around the chest area'
    },
    'Off Shoulder Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Off_Shoulder_Top.png',
      description: ''
    },
    'One Shoulder Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-One_Shoulder_Top.png',
      description: ''
    },
    'Peplum Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Peplum_Top.png',
      description: ''
    },
    'Scarf Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Scarf_Shape_Top.png',
      description: 'Scarf materials used as a top'
    },
    'Strappy Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Strappy_Top.png',
      description: 'Not a cami top. Has shoulder strap with any top design.'
    },
    'Sweater Tops': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Sweater_Tops.png',
      description: 'A fitting sweater used directly as a top. Not a cardigan.'
    },
    'Tank Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Tank_Top.png',
      description: ''
    },
    'Tube Flared Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Tube_Flared_Top.png',
      description: 'A tube shape clothing that covers the chest and back but not the  shoulders or arms and flowy and flared from the bust line'
    },
    'Tube off shoulder top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE_Tube_off_shoulder_top.png',
      description: 'An off shoulder tube top'
    },
    Tunics: {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Tunics.png',
      description: 'A loose top ending at the waistline, usually ethinic'
    },
    'Two Piece Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Two_Piece_Top.png',
      description: 'A top which looks like 2 pieces layered on top of each other'
    },
    'Vest Blazer Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Vest_Blazer_Top.png',
      description: ''
    },
    'Wrap Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Wrap_Top.png',
      description: ''
    }
  },
  TRANSPARENCY: {
    'Full Sheer/ Sheer Overlay': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRANSPARENCY-Full_Sheer__Sheer_Overlay.png',
      description: 'A full sheer garment, or a full sheer overlay worn'
    },
    'Low Sheer': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRANSPARENCY-Low_Sheer.png',
      description: 'Fabric that has little sheerness'
    },
    'Sheer Panels': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRANSPARENCY-Sheer_Panels.png',
      description: 'With sheer panels in between'
    },
    'Sheer Sleeves': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRANSPARENCY-Sheer_Sleeves.png',
      description: 'Only the sleeves are sheer'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'TRIM/ EDGE/ HEM': {
    'Applique trim & edge (flower, bow etc.)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM-_EDGE-_HEM_Applique_trim_&_edge_(flower,_bow_etc.).png',
      description: 'Where 3d details, appliques are there at the trims & edgees.'
    },
    'Broader Fringes/ Cuts': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM-_EDGE-_HEM_Broader_Fringes-_Cuts.png',
      description: 'Like fringed, but friges are much broader.'
    },
    'Contrasting Striped Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Contrasting_Striped_Edge.png',
      description: 'The edges are of contrasting color. This is not piped edge.'
    },
    'Drawstring Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Drawstring_Edge.png',
      description: "There's drawstring at the edge of the garment"
    },
    'Embellished/ Sequin/ Shimmery Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Embellished__Sequin__Shimmery_Edge.png',
      description: 'The edge with emblished work'
    },
    'Embroidered Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Embroidered_Edge.png',
      description: 'Embroiderey at the edges only'
    },
    'Feather Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Feather_Edge.png',
      description: 'Garments that has feather at the edge'
    },
    'Fringed / Tassel Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Fringed___Tassel_Edge.png',
      description: 'The edge of a grament with fringed/tassel'
    },
    'Laced/ Laser Cut Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Laced__Laser_Cut_Edge.png',
      description: 'The edge of the garment has laces'
    },
    'Meshed Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE__HEM-Meshed_Edge__Hem.png',
      description: 'Where mesh is present at the edge or hemline'
    },
    'Peplum Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE__HEM-Peplum_Edge__Hem.png',
      description: 'Where peplum is there at the edge or hemline'
    },
    'Piped Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Piped_Edge.png',
      description: "There's piping at the edges"
    },
    'Pleated Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Pleated_Edge.png',
      description: 'Pleasted at the edge garment'
    },
    'Raw Cut/ Frayed Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Raw_Cut__Frayed_Edge.png',
      description: 'The edge is raw-cut or frayed'
    },
    'Ribbed/ Elastic Cuffs/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM-_EDGE-_HEM_Ribbed-_Elastic_Cuffs-_Hem.png',
      description: 'Hems, cuffs that have ribbed elastic in them'
    },
    'Ruffled Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Ruffled_Edge.png',
      description: 'Clothing that has ruffles at the edge'
    },
    'Turned Up Hem/ Edge': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/HEMLINE_SHAPE-Turned_Up_Hem.png',
      description: 'Hem folded inside out'
    },
    'Whipstiched Edge/ Hem': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TRIM__EDGE-Whipstiched_Edge.png',
      description: 'The edges have whipstiches'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'TSHIRT SILHOUETTE': {
    'Basic Tshirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Basic_Tshirt.png',
      description: 'Basic t.Shirt that can be either solid or with prints'
    },
    'Fitted Tshirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Fitted_Tshirt.png',
      description: 'A tight-fitting small tshirt'
    },
    'Jersey Top': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Jersey_Top.png',
      description: ''
    },
    'Polo Tshirt': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/TOPWEAR_SILHOUETTE-Polo_Tshirt.png',
      description: ''
    }
  },
  'UNIQUE SHAPES & DETAILS': {
    'Big Size Buttons': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS_Big_Size_Buttons.png',
      description: 'Big size ornamental buttons'
    },
    'Bow Attachments': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/SURFACE_DETAIL-Bow_Detail.png',
      description: 'Bow style details on a garment'
    },
    'Bow Shape Product': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Bow_Shape_Product.png',
      description: 'Product that have a bow shape'
    },
    'Butterfly Attachments': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Butterfly_Attachments.png',
      description: 'Butterfly details on the garment'
    },
    'Butterfly Shape Product': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Butterfly_Shape.png',
      description: 'A garment,sunglasses or a bag that has the shape of a butterfly'
    },
    'Flower Attachments': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Flower_Attachments.png',
      description: 'Flower details on the garment'
    },
    'Flower Shape Product': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Flower_Shape_Product.png',
      description: 'Product that have a flower shape'
    },
    'Heart Attachments': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Heart_Attachments.png',
      description: 'Heart style details on the garment'
    },
    'Heart Shape Product': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Heart_Shape_Product.png',
      description: 'Product that have a heart shape'
    },
    'Selectively Balooned': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Selectively_Balooned.png',
      description: 'Portions of the garment which are balooned'
    },
    'Selectively Slouched': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Slouch.png',
      description: 'Very loose slouchy, gathered, falling appearence'
    },
    'Small 3d detailings (bow, flower, butterfly, heart etc.)': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/DESIGN__TREND-Small_3d_detailings_(all_over).png',
      description: 'Anything protuding out of a garment, which feel like 3d and is all over, or covering a large portion of the garment'
    },
    'Unique Shaped Buttons': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS_Unique_Shaped_Buttons.png',
      description: 'Unique shaped buttons'
    },
    'Voluminous Skirt/ Dress': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/UNIQUE_SHAPES_&_DETAILS-Voluminous_Skirt__Dress.png',
      description: 'Any garment that has a lot of volume in it and looks fitted and large at same time'
    },
    '#N/A': { thumbnail: '', description: '' }
  },
  'WAIST STYLING': {
    'Asymetric Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Asymetric_Waist.png',
      description: ''
    },
    'Belted Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING_Belted_Waist.png',
      description: 'A waist that has belt'
    },
    'Chain Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING_Chain_Waist.png',
      description: 'A chain belt around the waist'
    },
    'Cinched Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Cinched_Waist.png',
      description: 'An elastic that is tight around the waistline'
    },
    'Double Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Double_Waist.png',
      description: ''
    },
    'Drawstring Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Drawstring_Waist.png',
      description: ''
    },
    'Drop Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Drop_Waist.png',
      description: 'Waistline shifted way down, till the hip'
    },
    'Empire Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Empire_Waist.png',
      description: 'Waistline shifted way up, below the bust'
    },
    'Fitted/ Structured Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING_Fitted-_Structured_Waist.png',
      description: 'A fitted appearence waist design'
    },
    'Folded/ Inverted Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Folded__Inverted_Waist.png',
      description: "Waistband that's folded outside"
    },
    'High Cut Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-High_Cut_Waist.png',
      description: 'A high cut from the sides, way above the hips'
    },
    'High Waisted': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-High_Waisted.png',
      description: 'A higher up waistline of bottomwear'
    },
    'Logo Waistband': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Logo_Waistband.png',
      description: ''
    },
    'Paperbag Waist': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-Paperbag_Waist.png',
      description: ''
    },
    'V Waistline': {
      thumbnail: 'https://storage.googleapis.com/fast-fashion-trends/tag-images-sample/compressed/WAIST_STYLING-V_Waistline.png',
      description: ''
    },
    '#N/A': { thumbnail: '', description: '' }
  }
}



export const ToolTipText = {

  "BELTS": "Corset, Wide, Narrow Belts etc.",
  "CAPS/ HATS": "Baseball, Beanie, Beret, Breton, Bucket, Cowboy, Fedora, Gaucho, Newsboy etc. ",
  "CASUAL COORDS": "Loungewear, Pyjama, Tracksuit etc.",
  "COATS, SUITS TYPE": "Blazer, Pea coat, Suits, Trench coat, Waist coat etc.",
  "DRESS SILHOUETTE": "Bodycon, Column, Corset, Empire, Fit-Flare, A-Line, Mermaid, Peplum, Pinafore, Sheath, Shift, Slip, Tube, Wrap, Tennis etc. ",
  "ETHINIC WEAR": "Salwar, Kurta, Lehenga, Sharara, Saree etc.",
  "GLOVES": "Fingerless, Full finger gloves etc.",
  "JACKETS": "Bomber, Denim, Gilet, Nehru, Puffer, Parka, Moto, Shacket, Teddy, Varsity etc.",
  "KURTA SILHOUETTE": "A Line, Fit Flare, Slip, Short etc.",
  "OTHER OUTERWEAR": "Kimonos, Shrugs etc.",
  "PANT/ JEANS FIT": "Baggy, Flared, Skinny, Straight leg, Wide leg, Chino, Cargo, Cigarette, Mom fit, Culottes, Parachute, etc.",
  "SHIRT SILHOUETTE": "Formal, Casual, Resort shirt etc.",
  "SHORTS SILHOUETTE": "Bicycle, Boat, Cargo, Jorts, Denim, Highcut, Jersey, Mini, Pyjama etc. ",
  "SKIRT SILHOUETTE": "A Line, Bandeau, Bodycon, Pencil, Skater, Cargo, Mermaid, Peplum,  Wrap etc. ",
  "SOCKS & STOCKINGS": "Stockings, Socks, Leg warmers etc.",
  "SWEATER & CARDIGAN": "Bolero, Cardigan, Vest, Regular, Poncho, Cropped etc. ",
  "TOPWEAR SILHOUETTE": "Bandeau, Tube, Tank, Cami, Corset, Halterneck, Milkmaid, Scarf, Wrap, Tunic, Bodysuit etc.",
  "TSHIRT SILHOUETTE": "Basic, Fitted, Jersey, Polo etc.",
  "DESIGN/ THEME": "Athleisure, Tailor cut, Cargo, Layered, Oversized, Ripped, Scrunched, Ruffled, Tiered etc. ",
  "UNIQUE SHAPES & DETAILS": "Bow, Butterfly, Flower, Heart, Balooned, Slouched etc.",
  "SURFACE ADDONS": "Drawstring, Fringe, Knot, Piping, Slits, Rings, Patches, Straps, etc.",
  "NECKLINE": "Boat, Halter, Henley, Turtle, Plunging, Racer, Scoop, Spaghetti, Sweetheart etc.",
  "CHEST DESIGN": "Balconette, Cone, Studded, Milkmaid, Underwired etc.",
  "TOP LENGTH": "Crop, Regular, Long etc.",
  "BOTTOM LENGTH": "Micro, Mini, Knee, Calf, Maxi, Floor etc.",
  "COLOR": "All Pantone Colors",
  "PATTERN": "Multiple pattern names",
  "PATTERN SIZE": "Small, Medium, Large",
  "TEXTURES": "Crochet, Denim, Leather, Fishnet, Mesh, Ribbed, Satin, Straw, Sequin etc. ",
  "SLEEVE STYLE": "Bell, Bishop, Cap, Flutter, Puff, Juliet, Fitter, Raglan etc.",
  "SLEEVE LENGTH": "Sleeveless, 1/4th, 2/4th, 3/4th, Full, Extra long etc.",
  "SHOULDER STYLE": "Cold, Dropped, Off, One Shoulder etc. ",
  "FRONT CLOSURE": "Buttoned, Zipped, Drawstring, Wrap, Pullover, etc.",
  "WAIST STYLING": "Cinched, Drawstring, Drop Waist, Empire, High Cut, Paperbag etc.",
  "HEMLINE SHAPE": "Asymmetric, Curved, Flared, Split, Straight etc.",
  "TRIM/ EDGE/ HEM": "Fringed edge, Feathered edge, Contrasting edge, Raw Cut edge, etc.",
  "TRANSPARENCY": "Full/Mid/Low Sheer, Sheer Panels, Sheer Sleeves etc.",
  "DENIM EFFECT": "Slashed, Washed, Patched, Distress Levels etc.",
  "BACK": "Keyhole, Tieback, Backless etc.",
  "FOOTWEAR SILHOUETTE": "Broader classification: Sneakers, Boots, Sandals, Pumps, Slipons etc.",
  "FOOTWEAR NAME": "Names: Ballet, Oxfords, Chelsea, Combat, Converse, Loafer, Maryjane, Mule, Thong etc.",
  "FOOTWEAR HEEL/ SOLE": "Block, Kitten, Chunky, Stiletto, Wedge etc. ",
  "TOE SHAPE": "Open, Close, Peep, Pointed, Round, Square, Split etc. ",
  "BAG SHAPE": "Broader Shapes: Bucket, Curve edge, Envelope, Heart shape, Oval, Trapeze etc.",
  "BAG TYPE": "Names: Baquette, Boston, Duffel, Clutch, Fanny, Hobo, Jodie, Saddle, Tote, Pillow etc.",
  "BAG HANDLE": "Bead, Chain, Knot, Strap, Metal, Wood handle etc. ",
  "SUNGLASS FRAME": "Aviator, Cateye, Chunky, Round, Square, Wasp, Wayfarer, Wrap etc.",
  "SUNGLASS LENS": "Black/Brown/Pink/Red/Yellow Tint, Transparent, Reflective etc."
}


export const HeadersRanking = {
  "Topwear": {
    "TOPWEAR SILHOUETTE": 1,
    "DESIGN/ THEME": 2,
    "UNIQUE SHAPES & DETAILS": 3,
    "SURFACE ADDONS": 4,
    "NECKLINE": 5,
    "CHEST DESIGN": 6,
    "TOP LENGTH": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "PATTERN SIZE": 11,
    "TEXTURES": 12,
    "TRANSPARENCY": 13,
    "SLEEVE STYLE": 14,
    "FRONT CLOSURE": 15,
    "SHOULDER STYLE": 16,
    "WAIST STYLING": 17,
    "TRIM/ EDGE/ HEM": 18,
    "HEMLINE SHAPE": 19,
    "SLEEVE LENGTH": 20,
    "DENIM EFFECT": 21,
    "BACK": 22
  },
  "Tshirts": {
    "TSHIRT SILHOUETTE": 1,
    "TOP LENGTH": 2,
    "NECKLINE": 3,
    "COLOR": 4,
    "MULTICOLOR": 5,
    "PATTERN": 6,
    "PATTERN SIZE": 7,
    "TEXTURES": 8,
    "DESIGN/ THEME": 9,
    "UNIQUE SHAPES & DETAILS": 10,
    "SURFACE ADDONS": 11,
    "TRIM/ EDGE/ HEM": 12,
    "HEMLINE SHAPE": 13,
    "SLEEVE STYLE": 14,
    "SLEEVE LENGTH": 15,
    "SHOULDER STYLE": 16,
    "FRONT CLOSURE": 17,
    "CHEST DESIGN": 18,
    "WAIST STYLING": 19,
    "TRANSPARENCY": 20,
    "DENIM EFFECT": 21,
    "BACK": 22
  },
  "Shirts": {
    "SHIRT SILHOUETTE": 1,
    "DESIGN/ THEME": 2,
    "UNIQUE SHAPES & DETAILS": 3,
    "SURFACE ADDONS": 4,
    "NECKLINE": 5,
    "TOP LENGTH": 6,
    "SLEEVE LENGTH": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "PATTERN SIZE": 11,
    "TEXTURES": 12,
    "HEMLINE SHAPE": 13,
    "TRIM/ EDGE/ HEM": 14,
    "SLEEVE STYLE": 15,
    "CHEST DESIGN": 16,
    "SHOULDER STYLE": 17,
    "FRONT CLOSURE": 18,
    "WAIST STYLING": 19,
    "TRANSPARENCY": 20,
    "DENIM EFFECT": 21,
    "BACK": 22
  },
  "Jeans": {
    "PANT/ JEANS FIT": 1,
    "WAIST STYLING": 2,
    "DENIM EFFECT": 3,
    "DESIGN/ THEME": 4,
    "COLOR": 5,
    "MULTICOLOR": 6,
    "HEMLINE SHAPE": 7,
    "TRIM/ EDGE/ HEM": 8,
    "UNIQUE SHAPES & DETAILS": 9,
    "SURFACE ADDONS": 10,
    "BOTTOM LENGTH": 11,
    "PATTERN": 12,
    "PATTERN SIZE": 13,
    "TEXTURES": 14,
    "TRANSPARENCY": 15
  },
  "Track/ Sweat/ Pyjama Pants": {
    "PANT/ JEANS FIT": 1,
    "TRIM/ EDGE/ HEM": 2,
    "COLOR": 3,
    "MULTICOLOR": 4,
    "PATTERN": 5,
    "PATTERN SIZE": 6,
    "TEXTURES": 7,
    "DESIGN/ THEME": 8,
    "UNIQUE SHAPES & DETAILS": 9,
    "SURFACE ADDONS": 10,
    "BOTTOM LENGTH": 11,
    "WAIST STYLING": 12,
    "HEMLINE SHAPE": 13,
    "TRANSPARENCY": 14,
    "DENIM EFFECT": 15
  },
  "Leggings": {
    "PANT/ JEANS FIT": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "PATTERN SIZE": 5,
    "TEXTURES": 6,
    "DESIGN/ THEME": 7,
    "UNIQUE SHAPES & DETAILS": 8,
    "SURFACE ADDONS": 9,
    "BOTTOM LENGTH": 10,
    "WAIST STYLING": 11,
    "HEMLINE SHAPE": 12,
    "TRIM/ EDGE/ HEM": 13,
    "TRANSPARENCY": 14,
    "DENIM EFFECT": 15
  },
  "Shorts": {
    "SHORTS SILHOUETTE": 1,
    "WAIST STYLING": 2,
    "BOTTOM LENGTH": 3,
    "DENIM EFFECT": 4,
    "TRIM/ EDGE/ HEM": 5,
    "DESIGN/ THEME": 6,
    "UNIQUE SHAPES & DETAILS": 7,
    "SURFACE ADDONS": 8,
    "COLOR": 9,
    "MULTICOLOR": 10,
    "PATTERN": 11,
    "PATTERN SIZE": 12,
    "TEXTURES": 13,
    "HEMLINE SHAPE": 14,
    "TRANSPARENCY": 15
  },
  "Skirts": {
    "SKIRT SILHOUETTE": 1,
    "BOTTOM LENGTH": 2,
    "DESIGN/ THEME": 3,
    "UNIQUE SHAPES & DETAILS": 4,
    "SURFACE ADDONS": 5,
    "HEMLINE SHAPE": 6,
    "TRIM/ EDGE/ HEM": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "PATTERN SIZE": 11,
    "TEXTURES": 12,
    "DENIM EFFECT": 13,
    "WAIST STYLING": 14,
    "TRANSPARENCY": 15
  },
  "Skorts": {
    "WAIST STYLING": 1,
    "DESIGN/ THEME": 2,
    "UNIQUE SHAPES & DETAILS": 3,
    "SURFACE ADDONS": 4,
    "COLOR": 5,
    "MULTICOLOR": 6,
    "PATTERN": 7,
    "PATTERN SIZE": 8,
    "TEXTURES": 9,
    "HEMLINE SHAPE": 10,
    "TRIM/ EDGE/ HEM": 11,
    "BOTTOM LENGTH": 12,
    "TRANSPARENCY": 13,
    "DENIM EFFECT": 14
  },
  "Trousers": {
    "PANT/ JEANS FIT": 1,
    "WAIST STYLING": 2,
    "DESIGN/ THEME": 3,
    "UNIQUE SHAPES & DETAILS": 4,
    "SURFACE ADDONS": 5,
    "COLOR": 6,
    "MULTICOLOR": 7,
    "PATTERN": 8,
    "PATTERN SIZE": 9,
    "TEXTURES": 10,
    "HEMLINE SHAPE": 11,
    "TRIM/ EDGE/ HEM": 12,
    "BOTTOM LENGTH": 13,
    "TRANSPARENCY": 14
  },
  "Dresses": {
    "DRESS SILHOUETTE": 1,
    "BOTTOM LENGTH": 2,
    "NECKLINE": 3,
    "CHEST DESIGN": 4,
    "DESIGN/ THEME": 5,
    "UNIQUE SHAPES & DETAILS": 6,
    "SURFACE ADDONS": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "PATTERN SIZE": 11,
    "TEXTURES": 12,
    "TRANSPARENCY": 13,
    "WAIST STYLING": 14,
    "HEMLINE SHAPE": 15,
    "TRIM/ EDGE/ HEM": 16,
    "SLEEVE STYLE": 17,
    "SLEEVE LENGTH": 18,
    "SHOULDER STYLE": 19,
    "FRONT CLOSURE": 20,
    "DENIM EFFECT": 21,
    "BACK": 22
  },
  "Dungaree": {
    "NECKLINE": 1,
    "CHEST DESIGN": 2,
    "FRONT CLOSURE": 3,
    "DESIGN/ THEME": 4,
    "UNIQUE SHAPES & DETAILS": 5,
    "SURFACE ADDONS": 6,
    "COLOR": 7,
    "MULTICOLOR": 8,
    "PATTERN": 9,
    "PATTERN SIZE": 10,
    "TEXTURES": 11,
    "WAIST STYLING": 12,
    "TRANSPARENCY": 13,
    "SLEEVE STYLE": 14,
    "SLEEVE LENGTH": 15,
    "SHOULDER STYLE": 16,
    "TRIM/ EDGE/ HEM": 17,
    "HEMLINE SHAPE": 18,
    "BOTTOM LENGTH": 19,
    "DENIM EFFECT": 20,
    "BACK": 21
  },
  "Jumpsuits": {
    "NECKLINE": 1,
    "CHEST DESIGN": 2,
    "FRONT CLOSURE": 3,
    "DESIGN/ THEME": 4,
    "UNIQUE SHAPES & DETAILS": 5,
    "SURFACE ADDONS": 6,
    "COLOR": 7,
    "MULTICOLOR": 8,
    "PATTERN": 9,
    "PATTERN SIZE": 10,
    "TEXTURES": 11,
    "WAIST STYLING": 12,
    "TRANSPARENCY": 13,
    "SLEEVE STYLE": 14,
    "SLEEVE LENGTH": 15,
    "SHOULDER STYLE": 16,
    "TRIM/ EDGE/ HEM": 17,
    "HEMLINE SHAPE": 18,
    "BOTTOM LENGTH": 19,
    "DENIM EFFECT": 20,
    "BACK": 21
  },
  "Sweaters & Cardigans": {
    "SWEATER & CARDIGAN": 1,
    "TOP LENGTH": 2,
    "FRONT CLOSURE": 3,
    "DESIGN/ THEME": 4,
    "UNIQUE SHAPES & DETAILS": 5,
    "SURFACE ADDONS": 6,
    "NECKLINE": 7,
    "TRIM/ EDGE/ HEM": 8,
    "COLOR": 9,
    "MULTICOLOR": 10,
    "PATTERN": 11,
    "PATTERN SIZE": 12,
    "TEXTURES": 13,
    "WAIST STYLING": 14,
    "HEMLINE SHAPE": 15,
    "SLEEVE STYLE": 16,
    "SLEEVE LENGTH": 17,
    "SHOULDER STYLE": 18,
    "CHEST DESIGN": 19,
    "BACK": 20
  },
  "Coats, Suits, Blazers": {
    "COATS, SUITS TYPE": 1,
    "TOP LENGTH": 2,
    "FRONT CLOSURE": 3,
    "DESIGN/ THEME": 4,
    "UNIQUE SHAPES & DETAILS": 5,
    "SURFACE ADDONS": 6,
    "COLOR": 7,
    "MULTICOLOR": 8,
    "PATTERN": 9,
    "PATTERN SIZE": 10,
    "TEXTURES": 11,
    "NECKLINE": 12,
    "WAIST STYLING": 13,
    "CHEST DESIGN": 14,
    "TRIM/ EDGE/ HEM": 15,
    "HEMLINE SHAPE": 16,
    "SLEEVE STYLE": 17,
    "SLEEVE LENGTH": 18,
    "SHOULDER STYLE": 19,
    "TRANSPARENCY": 20,
    "DENIM EFFECT": 21,
    "BACK": 22
  },
  "Sweatshirts & Hoodies": {
    "DESIGN/ THEME": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "PATTERN SIZE": 5,
    "TEXTURES": 6,
    "UNIQUE SHAPES & DETAILS": 7,
    "SURFACE ADDONS": 8,
    "NECKLINE": 9,
    "TOP LENGTH": 10,
    "TRIM/ EDGE/ HEM": 11,
    "WAIST STYLING": 12,
    "HEMLINE SHAPE": 13,
    "SLEEVE STYLE": 14,
    "SLEEVE LENGTH": 15,
    "SHOULDER STYLE": 16,
    "FRONT CLOSURE": 17,
    "CHEST DESIGN": 18,
    "TRANSPARENCY": 19,
    "DENIM EFFECT": 20,
    "BACK": 21
  },
  "Jackets": {
    "JACKETS": 1,
    "TOP LENGTH": 2,
    "FRONT CLOSURE": 3,
    "DENIM EFFECT": 4,
    "DESIGN/ THEME": 5,
    "UNIQUE SHAPES & DETAILS": 6,
    "SURFACE ADDONS": 7,
    "NECKLINE": 8,
    "COLOR": 9,
    "MULTICOLOR": 10,
    "PATTERN": 11,
    "PATTERN SIZE": 12,
    "TEXTURES": 13,
    "TRIM/ EDGE/ HEM": 14,
    "SLEEVE STYLE": 15,
    "SLEEVE LENGTH": 16,
    "SHOULDER STYLE": 17,
    "WAIST STYLING": 18,
    "HEMLINE SHAPE": 19,
    "CHEST DESIGN": 20,
    "TRANSPARENCY": 21,
    "BACK": 22
  },
  "Other Outerwear": {
    "OTHER OUTERWEAR": 1,
    "DESIGN/ THEME": 2,
    "UNIQUE SHAPES & DETAILS": 3,
    "SURFACE ADDONS": 4,
    "NECKLINE": 5,
    "TOP LENGTH": 6,
    "BOTTOM LENGTH": 7,
    "FRONT CLOSURE": 8,
    "WAIST STYLING": 9,
    "COLOR": 10,
    "MULTICOLOR": 11,
    "PATTERN": 12,
    "PATTERN SIZE": 13,
    "TEXTURES": 14,
    "SLEEVE STYLE": 15,
    "SLEEVE LENGTH": 16,
    "SHOULDER STYLE": 17,
    "HEMLINE SHAPE": 18,
    "TRIM/ EDGE/ HEM": 19,
    "CHEST DESIGN": 20,
    "TRANSPARENCY": 21,
    "DENIM EFFECT": 22
  },
  "Ethinic Wear": {
    "ETHINIC WEAR": 1,
    "KURTA SILHOUETTE": 2,
    "NECKLINE": 3,
    "CHEST DESIGN": 4,
    "TOP LENGTH": 5,
    "BOTTOM LENGTH": 6,
    "DESIGN/ THEME": 7,
    "UNIQUE SHAPES & DETAILS": 8,
    "SURFACE ADDONS": 9,
    "TRANSPARENCY": 10,
    "COLOR": 11,
    "MULTICOLOR": 12,
    "PATTERN": 13,
    "PATTERN SIZE": 14,
    "TEXTURES": 15,
    "SLEEVE STYLE": 16,
    "SLEEVE LENGTH": 17,
    "SHOULDER STYLE": 18,
    "FRONT CLOSURE": 19,
    "WAIST STYLING": 20,
    "HEMLINE SHAPE": 21,
    "TRIM/ EDGE/ HEM": 22
  },
  "Lounge Coords": {
    "CASUAL COORDS": 1,
    "TOPWEAR SILHOUETTE": 2,
    "PANT/ JEANS FIT": 3,
    "SKIRT SILHOUETTE": 4,
    "SHORTS SILHOUETTE": 5,
    "TOP LENGTH": 6,
    "BOTTOM LENGTH": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "PATTERN SIZE": 11,
    "TEXTURES": 12,
    "DESIGN/ THEME": 13,
    "UNIQUE SHAPES & DETAILS": 14,
    "SURFACE ADDONS": 15,
    "NECKLINE": 16,
    "CHEST DESIGN": 17,
    "SLEEVE STYLE": 18,
    "SLEEVE LENGTH": 19,
    "SHOULDER STYLE": 20,
    "FRONT CLOSURE": 21,
    "WAIST STYLING": 22
  },
  "Bags": {
    "BAG SHAPE": 1,
    "BAG TYPE": 2,
    "BAG HANDLE": 3,
    "DESIGN/ THEME": 4,
    "UNIQUE SHAPES & DETAILS": 5,
    "SURFACE ADDONS": 6,
    "COLOR": 7,
    "MULTICOLOR": 8,
    "PATTERN": 9,
    "PATTERN SIZE": 10,
    "TEXTURES": 11,
    "TRIM/ EDGE/ HEM": 12,
    "TRANSPARENCY": 13,
    "DENIM EFFECT": 14
  },
  "Footwear": {
    "FOOTWEAR SILHOUETTE": 1,
    "FOOTWEAR NAME": 2,
    "FOOTWEAR HEEL/ SOLE": 3,
    "TOE SHAPE": 4,
    "DESIGN/ THEME": 5,
    "UNIQUE SHAPES & DETAILS": 6,
    "SURFACE ADDONS": 7,
    "COLOR": 8,
    "MULTICOLOR": 9,
    "PATTERN": 10,
    "TEXTURES": 11,
    "TRIM/ EDGE/ HEM": 12,
    "TRANSPARENCY": 13,
    "DENIM EFFECT": 14
  },
  "Caps & Hats": {
    "CAPS/ HATS": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "TEXTURES": 5
  },
  "Sunglasses": {
    "SUNGLASS FRAME": 1,
    "SUNGLASS LENS": 2,
    "COLOR": 3,
    "MULTICOLOR": 4,
    "PATTERN": 5
  },
  "Lingerie & Swimwear": {
    "COLOR": 1,
    "PATTERN": 2,
    "MULTICOLOR": 3,
    "PATTERN SIZE": 4,
    "TEXTURES": 5,
    "NECKLINE": 6,
    "CHEST DESIGN": 7,
    "DESIGN/ THEME": 8,
    "UNIQUE SHAPES & DETAILS": 9,
    "SURFACE ADDONS": 10,
    "SHOULDER STYLE": 11,
    "FRONT CLOSURE": 12,
    "WAIST STYLING": 13,
    "HEMLINE SHAPE": 14,
    "TRIM/ EDGE/ HEM": 15,
    "TRANSPARENCY": 16,
    "DENIM EFFECT": 17
  },
  "Socks & Stockings": {
    "SOCKS & STOCKINGS": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "TEXTURES": 5,
    "TRANSPARENCY": 6,
    "DESIGN/ THEME": 7,
    "UNIQUE SHAPES & DETAILS": 8,
    "SURFACE ADDONS": 9
  },
  "Glove": {
    "GLOVES": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "TEXTURES": 5,
    "TRANSPARENCY": 6,
    "DESIGN/ THEME": 7,
    "UNIQUE SHAPES & DETAILS": 8,
    "SURFACE ADDONS": 9
  },
  "Belt": {
    "BELTS": 1,
    "COLOR": 2,
    "MULTICOLOR": 3,
    "PATTERN": 4,
    "TEXTURES": 5,
    "DESIGN/ THEME": 6,
    "UNIQUE SHAPES & DETAILS": 7,
    "SURFACE ADDONS": 8
  }
}




export const HeaderSets = {
  "Topwear": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "WAIST STYLING",
      "TRIM/ EDGE/ HEM",
      "HEMLINE SHAPE",
      "BACK"
    ],
    "SET THREE": [
      "NECKLINE",
      "CHEST DESIGN",
      "FRONT CLOSURE"
    ],
    "SET SIX": [
      "SLEEVE STYLE",
      "SHOULDER STYLE",
      "SLEEVE LENGTH"
    ],
    "SET ONE": [
      "TOPWEAR SILHOUETTE",
      "TOP LENGTH"
    ],
    "SET FOUR": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET FIVE": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Tshirts": {
    "SET TWO": [
      "NECKLINE",
      "FRONT CLOSURE",
      "CHEST DESIGN"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET SIX": [
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE"
    ],
    "SET ONE": [
      "TSHIRT SILHOUETTE",
      "TOP LENGTH"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET FIVE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRIM/ EDGE/ HEM",
      "HEMLINE SHAPE",
      "WAIST STYLING",
      "BACK"
    ]
  },
  "Shirts": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "WAIST STYLING",
      "BACK"
    ],
    "SET THREE": [
      "NECKLINE",
      "CHEST DESIGN",
      "SHOULDER STYLE",
      "FRONT CLOSURE"
    ],
    "SET SIX": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET ONE": [
      "SHIRT SILHOUETTE",
      "TOP LENGTH"
    ],
    "SET FOUR": [
      "SLEEVE LENGTH",
      "SLEEVE STYLE"
    ],
    "SET FIVE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ]
  },
  "Jeans": {
    "SET TWO": [
      "WAIST STYLING",
      "DESIGN/ THEME",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "BOTTOM LENGTH",
      "TEXTURES",
      "TRANSPARENCY"
    ],
    "SET ONE": [
      "PANT/ JEANS FIT",
      "DENIM EFFECT"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ]
  },
  "Track/ Sweat/ Pyjama Pants": {
    "SET TWO": [
      "TRIM/ EDGE/ HEM",
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "BOTTOM LENGTH",
      "WAIST STYLING",
      "HEMLINE SHAPE"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "PANT/ JEANS FIT"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Leggings": {
    "SET TWO": [
      "PATTERN SIZE",
      "PATTERN",
      "MULTICOLOR",
      "COLOR"
    ],
    "SET THREE": [
      "TRANSPARENCY",
      "TEXTURES",
      "DENIM EFFECT"
    ],
    "SET ONE": [
      "PANT/ JEANS FIT"
    ],
    "SET FOUR": [
      "WAIST STYLING",
      "UNIQUE SHAPES & DETAILS",
      "TRIM/ EDGE/ HEM",
      "SURFACE ADDONS",
      "HEMLINE SHAPE",
      "DESIGN/ THEME",
      "BOTTOM LENGTH"
    ]
  },
  "Shorts": {
    "SET TWO": [
      "DENIM EFFECT",
      "TEXTURES",
      "TRANSPARENCY"
    ],
    "SET THREE": [
      "TRIM/ EDGE/ HEM",
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "HEMLINE SHAPE"
    ],
    "SET ONE": [
      "SHORTS SILHOUETTE",
      "WAIST STYLING",
      "BOTTOM LENGTH"
    ],
    "SET FOUR": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ]
  },
  "Skirts": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "WAIST STYLING"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "SKIRT SILHOUETTE",
      "BOTTOM LENGTH"
    ],
    "SET FOUR": [
      "TEXTURES",
      "DENIM EFFECT",
      "TRANSPARENCY"
    ]
  },
  "Skorts": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "BOTTOM LENGTH"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Trousers": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "BOTTOM LENGTH"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "PANT/ JEANS FIT",
      "WAIST STYLING"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY"
    ]
  },
  "Dresses": {
    "SET TWO": [
      "NECKLINE",
      "CHEST DESIGN",
      "FRONT CLOSURE"
    ],
    "SET THREE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "BACK"
    ],
    "SET SIX": [
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE"
    ],
    "SET ONE": [
      "DRESS SILHOUETTE",
      "BOTTOM LENGTH"
    ],
    "SET FOUR": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET FIVE": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Dungaree": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "WAIST STYLING",
      "TRIM/ EDGE/ HEM",
      "HEMLINE SHAPE",
      "BOTTOM LENGTH",
      "BACK"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "NECKLINE",
      "CHEST DESIGN",
      "FRONT CLOSURE"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET FIVE": [
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE"
    ]
  },
  "Jumpsuits": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "WAIST STYLING",
      "TRIM/ EDGE/ HEM",
      "HEMLINE SHAPE",
      "BOTTOM LENGTH",
      "BACK"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "NECKLINE",
      "CHEST DESIGN",
      "FRONT CLOSURE"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET FIVE": [
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE"
    ]
  },
  "Sweaters & Cardigans": {
    "SET TWO": [
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRIM/ EDGE/ HEM",
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "CHEST DESIGN",
      "BACK",
      "DESIGN/ THEME",
      "TEXTURES"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "SWEATER & CARDIGAN",
      "TOP LENGTH",
      "FRONT CLOSURE"
    ],
    "SET FOUR": [
      "NECKLINE",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE"
    ]
  },
  "Coats, Suits, Blazers": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "WAIST STYLING",
      "TRIM/ EDGE/ HEM",
      "HEMLINE SHAPE",
      "SHOULDER STYLE",
      "BACK"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET SIX": [
      "SLEEVE STYLE",
      "SLEEVE LENGTH"
    ],
    "SET ONE": [
      "COATS, SUITS TYPE",
      "TOP LENGTH",
      "FRONT CLOSURE"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET FIVE": [
      "NECKLINE",
      "CHEST DESIGN"
    ]
  },
  "Sweatshirts & Hoodies": {
    "SET TWO": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET THREE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ],
    "SET ONE": [
      "TEXTURES",
      "TOP LENGTH",
      "TRIM/ EDGE/ HEM",
      "SHOULDER STYLE"
    ],
    "SET FOUR": [
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "NECKLINE",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "FRONT CLOSURE",
      "CHEST DESIGN",
      "TRANSPARENCY",
      "DENIM EFFECT",
      "BACK"
    ]
  },
  "Jackets": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRIM/ EDGE/ HEM"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "JACKETS",
      "TOP LENGTH",
      "FRONT CLOSURE",
      "TEXTURES"
    ],
    "SET FIVE": [
      "DENIM EFFECT",
      "TRANSPARENCY"
    ],
    "SET FOUR": [
      "NECKLINE",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE",
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "CHEST DESIGN",
      "BACK"
    ]
  },
  "Other Outerwear": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM"
    ],
    "SET THREE": [
      "NECKLINE",
      "FRONT CLOSURE",
      "WAIST STYLING",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE",
      "CHEST DESIGN",
      "BACK"
    ],
    "SET SIX": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ],
    "SET ONE": [
      "OTHER OUTERWEAR"
    ],
    "SET FOUR": [
      "TOP LENGTH",
      "BOTTOM LENGTH"
    ],
    "SET FIVE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ]
  },
  "Ethinic Wear": {
    "SET TWO": [
      "NECKLINE",
      "CHEST DESIGN",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE",
      "FRONT CLOSURE"
    ],
    "SET THREE": [
      "TOP LENGTH",
      "BOTTOM LENGTH"
    ],
    "SET SIX": [
      "TEXTURES"
    ],
    "SET ONE": [
      "ETHINIC WEAR",
      "KURTA SILHOUETTE"
    ],
    "SET FOUR": [
      "WAIST STYLING",
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRANSPARENCY",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM",
      "BACK"
    ],
    "SET FIVE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ]
  },
  "Lounge Coords": {
    "SET TWO": [
      "TOP LENGTH",
      "BOTTOM LENGTH"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE",
      "TEXTURES"
    ],
    "SET SIX": [
      "WAIST STYLING",
      "HEMLINE SHAPE",
      "TRIM/ EDGE/ HEM"
    ],
    "SET ONE": [
      "CASUAL COORDS",
      "TOPWEAR SILHOUETTE",
      "PANT/ JEANS FIT",
      "SKIRT SILHOUETTE",
      "SHORTS SILHOUETTE"
    ],
    "SET FOUR": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRANSPARENCY"
    ],
    "SET FIVE": [
      "NECKLINE",
      "CHEST DESIGN",
      "SLEEVE STYLE",
      "SLEEVE LENGTH",
      "SHOULDER STYLE",
      "FRONT CLOSURE"
    ]
  },
  "Bags": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ],
    "SET THREE": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "BAG SHAPE",
      "BAG TYPE",
      "BAG HANDLE",
      "TEXTURES"
    ],
    "SET FOUR": [
      "TRIM/ EDGE/ HEM",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Footwear": {
    "SET TWO": [
      "FOOTWEAR HEEL/ SOLE",
      "TOE SHAPE"
    ],
    "SET THREE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRIM/ EDGE/ HEM"
    ],
    "SET ONE": [
      "FOOTWEAR SILHOUETTE",
      "FOOTWEAR NAME"
    ],
    "SET FOUR": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN"
    ],
    "SET FIVE": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Caps & Hats": {
    "SET ONE": [
      "CAPS/ HATS",
      "TEXTURES"
    ],
    "SET TWO": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN"
    ]
  },
  "Sunglasses": {
    "SET ONE": [
      "SUNGLASS FRAME"
    ],
    "SET TWO": [
      "SUNGLASS LENS",
      "COLOR",
      "MULTICOLOR",
      "PATTERN"
    ]
  },
  "Lingerie & Swimwear": {
    "SET TWO": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS",
      "TRIM/ EDGE/ HEM",
      "WAIST STYLING",
      "HEMLINE SHAPE"
    ],
    "SET THREE": [
      "COLOR",
      "PATTERN",
      "MULTICOLOR",
      "PATTERN SIZE"
    ],
    "SET ONE": [
      "NECKLINE",
      "CHEST DESIGN",
      "SHOULDER STYLE",
      "FRONT CLOSURE"
    ],
    "SET FOUR": [
      "TEXTURES",
      "TRANSPARENCY",
      "DENIM EFFECT"
    ]
  },
  "Socks & Stockings": {
    "SET ONE": [
      "SOCKS & STOCKINGS"
    ],
    "SET TWO": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN",
      "TEXTURES",
      "TRANSPARENCY"
    ],
    "SET THREE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ]
  },
  "Glove": {
    "SET ONE": [
      "GLOVES",
      "TEXTURES",
      "TRANSPARENCY"
    ],
    "SET TWO": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN"
    ],
    "SET THREE": [
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ]
  },
  "Belt": {
    "SET ONE": [
      "BELTS",
      "TEXTURES",
      "DESIGN/ THEME",
      "UNIQUE SHAPES & DETAILS",
      "SURFACE ADDONS"
    ],
    "SET TWO": [
      "COLOR",
      "MULTICOLOR",
      "PATTERN"
    ]
  }
}

export const SetSorts = {
  'SET ONE': 1,
  'SET TWO': 2,
  'SET THREE': 3,
  'SET FOUR': 4,
  'SET FIVE': 5,
  'SET SIX': 6,
  'SET SEVEN': 7,
  'SET EIGHT': 8
}

export const CategoriesSortHomePage = ["Topwear", "Shirts", "Tshirts", "Jeans", "Shorts", "Skorts", "Skirts", "Trousers", "Dresses",
  "Jumpsuits", "Dungaree",
  "Coats, Suits, Blazers", "Sweatshirts & Hoodies", "Jackets", "Other Outerwear", "Sweaters & Cardigans",
  "Blouse", "Track/ Sweat/ Pyjama Pants", "Kurta", "Ethinic Wear", 'Lounge Coords', "Belt",
  "Bags", "Footwear", "Caps & Hats", "Sunglasses", "Leggings", 'Lingerie & Swimwear', "Socks & Stockings", "Glove"
]

