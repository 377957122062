import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ResponsiveMenu = () => {
  const { userLoggedIn } = useSelector(state => state)
  return (
    <div className='flex flex-col absolute right-0 top-11 rounded-b-md w-screen items-right bg-gray-900 max-[350px]:top-9 z-50 min-[751px]:hidden text-center'>
      {!userLoggedIn.curator && <Link className='mr-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/saved'>My Shortlist</Link>}
      {userLoggedIn.curator && <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/deleted-trend'>Deleted Trends</Link>}
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/'>Leaderboard</Link>
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/social-trends'>Social Trends</Link>
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/retailer-trends'>Retailer Trends</Link>
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/competitor-analysis'>Competitor Analysis</Link>
      {/* {userLoggedIn.curator && <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/design'>Design Trends</Link>} */}
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/trend-graph'>Occurrence Graph</Link>
      <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/chatter-graph'>Chatter Graph</Link>
      {/* <Link className='mx-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/products-search'>Products</Link> */}
      <Link className='ml-1 px-1 text-lg font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/raw-images'>Raw Images</Link>
    </div>
  )
}

export default ResponsiveMenu
