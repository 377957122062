import React, { Suspense, useState } from 'react'
import moment from "moment"
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Blocks } from 'react-loader-spinner'
import SimilarProductsPopup from './SimilarProductspopup'

const TrendImage = ({ image, selectedImage, setSelectedImage, uVal, setFetch, category, gender }) => {
  const { userLoggedIn } = useSelector(state => state)
  const [imageSaved, setImageSaved] = useState(image.isSaved)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [showSimilarProductsPopup, setSimilarProductsPopup] = useState(false)
  const [queryImageData, setQueryImageData] = useState({});
  const locationSaved = window.location.pathname === '/saved'
  const locationDeleted = window.location.pathname === '/deleted-trend'
  const locationGraph = window.location.pathname === '/trend-graph'
  const handleDeleteAnImage = () => {
    const response = window.confirm(`Do You really want to delete image with id ${image.mediaId} in trend with id ${uVal}`)
    if (response) {
      axios.delete(`/trend/delete-image/`, {
        data: { mediaId: image.mediaId, pk: uVal }
      })
        .then(res => {
          if (res.data === "deleted") {
            setFetch(prev => !prev)
          }
          console.log(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const isSelected = () => {
    for (let i = 0; i < selectedImage.length; i++) {
      if (selectedImage[i].pk === uVal) {
        for (let j = 0; j < selectedImage[i].mediaId.length; j++) {
          if (selectedImage[i].mediaId[j] === image.mediaId) {
            return true
          }
        }
      }
    }
    return false
  }

  const updateSavedData = async (type, dataId) => {
    try {
      if (!imageSaved)
        axios.put(`/save/${type}/${dataId}`)
      else
        axios.delete(`/save/${type}/${dataId}`)
      setImageSaved(!imageSaved)
    } catch (e) {
      console.error(e)
    }
  }

  const handleChange = () => {
    let data = [...selectedImage]
    let ifUvalFound = false
    for (let i = 0; i < data.length; i++) {
      if (data[i].pk === uVal) {
        ifUvalFound = true
        let mIdFound = false
        let d1 = []
        for (let j = 0; j < data[i].mediaId.length; j++) {
          if (data[i].mediaId[j] === image.mediaId) {
            mIdFound = true
          }
          else {
            d1.push(data[i].mediaId[j])
          }
        }
        if (!mIdFound) {
          d1.push(image.mediaId)
        }
        if (d1.length === 0) {
          data.splice(i, 1)
        }
        else {
          data[i].mediaId = d1
        }
        break
      }
    }
    if (!ifUvalFound) {
      setSelectedImage([...data, { pk: uVal, mediaId: [image.mediaId] }])
    }
    else {
      setSelectedImage([...data])
    }
  }
  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  const queryProductsForImage = (mediaId, imageUrl) => {
    setQueryImageData({
      imageUrl,
      mediaId,
      category
    })
    setSimilarProductsPopup(true);
  }

  const hideSimilarProductsPopup = () => {
    setQueryImageData({})
    setSimilarProductsPopup(false);
  }

  return (
    <div className='mr-10 max-[500px]:mr-1 min-w-max text-center'>
      <div className='flex mb-4 flex-col items-end min-h-80' style={{ minHeight: '19.5rem' }}>
        <div className="relative">
          <img className='-mb-8 h-80'
            src={image.imageUrl}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={onImageLoaded}
            onError={(err) => { console.log('error', err) }}
            alt="name"
          />
          {
            !imageLoaded && <div className='mx-5 h-80 w-52 flex items-center justify-center'>
              <Blocks
                visible={true}
                height="50"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            </div>
          }
          {
            !locationDeleted && <button className="absolute top-1 right-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { updateSavedData('image', image.mediaId) }}>{imageSaved ? 'Remove' : 'Save'}</button>
          }
          {gender == "f" && <button className="absolute top-1 left-1 px-2 py-1 text-white bg-blue-500 rounded-xl" onClick={() => { queryProductsForImage(image.mediaId, image.imageUrl) }}>Find Similar</button>}
        </div>
        {
          userLoggedIn.curator && <div className=' flex items-center opacity-40 rounded-full p-1 text-black mr-2'>
            {!locationSaved && !locationGraph && <div className='mx-2'>
              <input type="checkbox" name="todelete" onChange={handleChange} checked={isSelected()} />
            </div>}
            <div className='cursor-pointer' onClick={handleDeleteAnImage} >
              <RiDeleteBinLine />
            </div>
          </div>
        }
      </div>
      {<a className='mx-2 flex justify-evenly items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
        <div>
          <div className='flex items-center'>{parseInt(image.noOfLikes) <= 3 ? 'Hidden' : image.noOfLikes}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></div>
        </div>
        <div>
          <div className='flex items-center'>{image.username}<span className='text-xl'></span></div>
        </div>
        <div>
          <div className='flex items-center'>{image.noOfComments}<span className='text-xl'><BiCommentDots /></span></div>
        </div>
      </a>}
      <p>{moment(image.timeStamp).format("DD MMM YYYY")}</p>
      {image.likes && <p>({image.likes}) Likes</p>}
      {
        showSimilarProductsPopup && <>
          <SimilarProductsPopup showSimilarProductsPopup={showSimilarProductsPopup} queryImageData={queryImageData} hideSimilarProductsPopup={hideSimilarProductsPopup} />
        </>
      }
    </div>
  )
}

export default TrendImage