

const DisplayProducts = ({ products }) => {


  return <div className="m-2 flex flex-row flex-wrap">
    {products.map((product) => {
      return <a href={product.postUrl} target='_blank' className="my-1 hover:shadow w-1/4 p-2 shrink-0 ">
        <div className="justify-center w-4/5 h-60 ">
          <div></div>
          <img src={product.imageUrl} alt={product.mediaId} className='h-full' />
        </div>
      </a>
    })}
  </div>
}

export default DisplayProducts;
