import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { FiMenu } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import ResponsiveMenu from './ResponsiveMenu';
import CategoryDisplay from '../pages/SocialTrendsTags/CategoryDisplay';
import CategoryDisplayWrapper from '../pages/SocialTrendsTags/CategoryDisplayWrapper';
const TopBar = () => {
  const { userLoggedIn } = useSelector(state => state)
  const [showMenu, setShowMenu] = useState(false)
  const handleMenu = () => {
    setShowMenu(!showMenu)
  }
  const route = useLocation().pathname
  useEffect(() => {
    setShowMenu(false)
  }, [route])
  if (userLoggedIn.updater) {
    return <div></div>
  }
  else
    return (
      <div className='flex pl-4 max-[750px]:pl-1 py-1 bg-gray-900 items-center justify-between text-white sticky top-0 z-20'>
        <Link to={'/'}><p className='text-2xl py-1 max-[350px]:text-lg font-extrabold font-serif'>{userLoggedIn && userLoggedIn.clientName == 'AJIO' ? <img src='/reliance-retail-logo.jpeg' className='h-11 pt-2 relative bottom-2 inline-block mx-2' />
          : userLoggedIn && userLoggedIn.clientName == 'FASHINZAJUNE' ? null : <img src='/fastfashion-live-logo.png' className='h-full w-40' />
        }
        </p></Link>
        {/* <p className='text-2xl font-serif'>Fashion Trend Forecast</p> */}
        <div className='h-full flex items-center pr-1 max-[750px]:hidden '>
          {/*
          <div className='group mx-1 px-1 text-sm font-serif cursor-pointer hover:bg-slate-700 rounded-lg text-[#c1dd9b]' >Women Trends <CategoryDisplayWrapper left={'left-0'}><CategoryDisplay gender={'Female'} /></CategoryDisplayWrapper></div>
          <div className='group mx-1 px-1 text-sm font-serif cursor-pointer hover:bg-slate-700 rounded-lg text-[#c1dd9b]' >Men Trends<CategoryDisplayWrapper left={'left-0'}><CategoryDisplay gender={'Male'} /></CategoryDisplayWrapper></div>
          */}
          <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/saved-images' >My Collections</Link>
          {userLoggedIn.curator && <Link className='mx-1 px-1 text-xs font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/deleted-trend'>Deleted Trends</Link>}
          <Link className='mx-1 px-1 text-xs font-serif cursor-pointer hover:bg-slate-700 rounded-lg text-[#ff9c6b]' to='/social-trends'>Social Trends V1</Link>
          {false && <Link className='mx-1 px-1 text-xs font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/color-and-pattern-trends'>Color & Pattern Trends</Link>}
          {/*           <Link className='mx-1 px-1 text-xs font-serif cursor-pointer hover:bg-slate-700 rounded-lg text-[#ff84cc]' to='/designer-features'>Brand Trends</Link>
              <Link className='mr-1 px-1 text-xs font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/saved'>My Shortlist</Link>
          <Link className='mx-1 px-1 text-sm font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/retailer-trends'>Retailer Trends</Link> */}
          {       /*  <div className='mx-1 px-1 text-xs font-serif relative  cursor-pointer h-full dropdown'>
            <span className='dropbtn'>Competitor Analysis</span>
            <div className="dropdown-content w-full bg-gray-900 ">
              <Link to='/competitor-analysis?ecommerce=Zara+India' className='my-3 text-sm font-serif cursor-pointer bg-gray-900 w-full hover:bg-slate-700 text-center' >Zara</Link>
              <Link to='/competitor-analysis?ecommerce=Urbanic' className='my-3 text-sm font-serif cursor-pointer bg-gray-900 w-full hover:bg-slate-700 text-center' >Urbanic</Link>
              <Link to='/competitor-analysis?ecommerce=Myntra' className='my-3 text-sm font-serif cursor-pointer bg-gray-900 w-full hover:bg-slate-700 text-center' >Myntra</Link>
              <Link to='/competitor-analysis?ecommerce=Shein' className='my-3 text-sm font-serif cursor-pointer bg-gray-900 w-full hover:bg-slate-700 text-center' >Shein</Link>
              <Link to='/competitor-analysis?ecommerce=NewMe' className='my-3 text-sm font-serif cursor-pointer bg-gray-900 w-full hover:bg-slate-700 text-center' >NewMe</Link>
            </div>
          </div>
 */       }
          <div className='mx-1 mr-2 px-1  text-sm font-serif relative  cursor-pointer h-full dropdown '>
            <div className='dropbtn w-full pl-10'>More</div>
            <div className="dropdown-content bg-gray-900 px-3 pb-10 w-48 -right-10 ">
              {/*
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/leaderboard'>Leaderboard</Link>
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/saved-images'>Saved Images</Link>
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/trend-graph'>Occurrence Graph</Link>
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/social-analysis'>Social Analysis</Link>
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/chatter-graph'>Chatter Graph</Link>
              */
              }
              <Link className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' to='/raw-images'>Raw Images</Link>
              <a className='mx-1 py-1 px-2 text-sm font-serif cursor-pointer hover:bg-slate-700 ' href='https://docs.google.com/forms/d/e/1FAIpQLSd_1DS1sFbQMRdgm7-eloqSqsh09m4IgH1aA2a9Y8jzfEt0rQ/viewform?usp=sf_link' target='_blank'>Feedback</a>
            </div>
          </div>
          {/* {userLoggedIn.curator && <Link className='mx-1 px-1 text-sm font-serif cursor-pointer hover:bg-slate-700 rounded-lg' to='/design'>Design Trends</Link>} */}
        </div>
        <div className='text-2xl max-[350px]:text-sm border rounded-md border-gray-500 p-1 mr-1 min-[751px]:hidden' onClick={handleMenu}>
          {showMenu ? <AiOutlineClose /> : <FiMenu />}
        </div>
        {showMenu && <ResponsiveMenu />}
      </div >
    )
}

export default TopBar
