import axios from "axios";
import { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import SocialV2Curator from './SocialV2Curator';

function CuratorData() {

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('APIFY');
  const { userLoggedIn } = useSelector(state => state)
  /*
   *
Date = 6th Nov 2023
Total raw images added =
Count of handles for which raw images came today = 
Trends queried today =
Count of new images added to existing/new trends = 
New trends made today =
   * */

  const getPageData = () => {
    const limit = 20
    setPageData([])
    setLoading(true)
    axios.get(`/curator-api/daily-data?page=${page}&limit=${limit}`).then(response => {
      setPageData(response.data)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getPageData();
  }, [page])



  return <div className="w-full p-4">
    <div className="flex justify-center border-b">
      <button className="border rounded px-4 py-1 mx-1" onClick={() => { setTab('APIFY') }}>Apify Data</button>
      <button className="border rounded px-4 py-1 mx-1" onClick={() => { setTab('V2') }}>Tags Data</button>
    </div>
    {
      tab == "APIFY" ? <>
        <div className="w-full m-4 float-left">
          <div className="float-right mr-10 flex flex-row">
            <button className="primary-btn m-2" disabled={page == 1} onClick={() => { setPage(page => { return page - 1 }) }}>prev</button>
            <p className="my-2 py-1 font-bold">{page}</p>
            <button className="primary-btn m-2" onClick={() => { setPage(page => { return page + 1 }) }}>next</button>
          </div>
        </div>
        {loading
          ? <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"><TailSpin /></div>
          : <table className="table-auto w-full my-6 bg-slate-100 p-10 border-2 border-slate-300">
            <thead className="p-2 h-12 border-b-2 border-slate-300">
              <tr>
                <th>Date</th>
                <th>Images Added</th>
                <th>Count of handles</th>
                <th>Trends queried</th>
                <th>Images used</th>
                <th>New trends</th>
              </tr>
            </thead>
            <tbody>
              {pageData.map((row, index) => <tr key={index} className="h-12 ">
                <td className="text-center">{row.date}</td>
                <td className="text-center">{row.raw}</td>
                <td className="text-center">{row.handles}</td>
                <td className="text-center">{row.queries}</td>
                <td className="text-center">{row.imagesUsed}</td>
                <td className="text-center">{row.newTrends}</td>
              </tr>)}
            </tbody>
          </table>}
      </> :
        <SocialV2Curator />
    }
  </div>
}


export default CuratorData
