import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SentenceCase } from "../utility/helper_functions";
import Track from "./Track";
import TrackGraph from "./TrackGraph";
const TrendTrack = ({ trend }) => {

  const userLoggedIn = useSelector(state => state.userLoggedIn)
  const initialState = {
    lifeCycle: [
      { value: '', title: '-5 Week' },
      { value: '', title: '-4 Week' },
      { value: '', title: '-3 Week' },
      { value: '', title: '-2 Week' },
      { value: '', title: '-1 Week' },
      { value: '', title: 'Current Week' },
    ],
    indianLifeCycle: [
      { value: '', title: '-5 Week' },
      { value: '', title: '-4 Week' },
      { value: '', title: '-3 Week' },
      { value: '', title: '-2 Week' },
      { value: '', title: '-1 Week' },
      { value: '', title: 'Current Week' },
    ]
  }
  const weekNames = ['-5 Week', '-4 Week', '-3 Week', '-2 Week', '-1 Week', 'Current Week']
  const [trendLifeCycles, setTrendLifeCycles] = useState(initialState)

  const fetchData = () => {
    axios.get(`/leaderboard-api/track-data/${trend._id}`).then(response => {
      setTrendLifeCycles(response.data.trendCycle)
    })

  }
  useEffect(() => {
    fetchData();
  }, [trend])

  return <div className="w-[80vw] h-[80vh] overflow-auto flex flex-col">
    <h1 className="text-center text-3xl font-medium mb-8">{SentenceCase(trend?.name)}</h1>
    <section className="py-4 wrap float-left w-full ">
      <h2 className="text-center text-xl font-medium mb-4">Global Trend Lifecycle Track</h2>
      <div className="w-full  mt-2">

        {/* <Track data={trendLifeCycles.lifeCycle} />*/}
      </div>
      <TrackGraph data={trendLifeCycles.lifeCycle} />
      <hr className="mb-4 mt-2" />
    </section>
    <section className="py-4">
      <h2 className="text-center text-xl font-medium mb-4">Trend waterfall to India Track</h2>
      <div className="w-full  mt-5">

        {/*        <Track data={trendLifeCycles.indianLifeCycle} />*/}
      </div>
      <TrackGraph data={trendLifeCycles.indianLifeCycle} type='waterfall' />
      <hr />
    </section>
  </div>
}
export default TrendTrack;
