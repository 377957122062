import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Trend from '../trendDisplayPage/Trend';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';

const DeletedTrends = () => {
    const navigate = useNavigate();
    const [searchParmas, setSearchParms] = useSearchParams()

    const iniFormData = {
        gender: searchParmas.get('gender') || 'f',
        category: searchParmas.get('category') || 'All Trends'
    }
    const { userLoggedIn } = useSelector(state => state)
    const limit = 50
    const [page, setPage] = useState(searchParmas.get('page') || 1);
    const [totalTrends, setTotalTrends] = useState(0);
    const [toMerge, setToMerge] = useState([])
    const [fetch, setFetch] = useState(false)
    const [selectedImage, setSelectedImage] = useState([])
    const [trendsData, setTrendsData] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const [formdata, setFormData] = useState(iniFormData)
    const trendCategory = formdata.gender === 'f' ? [
        "All Trends",
        "Topwear Trends",
        "Dress Trends",
        "Jeans & Trouser Trends",
        "Skirt Trends",
        "Jumpsuit Trends",
        "Trendy Shorts",
        "Outerwear Trends",
        "Trendy Bags",
        "Trendy Footwear",
    ] : [
        "All Trends",
        "Topwear Trends",
        "Trendy Accessories",
        "Trendy Bags",
        "Bottomwear Trends",
        "Trendy Footwear",
        "Outerwear Trends",
    ]
    useEffect(() => {
        if (!userLoggedIn.loggedIn && (userLoggedIn.admin || userLoggedIn.curator)) {
            navigate('/login');
        }
        else {
            getDeletedTrends(page, formdata.gender, formdata.category)
        }
    }, [fetch])
    const getDeletedTrends = (page, gender, category) => {
        setShowLoader(true)
        axios.get(`/trend/deleted-trends?page=${page}&limit=${limit}&gender=${gender}&category=${category}`)
            .then(res => {
                setTrendsData(res.data.data)
                setTotalTrends(res.data.count)
            })
            .catch(err => {
                console.log(err)
            })
        setShowLoader(false)

    }
    const decreasePage = () => {
        setPage(prev => prev - 1)
        getDeletedTrends(page - 1, formdata.gender, formdata.category)
        setSearchParms({ 'page': page - 1, gender: formdata.gender })
    }
    const increasePage = () => {
        setPage(prev => prev + 1)
        getDeletedTrends(page + 1, formdata.gender, formdata.category)
        setSearchParms({ 'page': page + 1, gender: formdata.gender })
    }
    const fillForm = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({ ...prev, [name]: value }))
        if (name === 'gender') {
            setFormData(prev => ({ ...prev, category: 'All Trends' }))
            getDeletedTrends(page, value, 'All Trends')
            setSearchParms({ 'page': page, ...formdata, [name]: value })
        }
        else {
            getDeletedTrends(page, formdata.gender, value)
            setSearchParms({ 'page': page, ...formdata, [name]: value })
        }
    }
    if (showLoader) {
        return (
            <div className=' h-screen w-screen flex justify-center items-center'>
                <TailSpin
                    height="80"
                    width="80"
                    color="grey"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible="true"
                />
            </div>
        )
    }
    return (
        <div className='mx-4'>
            <div className='flex justify-between items-center mt-1 sticky top-10 z-50 bg-white'>
                <div>
                    <select className='mr-2 p-2 rounded-md outline-none text-center' value={formdata.gender} onChange={fillForm} name="gender" >
                        <option value="" hidden>--select Gender--</option>
                        <option value="m">Male</option>
                        <option value="f">Female</option>
                    </select>
                    <select className='mx-2 p-2 rounded-md outline-none text-center' value={formdata.category} onChange={fillForm} name="category">
                        <option value="" hidden>--Product Categoty--</option>
                        {
                            trendCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                        }
                    </select>
                </div>
                <div className='mr-2 flex items-center'>
                    <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-3 mx-2 rounded-3xl' disabled={page <= 1} onClick={decreasePage}>prev</button>
                    <span className='mx-2'>{page}</span>
                    <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-3 ml-2 rounded-3xl' disabled={page >= Math.ceil(totalTrends / limit)} onClick={increasePage}>next</button>
                    <div className='ml-2'>{totalTrends} Trends </div>
                </div>
            </div>
            {trendsData.length>0?trendsData.map(trend => <Trend toMerge={toMerge} fetch={fetch} setFetch={setFetch} setSelectedImage={setSelectedImage} selectedImage={selectedImage} setToMerge={setToMerge} trend={trend} key={trend.id} />):<div>No trend found</div>
            }
        </div>
    )
}

export default DeletedTrends