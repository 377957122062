import axios from 'axios'
import { useEffect, useState } from 'react'
import ModalImage from 'react-modal-image'
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { AiTwotoneHeart } from "react-icons/ai";
import { useSelector } from 'react-redux';
import Loading from '../../component/Loading';

const ProductsPopup = ({ gender, category, tagName, tagValue, rank, brandsString, month, filteredTagsUrl }) => {
  const [page, setPage] = useState(1)
  const limit = 50
  const { userLoggedIn } = useSelector(state => state)
  const [products, setProducts] = useState([])
  const [count, setCount] = useState(1)
  const [imageListModal, setImageListModal] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const getProducts = () => {
    setProducts([])
    setLoading(true)
    axios.get(`/meta-trend-api/designer-feature-products?gender=${gender}&category=${category}&page=${page}&limit=${limit}&tagName=${tagName}&tagValue=${tagValue}&rank=${rank}${brandsString}&month=${month}${filteredTagsUrl}`).then(response => {
      setProducts(response.data.products);
      setCount(response.data.count);
    }).finally(() => {
      setLoading(false)
    })
  }

  const setImagesForProductId = (productEvent) => {
    const productId = productEvent.target.id
    const product = products.filter(prod => prod._id === productId)[0]
    if (!product)
      return;
    setImageListModal([product.imageUrl])
    setPhotoIndex(0)
  }


  useEffect(getProducts, [page])
  return <div className='w-[80vw] h-[80vh] flex flex-col'>
    <h3 className='text-center text-2xl font-medium'>{tagName} - {tagValue} Images</h3>
    <div className="flex flex-row sticky bg-white  top-0 w-full flex-wrap ">
      <div className="px-6 w-3/4 shrink-0">

      </div>
      <div className="ml-auto pr-6 w-1/4 shrink-0">
        <button className="primary-btn-padding" onClick={() => setPage(p => p - 1)} disabled={page <= 1}>Prev</button>
        {count} Images
        <button className="primary-btn-padding" onClick={() => setPage(p => p + 1)} disabled={page >= count / limit}>Next</button>
      </div>
    </div>
    <Loading loading={loading} >
      <div className="flex flex-row bg-white  top-0 w-full flex-wrap overflow-y-auto ">
        {
          products.map((prod, i) => {
            return <div key={i} className="align-bottom w-60 shrink-0 h-96 py-4 mx-1 hover:shadow" >
              <div className='h-3/5 w-full'>
                <img className='h-[13rem]' src={prod.imageUrl} onClick={setImagesForProductId} id={prod._id} />
              </div>

              <div className="h-2/5 pl-4 border-t-2 border-t-slate-200 border-t-w-">
                <div className='flex items-center'>{parseInt(prod.like_count) <= 3 ? 'Hidden' : prod.like_count}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></div>
                <p>{new Date(prod.timeStamp).toLocaleString()}</p>
                {
                  userLoggedIn.curator && <a className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' href={`/mediaId/${prod.mediaId}`} target='_blank'>Image Data</a>
                }
              </div>
            </div>
          })}
      </div>
    </Loading>

    <Lightbox
      open={imageListModal.length > 0}
      close={() => { setImageListModal([]) }}
      slides={imageListModal.map(img => ({ src: img }))}
      styles={{ container: { backgroundColor: "rgba(0, 0, 0, .7)" } }}
      plugins={[Thumbnails]}
    />
  </div>

}

export default ProductsPopup;
