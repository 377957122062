import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrEdit } from 'react-icons/gr'
import TrendImage from '../trendDisplayPage/TrendImage'
import axios from 'axios'
import Graph from '../trendDisplayPage/Graph'
import Popup from '../../component/Popup'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { FaImages } from 'react-icons/fa'
import TopCommentsPosts from '../../component/TopCommentsPosts'
import { useSelector } from 'react-redux'

const Trend = ({ trend, fetch, setFetch }) => {
	const { userLoggedIn } = useSelector(state => state)
  const deleteTrend = (id) => {
    const response = window.confirm(`Do You really want to delete trend with id ${id}`)
    if (response) {
      axios.delete(`/trend/${id}`)
        .then(res => {
          console.log(res.data)
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
      console.log("deleted trend with id", id);
    }
  }
  const changeName = () => {
    const newName = prompt("Set new trend name", trend.name)
    if (newName && newName.trim() && newName !== trend.name) {
      axios.patch(`/trend/${trend.pk}`, { nameToChange: newName })
        .then(res => {
          setFetch(!fetch)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const [showGraph, setShowGraph] = useState(false)
  const toogleGraph = () => {
    setShowGraph(!showGraph)
  }

  const images = [...trend.similarCategoryImages].reverse();
	const [showTrendComments, setShowTrendComments] = useState(false)
	const [commentsPopupTrendImages, setCommentsPopupTrendImages] = useState([])
    
	const handleShowTrendComments = () =>{
		setCommentsPopupTrendImages(images)
		setShowTrendComments(true)
	    }

  return (
    <div className=' my-10'>
      <div className='flex justify-between items-center my-5'>
        <div className='flex items-center'>
          <p className='mx-4'>Category: <span className='font-bold'>{trend.searchedCategory}</span></p>
          <p className='flex justify-center items-center cursor-pointer mx-4' onClick={toogleGraph}>Graph<img className='h-7' src="./growth.png" alt="growth" /></p>
          <p className='mx-4 flex items-center'>{trend.similarCategoryImages.length} <span className='text-xl'> <FaImages /></span></p>
          <p className='mx-4 flex items-center'>{trend.totalLikes} <span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></p>
          <p className='mx-4 flex items-center'>{trend.totalComments}<span className='text-xl'><BiCommentDots /></span></p>
          <p className='flex justify-center items-center cursor-pointer mx-4' onClick={changeName}>Trend Name: <span className='ml-1'>{trend.name ?trend.name.slice(0,1).toUpperCase()+ trend.name.slice(1).toLowerCase():""}</span> <span><GrEdit /></span></p>
          {/* <p className='flex justify-center items-center cursor-pointer mx-4' onClick={() => { deleteTrend(trend.id) }}>Delete Trend <span className=' text-red-700'><RiDeleteBinLine /></span></p> */}
          {/* <p className='mx-4'>Trend Id: {trend.pk}</p> */}
	    {
				userLoggedIn.curator && <button className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={handleShowTrendComments}>Top Comments</button>
		}
        </div>
      </div>
      <Popup isPopUp={showGraph} setIsPopUp={setShowGraph}>
        <Graph graphData={trend.graphData} showDates={trend.showDates} />
      </Popup>
	<Popup isPopUp={showTrendComments} setIsPopUp={setShowTrendComments}>
		<TopCommentsPosts images={commentsPopupTrendImages} category={trend.searchedCategory}/>
	</Popup>
      <div className='flex overflow-auto'>
        {
          images.map((image, index) => <TrendImage setFetch={setFetch} uVal={trend.pk} image={image} key={index} />)
        }
      </div>
    </div>
  )
}

export default Trend