import { useEffect, useState } from "react"
import axios from 'axios'

export default  ({trend})=>{

	const [productUrls, setProductUrls] = useState([]);
	const [newProductsText, setNewProductsText ] = useState('')
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [ApifyID, setApifyID] = useState("")
	const [serverApifyId, setServerApifyId] = useState("")
	const getProductIds = ()=>{
		axios.get(`/curator-api/new-product-ids/${trend.id}`).then(res=>{
			setProductUrls(res.data)
		}).catch(console.log)
	}

	const postProductIds = ()=>{
		setSubmitDisabled(true)
		setNewProductsText('')
		axios.post(`/curator-api/new-product-ids/${trend.id}`,{productText:newProductsText}).then(res=>{
			getProductIds()
		}).catch(console.log).finally(()=>{
			setSubmitDisabled(false)
		})
	}

	const deleteProductIds = (productUrl)=>{
		axios.post(`/curator-api/delete-product-ids/${trend.id}`,{productUrl}).then(res=>{
			getProductIds()
		}).catch(console.log).finally(()=>{
		})
	}

	const postApifyID = () =>{
		axios.post(`/curator-api/apifyId/${trend.id}`,{ apifyId :ApifyID }).then((response)=>{
			getApifyId();
		}).catch(console.log)
	}

	const getApifyId = ()=>{
		axios.get(`/curator-api/apifyId/${trend.id}`).then((response)=>{
			setServerApifyId(response.data)
		}).catch(console.log)
	}

	const downloadProductsData = async ()=>{
		let csvString = ''
		for(let prod of productUrls){
			csvString = csvString+`${prod}\n`
		}
		const link = document.createElement("a");
		const file = new Blob([csvString], { type: 'text/plain;encoding:utf-8' });
		link.href = URL.createObjectURL(file);
		link.download = `${trend.id}_${trend.category}.txt`;
		link.click();
		URL.revokeObjectURL(link.href);
	}

	useEffect(()=>{
		getProductIds();
		getApifyId();
	},[])

	return  (
		<div className='self center w-[75vw] m-5  h-[65vh] px-5'>
			<h1 className='text-center font-medium text-lg mb-10'>ProductIds</h1>
			{productUrls.length>0 && <div className="w-full m-4 p-3  rounded-lg">
				{productUrls.map((prod,i)=>{
					return <>
						<p className="h-8 mt-2">
							<span className="mr-8 font-semibold">{i+1}</span>
							<a href={prod} target="_blank">{prod}</a>
							<span className="float-right">
								<button className="px-3 mx-2 rounded-md bg-slate-500 text-white active:bg-slate-700" onClick={() => window.navigator.clipboard.writeText(prod)}>COPY</button>
								<button className="px-3 mx-2 rounded-md bg-red-500 text-white" onClick={()=> deleteProductIds(prod) }>DELETE</button>
							</span>
						</p>
						<hr />
					</>
				})}
			</div>}

			<textarea value={ newProductsText} onChange={(e)=>{
				setNewProductsText(e.target.value);
			}}  className="bg-slate-100 p-3 font-thin text-xs outline-none px-6 border-2 border-slate-300  rounded-2xl w-full h-[20vh] resize-none"
				placeholder="product urls"
			/>
			<div className="relative h-20"><div className="absolute translate-x-[-50%] bottom-0 left-1/2">
				<button className=" rounded-lg border-2 border-slate-600 px-10 py-4 disabled:bg-slate-500 bg-slate-500 font-bold text-white m-3" onClick={postProductIds} disabled={submitDisabled}>Add urls</button>
				<button className=" rounded-lg border-2 border-slate-600 px-10 py-4 disabled:bg-slate-500 bg-slate-500 font-bold text-white m-3" onClick={downloadProductsData}>Download Text File</button>		
			</div></div>
			<p>Apify Id : <span className="bold underline px-2">{serverApifyId}</span></p>
			<input type="text" value={ApifyID} className="border rounded-md outline-none bg-slate-100 px-5 py-3 m-5" onChange={(e)=>{ setApifyID(e.target.value) }}/>
			<div className="relative h-20"><button className="absolute translate-x-[-50%] bottom-0 left-1/2 rounded-lg border-2 border-slate-600 px-10 py-4 disabled:bg-slate-500 bg-slate-500 font-bold text-white" onClick={postApifyID}>Update Apify Id</button></div>
			

		</div>
	)

}