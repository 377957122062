import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { CartesianGrid, Label, LabelList, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";





const TrackGraph = ({ data, type = 'lifecycle' }) => {

  const { userLoggedIn } = useSelector(state => state)
  const [name, setName] = useState('Born')
  const [value, setValue] = useState(0)
  const [graphData, setGraphData] = useState(0)

  const [stepValues, setStepValues] = useState({

    start: 1,
    Born: 1,
    Dead: 0,
    'Rising Fast': 3,
    'Past Peak': -1,
    'Approaching Peak': 1,
    'Steadily Rising': 2,
    Decaying: -2,
    Reviving: 1,
    'Globally Reviving': 1,
    Surviving: 0,
  })

  const [stepWaterfallValues, setWaterfallStepValues] = useState({
    start: 1,
    'Low impact in India': 0,
    'Mid impact in India': 2,
    'Mid-high impact in India': 3,
    'High impact in India': 4,
    'Very high impact in India': 5,
  })
  const updateGraphData = () => {
    let startValue = stepValues['start'];
    if (data[0].value == 'Dead')
      startValue = 0
    const newGraphData = [];
    for (let weekData of data) {
      //      if (weekData.value == 'Born' || weekData.value == 'Dead') {
      //        startValue = 0
      //      } else {
      startValue = startValue + (type == 'lifecycle' ? stepValues[weekData.value] : stepWaterfallValues[weekData.value])
      //      }

      newGraphData.push({
        title: weekData.title,
        value: startValue
      })


    }

    setGraphData(newGraphData)
  }
  useEffect(() => {

    updateGraphData();
  }, [data, stepValues])

  const updateValue = () => {

    setStepValues(prev => {
      const newValues = { ...prev };
      newValues[name] = parseInt(value)
      return newValues
    })

  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && label) {
      return (
        <div className="custom-tooltip">
          <p className="text-xs">{`${data.filter(week => week.title == label)[0].value}`}</p>
          <div>
            {payload.map((pld) => (
              <div style={{ display: "inline-block", padding: 10 }}>
                <div style={{ color: pld.fill }}>{pld.value}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };
  return <div className="w-[800px] m-auto  py-[20px]">
    {userLoggedIn.curator && <div>
      <div className="flex ">
        <table className="w-56 border-2 border-slate-600">
          <thead className="">
            <th className="w-40 border border-slate-700">Name</th><th className="w-10 border border-slate-700">Value</th>
          </thead>
          <tbody>
            {Object.keys(stepValues).sort().map(k => <tr>
              <td className="border border-slate-300">{k}</td><td className="border border-slate-300">{stepValues[k]}</td>
            </tr>)}
          </tbody>
        </table>
        <div className="w-80 shrink-0 flex flex-col px-20">
          <select className="h-10 m-2" value={name} onChange={e => { setName(e.target.value) }}>
            {Object.keys(stepValues).sort().map(k => <option value={k}>{k}</option>)}
          </select>
          <br />
          <select className="h-10 m-2" value={value} onChange={e => { setValue(e.target.value) }}>
            {[-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(k => <option value={k}>{k}</option>)}
          </select>
          <button className="primary-btn m-2" onClick={updateValue}>Update Value </button>
        </div>
      </div>
    </div>}
    <LineChart width={800} height={400} data={graphData} margin={{ top: 90, right: 20, bottom: 120, left: 20 }}>
      <XAxis interval={0} angle={-90} offset={40} tickMargin={30} fontSize={10} dataKey="title"><Label value="Weeks" offset={-80} position="insideBottom" /></XAxis>
      <Line type="natural" strokeWidth={3} dataKey="value" stroke="#00AEFF" dot={{ r: 0, width: 8 }} >
      </Line>
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
    </LineChart>
  </div >
}

export default TrackGraph;
