import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { setMinSimilarityScore } from '../Redux/imageUpload/similarityScoreSlice'
import uploadNewImage from '../Api/uploadNewImage'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'
import { femaleTrendCategory as categoryOptions, mensTrendsCategory as mensCategoryOptions } from '../constants'

const UploadImg = ({ searchDetail, saveSearch, discardSearch }) => {
  const iniformData = {
    category: categoryOptions[1],
    photo: "",
    gender: 'f',
    photoUrl: ""
  }

  const dispatch = useDispatch();

  const { searchHash } = useParams()
  const navigate = useNavigate()
  const isImgUploaded = searchHash ? true : false
  const [imageURL, setImageURL] = useState("")
  const [formData, setFormData] = useState(iniformData)
  const [apiCalled, setApiCalled] = useState(false)

  const handleForm = (e) => {
    const { name } = e.target
    if (name === 'photo') {
      setFormData(prev => ({ ...prev, photoUrl: "", photo: e.target.files[0] }))
      const newImageUrl = URL.createObjectURL(e.target.files[0])
      setImageURL(newImageUrl)
    } else if (name == "photoUrl") {
      setFormData(prev => ({ ...prev, photoUrl: e.target.value, photo: null }))
      setImageURL("")
    } else {
      const { value } = e.target
      setFormData(prev => ({ ...prev, [name]: value }))
      if (name == 'gender') {
        setFormData(prev => ({
          ...prev,
          category: iniformData.category
        }))
      }
    }
  }

  useEffect(() => {
    if (isImgUploaded && searchDetail) {
      const imageUrl = searchDetail.imageUrl
      setImageURL(imageUrl)
      setFormData(prev => ({
        ...prev,
        gender: searchDetail.gender,
        category: searchDetail.searchedCategory,
        minScore: searchDetail.minScore,
        gender: searchDetail.gender
      }))
    }
  }, [searchDetail])

  const uploadImage = async (e) => {
    e.preventDefault()
    if (!formData.photo && !formData.photoUrl) {
      Swal.fire("Upload image or give images url for search", "", "warning")
      return;
    }
    setApiCalled(true)
    let data, response;
    if (!formData.photoUrl || formData.photoUrl.length == 0) {
      data = new FormData()
      data.append('photo', formData.photo)
      data.append('category', formData.category)
      data.append('timeStamp', new Date())
      data.append('gender', formData.gender)
    } else {
      data = formData
    }
    try {
      response = await uploadNewImage.getSimilarImages(data);
    } catch (e) {
      Swal.fire("Request failed", "", "error");
    }
    setApiCalled(false)
    navigate(`/s/${response.searchHash}`)
    setFormData(iniformData)
  }



  return (<>
    <div className='self center w-1/5 m-5 mt-[9vh]  h-[80vh] px-5 bg-gray-300 flex justify-center items-center sticky top-50 flex-col'>
      <form onSubmit={isImgUploaded ? () => { navigate('/query') } : uploadImage} className='flex justify-center items-center flex-col'>
        <div className='flex w-7/8 flex-col justify-center item start my-3'>
          {imageURL?.length > 0 && <img className=' hover:shadow-lg' src={imageURL} alt="Image" />}
          {isImgUploaded && <div className='flex flex-col item-center w-full'>
            <p className='mt-4 font-extralight'>Category <span className='font-semibold'>{formData.category}</span></p>
            <p className='font-light'>Gender <span className='font-semibold'>{formData.gender === 'f' ? 'Female' : 'Male'}</span></p>
            {/*
            <input id="minScore" name="minScore" type="range" min="0" max="100" value={similarityScore.minSimilarityScore} step="0.01" className="w-full h-2 bg-gray-500 rounded-lg cursor-pointer dark:bg-gray-700" onChange={(e) => { e.preventDefault(); dispatch(setMinSimilarityScore(e.target.value)); }} />
            <label htmlFor="minScore" className="mt-5 block mb-0 text-sm font-bold text-gray-900 ">Min Similarity <span className='font-extrabold'>{similarityScore.minSimilarityScore}%</span></label>
          */}
          </div>
          }
          {!isImgUploaded && <div>
            <input className='my-2 justify-center' type="file" accept='.png, .jpg, .jpeg' name='photo' onChange={handleForm} />
            <p className='font-bold text-center'>-- OR --</p>
            <input className=' w-4/5 justify-center focus:outline-none focus:shadow-lg rounded-md py-1 px-3 m-2' type='text' onChange={handleForm} name='photoUrl' value={formData.photoUrl} placeholder='input image url' />
            <div className='flex'>
              <label className='block mb-2 text-sm font-bold text-gray-900 '>Gender </label>
              <div className='mx-2'>
                <span>F</span>
                <input className='mx-3 w-5 h-5' type="radio" name="gender" value={'f'} checked={formData.gender === 'f'} onChange={handleForm} />
              </div>
              <div>
                <span>M</span>
                <input className='mx-3 w-5 h-5' type="radio" name="gender" value={'m'} checked={formData.gender === 'm'} onChange={handleForm} />
              </div>
            </div>
            {/*
            <select required className='my-2 p-2 rounded-md outline-none w-4/5 text-center' onChange={handleForm} name='category' value={formData.category}>
              <option value="" disabled={true} hidden={true}>--Select your option--</option>
              {formData.gender === 'f' ?
                categoryOptions.map((option, index) => <option key={index} className="text-center" value={option}>
                  {option}
                </option>) :
                mensCategoryOptions.map((option, index) => <option key={index} className="text-center" value={option}>
                  {option}
                </option>)
              }
            </select>

            */}

          </div>}
        </div>
        <button className='mt-10 hover:bg-blue-700 text-white bg-blue-500 px-7 py-3 rounded-3xl' type="submit" disabled={apiCalled}>{isImgUploaded ? 'Upload another' : 'Get Styles'}</button>
      </form>
      {
        isImgUploaded &&
        <div className='mt-4'>
          {/*
          <button className='mr-1 hover:bg-gray-700 text-white bg-gray-500 p-3 rounded-3xl text-xs' onClick={() => saveSearch()} >Save Search</button>
          <button className='ml-1 hover:bg-red-600 text-white bg-red-400 p-3 rounded-3xl text-xs' onClick={() => discardSearch()} >Discard Search</button>
          */}
        </div>
      }
    </div>
    {apiCalled && <div className='w-4/5 grid'>
      <div className=" place-self-center inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
      </div>
    </div>}
  </>
  )
}

export default UploadImg
