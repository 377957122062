import React, { useEffect, useState, Suspense, lazy } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Popup from '../../component/Popup';
import SelectedTrend from './SelectedTrend';
import DateRangeFilter from '../../component/DateRangeFilter';
import { updateLogin } from '../../Redux/Slices/loginStatusSlice';
import { TailSpin } from 'react-loader-spinner'
import Trend from './Trend';
import { HiOutlineChevronDown } from 'react-icons/hi'

const TrendDisplay = ({designPage = false}) => {
    const [searchParmas, setSearchParms] = useSearchParams()
    const navigate = useNavigate();
    const { userLoggedIn } = useSelector(state => state)
    const [showLoader, setShowLoader] = useState(false)
    const [searchText, setSearchText] = useState(searchParmas.get('search') || "")
    const dispatch = useDispatch();
    useEffect(() => {
        if (!userLoggedIn.loggedIn) {
            navigate('/login');
        }
    }, []);
    const [showFilter, setShowFilter] = useState(false)
    const [maxDateRange, setMaxDateRange] = useState("")
    useEffect(() => {
        axios.get(`/design-api/date`)
            .then(res => {
                setMaxDateRange(moment(res.data.maxDate, "YYYY-MM-DD").format("YYYY-MM-DD"))
            })
            .catch(e => {
                console.log(e)
            })
    }, [])
    const iniFormData = {
        gender: searchParmas.get('gender') || "f",
        category: searchParmas.get('category') || 'All Trends',
        sort: searchParmas.get('sort') || '0',
	  geography : searchParmas.get('geography') || 'all',
	  search: searchParmas.get('search')|| "",
	  type: {
		"Celebrity": true,
		"Micro Influencer" : true,
		"Mega Influencer" :true,
		"Stores/Brands" : true,
		"Pages/Magazines": true
	  }
    }
    const iniDateFilter = {
        from: searchParmas.get('from') || "",
        to: searchParmas.get('to') || "",
        dateType: searchParmas.get('dateType') || 'range'
    }
    const [dateFilter, setDateFilter] = useState(iniDateFilter)
    const trendCategory = [
        "All Trends",
        "Topwear Trends",
        "Dress Trends",
        "Jeans & Trouser Trends",
        "Skirt Trends",
        "Jumpsuit Trends",
        "Trendy Shorts",
        "Outerwear Trends",
        "Trendy Bags",
        "Trendy Footwear",
    ]

    const mensTrendsCategory = [
        "All Trends",
        "Trendy Accessories",
        "Trendy Bags",
        "Bottomwear Trends",
        "Trendy Footwear",
        "Outerwear Trends",
        "Topwear Trends"
    ]
    const [page, setPage] = useState(() => parseInt(searchParmas.get('page')) || 1)
    const limit = 20
    const [count, setCount] = useState(0)
    const [formdata, setFormData] = useState(iniFormData)
    const [fetch, setFetch] = useState(false)
    const fillForm = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))

        if (name == 'gender') {
            setFormData(prev => ({
                ...prev,
                category: "All Trends"
            }))
        }
    }

    const updateType = (name)=>{
	const type = formdata.type;
	type[name]=!type[name]
	setFormData(prev => ({
            ...prev,
            type
      }))
    }
    const datesToShow = (startDate, endDate) => {
        const dates = []
        if (startDate && endDate) {
            let currentDate = moment(startDate, 'DD MMM YYYY')
            while (currentDate <= moment(endDate)) {
                dates.push(currentDate.format('DD MMM YYYY'))
                currentDate = moment(currentDate).add(1, 'months')
            }
            if (endDate !== dates[dates.length - 1])
                dates.push(endDate)
            if (dates[dates.length - 1] != moment(maxDateRange, "YYYY-MM-DD").format("DD MMM YYYY")) {
                dates.push(moment(maxDateRange, "YYYY-MM-DD").format("DD MMM YYYY"))
            }
        }
        return dates
    }
    const incrementDay = (date) => {
        const newDate = moment(date).add(1, 'days')
        return newDate.format('YYYY/MM/DD')
    }
    const getGraphData = (images) => {
        const imgs = images
        const t = []
        for (let i = 0; i < imgs.length; i++) {
            t.push(moment(imgs[i].timeStamp).format('YYYY/MM/DD'))
        }
        t.sort()
        let i = 0
        let noOfImage = 0
        const ans = []
        let cDate = t[0]
        while (i < t.length - 1) {
            if (cDate === t[i]) {
                while (t[i] == t[i + 1] && i < t.length - 1) {
                    i++;
                    noOfImage++;
                }
                if (i < t.length - 1) {
                    noOfImage++
                    ans.push({
                        date: moment(t[i], 'YYYY/MM/DD').format('DD MMM YYYY'),
                        noOfImages: noOfImage
                    })
                    i++;
                }
            }
            else {
                ans.push({
                    date: moment(cDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
                    noOfImages: noOfImage
                })
            }
            cDate = incrementDay(cDate)
        }
        while (cDate < t[i]) {
            ans.push({
                date: moment(cDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
                noOfImages: noOfImage
            })
            cDate = incrementDay(cDate)
        }
        ans.push({
            date: moment(t[i], 'YYYY/MM/DD').format('DD MMM YYYY'),
            noOfImages: noOfImage + 1
        })
        return ans
    }
    const getNonCumGraphData = (graphData) => {
        const nonCumGraphData = graphData.map((data, index) => {
            if (index === 0) {
                return data
            }
            else {
                return {
                    date: data.date,
                    noOfImages: data.noOfImages - graphData[index - 1].noOfImages
                }
            }
        })
        return nonCumGraphData
    }
    const getCycleGraph = (graphdata, noOfimages) => {
        const graphData = [...graphdata]
        let newDate = moment(graphData[graphData.length - 1].date, "DD MMM YYYY").format("YYYY-MM-DD")
        // console.log(newDate)
        while (newDate != maxDateRange) {
            newDate = moment(newDate, 'YYYY-MM-DD').add(1, 'days')
            graphData.push({
                date: newDate.format('DD MMM YYYY'),
                noOfImages: 0
            })
            newDate = newDate.format("YYYY-MM-DD")
        }
        const imgPerDay = Math.ceil(graphData.length / noOfimages)
        let ans = []
        if (imgPerDay != 1) {
            ans.push(graphData[0])
            for (let i = 1; i < graphData.length;) {
                let noOfImg = 0
                for (let j = 0; j < imgPerDay && i < graphData.length; j++) {
                    noOfImg += graphData[i].noOfImages
                    i++;
                }
                ans.push({ date: graphData[i - 1].date, noOfImages: noOfImg })
            }
        }
        else {
            ans = graphData
        }
        return ans
    }

    const updateSearchText = ()=>{

	setFormData(prev => ({
            ...prev,
		search: searchText,
            geography : 'all',
		type: {
			"Celebrity": true,
			"Micro Influencer" : true,
			"Mega Influencer" :true,
			"Stores/Brands" : true,
			"Pages/Magazines": true
		  }
      }))
    }

    const getTrend = (page = 1, limit = 20) => {
        const category = formdata.category.replace(/&/g, "%26")
	  if(formdata.search!=searchText){
		updateSearchText()
	  }
        setShowLoader(true)
	  
        axios.get(`/design-api/userid?gender=${formdata.gender}&category=${category}&sortBy=${formdata.sort}&geography=${formdata.geography}&page=${page}&limit=${limit}&from=${dateFilter.from}&to=${dateFilter.to}&dateType=${dateFilter.dateType}`
	  			+`&curator=${userLoggedIn.curator}&search=${searchText}&${Object.keys(formdata.type).map(k => k+"="+`${formdata.type[k]}`).join('&')}`)
            .then(res => {
                if (res.data.unauthorized == true || res.data.cookieExpired == true) {
                    const curator = false, admin = false;
                    dispatch(updateLogin({ loggedIn: false, curator, admin }));
                    navigate('/login');
                } else {
                    res.data.data.forEach(trend => {
                        trend.graphData = getGraphData(trend.images)
                        trend.nonCumGraphType = getNonCumGraphData(trend.graphData)
                        trend.showDates = datesToShow(trend.graphData[0]?.date, trend.graphData[trend.graphData.length - 1]?.date)
                        // trend.cycleGraph = getCycleGraph(trend.nonCumGraphType, trend.images.length)
                    })
                    setSearchParms({ ...formdata, ...dateFilter, page: page })
                    setCount(res.data.count)
                    setTrendData(res.data.data)
                    setShowLoader(false)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const fetchTrend = (e) => {
        e.preventDefault()
    }
    const [trendData, setTrendData] = useState([])
    useEffect(() => {
        if (maxDateRange) {
            getTrend(1)
            setPage(1)
        }
    }, [fetch, formdata, maxDateRange])
    const [toMerge, setToMerge] = useState([])
    const mergeTrend = () => {
        const ids = toMerge.map(trend => trend.id)
        axios.post(`/design-api/merge`, { trend_ids: ids })
            .then(res => {
                console.log(res)
                setToMerge([])
                setOpenMergePopUp(false)
                setFetch(!fetch)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const decreasePage = () => {
        setPage(page - 1)
        getTrend(page - 1)
        window.scrollTo(0, 0)
    }
    const increasePage = () => {
        setPage(page + 1)
        getTrend(page + 1)
        window.scrollTo(0, 0)
    }
    const [openMergePopUp, setOpenMergePopUp] = useState(false)
    const openMergeTrend = () => {
        setOpenMergePopUp(true)
    }
    const [selectedImage, setSelectedImage] = useState([])
    const deleteMultipleImages = () => {
        const cnfrm = window.confirm('Are you sure you want to delete these images?')
        // console.log(cnfrm)
        if (cnfrm) {
            axios.delete(`/design-api/selectedimages`, { data: selectedImage })
                .then(res => {
                    console.log(res)
                    setSelectedImage([])
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    if (showLoader) {
        return (
            <div className=' h-screen w-screen flex justify-center items-center'>
                <TailSpin
                    height="80"
                    width="80"
                    color="grey"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible="true"
                />
            </div>
        )
    }
    else {
        return (
            <div className='mx-5 max-[1160px]:mx-1 max-[980px]:mx-0'>
                <div className='flex justify-between my-4 sticky top-12 mt-2 bg-white z-10 max-[950px]:items-start'>
                    <form onSubmit={fetchTrend} className='flex items-center flex-wrap'>
				<select className='mr-1 p-2 rounded-md outline-none text-center max-[1160px]:w-24 ' value={formdata.gender} onChange={fillForm} name="gender" >
                            <option value="" hidden>--select Gender--</option>
                            <option value="m">Male</option>
                            <option value="f">Female</option>
                        </select>
                        <select className='mr-1 p-2 rounded-md outline-none text-center ' value={formdata.category} onChange={fillForm} name="category">
                            <option value="" hidden>--Product Categoty--</option>
                            {formdata.gender == 'f' ?
                                trendCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                                : mensTrendsCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                            }
                        </select>
                        <select className='mr-1 p-2 rounded-md outline-none text-center max-[767px]:mt-2' value={formdata.sort} onChange={fillForm} name="sort">
                            <option value="" hidden>--sort by--</option>
                            <option value="0">Sort By Latest (Freshly-brewed)</option>
                            <option value="1">Sort By Popular(Image Count)</option>
                            {/* <option value="2">Sort By Freshly-brewed</option> */}
                            <option value="3">Sort By Most-liked</option>
                            {userLoggedIn.curator && <option value="4">Sort By newly queried</option>}
                        </select>
				<select className='mr-1 p-2 rounded-md outline-none text-center mt-1' value={formdata.geography} onChange={fillForm} name="geography">
                            <option value="all">Geography</option>
                            <option value="western">Worn by Western</option>
                            <option value="asian">Worn by Asian</option>
                            <option value="indian">Worn by Indians</option>
                        </select>
				<div className='outline-none rounded-md p-2 m-3 text-center relative w-48  group text-black hover:shadow overflow-visible' value={formdata.geography} onChange={fillForm} name="geography">
					<div className='flex flex-row'><div className='w-3/5'>Source Type</div><div className=' w-1/10	 left'><HiOutlineChevronDown size={12}/></div></div>
					<div className='text-left p-3 absolute w-full  hidden group-hover:block left-0 rounded-md bg-white shadow opacity-100 bg-opacity-100 z-20'>
						{ Object.keys(formdata.type).map( t => <div key={t} className="px-2 mb-2"><input type='checkbox' checked={formdata.type[t]} onChange={()=>{ updateType(t)}}/>{t}</div>)}
					</div>
				</div>
                        <DateRangeFilter setFetch={setFetch} dateFilter={dateFilter} setDateFilter={setDateFilter} apiRoute='design-api'/>
				<input type='text' className='mr-1 p-2 rounded-md outline-none text-left  px-8' placeholder='search'value={searchText} onChange={(e)=>{ setSearchText(e.target.value)  }}/> 
				<button onClick={updateSearchText} className='mx-5 p-1 rounded-lg bg-blue-500 text-white'>Search</button>
                        {
                            userLoggedIn.curator && <div>
                                <button onClick={deleteMultipleImages} disabled={selectedImage.length === 0} className='hover:bg-blue-700  text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mx-2 rounded-3xl'>delete</button>
                            </div>
                        }
                        <div className='min-[564px]:hidden'>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page <= 1} onClick={decreasePage}>prev</button>
                            <span className='mx-1'>{page}/{Math.ceil(count / limit)}</span>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
                        </div>
                        {userLoggedIn.curator && <div className='min-[564px]:hidden mx-2'><button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 py-1 rounded-3xl' onClick={openMergeTrend} style={{ opacity: toMerge.length < 2 ? '50%' : '100%' }} disabled={toMerge.length < 2} >Merge</button></div>}
                        <div className='ml-2 min-[564px]:hidden'>{count} trends</div>
                        {/* <button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 ml-2 py-2 rounded-3xl' type="submit">Go</button> */}
                    </form>
                    <div className='flex items-center flex-wrap justify-end max-[563px]:hidden'>
                        {
                            userLoggedIn.curator && <div>
                                <button onClick={deleteMultipleImages} disabled={selectedImage.length === 0} className='hover:bg-blue-700  text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mx-2 rounded-3xl'>delete</button>
                            </div>
                        }
                        <div className=''>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page <= 1} onClick={decreasePage}>prev</button>
                            <span className='mx-1'>{page}/{Math.ceil(count / limit)}</span>
                            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl max-[1050px]:px-2' disabled={page >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
                        </div>
                        {userLoggedIn.curator && <div className=' mx-2'><button className=' hover:bg-blue-700 text-white bg-blue-500 px-7 py-1 rounded-3xl' onClick={openMergeTrend} style={{ opacity: toMerge.length < 2 ? '50%' : '100%' }} disabled={toMerge.length < 2} >Merge</button></div>}
                        <div className='ml-2'>{count} trends</div>
                        <Popup isButton={true} buttonFunction={mergeTrend} isPopUp={openMergePopUp} setIsPopUp={setOpenMergePopUp}>
                            <SelectedTrend mergeData={toMerge} setSelectedImage={setSelectedImage} selectedImage={selectedImage} fetch={fetch} setFetch={setFetch} setToMerge={setToMerge} />
                        </Popup>
                    </div>
                </div>
                <div>
                    {trendData.map((trend) => <Trend key={trend.id} toMerge={toMerge} setSelectedImage={setSelectedImage} selectedImage={selectedImage} setToMerge={setToMerge} trend={trend} setFetch={setFetch} fetch={fetch} gender={formdata.gender} />
                    )}
                </div>
            </div>
        )
    }
}

export default TrendDisplay