import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux';
import RawImage from './RawImage';
import { TailSpin } from 'react-loader-spinner';
import DateRangeFilter from './DateRangeFilter';
import TagsUpdatePopup from './UpdateTagsPopup';
import Popup from '../../component/Popup';
import RetailerImages from './RetailerImages';
import RunwayImages from './RunwayImages';

const RaWImage = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useSelector(state => state)
  const [searchParams, setSearchParams] = useSearchParams()
  const queryIghandle = searchParams.get('ighandle') || 'all'
  const queryPage = parseInt(searchParams.get('page')) > 0 ? parseInt(searchParams.get('page')) : 1
  const queryGender = searchParams.get('gender') || 'all'
  const geography = searchParams.get('geography') || 'all';
  const [showTagsPopup, setShowTagsPopup] = useState(false);
  const [tagspopupMediaId, setTagsPopupMediaId] = useState(null);
  const [allTagsFilled, setAllTagsFilled] = useState(true)
  const [igHandle, setIgHandle] = useState(queryIghandle)
  const [runwayProducts, setRunwayProducts] = useState([])
  const [runwayCount, setRunwayCount] = useState(0)
  const queryDate = {
    from: searchParams.get('from') || "",
    to: searchParams.get('to') || ""
  }
  const [dateFilter, setDateFilter] = useState(queryDate)
  const [isDataFetched, setIsDataFetched] = useState(true)
  const limit = 120
  const [igHandleList, setIgHandleList] = useState([])
  const [images, setImages] = useState([])
  const [count, setCount] = useState(0)
  const [tagsfilter, setTagsFilter] = useState('')
  const [gender, setGender] = useState(queryGender)
  const [retailerProducts, setRetailerProducts] = useState([]);
  const [retailerTotalCount, setRetailerTotalCount] = useState([]);

  useEffect(() => {
    if (showTagsPopup == false) {
      setTagsPopupMediaId(null)
    }
  }, [showTagsPopup])

  useEffect(() => {
    if (tagspopupMediaId != null) setShowTagsPopup(true)
  }, [tagspopupMediaId])

  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
    else {
      getIgHandle()
      const newParams = { ighandle: queryIghandle, page: queryPage, gender: queryGender, from: queryDate.from, to: queryDate.to, geography: geography || 'all' }
      if (searchParams.get('tab')) {
        newParams['tab'] = searchParams.get('tab');
      }
      setSearchParams(newParams)
      getImages(queryIghandle, gender, queryPage, queryDate.from, queryDate.to)
    }
  }, [tagsfilter]);

  const getIgHandle = (gender) => {
    axios.get(`rawimage/ighandle?gender=${gender}`)
      .then(res => {
        setIgHandleList(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }




  const getImages = (igHandle, gender, page, from, to) => {
    setIsDataFetched(false)
    axios.get(`/rawimage?ighandle=${igHandle}&page=${page}&gender=${gender}&limit=${limit}&from=${from}&to=${to}&tagsFilter=${tagsfilter}&geography=${geography || 'all'}`)
      .then(res => {
        setImages(res.data.imageList)
        setCount(res.data.count)
        setIsDataFetched(true)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleGenderChange = (e) => {
    setGender(e.target.value)
    getIgHandle(e.target.value)
    setIgHandle('all')
    setSearchParams({ ighandle: 'all', page: 1, gender: e.target.value, ...dateFilter, geography: geography || 'all' })
    getImages('all', e.target.value, 1, dateFilter.from, dateFilter.to);
  }
  const handleIgHandle = (e) => {
    setIgHandle(e.target.value)
    setSearchParams({ ighandle: e.target.value, page: 1, gender: gender, ...dateFilter })
    getIgHandle(gender);
    getImages(e.target.value, gender, 1, dateFilter.from, dateFilter.to);
  }
  const decreasePage = () => {
    const page = parseInt(searchParams.get('page') || '1')
    setSearchParams({ ighandle: igHandle, gender: gender, page: page - 1, ...dateFilter, geography: (searchParams.get('geography') || 'all') })
  }
  const increasePage = () => {
    const page = parseInt(searchParams.get('page') || '1')
    setSearchParams({ ighandle: igHandle, gender: gender, page: page + 1, ...dateFilter, geography: (searchParams.get('geography') || 'all') })
  }
  const onGo = (what) => {
    if (what) {
      const page = parseInt(searchParams.get('page') || '1')
      getImages(igHandle, gender, 1, dateFilter.from, dateFilter.to)
      setSearchParams({ ighandle: igHandle, gender: gender, page: page + 1, ...dateFilter })
    }
    else {
      const page = parseInt(searchParams.get('page') || '1')
      getImages(igHandle, gender, 1, "", "")
      setSearchParams({ ighandle: igHandle, gender: gender, page: page + 1, from: "", to: "" })
    }
  }

  // retailer products
  useEffect(() => {
    if (!searchParams.get('tab')) {
      getImages(queryIghandle, gender, queryPage, queryDate.from, queryDate.to)
    } else if (searchParams.get('tab') == 'retailer') {
      getRetailerProducts()
    } else {
      getRunwayProducts()
    }
  }, [searchParams.get('tab'), searchParams.get('page')])

  const getRetailerProducts = () => {
    const page = parseInt(searchParams.get('page') || '1')
    axios.get(`/api/retailer-v2/rawimage?page=${page}`).then(resp => {
      setRetailerProducts(resp.data.products);
      setRetailerTotalCount(resp.data.total);
    })
  }


  const getRunwayProducts = () => {
    const page = parseInt(searchParams.get('page') || '1')
    axios.get(`/api/runway/rawimage?page=${page}`).then(resp => {
      setRunwayProducts(resp.data.products);
      setRunwayCount(resp.data.total);
    })
  }

  return isDataFetched ? <div>
    {userLoggedIn.curator ? <div className='flex m-2 px-20'>
      <button className={`primary-btn ${!searchParams.get('tab') ? 'bg-blue-700' : 'bg-blue-300'}`} onClick={() => { setSearchParams(prev => { prev.delete('tab'); return prev; }) }} >Influencer Images</button>
      <button className={`primary-btn ${searchParams.get('tab') == 'retailer' ? 'bg-blue-700' : 'bg-blue-300'}`} onClick={() => { setSearchParams(prev => { prev.set('tab', 'retailer'); return prev; }) }}>Retailer Images</button>
      <button className={`primary-btn ${searchParams.get('tab') == 'runway' ? 'bg-blue-700' : 'bg-blue-300'}`} onClick={() => { setSearchParams(prev => { prev.set('tab', 'runway'); return prev; }) }}>Runway Images</button>
    </div> : null}
    {!searchParams.get('tab') ? <>
      <div className='mx-5 max-[805px]:mx-0 mb-5 flex sticky top-12 mt-2 bg-white justify-between items-center'>
        <form className='flex items-center flex-wrap'>
          <select className='p-2 mr-2 w-24 rounded-md outline-none text-center' name="gender" value={gender} onChange={handleGenderChange}>
            <option value="" hidden>--select Gender--</option>
            <option value="all">All</option>
            <option value="m">Male</option>
            <option value="f">Female</option>
          </select>
          {userLoggedIn.curator ? <>
            <select className='p-2 mr-2 w-44 rounded-md outline-none text-center' name="igHandle" value={igHandle} onChange={handleIgHandle}>
              <option value="" hidden>--select Ig handle--</option>
              <option value="all">All</option>
              {
                igHandleList.map(igHandle => <option value={igHandle} key={igHandle}>{igHandle}</option>)
              }
            </select>
            <select value={tagsfilter} onChange={(e) => setTagsFilter(e.target.value)} className='w-40 rounded px-2 py-2'>
              <option value=''>All</option>
              <option value='tags'>Images with tags</option>
              <option value='color-tags'>Images with colors and tags</option>
              <option value='no-color-tags'>Images without colors</option>
            </select>
          </>
            : null}
          <DateRangeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} onGo={onGo} />
          <div className=' min-[1150px]:hidden mr-2'>({count})Images</div>
          <div className=' min-[1150px]:hidden'>
            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') <= 1} onClick={decreasePage}>prev</button>
            <span className=' mr-2'>{searchParams.get('page') || '1'}/{Math.ceil(count / limit)}</span>
            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
          </div>
        </form>
        <div className='flex items-center  max-[1150px]:hidden'>
          <div className='mr-2'>({count})Images</div>
          <div>
            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') <= 1} onClick={decreasePage}>prev</button>
            <span className='mr-2'>{searchParams.get('page') || 1}/{Math.ceil(count / limit)}</span>
            <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') >= Math.ceil(count / limit)} onClick={increasePage}>next</button>
          </div>
        </div>
      </div>
      {
        images.length > 0 ? <div className='flex flex-wrap justify-center mt-5'>
          {
            images.map((image, i) => <RawImage image={image} key={i} curator={userLoggedIn.curator} updateTagsPopupMediaId={setTagsPopupMediaId} />)
          }
        </div> : <div className="mt-10">No Image Found</div>
      }
    </> : searchParams.get('tab') == 'retailer' ? <RetailerImages products={retailerProducts} count={retailerTotalCount} page={parseInt(searchParams.get('page') || '1')} setPage={(next) => {
      let nextValue;
      if (typeof next == 'number') {
        nextValue = next
      } else {
        nextValue = next(parseInt(searchParams.get('page') || '1'))
      }



      setSearchParams(prev => {
        prev.set('page', nextValue);
        return prev
      })

    }} /> : <RunwayImages products={runwayProducts} count={runwayCount} />
    }
    <Popup isPopUp={showTagsPopup} setIsPopUp={(nState) => {
      if (!nState && allTagsFilled)
        setShowTagsPopup(false)
    }} >

      <TagsUpdatePopup mediaId={tagspopupMediaId} setAllTagsFilled={setAllTagsFilled} allTagsFilled={allTagsFilled} />
    </Popup>
  </div> : <div className=' h-screen w-screen flex justify-center items-center'>
    <TailSpin
      height="80"
      width="80"
      color="grey"
      ariaLabel="tail-spin-loading"
      radius="1"
      visible="true"
    />
  </div>


}
export default RaWImage
