import React from 'react'
import { Link } from 'react-router-dom'

const TopBar = () => {
  return (
    <div className='grid h-screen place-items-center'>
		<div>
			<h2 className=' text-6xl font-bold'>404</h2>
			<h3>Page Not Found!</h3>
		</div>
    </div>
  )
}

export default TopBar