import { configureStore } from '@reduxjs/toolkit'
import blurHashReducer from './imageUpload/blurHashSlice'

import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import imageUploadReducer from './imageUpload/imageUploadSlice'
import loginReducer from './Slices/loginStatusSlice'
import similarityScoreReducer from './imageUpload/similarityScoreSlice';

const reducers = combineReducers({
	imageUplaod:imageUploadReducer,
	userLoggedIn: loginReducer,
	similarityScore : similarityScoreReducer

});

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['userLoggedIn']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware:[thunk],

})