import React, { useEffect, useState } from 'react'
import UploadImg from './UploadImg'
import ImageSet from './ImageSet'
import TrendSet from './TrendSet'
import AllTrendSet from './QueryAllTrendSet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { getResultFromSearchHash } from '../Api/uploadNewImage'
import axios from 'axios'
import Swal from 'sweetalert2'

const ImgUploded = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useSelector(state => state)

  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    } else if (!userLoggedIn.curator) {
      navigate('/')
    }
  }, []);
  const { searchHash } = useParams();
  const [images, setImages] = useState([]);
  const { similarityScore } = useSelector(state => state)
  const [trends, setTrends] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const [queryErrored, setqueryErrored] = useState(false);
  const [queryImageUrl, setQueryImageUrl] = useState(false);
  const [uploadImageData, setUploadImageData] = useState({})
  const [queries, setQueries] = useState([])
  const [tab, setTab] = useState('Matching Images')
  const [selectedImages, setSelectedImages] = useState([])

  async function fetchDataForHash() {
    const data = await getResultFromSearchHash(searchHash);
    setResponseReceived(true);
    setUploadImageData({
      searchedCategory: data.searchedCategory,
      imageUrl: data.imageUrl,
      gender: data.gender === 'F' ? 'f' : 'm',
      minScore: data.minScore,
    })
    setQueries(data.queries)
    if (data.resultsExist != true) {
      setqueryErrored(true);
    } else {
      setImages(data.similarCategoryImages)
      setTrends(data.similarTrends)
      //  setSelectedImages(data.similarCategoryImages.map(img => img.mediaId))
    }
  }

  useEffect(() => {
    fetchDataForHash()
  }, [searchHash])


  const saveSearch = async (forMerge = false) => {
    const data = new FormData()
    data.append('selectedImages', selectedImages)
    data.append('trend_Id', searchHash)

    const response = await axios.post(`/trend/save-trend`, {

      selectedImages,
      trend_Id: searchHash
    })
    if (!forMerge)
      Swal.fire("Search saved", "", "success");
    return response.data.pk

  }

  const discardSearch = () => {

    axios.delete(`/trend/${searchHash}`)
      .then(res => {
        Swal.fire("Search discarded", "", "success");
        //        setTimeout(() => navigate('/query'), 1000)
      })
      .catch(err => {
        console.log(err)
      })

  }

  const addToExistingTrend = async (trendId) => {
    try {
      const newTrendId = await saveSearch(true)
      const response = await axios.post('/trend/merge', {
        trend_ids: [trendId, newTrendId]
      })
      Swal.fire('Added result to trend', 'success')
      fetchDataForHash()

    } catch (e) {
      console.log(e)
      Swal.fire("Error - Could not be merged", 'error')

    }
  }

  const updateSelectedImage = (mediaId, add) => {
    if (add) {
      setSelectedImages(imgs => { return [...imgs, mediaId] })
    } else {
      setSelectedImages(imgs => { return imgs.filter(img => img != mediaId) })
    }
  }

  return (
    <div>
      <select className='m-6 p-2 font-medium text-bg ml-56' value={searchHash} onChange={(e) => { navigate(`/s/${e.target.value}`) }}>
        <option value='' disabled={true}>--Select--</option>
        {queries.map(q => <option value={q.pk}>{q.category}</option>)}
      </select>
      <button className='mr-1 hover:bg-gray-700 text-white bg-gray-500 p-3 rounded-3xl text-xs w-60' onClick={() => saveSearch()} >Save Search for <span className='font-semibold '>{uploadImageData.searchedCategory}</span></button>
      <div className='flex w-screen'>

        <UploadImg searchDetail={uploadImageData} saveSearch={saveSearch} discardSearch={discardSearch} />
        <div className='w-4/5 border border-slate-100 shrink-0'>
          <div className='h-6 z-10 bg-slate-100'>
            {['Matching Images', 'Matching Trends', 'All Trends in Category'].map(type => <button className={`rounded-t-sm bg-slate-100 font-bold py-2 px-2 w-1/3 border-l border-x-l-slate-300 ${tab === type ? 'border-b-4 border-slate-4' : ''}`} onClick={() => { setTab(type) }}>{type}{type != 'All Trends in Category' ? `(${type == 'Matching Images' ? images.length : trends.length})` : ''}</button>)}
          </div>
          {
            tab === 'Matching Images' && <ImageSet images={images} responseReceived={responseReceived} queryErrored={queryErrored} selectedImages={selectedImages} updateSelectedImage={updateSelectedImage} />
          }
          {
            tab === 'Matching Trends' && <TrendSet trends={trends} presentMediaIds={images.map(img => img.mediaId)} mergeTrend={addToExistingTrend} />
          }
          <div className={`w-full  z-0 mt-[5vh] ${tab === 'All Trends in Category' ? 'visible' : 'hidden'} `}>
            <AllTrendSet presentMediaIds={images.map(img => img.mediaId)} mergeTrend={addToExistingTrend} queryData={uploadImageData} />
          </div>


        </div>
      </div>
    </div >
  )

}

export default ImgUploded
