import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FemaleTrendToStlCategory, MaleTrendToStlCategory, months } from "../../constants";
import Popup from '../../component/Popup';
import ProductsPopup from "./ProductsPopup";
import ModalImage from "react-modal-image";
import { ImCross } from "react-icons/im";
import { formatWeek, SentenceCase } from "../../utility/helper_functions";
import { TailSpin } from "react-loader-spinner";
import { AiTwotoneHeart } from "react-icons/ai";

const SocialMetTagsCards = ['Color', 'Multicolor', 'Pattern', 'Texture'];
const DesignerFeatureTrends = () => {

  const [trends, setTrends] = useState([]);
  const [count, setCount] = useState(0)
  const [pages, setPages] = useState(1);
  const [params, setParams] = useSearchParams()
  const [selectedTagKey, setSelectedTagKey] = useState("")
  const [tagsdata, setTagsData] = useState([])
  const [periodicData, setPeriodicData] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedTag, setSelectedTag] = useState(null)
  const [categoryCounts, setCategoryCounts] = useState({})
  const [selectedHighlight, setSelectedHighlight] = useState('');
  const [monthsSinceJanuary, setMonthsSinceJanuary] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [filteredTagsUrl, setFilteredTagsUrl] = useState('');
  const today = useRef(new Date());
  const thisWeek = useRef((() => {
    const d = new Date();
    let yearStart = +new Date(d.getFullYear(), 0, 1);
    let today = +new Date(d.getFullYear(), d.getMonth(), d.getDate());
    let dayOfYear = ((today - yearStart + 1) / 86400000);
    let week = Math.ceil(dayOfYear / 7)
    return week
  })())
  const setInitialParams = () => {

    setParams((prev) => {
      if (!prev.get('category')) {
        prev.set('category', 'All');
      }
      if (!prev.get('page')) {
        prev.set('page', '1');
      }
      prev.set('page', prev.get('page') || '1');
      prev.set('gender', prev.get('gender') || 'F');
      prev.set('time', prev.get('time') || '1 month');

      prev.set('page', prev.get('page') || '1');
      prev.set('limit', prev.get('limit') || 50)
      // prev.set('tag', prev.get('tag') || tagNames[0])

      return prev
    })
  }

  useEffect(() => {
    if (filteredTags.length == 0) {
      setFilteredTagsUrl('')
      return;
    }
    let returnStr = `&filterTagCount=${filteredTags.length}`
    for (let i = 0; i < filteredTags.length; i++) {
      const filterPair = filteredTags[i]
      returnStr = returnStr + `&filterTag${i + 1}=${filterPair.key}&filterTagValue${i + 1}=${filterPair.value}`
    }
    setFilteredTagsUrl(returnStr)
  }, [filteredTags])

  //tags-data update --
  useEffect(() => {

    if (!params.get('category'))
      return
    setTagsData([])
    setLoading(true)
    axios.get(`/meta-trend-api/designer-feature-tags-data?gender=${params.get('gender')}&category=${params.get('category').split('&').join('%26')}&time=${params.get('time')}${filteredTagsUrl}`).then(response => {
      const sortedTagsData = [...response.data]

      setTagsData(sortedTagsData);
    }).catch(e => { console.error(e) })
      .finally(() => {
        setLoading(false)
      })
  }, [params.get('gender'), params.get('category'), params.get('time'), filteredTagsUrl])


  useEffect(() => {
    setInitialParams()
  }, [])


  useEffect(() => {
    if (params.size === 0) {
      setInitialParams()
    }
  }, [params])


  const getTrends = () => {
    if (!params.get('page') || !params.get('tag'))
      return

    setTrends([])

    axios.get(`/meta-trend-api/designer-feature-trends?gender=${params.get('gender')}&category=${params.get('category')?.split('&').join('%26')}&page=${params.get('page')}&limit=${params.get('limit')}&tagName=${params.get('tag')}&time=${params.get('time')}`).then(response => {
      setTrends(response.data.trends)
      setCount(response.data.count)
      setPages(Math.ceil(response.data.count / params.get('limit')))
      window.scrollTo(0, 0)
    }).catch(e => {
      console.log(e);
    })
  }

  // update-category counts --- updated
  const getCategoryCounts = () => {
    axios.get(`/meta-trend-api/designer-feature-category-counts?gender=${params.get('gender')}${params.get('tag') ? `&tagName=${params.get('tag')}` : ''}&time=${params.get('time')}`).then(response => {
      setCategoryCounts(response.data)

    }).catch(console.error)
  }
  useEffect(getCategoryCounts, [params.get('tag'), params.get('gender'), params.get('time')])


  function updatePage(change) {
    setParams(prev => {
      prev.set('page', parseInt(prev.get('page')) + change)
      return prev
    })
  }



  function changeParams(event) {
    setParams(prev => {
      prev.set(event.target.name, event.target.value)
      if (event.target.name === 'gender') {
        prev.set('category', 'All');
      }
      prev.set('page', 1)
      return prev
    })
  }

  const getPeriodicData = () => {

    if (!params.get('periodic'))
      return
    setPeriodicData([])
    setLoading(true)
    axios.get(`/meta-trend-api/designer-feature-periodic-data?gender=${params.get('gender')}&category=${params.get('category').split('&').join('%26')}&tag=${params.get('periodic')}&periodicType=${params.get('periodic-type')}&time=${params.get('time')}`).then(response => {
      setPeriodicData(response.data);
    }).catch(e => {
      console.error((e))
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateFilterTag = (e) => {
    e.preventDefault()
    if (e && e.stopPropagation) e.stopPropagation();
    const name = e.target.name;
    const tagName = name.split('--')[0];
    const tagValue = name.split('--')[1];
    setFilteredTags(prev => {
      prev = [...prev]
      for (let pair of prev) {
        if (pair.key == tagName) {
          if (pair.value == tagValue) {
            const newList = prev.filter(p => p.key != tagName)
            return newList
          } else {
            pair.value = tagValue
            return prev
          }
        }
      }
      const tagDoc = tagsdata.filter(t => t.tag == tagName)[0]
      prev.push({ key: tagName, value: tagValue, products: tagDoc?.products, total: tagDoc.total })
      return prev
    })

  }
  useEffect(getPeriodicData, [params.get('periodic'), params.get('gender'), params.get('time')]);

  useEffect(getTrends, [params])

  return (<div className="w-screen">
    <div className="flex flex-row sticky bg-white py-2 top-12 w-full flex-wrap">
      <div className="px-6 w-2/3 shrink-0">
        {/*        <select value={params.get('time')} className='px-4 py-1 rounded mx-2' onChange={changeParams} name='time'>
          {['1 week', '2 weeks', '3 weeks', '1 month', '2 month', '3 months', '6 months', '1 year'].map((t) => (<option value={t}>{t}</option>))}
        </select>
        */}
        <select value={params.get('gender')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='gender'>
          <option value='' disabled={true}>--gender--</option>
          <option value='F'>Female</option>
          <option value='M'>Male</option>
        </select>

        <select value={params.get('category')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='category'>
          <option value='' disabled>--category--</option>

          <option value='All' >All Categories ({categoryCounts.all || '0'})</option>
          <option value='topwear' >All Topwear ({categoryCounts.topwear || '0'})</option>
          <option value='bottomwear' >All Bottomwear ({categoryCounts.bottomwear || '0'})</option>
          <option value='outerwear' >All Outerwear ({categoryCounts.outerwear || '0'})</option>
          <option value='one-piece' >All One piece ({categoryCounts.onepiece || '0'})</option>
          <option value='clothing' >All Clothing ({categoryCounts.clothing || '0'})</option>
          <option value='footwear' >All Bags & Footwear ({categoryCounts.footwear || '0'})</option>
          {(params.get('gender') === 'F' ? FemaleTrendToStlCategory :
            params.get('gender') === 'M' ? MaleTrendToStlCategory.filter(p => !["Loungewear", "Shoes", "Handbags", "Clutches", "Sunglasses"].includes(p))
              : Array.from(new Set([...FemaleTrendToStlCategory, ...MaleTrendToStlCategory].filter(p => !["Loungewear", "Shoes", "Handbags", "Clutches", "Sunglasses"].includes(p)))))
            .filter(p => !["Heels", "Sunglasses", "Co-ord Sets", "Flats"].includes(p))
            .map(cat => <option key={cat} value={cat}>{cat} ({categoryCounts[cat] || '0'})</option>)
          }
        </select>

      </div>
      <div className="w-full shrink-0 px-6">
      </div>
      <div className="relative w-screen pb-2    bg-white ">
        {/*<div className="shrink-0  flex px-[4vw] my-1 flex-wrap justify-stretch bg-white">
          {tagNames.map((tag, i) => <button className={`px-2 py-[1px] text-sm my-[1px] bg-slate-600  text-white h-10  ${params.get('tag') === tag ? 'bg-slate-800' : ''} ${i === 0 ? 'rounded-l-lg' : ''} ${i !== tagNames.length - 1 ? 'border-r border-r-white' : 'rounded-r-lg'}`} onClick={() => setParams(prev => {
            prev.set('tag', tag)
            prev.set('page', 1)
            return prev
          })}>{tag == 'color' ? 'single color' : tag}</button>)}
	</div>*/}
      </div>
    </div>

    {
      loading &&
      <div className="w-full h-[70vh] flex justify-center items-center">
        <TailSpin
        />
      </div>
    }

    {params.get("tag") && <div className="w-screen pb-20 ">
      {
        !loading &&
        <h2 className="w-full text-xl font-medium text-center">{SentenceCase(params.get('tag') == 'color' ? 'single color' : params.get('tag'))} Trends<button className="ml-4" onClick={() => setParams(prev => { prev.delete("tag"); return prev; })}> <ImCross /> </button></h2>
      }

      {trends.map((trend, i) => {
        return <div className="w-full border-b-2 pt-14">
          <div className="flex justify-between w-full">
            <h3 className="font-semibold pl-10 text-xl ">{trend.name} - {trend.count} images</h3>
            <div className="shrink-0 pr-60 " >
              <div className="p-2  rounded-xl hover:bg-blue-600 bg-blue-500 text-center font-semibold text-white cursor-pointer" onClick={() => { setSelectedTag(trend.name) }}>
                All Images
              </div>
            </div>
          </div>
          <div key={i} className="w-screen overflow-y-hidden flex overflow-auto h-80  px-6">

            {trend.products.map((prod, i) => {
              return <div key={i} className="align-bottom w-60 shrink-0 h-full py-4 mx-1 hover:shadow" >
                <div className='h-3/5 w-full flex justify-center'>
                  <ModalImage src={prod.imageUrl} className=' h-[10rem]' small={prod.imageUrl} large={prod.imageUrl} hideZoom={false} hideDownload={true} />
                </div>
                <div className="h-2/5 pl-4 border-t-2 border-t-slate-200 border-t-w-">
                  <div className='flex items-center'>{parseInt(prod.like_count) <= 3 ? 'Hidden' : prod.like_count}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></div>
                  <p>{new Date(prod.timeStamp).toLocaleString()}</p>
                </div>
              </div>
            })}
          </div>
        </div>
      })}
      <div className="fixed bottom-0 w-screen bg-white">
        <div className="bg-white py-2 top-12 w-full flex-wrap">
          <div className="ml-auto pr-6 w-1/3 shrink-0">
            <button className="primary-btn-padding" onClick={() => updatePage(-1)} disabled={params.get('page') <= 1}>Prev</button>
            {count} {params.get('tag')} Trends
            <button className="primary-btn-padding" onClick={() => updatePage(1)} disabled={params.get('page') >= pages}>Next</button>
          </div>

        </div>
      </div>

    </div>}

    {!params.get("tag") && !params.get('periodic') && <div className="w-screen flex overflow-x-auto">

      {!loading &&
        filteredTags.map((tagdata) => {
          return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1" key={tagdata.key}>
            <div className="border-b border-slate-500 float-left w-full">
              <button className="w-full text-center text-blue-500 font-semibold hover:underline" onClick={() => setParams(prev => {
                prev.set("tag", tagdata.key)
                return prev
              })}>{SentenceCase(tagdata.key)} Trends</button>
              <p className="pl-4 text-sm text-slate-500 text-center">{tagdata.total} {tagdata.key} Trends</p>
            </div>
            <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[80vh]">
              {
                tagdata.products && tagdata.products.map(prod => <div className={`h-40 w-60 flex items-center justify-center float-left shrink-0 ${tagdata.value !== prod[0].value ? 'bg-slate-200' : ''}`}>
                  <div className="w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow hover:shadow-xl float-left group relative" onClick={() => { setSelectedTagKey(tagdata.key); setSelectedTag(prod[0].value); }}>
                    <h3 className="text-sm text-center text-slate-500"> {prod[0].value} - <span className="text-slate-400"> {prod[1]}</span></h3>
                    <div className="h-4/5 w-4/5 flex justify-center items-center object-cover overflow-hidden">
                      <img className="h-20 " src={prod[2].cdnUrl || prod[2].image_url} loading="lazy" />
                    </div>
                    {false && <button
                      className={`absolute bottom-1 right-4 hidden group-hover:block z-10 ${tagdata.value == prod[0].value ? 'bg-red-400 hover:bg-red-500' : 'bg-slate-700 hover:bg-slate-600'} text-white rounded px-2 py-1`}
                      onClick={updateFilterTag} name={`${tagdata.key}--${prod[0].value}--delete`} >{tagdata.value == prod[0].value ? 'Remove' : 'Select'}</button>

                    }
                  </div>
                </div>)
              }


            </div>
            <div className="h-0 relative w-full">
              <div className="absolute bottom-4 w-full flex justify-center items-center">
                {/*
                    <button className="mx-auto bg-white  p-1 px-3 border-slate-600 border rounded-lg hover:bg-sky-100 " onClick={() => { setParams(prev => { prev.set('periodic', tagdata.tag); return prev; }); setSelectedHighlight(''); }}>See Monthly Changes</button>
                */}
              </div>
            </div>
          </div>
        })
      }
      {tagsdata.map((tagdata, i) => {
        return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1" key={i}>
          <div className="border-b border-slate-500 float-left w-full">
            <button className="w-full text-center text-blue-500 font-semibold hover:underline" onClick={() => setParams(prev => {
              prev.set("tag", tagdata._id)
              return prev
            })}>{SentenceCase(tagdata._id)} Trends</button>
            <p className="pl-4 text-sm text-slate-500 text-center">{tagdata.total} {tagdata._id} Trends</p>
          </div>
          <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[75vh]">
            {
              tagdata.products && tagdata.products.map(prod => <div className="h-40 w-60 flex items-center justify-center float-left shrink-0">
                <div className="w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow hover:shadow-xl float-left group relative" onClick={() => { setSelectedTagKey(prod.tag.tag); setSelectedTag(prod.tag.value); }}>
                  <h3 className="text-sm text-center text-slate-500"> {prod.tag.value} - <span className="text-slate-400"> {prod.total}</span></h3>
                  <div className="h-4/5 w-4/5 flex justify-center items-center object-cover overflow-hidden">
                    <img className="h-20 " src={prod.product.imageUrl} loading="lazy" />
                  </div>
                  {false && <button className={`absolute bottom-1 right-4 hidden group-hover:block z-10 bg-slate-700 hover:bg-slate-600 text-white rounded px-2 py-1`} onClick={updateFilterTag} name={`${tagdata.tag}--${prod.tag.value}`} >Select</button>
                  }
                </div>
              </div>)
            }


          </div>
          <div className="h-0 relative w-full align-bottom top-10">
            <div className="absolute bottom-4 w-full flex justify-center items-center">
              <button className="mx-1 text-xs bg-white  p-0.5 px-1 border-slate-600 border rounded-lg hover:bg-sky-100 " onClick={() => { setParams(prev => { prev.set('periodic', tagdata._id); prev.set('periodic-type', 'weekly'); return prev; }); setSelectedHighlight(''); }}>See Weekly Changes</button>
              {false && <button className="mx-1 text-xs bg-white  p-0.5 px-1 border-slate-600 border rounded-lg hover:bg-sky-100 " onClick={() => { setParams(prev => { prev.set('periodic', tagdata._id); prev.set('periodic-type', 'weekly'); return prev; }); setSelectedHighlight(''); }}>See Monthly Changes</button>}
            </div>
          </div>
        </div>
      })}
    </div>}

    {
      !loading && !params.get("tag") && params.get('periodic') && <>

        <h2 className="w-full text-xl font-medium text-center">{SentenceCase(params.get('periodic') == 'color' ? 'single color' : params.get('periodic'))} {params.get('periodic-type') == 'weekly' ? 'Weekly' : 'Monthly '} Changes<button className="ml-4" onClick={() => setParams(prev => { prev.delete("periodic"); return prev; })}> <ImCross /> </button></h2>
        <div className="w-[90%] flex overflow-x-auto mx-4 my-2">

          {periodicData.map((period, i) => {
            return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1" key={i}>
              <div className="border-b border-slate-500 float-left w-full">
                <h3 className="w-full text-center text-blue-500 font-semibold hover:underline"  >{params.get('periodic-type') == 'weekly' ? `${thisWeek.current == period.month.month ? 'Current' : `-${thisWeek.current - period.month.month}`} week` : `${today.current.getUTCMonth() == period.month.month ? 'Current' : `-${today.current.getUTCMonth() - period.month.month}`} month`}</h3>
                <p className="pl-4 font-bold text-sm text-slate-700 text-center">{params.get('periodic-type') == 'weekly' ? formatWeek(period.month.month, period.month.year) : `${months[period.month.month - 1]} ${period.month.year}`}</p>
              </div>
              <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[75vh]">
                {
                  period.products && period.products.map(prod => <div className={`w-60 flex items-center justify-center float-left shrink-0 my-1  `}>
                    <div className={`w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow-sm hover:shadow float-left px-3 py-2  ${selectedHighlight == prod.name ? 'bg-slate-100 font-semibold' : ''}  `} >
                      <h3 className={`text-xs text-center text-slate-500 `} onClick={() => setSelectedHighlight(prev => prev == prod.name ? '' : prod.name)}> {SentenceCase(prod.name)} - <span className="text-slate-400">{prod.total} products</span></h3>
                    </div>
                  </div>)
                }


              </div>
            </div>
          })}
        </div>
      </>
    }

    <Popup isPopUp={selectedTag} setIsPopUp={setSelectedTag}>
      <ProductsPopup gender={params.get('gender')} category={params.get('category')?.split('&').join('%26')} tagName={params.get('tag') || selectedTagKey} tagValue={selectedTag} rank={params.get('rank')} time={params.get('time')} filteredTagsUrl={filteredTagsUrl} />
    </Popup>
  </div >)
}
export default DesignerFeatureTrends
