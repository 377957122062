import React, { Suspense, useEffect, useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrEdit } from 'react-icons/gr'
import axios from 'axios'
import Graph from './Graph'
import Popup from '../../component/Popup'
import { AiTwotoneHeart } from 'react-icons/ai'
import { BiCommentDots } from 'react-icons/bi'
import { MdRestore } from 'react-icons/md'
import { FaImages } from 'react-icons/fa'
import OverLappingTrends from './OverLappingTrends'
import { useSelector } from 'react-redux'
import TrendImage from './TrendImage'
import SimilarProductsTrend from './SimilarProductsTrend'


const Trend = ({ trend, selectedImage, setSelectedImage, fetch, setFetch, setToMerge, toMerge ,gender}) => {
    const { userLoggedIn } = useSelector(state => state)
    const [trendSaved, setTrendSaved] = useState(trend.isSaved)
    const location = window.location.pathname === '/deleted-trend'
    const deleteTrend = (id) => {
        const response = window.confirm(`Do You really want to delete trend with id ${id}`)
        if (response) {
            axios.delete(`/design-api/${id}`)
                .then(res => {
                    console.log(res.data)
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
            console.log("deleted trend with id", id);
        }
    }
    const restoreTrend = (id) => {
        const response = window.confirm(`Do You really want to restore trend with id ${id}`)
        if (response) {
            axios.post(`/design-api/restore-trend/${id}`)
                .then(res => {
                    console.log(res)
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    const updateSavedData = async (type, dataId) => {
        try {
            if (!trendSaved)
                axios.put(`/save/${type}/${dataId}`)
            else
                axios.delete(`/save/${type}/${dataId}`)
            setTrendSaved(!trendSaved)
        } catch (e) {
            console.error(e)
        }
    }

    const duplicateTrend = (id) => {
        const response = window.confirm(`Do You really want to duplicate trend with id ${id}`)
        if (response) {
            axios.post(`/design-api/duplicate/${id}`)
                .then(res => {
                    console.log(res.data)
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const changeName = () => {
        if (!userLoggedIn.curator)
            return;
        const newName = prompt("Set new trend name", trend.name)
        if (newName && newName.trim() && newName !== trend.name) {
            axios.patch(`/design-api/${trend.id}`, { nameToChange: newName })
                .then(res => {
                    setFetch(!fetch)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    const handleChange = () => {
        setToMerge(prev => {
            if (isSelected) {
                const temp = prev.filter(trends => trends.id !== trend.id)
                return temp
            }
            else {
                return [...prev, trend]
            }
        })
        setIsSelected(!isSelected)
    }
    useEffect(() => {
        let ans = false
        for (let i = 0; i < toMerge.length; i++) {
            if (toMerge[i].id === trend.id) {
                ans = true;
                break
            }
        }
        setIsSelected(ans)
    }, [toMerge])
    const [isSelected, setIsSelected] = useState(false)
    const [showGraph, setShowGraph] = useState(false)
    const [graphType, setGraphType] = useState(0)
    const [showOverLaps, setShowOverlaps] = useState(false)
    const [selectedCount, setSelectedCount] = useState(0)
    const [overLapingTrends, setOverLappingTrends] = useState([])
    const [overlappingSelectedTrend, setOverlappingSelectedTrend] = useState([])
    const [showCycleGraph, setShowCycleGraph] = useState(false)
    const [showPairItWith, setShowPairItWith] = useState(false)
    const [showPriceRange, setShowPriceRange] = useState(false)
    const toogleGraph = () => {
        setShowGraph(!showGraph)
    }
    useEffect(() => {
        if (userLoggedIn.curator) {
            axios.get(`/design-api/overlapping-trend/${trend.id}`)
                .then(res => {
                    setOverLappingTrends(res.data)
                    const t = res.data.map(e => false)
                    setSelectedCount(0)
                    setOverlappingSelectedTrend(t)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [fetch])
    const capitalizeEachWord = (mySentence) => {
        if (mySentence) {
            const words = mySentence.split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
            }
            const ans = words.join(" ");
            return ans
        }
        return ""
    }
    const handleGoingLive = () => {
        const response = window.confirm(`Do You really want to ${trend.reviewed?'remove live':'go live'} trend with id ${trend.id}`)
        if(response){
            axios.patch(`/design-api/reviewed/${trend.id}`,{reviewed:!trend.reviewed})
            .then(res=>{
                console.log(res.data)
                setFetch(!fetch)
            })
            .catch(err=>{
                console.log(err)
            })
        }
    }
    return (
        <div className=' my-10'>
            <div className='flex justify-between items-center my-5'>
                <div className='flex items-center flex-wrap'>
                    {userLoggedIn.curator && <input className='' type="checkbox" name="toMergeCheckBox" checked={isSelected} onChange={handleChange} />}
                    {(userLoggedIn.curator || trend.name) &&
                        <p className='flex justify-center items-center cursor-pointer mx-2' onClick={userLoggedIn.curator ? changeName : null}><span>Trend Name: </span><span className='ml-1 font-extrabold'>{capitalizeEachWord(trend.name)}</span> {userLoggedIn.curator && <span><GrEdit /></span>}</p>
                    }
                    <p className='mx-2'>Category: <span className='font-bold'>{trend.category}</span></p>
                    {
                        !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={toogleGraph}>Timeline Graph<img className='h-7' src="./growth.png" alt="growth" /></p>
                    }
                    {
                        // !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => setShowCycleGraph(true)}>Rate Graph<img className='h-7' src="./growth.png" alt="growth" /></p>
                    }
                    <p className='mx-2 flex items-center'>{trend.images.length} <span className='text-xl'> <FaImages /></span></p>
                    <p className='mx-2 flex items-center'>{trend.totalLikes} <span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></p>
                    <p className='mx-2 flex items-center'>{trend.totalComments}<span className='text-xl'><BiCommentDots /></span></p>
                    {userLoggedIn.curator && !location && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => {deleteTrend(trend.id) }}>Delete Trend <span className=' text-red-700'><RiDeleteBinLine /></span></p>}
                    {userLoggedIn.curator && <p className='flex justify-center items-center cursor-pointer mx-2' onClick={() => { setShowOverlaps(true) }}><span>({overLapingTrends.length - 1 >= 0 ? overLapingTrends.length - 1 : 0})Overlapping Trends</span> <img src="overlapping-circles.png" className=' h-6' alt="overlapping" /> </p>}
                    {userLoggedIn.curator && <p className='flex justify-center items-center cursor-pointer mx-2 br-5 px-2' onClick={() => { duplicateTrend(trend.id) }}><span>Duplicate Trend</span> </p>}
                    {userLoggedIn.curator && <p className='mx-2'>Trend Id: {trend.id}</p>}
			  <button className='mx-1 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white flex items-center' onClick={()=> setShowPairItWith(true)}>Pair-it-with Beta</button>
			  <button className='mx-1 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white flex items-center' onClick={()=> setShowPriceRange(true)}>Price Range</button>	
                    {
                        !location && <button className='mx-4 bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded-xl text-white' onClick={() => { updateSavedData('trend', trend.id) }}>{trendSaved ? 'Remove' : 'Save'}</button>
                    }
                    {
                        location && <button className='mx-4 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => { restoreTrend(trend.id) }}> <span>Restore Trend</span> <MdRestore /></button>
                    }
                    {
                        userLoggedIn.curator && <button className={trend.reviewed?'mx-1 bg-yellow-500 px-3 py-1 rounded-xl text-white flex items-center':"'mx-1 bg-green-500 px-3 py-1 rounded-xl text-white flex items-center'"} onClick={handleGoingLive}>{trend.reviewed?'Go Review':'Go Live'}</button>
                    }
                </div>
            </div>
            <Popup isPopUp={showOverLaps} setIsPopUp={setShowOverlaps}>
                <OverLappingTrends pk={trend.id} fetch={fetch} setFetch={setFetch} selectedTrend={overlappingSelectedTrend} setSelectedCount={setSelectedCount} setSelectedTrend={setOverlappingSelectedTrend} selectedCount={selectedCount} overLapingTrends={overLapingTrends} />
            </Popup>
            <Popup isPopUp={showGraph} setIsPopUp={setShowGraph}>
                <Graph graphData={graphType === 0 ? trend.graphData : trend.nonCumGraphType} graphType={graphType} setGraphType={setGraphType} showDates={trend.showDates} />
            </Popup>
            <Popup isPopUp={showCycleGraph} setIsPopUp={setShowCycleGraph}>
                <Graph graphData={trend.cycleGraph} showDates={trend.showDates} />
            </Popup>
		{
			showPairItWith && <Popup isPopUp={true} setIsPopUp={setShowPairItWith}>
				<SimilarProductsTrend trend={trend} forPairItWith={true}/>
			</Popup>
		}
		{
			showPriceRange &&  <Popup isPopUp={true} setIsPopUp={setShowPriceRange}>
				<SimilarProductsTrend trend={trend} forPairItWith={false}/>
			</Popup>
		}
            <div className='flex overflow-auto'>
                {
                    trend.images.map((image, index) => <TrendImage selectedImage={selectedImage} key={index} setSelectedImage={setSelectedImage} setFetch={setFetch} uVal={trend.id} image={image} category={trend.category} gender={gender}/>)
                }
            </div>
        </div>
    )
}

export default Trend