import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductsPriceGraph from '../ProductsAnalysis/ProductsPriceGraph.jsx'
import ProductsPopup from "../ProductsAnalysis/ProductsPopup.jsx";
const RetailerProductsPopup = ({ trend }) => {

  const [loading, setLoading] = useState(true);
  const { userLoggedIn } = useSelector(state => state)
  const [fetch, setFetch] = useState(true)
  const [productPrices, setProductPrices] = useState([])
  const [selectedTagData, setSelectedTagData] = useState(null);
  const getData = () => {
    if (!trend || !fetch)
      return

    axios.get(`retailer-analysis-api/trend-charts/${trend._id}`)
      .then(res => {
        setProductPrices(res.data.productsPrices)
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })

    /* axios.get(`retailer-analysis-api/products?trendid=${trend._id}&category=${trend.retailerTagsData.retailerCategory}&ecommerce=Myntra`)
       .then(res => {
         setProductPrices(res.data.productsPrices)
       }).catch(console.error)
       .finally(() => {
         setLoading(false)
       })
       */
  }


  useEffect(getData, [trend, fetch])
  return <div className="h-[80vh] w-[80vw]">
    <ProductsPopup tagsData={selectedTagData} category={trend.retailerTagsData.retailerCategory} ecommerce={'Myntra'} rank={'1-10000'} trend={trend} >
      {
        productPrices && productPrices.length > 0 ?
          <ProductsPriceGraph products={productPrices} />
          : null
      }
    </ProductsPopup>
  </div>
}

export default RetailerProductsPopup 
