import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BrandsPopup from './BrandsPopup'
import { tagNames as initialTagNames } from './constants'
import { FemaleTrendToStlCategory, MaleTrendToStlCategory, months } from "../../constants";
import Popup from '../../component/Popup';
import ProductsPopup from "./ProductsPopup";
import ModalImage from "react-modal-image";
import { ImCross } from "react-icons/im";
import { SentenceCase } from "../../utility/helper_functions";
import { TailSpin } from "react-loader-spinner";

const DesignerFeatureTrends = () => {

  const [trends, setTrends] = useState([]);
  const [count, setCount] = useState(0)
  const [pages, setPages] = useState(1);
  const [params, setParams] = useSearchParams()
  const [brands, setBrands] = useState([])
  const [tagNames, setTagNames] = useState(initialTagNames)
  const [selectedTagKey, setSelectedTagKey] = useState("")
  const [tagsdata, setTagsData] = useState([])
  const [periodicData, setPeriodicData] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedTag, setSelectedTag] = useState(null)
  const [brandsString, setBrandsString] = useState(brands.length > 0 ? `&brand=${brands.map(b => encodeURIComponent(b)).join(',')}` : '')
  const [categoryCounts, setCategoryCounts] = useState({})
  const [selectedHighlight, setSelectedHighlight] = useState('');
  const [monthsSinceJanuary, setMonthsSinceJanuary] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [filteredTagsUrl, setFilteredTagsUrl] = useState('');
  const today = useRef(new Date());
  const setInitialParams = () => {

    setParams((prev) => {
      if (!prev.get('category')) {


        prev.set('category', 'All');
      }
      if (!prev.get('page')) {
        prev.set('page', '1');
      }
      prev.set('page', prev.get('page') || '1');
      prev.set('month', prev.get('month') || 'all');
      prev.set('limit', prev.get('limit') || 50)
      prev.set('sort', prev.get('sort') || 'popularity')
      prev.set('rank', prev.get('rank') || 'All')
      prev.set('gender', prev.get('gender') || 'Both')
      // prev.set('tag', prev.get('tag') || tagNames[0])

      return prev
    })
  }

  useEffect(() => {
    if (filteredTags.length == 0) {
      setFilteredTagsUrl('')
      return;
    }
    let returnStr = `&filterTagCount=${filteredTags.length}`
    for (let i = 0; i < filteredTags.length; i++) {
      const filterPair = filteredTags[i]
      returnStr = returnStr + `&filterTag${i + 1}=${filterPair.key}&filterTagValue${i + 1}=${filterPair.value}`
    }
    setFilteredTagsUrl(returnStr)
  }, [filteredTags])

  useEffect(() => {
    if (!params.get('category') || true)
      return
    axios.get(`/retailer-api/designer-feature-tags?gender=${params.get('gender')}&category=${params.get('category').split('&').join('%26')}${brandsString}&rank=${params.get('rank')}`).then(response => {
      const tagToShow = new Set(response.data);
      const newTagList = initialTagNames.filter(t => tagToShow.has(t))
      setTagNames(newTagList)
      // if (!tagToShow.has(params.get('tag'))) {
      //   setParams(prev => {
      //     prev.set('tag', newTagList[0] || 'color');
      //     return prev
      //   })
      // }
    })
  }, [params.get('gender'), params.get('rank'), params.get('category'), brandsString])
  useEffect(() => {

    if (!params.get('category'))
      return
    setTagsData([])
    setLoading(true)
    axios.get(`/retailer-api/designer-feature-tags-data?gender=${params.get('gender')}&category=${params.get('category').split('&').join('%26')}${brandsString}&rank=${params.get('rank')}&month=${params.get('month')}${filteredTagsUrl}`).then(response => {
      const sortedTagsData = [...response.data].filter(t => initialTagNames.includes(t._id)).sort((a, b) => {

        return initialTagNames.indexOf(a._id) - initialTagNames.indexOf(b._id)
      })
      setTagsData(sortedTagsData);
    }).catch(e => { console.error(e) })
      .finally(() => {
        setLoading(false)
      })
  }, [params.get('gender'), params.get('rank'), params.get('category'), brandsString, params.get('month'), filteredTagsUrl])


  useEffect(() => {
    setInitialParams()
  }, [])

  useEffect(() => {
    setBrandsString(brands.length > 0 ? `&brand=${brands.map(b => encodeURIComponent(b)).join(',')}` : '')
  }, [brands])

  useEffect(() => {
    if (params.size === 0) {
      setInitialParams()
    }
  }, [params])
  const getTrends = () => {
    if (!params.get('page') || !params.get('tag'))
      return

    setTrends([])

    axios.get(`/retailer-api/designer-feature-trends?gender=${params.get('gender')}&category=${params.get('category')?.split('&').join('%26')}&page=${params.get('page')}&limit=${params.get('limit')}&tagName=${params.get('tag')}&brands=${brandsString}&rank=${params.get('rank')}&sort=${params.get('sort')}&month=${params.get('month')}`).then(response => {
      setTrends(response.data.trends)
      setCount(response.data.count)
      setPages(Math.ceil(response.data.count / params.get('limit')))
      window.scrollTo(0, 0)
    }).catch(e => {
      console.log(e);
    })
  }

  const getCategoryCounts = () => {
    axios.get(`/retailer-api/designer-feature-category-counts?gender=${params.get('gender')}${params.get('tag') ? `&tagName=${params.get('tag')}` : ''}&brands=${brandsString}&rank=${params.get('rank')}&month=${params.get('month')}`).then(response => {
      setCategoryCounts(response.data)

    }).catch(console.error)
  }

  useEffect(getCategoryCounts, [brandsString, params.get('tag'), params.get('rank'), params.get('gender'), params.get('month')])


  const getGenderCounts = () => {
    //     axios.get(`/retailer-api/designer-feature-gender-counts?${params.get('tag')?`tagName=${params.get('tag')}&`:''}brands=${brandsString}&rank=${params.get('rank')}&category=${params.get('category')?.split('&').join('%26')}`).then(response => {
    //       setCategoryCounts(response.data)

    //     }).catch(console.error)
  }

  useEffect(getGenderCounts, [brandsString, params.get('tag'), params.get('rank'), params.get('category'), params.get('month')])
  function updatePage(change) {
    setParams(prev => {
      prev.set('page', parseInt(prev.get('page')) + change)
      return prev
    })
  }

  const updateMonths = async () => {
    const startDate = new Date(new Date().getTime() + 30 * 3600 * 1000 * 24)
    startDate.setDate(15);
    const endDate = new Date(2024, 0, 1)
    let monthsArray = []
    let tempDate = startDate
    while (tempDate > endDate) {
      monthsArray.push(`${months[tempDate.getMonth()]}-${tempDate.getFullYear()}`)
      tempDate.setMonth(tempDate.getMonth() - 1);
    }
    setMonthsSinceJanuary(monthsArray)
  }
  useEffect(() => {
    updateMonths()
  }, []);

  const previosBrandString = useRef('');
  useEffect(() => {
    if (brandsString == previosBrandString.current) {
      return;
    }
    previosBrandString.current = brandsString
    setParams(prev => {

      prev.set('page', 1)
      return prev;
    })
  }, [brandsString])

  function changeParams(event) {
    setParams(prev => {
      prev.set(event.target.name, event.target.value)
      if (event.target.name === 'gender') {
        prev.set('category', 'All');
      }
      prev.set('page', 1)
      return prev
    })
  }

  const getPeriodicData = () => {

    if (!params.get('periodic'))
      return
    setPeriodicData([])
    setLoading(true)
    axios.get(`/retailer-api/designer-feature-periodic-data?gender=${params.get('gender')}&category=${params.get('category').split('&').join('%26')}${brandsString}&rank=${params.get('rank')}&tag=${params.get('periodic')}&month=${params.get('month')}`).then(response => {
      setPeriodicData(response.data);
    }).catch(e => {
      console.error((e))
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateFilterTag = (e) => {
    e.preventDefault()
    if (e && e.stopPropagation) e.stopPropagation();
    const name = e.target.name;
    const tagName = name.split('--')[0];
    const tagValue = name.split('--')[1];
    setFilteredTags(prev => {
      prev = [...prev]
      for (let pair of prev) {
        if (pair.key == tagName) {
          if (pair.value == tagValue) {
            const newList = prev.filter(p => p.key != tagName)
            return newList
          } else {
            pair.value = tagValue
            return prev
          }
        }
      }
      const tagDoc = tagsdata.filter(t => t.tag == tagName)[0]
      prev.push({ key: tagName, value: tagValue, products: tagDoc?.products, total: tagDoc.total })
      return prev
    })

  }
  useEffect(getPeriodicData, [params.get('periodic'), brandsString, params.get('gender'), params.get('rank'), params.get('month')]);

  useEffect(getTrends, [params, brandsString])

  return (<div className="w-screen">
    <div className="flex flex-row sticky bg-white py-2 top-12 w-full flex-wrap">
      <div className="px-6 w-2/3 shrink-0">
        {
          false && <select value={params.get('sort')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='sort' >
            <option value='' disabled={true}>--sort--</option>
            <option value='recency'>Sort By Recency</option>
            <option value='popularity'>Sort By Popularity</option>
          </select>
        }
        <select value={params.get('gender')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='gender'>
          <option value='' disabled={true}>--gender--</option>
          <option value='Both'>Both Genders</option>
          <option value='F'>Female</option>
          <option value='M'>Male</option>
        </select>
        <select value={params.get('month')} className='px-4 py-1 rounded mx-2' onChange={changeParams} name='month'>
          <option value='' disabled={true}>--month--</option>
          <option value='all'>Select months (all)</option>
          {
            monthsSinceJanuary.slice(1).map(m => <option key={m} value={m}>{m}</option>)
          }
        </select>

        <BrandsPopup brands={brands} setBrands={setBrands} category={params.get('category')} gender={params.get('gender')} rank={params.get('rank')} tag={params.get('tag')} month={params.get('month')} />
        {
          false && <select value={params.get('rank')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='rank'>
            <option value='' disabled={true}>--Recency--</option>
            {
              ['All', '1-100', ' 1-250', ' 1-500', ' 1-1k', ' 1-2.5k', ' 1-5k', ' 1-10k', '1-20k', '1-25k', '1-50k']
                .map(rank => <option value={rank} >Recent {rank}</option>)
            }
          </select>
        }
        <select value={params.get('category')} className='px-2 py-1 rounded mx-2' onChange={changeParams} name='category'>
          <option value='' disabled={true}>--category--</option>
          <option value='All' >All Categories ({categoryCounts.all || '0'})</option>
          <option value='topwear' >All Topwear ({categoryCounts.topwear || '0'})</option>
          <option value='bottomwear' >All Bottomwear ({categoryCounts.bottomwear || '0'})</option>
          <option value='outerwear' >All Outerwear ({categoryCounts.outerwear || '0'})</option>
          <option value='one-piece' >All One piece ({categoryCounts.onepiece || '0'})</option>
          <option value='clothing' >All Clothing ({categoryCounts.clothing || '0'})</option>
          <option value='footwear' >All Bags & Footwear ({categoryCounts.footwear || '0'})</option>
          {(params.get('gender') === 'F' ? FemaleTrendToStlCategory :
            params.get('gender') === 'M' ? MaleTrendToStlCategory.filter(p => !["Loungewear", "Shoes", "Handbags", "Clutches", "Sunglasses"].includes(p))
              : Array.from(new Set([...FemaleTrendToStlCategory, ...MaleTrendToStlCategory].filter(p => !["Loungewear", "Shoes", "Handbags", "Clutches", "Sunglasses"].includes(p)))))
            .filter(p => !["Heels", "Sunglasses", "Co-ord Sets", "Flats"].includes(p))
            .map(cat => <option key={cat} value={cat}>{cat} ({categoryCounts[cat] || '0'})</option>)
          }
        </select>

      </div>
      <div className="w-full shrink-0 px-6">
      </div>
      <div className="relative w-screen pb-2    bg-white ">
        {/*<div className="shrink-0  flex px-[4vw] my-1 flex-wrap justify-stretch bg-white">
          {tagNames.map((tag, i) => <button className={`px-2 py-[1px] text-sm my-[1px] bg-slate-600  text-white h-10  ${params.get('tag') === tag ? 'bg-slate-800' : ''} ${i === 0 ? 'rounded-l-lg' : ''} ${i !== tagNames.length - 1 ? 'border-r border-r-white' : 'rounded-r-lg'}`} onClick={() => setParams(prev => {
            prev.set('tag', tag)
            prev.set('page', 1)
            return prev
          })}>{tag == 'color' ? 'single color' : tag}</button>)}
	</div>*/}
      </div>
    </div>

    {
      loading &&
      <div className="w-full h-[70vh] flex justify-center items-center">
        <TailSpin
        />
      </div>
    }

    {params.get("tag") && <div className="w-screen pb-20 ">
      <h2 className="w-full text-xl font-medium text-center">{SentenceCase(params.get('tag') == 'color' ? 'single color' : params.get('tag'))} Trends<button className="ml-4" onClick={() => setParams(prev => { prev.delete("tag"); return prev; })}> <ImCross /> </button></h2>

      {trends.map((trend, i) => {
        return <div className="w-full border-b-2 pt-14">
          <div className="flex justify-between w-full">
            <h3 className="font-semibold pl-10 text-xl ">{trend.name} - {trend.count} products</h3>
            <div className="shrink-0 pr-60 " >
              <div className="p-2  rounded-xl hover:bg-blue-600 bg-blue-500 text-center font-semibold text-white cursor-pointer" onClick={() => { setSelectedTag(trend.name) }}>
                All Products
              </div>
            </div>
          </div>
          <div key={i} className="w-screen overflow-y-hidden flex overflow-auto h-80  px-6">

            {trend.products.map((prod, i) => {
              return <div key={i} className="align-bottom w-60 shrink-0 h-full py-4 mx-1 hover:shadow" >
                <div className='h-3/5 w-full flex justify-center'>
                  <ModalImage src={prod.image_url} className=' h-[10rem]' small={prod.image_url} large={prod.image_url} hideZoom={false} hideDownload={true} />
                </div>
                <div className="h-2/5 pl-4 border-t-2 border-t-slate-200 border-t-w-">
                  <p className="text-sm">Recency - {prod.rank}</p>
                  <p className="font-bold">{prod.brand}</p>
                  <p>{prod.title}</p>
                </div>
              </div>
            })}
          </div>
        </div>
      })}
      <div className="fixed bottom-0 w-screen bg-white">
        <div className="bg-white py-2 top-12 w-full flex-wrap">
          <div className="ml-auto pr-6 w-1/3 shrink-0">
            <button className="primary-btn-padding" onClick={() => updatePage(-1)} disabled={params.get('page') <= 1}>Prev</button>
            {count} {params.get('tag')} Trends
            <button className="primary-btn-padding" onClick={() => updatePage(1)} disabled={params.get('page') >= pages}>Next</button>
          </div>

        </div>
      </div>

    </div>}

    {
      !loading
      && !params.get("tag")
      && !params.get('periodic')
      && filteredTags.length > 0
      && <h2 className=" text-blue-600 font-bold text-center my-2">Breakdown of {filteredTags.map((tg, i) => <span key={tg.key}>{tg.key} - {tg.value}<button className="ml-1 mr-2" onClick={() => { setFilteredTags(prev => { prev = prev.filter(k => k.key != tg.key); return prev; }) }}><ImCross /></button>{i < filteredTags.length - 1 ? ', ' : ''}</span>)}</h2>
    }
    {!params.get("tag") && !params.get('periodic') && <div className="w-screen flex overflow-x-auto">

      {!loading &&
        filteredTags.map((tagdata) => {
          return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1 border-dashed bg-[#f7d3d2]" key={tagdata.key}>
            <div className="border-b border-slate-500 float-left w-full border-dashed">
              <button className="w-full text-center text-blue-500 font-semibold hover:underline " onClick={() => setParams(prev => {
                prev.set("tag", tagdata.key)
                return prev
              })}>{SentenceCase(tagdata.key)} Trends</button>
              <p className="pl-4 text-sm text-slate-500 text-center">{tagdata.total} {tagdata.key} Trends</p>
            </div>
            <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[80vh]">
              {
                tagdata.products && tagdata.products.map(prod => <div className={`h-40 w-60 flex items-center justify-center float-left shrink-0 ${tagdata.value !== prod[0].value ? '' : 'bg-white'}`}>
                  <div className="w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow hover:shadow-xl float-left group relative">
                    <h3 className="text-sm text-center text-slate-500"> {prod[0].value} - <span className="text-slate-400"> {prod[1]}</span></h3>
                    <div className="h-4/5 w-4/5 flex justify-center items-center object-cover overflow-hidden mx-auto">
                      <img className="h-20 " src={prod[2].cdnUrl || prod[2].image_url} loading="lazy" />
                    </div>
                    <div className="absolute bottom-1 w-full hidden group-hover:flex z-10 justify-between text-xs px-3">
                      <button className="z-10 bg-blue-700 rounded px-2 py-1 text-white" onClick={() => { setSelectedTagKey(tagdata.key); setSelectedTag(prod[0].value); }} >See Products</button>
                      {
                        tagdata.value == prod[0].value && <button
                          className={` z-10 ${tagdata.value == prod[0].value ? 'bg-red-400 hover:bg-red-500' : 'bg-blue-700 hover:bg-blue-600'} text-white rounded px-2 py-1`}
                          onClick={updateFilterTag} name={`${tagdata.key}--${prod[0].value}--delete`} >{tagdata.value == prod[0].value ? 'Remove' : 'Breakdown'}</button>
                      }
                    </div>
                  </div>
                </div>)
              }


            </div>
            <div className="h-0 relative w-full">
              <div className="absolute bottom-4 w-full flex justify-center items-center">
                {
                  false && <button className="mx-auto bg-white  p-1 px-3 border-slate-600 border rounded-lg hover:bg-sky-100 " onClick={() => { setParams(prev => { prev.set('periodic', tagdata.tag); return prev; }); setSelectedHighlight(''); }}>See Monthly Changes</button>
                }
              </div>
            </div>
          </div>
        })
      }
      {tagsdata.map((tagdata, i) => {
        return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1" key={i}>
          <div className="border-b border-slate-500 float-left w-full">
            <button className="w-full text-center text-blue-500 font-semibold hover:underline" onClick={() => setParams(prev => {
              prev.set("tag", tagdata.tag)
              return prev
            })}>{SentenceCase(tagdata.tag)} Trends</button>
            <p className="pl-4 text-sm text-slate-500 text-center">{tagdata.total} {tagdata.tag} Trends</p>
          </div>
          <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[80vh]">
            {
              tagdata.products && tagdata.products.map(prod => <div className="h-40 w-60 flex items-center justify-center float-left shrink-0">
                <div className="w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow hover:shadow-xl float-left group" onClick={() => { setSelectedTagKey(tagdata.tag); setSelectedTag(prod[0].value); }}>
                  <h3 className="text-sm text-center text-slate-500"> {prod[0].value} - <span className="text-slate-400"> {prod[1]}</span></h3>
                  <div className="h-4/5 w-4/5 flex justify-center items-center object-cover overflow-hidden">
                    <img className="h-20 " src={prod[2].cdnUrl || prod[2].image_url} loading="lazy" />
                  </div>

                  <div className="w-full h-0 relative">
                    <div className="absolute bottom-1 w-full hidden group-hover:flex z-10 justify-between text-xs px-3">
                      <button className="z-10 bg-blue-700 rounded px-2 py-1 text-white" onClick={() => { setSelectedTagKey(tagdata.tag); setSelectedTag(prod[0].value); }}>See Products</button>
                      <button className={`z-10 bg-blue-700 hover:bg-blue-600 text-white rounded px-2 py-1`} onClick={updateFilterTag} name={`${tagdata.tag}--${prod[0].value}`} >Breakdown</button>
                    </div>
                  </div>
                </div>
              </div>)
            }


          </div>
          <div className="h-0 relative w-full">
            <div className="absolute bottom-4 w-full flex justify-center items-center">
              {filteredTags.length < 1 && <button className="mx-auto bg-white  p-1 px-3 border-slate-600 border rounded-lg hover:bg-sky-100 " onClick={() => { setParams(prev => { prev.set('periodic', tagdata.tag); return prev; }); setSelectedHighlight(''); }}>See Monthly Changes</button>
              }
            </div>
          </div>
        </div>
      })}
    </div>}

    {
      !loading && !params.get("tag") && params.get('periodic') && <>

        <h2 className="w-full text-xl font-medium text-center">{SentenceCase(params.get('periodic') == 'color' ? 'single color' : params.get('periodic'))} Monthly Changes<button className="ml-4" onClick={() => setParams(prev => { prev.delete("periodic"); return prev; })}> <ImCross /> </button></h2>
        <div className="w-screen flex overflow-x-auto">

          {periodicData.map((period, i) => {
            return <div className="w-[15rem] border border-slate-500 h-[85vh] shrink-0 mt-1" key={i}>
              <div className="border-b border-slate-500 float-left w-full">
                <h3 className="w-full text-center text-blue-500 font-semibold hover:underline"  >{i == 0 ? 'Current' : `-${i}`} Month</h3>
                <p className="pl-4 text-sm text-slate-500 text-center">{months[period.month.month - 1]} {period.month.year}</p>
              </div>
              <div className="flex flex-col w-[15rem] overflow-y-auto overflow-x-hidden max-h-[75vh]">
                {
                  period.products && period.products.map(prod => <div className={`w-60 flex items-center justify-center float-left shrink-0 my-1  `} onMouseEnter={() => setSelectedHighlight(prev => prev == prod.name ? '' : prod.name)} onMouseLeave={() => setSelectedHighlight(prev => '')}   >
                    <div className={`w-[90%] h-[90%] border-2 rounded-lg shrink-0 shadow-sm hover:shadow float-left px-3 py-2  ${selectedHighlight == prod.name ? 'bg-[#8cb6fa] font-semibold text-white border-none' : ''}  `} >
                      <h3 className={`text-xs text-center `}  >{SentenceCase(prod.name)} - <span className="">{prod.total} products</span></h3>
                    </div>
                  </div>)
                }


              </div>
            </div>
          })}
        </div>
      </>
    }

    <Popup isPopUp={selectedTag} setIsPopUp={setSelectedTag}>
      <ProductsPopup gender={params.get('gender')} category={params.get('category')?.split('&').join('%26')} tagName={params.get('tag') || selectedTagKey} tagValue={selectedTag} rank={params.get('rank')} brandsString={brandsString} month={params.get('month')} filteredTagsUrl={filteredTagsUrl} />
    </Popup>
  </div >)
}
export default DesignerFeatureTrends
