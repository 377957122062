import { lifecycleSuggestions } from "../../utility/lifeCycleSuggestions";
import { Tooltip as ReactTooltip } from "react-tooltip";
const TrendLifeCycleTooptip = ({ trend, trendstype, index }) => {

  return trend.lifecycle && trendstype == 'social' ? <>
    <p className="h-4 shrink-0 mx-2 text-xs font-medium hover:font-semibold cursor-help" data-tooltip-id={trend._id || `${index}`} >{trend.lifecycle}</p>

    <ReactTooltip
      id={trend._id || `${index}`}
      place="bottom"
      className="w-60"

    >
      <div className="py-1">
        <div className="">Suggestion : {lifecycleSuggestions[trend.lifecycle]}</div>
      </div>
      <div className="py-1 text-[11px]">
        <div className="">Past 7 day timeframe: {trend.is7dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
        <div className="">Past 15 day timeframe: {trend.is15dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
        <div className="">Past 30 day timeframe: {trend.is30dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
        <div className="">Past 60 day timeframe: {trend.is60dayTrend ? 'Above Thershold' : 'Below Thershold'}</div>
      </div>
    </ReactTooltip>
  </> : null
}

export default TrendLifeCycleTooptip
