import React from 'react'
import Image from './Image'
import { useState } from 'react';

const ImageSet = (props) => {
  const { responseReceived, images, queryErrored, selectedImages, updateSelectedImage } = props
  return (
    <div className=' w-full mt-[10vh] flex flex-wrap justify-center overflow-auto h-[80vh]'>
      {!responseReceived
        ? <>
          <div className="bottom-0 inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
          </div>
        </>
        : <>
          {
            !queryErrored
              ? images.map((img, index) => <Image image={img} key={index} selectedImages={selectedImages} updateSelectedImage={updateSelectedImage} />)
              : <p>No similar styles found.</p>
          }
        </>}
    </div>
  )
}

export default ImageSet

