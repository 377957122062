import axios from "axios";
import { useEffect, useRef, useState } from "react"
import Select from "react-dropdown-select";
import { acceptedTags, MajorColorArrays, ColorMapping, patterns, textures, FemaleTrendToStlCategory, MaleTrendToStlCategory } from '../../constants'

function TagsUpdatePopup({ mediaId }) {

  const [fetch, setFetch] = useState(true)
  const [imageData, setImageData] = useState(null)
  const [category, setCategory] = useState('All Trends')
  const [subcategory, setSubCategory] = useState('All Trends')
  const [errorMessage, setErrorMessage] = useState(null)
  const [tagsText, setTagsText] = useState("")
  const [tagValuePairs, setTagvaluepairs] = useState([])
  const [colorsText, setColorsText] = useState("")
  const colorAllValues = Object.keys(ColorMapping)
  const getImageData = () => {
    setFetch(false);
    console.log(fetch)
    axios.get(`/usedimages-api/image-data/${mediaId}`).then(response => {
      setImageData(response.data)
    })
  }
  const updateTagsData = () => {
    const category = document.querySelector('#update-tags-category').value
    if (category == 'All Trends' || !category) {
      setErrorMessage('Please select category.')
      return
    }
    const tagsText = document.querySelector('#update-tags-text').value
    const lines = tagsText.split('\n').filter(line => line != '')
    const lastLine = lines[lines.length - 1];
    if (lines.length != 0 && (lastLine[lastLine.length - 1] != ']' && !lastLine.includes('.'))) {
      setErrorMessage('Please check copied text')
      return;
    }
    for (let tag of Object.keys(tagValuePairs)) {
      if (!acceptedTags.includes(tag))
        setErrorMessage(`${tag} - not accepted in tags `)
    }
    axios.post(`/usedimages-api/update-tags/${mediaId}`, { category, tagsText, tagValuePairs }).then(response => {
      setFetch(true);
    })
  }


  const updateColorsData = () => {
    const category = document.querySelector('#update-tags-category').value
    if (category == 'All Trends' || !category) {
      setErrorMessage('Please select category.')
      return
    }
    const tagsText = document.querySelector('#update-colors-text').value
    const lines = tagsText.split('\n').filter(line => line != '').map(l => l.trim())
    axios.post(`/usedimages-api/update-colors/${mediaId}`, { category, tagsText, lines }).then(response => {
      setFetch(true);
    })
  }


  const updateSpecificColorsData = (values, index = 1) => {
    if (values.length == 0) return
    const value = values[0].name
    const category = document.querySelector('#update-tags-category').value
    if (category == 'All Trends' || !category) {
      setErrorMessage('Please select category.')
      return
    }
    const tagsText = value === 'delete' ? 'delete' : ColorMapping[value]
    axios.post(`/usedimages-api/update-primary-color/${index}/${mediaId}`, { category, color: tagsText }).then(response => {
      setFetch(true);
    })
  }

  const updateTexture = (values, index = 1) => {
    if (values.length == 0) return
    const value = values[0].name
    const category = document.querySelector('#update-tags-category').value
    if (category == 'All Trends' || !category) {
      setErrorMessage('Please select category.')
      return
    }
    axios.post(`/usedimages-api/update-texture/${index}/${mediaId}`, { category, texture: value }).then(response => {
      setFetch(true);
    })
  }

  const updatePattern = (values, index = 1) => {
    if (values.length == 0) return
    const value = values[0].name
    const category = document.querySelector('#update-tags-category').value
    if (category == 'All Trends' || !category) {
      setErrorMessage('Please select category.')
      return
    }
    axios.post(`/usedimages-api/update-pattern/${index}/${mediaId}`, { category, pattern: value }).then(response => {
      setFetch(true);
    })
  }

  const resetTagsText = () => {
    const textbox = document.querySelector('#update-tags-text')
    if (textbox)
      textbox.value = ''
  }

  useEffect(() => {
    const tagPairs = {}
    let tagsTextUpdated = tagsText.split('\n')
    let currentKey = null;
    for (let tagLine of tagsTextUpdated) {
      if (tagLine.includes(':')) {
        if (currentKey) {
          tagPairs[currentKey] = '';
        }
        currentKey = tagLine.split(':')[0]
      }

      else if (tagLine.includes(']')) {
        const val = tagLine.split('[').join('').split(']').join('');
        tagPairs[currentKey] = val
        currentKey = null;
      }

    }
    if (currentKey) {
      tagPairs[currentKey] = ''
    }
    setTagvaluepairs(tagPairs);
  }, [tagsText])
  useEffect(resetTagsText, [category, mediaId]);
  useEffect(() => { setErrorMessage(null) }, [tagsText])

  useEffect(() => {
    if (!mediaId) return
    if (fetch)
      getImageData();
  }, [mediaId, fetch])

  useEffect(() => {
    if (errorMessage && category) {
      setErrorMessage(null)
    }
  }, [category])
  return (
    <div className="w-[80vw] overflow-auto h-[80vh] flex flex-row">
      {imageData && <>
        <div className="w-1/2 shrink-0 border-r-2 border-slate-300">
          <img className="w-full mx-auto" src={imageData.imageUrl} />
          <p className="text-center mt-4">{imageData.mediaId}</p>
          <button
            className="bg-slate-200 rounded-lg hover:bg-slate-400 hover:text-white active:bg-slate-600 font-bold px-4 py-2 active:text-white mx-auto"
            onClick={() => { window.navigator.clipboard.writeText(imageData.imageUrl) }}
          >Copy Image Url</button>
          <hr />
          <div className="w-full mt-4">
            {imageData && imageData.tags && Object.keys(imageData.tags).map(category => <div>
              <h3>{category}</h3>
              <div className="px-10">
                {Object.keys(imageData.tags[category]).map(tag => <div className="flex w-full "><p className="w-1/2">{tag}</p><p className="w-1/2">{imageData.tags[category][tag]}</p></div>)}

              </div>
            </div>)}
          </div>



        </div>
        <div className="w-1/2 px-6 shrink-0">
          <div className='text-2xl flex justify-center items-center py-10 flex-col'>
            <select id="update-tags-category" value={category} onChange={(e) => { setCategory(e.target.value) }}>
              <option value='' >--select subcategory--</option>
              {(imageData.gender == "Female" ? FemaleTrendToStlCategory : MaleTrendToStlCategory).filter(cat => cat != "All Trends").map(cat => < option value={cat} >{cat}</option>)}
            </select>
          </div>

          <div className="border-b-2 border-b-slate-500">
            <h3 className="text-xl font-bold ">Patterns</h3>
            <div className="w-full mt-4">
              {
                imageData && imageData.pattern && Object.keys(imageData.pattern).map((category, i) => <div>
                  <h3 className="font-semibold">{i + 1}. {category}</h3>
                  <div className="flex w-full">
                    <div className="px-10">1. {imageData.pattern[category]}</div>
                    {imageData.pattern2 && imageData.pattern2[category] && <div className="px-10">2. {imageData.pattern2[category]}</div>}
                  </div>
                </div>)
              }

            </div>
          </div>
          <div className="border-b-2 border-b-slate-500">
            <h3 className="text-xl font-bold ">Texture & Fabric</h3>
            <div className="w-full mt-4">
              {
                imageData && imageData.texture && Object.keys(imageData.texture).map((category, i) => <div>
                  <h3 className="font-semibold">{i + 1}. {category}</h3>
                  <div className="flex w-full">
                    <div className="px-10">1. {imageData.texture[category]}</div>
                    {imageData && imageData.texture2 && imageData.texture2[category] && <div className="px-10"> 2. {imageData.texture2[category]}</div>}
                  </div>
                </div>)
              }

            </div>
          </div>


          <div className="border-b-2 border-b-slate-500">
            <h3 className="text-xl font-bold ">Specific Color 1</h3>
            <div className="w-full mt-4">
              {
                imageData && imageData.specificColorPrimary && Object.keys(imageData.specificColorPrimary).map((category, i) => <div>
                  <h3 className="font-semibold">{i + 1}. {category}</h3>
                  <div className="px-10">{imageData.specificColorPrimary[category]} - [{MajorColorArrays[imageData.specificColorPrimary[category]]?.join(' , ')}]</div>
                </div>)
              }

            </div>
          </div>
          <div className="border-b-2 border-b-slate-500">
            <h3 className="text-xl font-bold ">Specific Color 2</h3>
            <div className="w-full mt-4">
              {
                imageData && imageData.specificColorSecondary && Object.keys(imageData.specificColorSecondary).map((category, i) => <div>
                  <h3 className="font-semibold">{i + 1}. {category}</h3>
                  <div className="px-10">{imageData.specificColorSecondary[category]} - [{MajorColorArrays[imageData.specificColorSecondary[category]]?.join(' , ')}]</div>
                </div>)
              }

            </div>
          </div>
          <div>
            <div className="w-full flex my-2 border-2 border-slate-400">
              <div className="w-full">
                <label>Pattern</label>
                <div className="w-full flex ">
                  <div className="w-1/2">
                    <Select
                      key={`pattern_1_${category}`}
                      options={[{ name: 'delete' }, ...patterns.map(c => ({ name: c }))]}
                      labelField="name"
                      valueField="name"
                      values={imageData?.pattern && imageData.pattern[category] ? [{ name: imageData.pattern[category] }] : []}
                      placeholder="Pattern 1"
                      onChange={values => { updatePattern(values, 1) }}
                    />
                  </div>
                  <div className="w-1/2">
                    <Select
                      key={`pattern_2_${category}`}
                      options={[{ name: 'delete' }, ...patterns.map(c => ({ name: c }))]}
                      labelField="name"
                      valueField="name"
                      placeholder="Pattern 2"
                      values={imageData?.pattern2 && imageData.pattern2[category] ? [{ name: imageData.pattern2[category] }] : []}
                      onChange={values => { updatePattern(values, 2) }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex my-2 border-2 border-slate-400">
              <div className="w-full">
                <label>Textures & Fabric</label>
                <div className="w-full flex ">
                  <div className="w-1/2">
                    <Select
                      key={`texture_${category}`}
                      options={[{ name: 'delete' }, ...textures.map(c => ({ name: c }))]}
                      labelField="name"
                      valueField="name"
                      placeholder="Texture 1"
                      values={imageData?.texture && imageData.texture[category] ? [{ name: imageData.texture[category] }] : []}
                      onChange={values => { updateTexture(values, 1) }}
                    />
                  </div>
                  <div className="w-1/2">
                    <Select
                      key={`texture_${category}`}
                      options={[{ name: 'delete' }, ...textures.map(c => ({ name: c }))]}
                      labelField="name"
                      valueField="name"
                      placeholder="Texture 2"
                      values={imageData?.texture && imageData.texture[category] ? [{ name: imageData.texture[category] }] : []}
                      onChange={values => { updateTexture(values, 2) }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex my-2 border-2 border-slate-400 flex-wrap">

              <div className="w-1/2">
                <label>Color 1</label>
                <Select
                  key={`color_${category}`}
                  options={[{ name: 'delete' }, ...colorAllValues.map(c => ({ name: c }))]}
                  labelField="name"
                  valueField="name"
                  placeholder="Color 1"
                  values={imageData?.specificColorPrimary && imageData.specificColorPrimary[category] ? [{ name: imageData.specificColorPrimary[category] }] : []}
                  onChange={values => { updateSpecificColorsData(values, 1) }}
                />
              </div>
              <div className="w-1/2">
                <label>Color 2</label>
                <Select
                  key={`color_${category}`}
                  options={[{ name: 'delete' }, ...colorAllValues.map(c => ({ name: c }))]}
                  labelField="name"
                  valueField="name"
                  placeholder="Color 2"
                  values={imageData?.specificColorSecondary && imageData.specificColorSecondary[category] ? [{ name: imageData.specificColorSecondary[category] }] : []}
                  onChange={values => { updateSpecificColorsData(values, 2) }}
                />
              </div>
            </div>
          </div>
          {/*
          <textarea id="update-tags-text" className="h-60 w-full bg-slate-50 border-2 rounded-xl outline-none p-6" value={tagsText} onChange={e => { setTagsText(e.target.value) }} />
          */}
          {errorMessage && <p className="font-semibold text-red-400">{errorMessage}</p>}
          {/*
          <button className="w-full py-2 text-center bg-slate-400 text-white rounded-xl font-bold my-2 hover:bg-slate-500 active:bg-slate-600" onClick={updateTagsData}>Update tags for category</button>
          */}
          <div>
            {Object.keys(tagValuePairs).map(k => <div key={k} className="flex flex-row w-full"><p className="w-1/2 text-center font-semibold">{k}</p><p className="w-1/2 text-center">{tagValuePairs[k]}</p> </div>)}
          </div>
        </div>
      </>}
    </div >
  )

}

export default TagsUpdatePopup
