import axios from "axios";
import { useEffect, useState } from "react";
import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";



const EcommerceProductsDisplay = ({ pk, downloadTrendProductsData, ecommerce, pairItWith, category }) => {

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false);
  const [barChartData, setBarChartData] = useState([])
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const getAmazonProducts = async () => {
    axios.get(`/products/amazon-saved-products/${pk}`).then(response => {
      setProducts(response.data)
      setBarChartData(getBarChartData(response.data.map(prod => prod.price)))
    }).catch(() => {
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }

  const fetchSaveProducts = () => {
    axios.post('/products/get-saved-products', { trendId: pk })
      .then(response => {
        setSelectedProductIds(response.data.productIds)
      })
  }


  const selectProducts = (action, ids) => {
    axios.post('/products/save-products', { trendId: pk, productIds: ids, action })
      .then(response => {
        fetchSaveProducts()
      })

  }

  const getBarChartData = (price) => {
    price.sort(function(a, b) { return a - b })
    price = price.filter(p => p != null && p < 10000)
    let arr = []
    let s = price[0]
    let e = price[price.length - 1]
    let t = Math.floor(s / 100) * 100;
    let t2 = 0;
    while (t < e) {
      let t1 = {}
      t1.priceRange = `${t + 1}-${t + 100}`
      t1.count = price.filter(p => p >= t + 1 && p <= t + 100).length
      arr.push(t1)
      t += 100
    }
    return arr;
  }

  const getEcommerceProducts = () => {
    axios.get(`/products/trend-products/${pk}?ecommerce=${ecommerce}&category=${category}`).then(response => {
      setProducts(response.data)
      setBarChartData(getBarChartData(response.data.map(prod => prod.price)))
    }).catch(() => {
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  }

  const getProducts = async () => {
    setLoading(true);
    if (ecommerce == 'Amazon') {
      getAmazonProducts()
    } else {
      getEcommerceProducts();
    }

  }


  useEffect(() => {
    if (ecommerce && pk)
      getProducts()


  }, [ecommerce, pk])

  return <div className="w-[80vw] h-[80vh]">
    <div className="w-[80%] h-8 " >
      {ecommerce == 'Amazon' && <div className="float-right">
        <button disabled={products.length == 0} className={`disabled:bg-blue-200 mx-1 px-3 py-1 rounded-xl text-white flex items-center bg-blue-500 mr-4 mb-3`} onClick={e => { downloadTrendProductsData(pk, true) }}  >Download Products</button>
      </div>}
    </div>
    <div className="w-full">

      {

        loading || !products || !products.hasOwnProperty('length') || products.length == 0
          ? null :
          <>
            <h3 className="text-slate-400 mt-2 text-center ">Range of Price</h3>
            <ResponsiveContainer height={250} width={"95%"}>
              <BarChart data={barChartData} margin={{ bottom: 50 }}>
                <XAxis tick={false} dataKey={'priceRange'} >
                  <Label value={""} dy={22} /> </XAxis>
                <YAxis allowDecimals={false} dataKey={'count'}><Label value={"Quantity"} position={'insideLeft'} angle={-90} offset={10} /></YAxis>
                <Tooltip />
                <Bar fill="#475569" dataKey={'count'} >
                  <LabelList dataKey={'priceRange'} fill="black" angle={-90} position={'insideBottom'} offset={-30} style={{ fontSize: '8px' }} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
      }
    </div>
    <h2 className="text-2xl font-medium text-center m-3">Shop-by-Trends on {ecommerce}</h2>
    {
      ecommerce == "Amazon" &&
      <>
        <button onClick={() => selectProducts('remove', products.map(p => p.ASIN))} className="bg-slate-500 text-white h-8 text-sm px-6 py-1.5 mx-1">Deselect All</button>
        <button onClick={() => selectProducts('add', products.map(p => p.ASIN))} className="bg-slate-500 text-white h-8 text-sm px-6 py-1.5 mx-1">Select All</button>
        <p className="inline-block mx-2 font-semibold">{selectedProductIds.length} products selected</p>
      </>
    }
    <div className="w-[90%] flex flex-row flex-wrap">
      {

        loading ?
          <div>Loading</div>
          :
          !products || !products.hasOwnProperty('length') || products.length == 0
            ? <div>No Products Found</div>
            : null
      }

      {

        products && products.length > 0 ?

          ecommerce == 'Amazon' ?
            products.sort((a, b) => b.price - a.price).map((prod, i) => {
              return <div className="shrink-0 w-1/4 p-3 border border-slate-100 text-center pb-4 relative" key={i}  >
                <a href={prod.url} target='_blank'>
                  <img src={prod.thumbnailImage} className='h-60 mb-2 mx-auto' />
                  <h3 className="font-semibold">{prod.title.slice(0, 60)}</h3>
                  <p>&#8377; {prod.price}</p>
                </a>
                <button className={`${selectedProductIds.includes(prod.ASIN) ? "bg-slate-500" : "bg-slate-400"} px-1 py-0.5 text-xs absolute top-3 right-3 text-white z-10`} onClick={() => selectProducts(selectedProductIds.includes(prod.ASIN) ? 'remove' : 'add', [prod.ASIN])}>{selectedProductIds.includes(prod.ASIN) ? 'Remove' : 'Select'}</button>
                <p>{prod.ASIN}</p>
              </div>
            })
            : products.map((prod, i) => {
              return <div className="shrink-0 w-1/4 p-3 border border-slate-100 text-center pb-10" key={i}  >
                <a href={prod.product_url} target='_blank'>
                  <img src={prod.image_url} className='h-60 mb-2 mx-auto' />
                  <h3 className="font-semibold">{prod.title.split('%2B').join(' ').split('%26').join('&')}</h3>
                  <p>&#8377; {prod.price}</p>
                </a>
              </div>
            })
          : null
      }
    </div>
  </div>
}

export default EcommerceProductsDisplay;
