import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios'
import moment from 'moment'
import Graph from './Graph';
import DateRangeFilter from '../../component/DateRangeFilter';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { useSelector } from 'react-redux';
import { mensTrendsCategory, femaleTrendCategory } from '../../constants';
import { TailSpin } from 'react-loader-spinner';

const TrendGraph = ({ commentsGraph = false }) => {
  const [searchParmas, setSearchParms] = useSearchParams()
  const iniFormState = {
    gender: searchParmas.get('gender') || "f",
    category: searchParmas.get('category') || "Topwear Trends",
    geography: searchParmas.get('geography') || 'all',
    indiaTier: searchParmas.get("indiaTier") || "None"
  }
  const navigate = useNavigate()
  const { userLoggedIn } = useSelector(state => state)

  const iniDateFilter = {
    from: searchParmas.get('from') || "",
    to: searchParmas.get('to') || "",
    dateType: searchParmas.get('dateType') || 'range'
  }
  const [dateFilter, setDateFilter] = useState(iniDateFilter)
  const [formdata, setFormdata] = useState(iniFormState)
  const [graphData, setGraphData] = useState([])
  const [uValArr, setUValArr] = useState([])
  const [trendData, setTrendData] = useState([])
  const [dateToShow, setDateToShow] = useState([])
  const [trendCount, setTrendCount] = useState(0)
  const [fetch, setFetch] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [nonCumulativeGraphData, setNonCumulativeGraphData] = useState([])
  const [graphType, setGraphType] = useState('cumulative')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
    else {
      if (commentsGraph)
        getCommentsGraph()
      else
        getTrendGraph()
    }
  }, [formdata, fetch]);
  const maleCategories = mensTrendsCategory.filter(cat => cat != 'All Trends')
  const femaleCategories = femaleTrendCategory.filter(cat => cat != 'All Trends')
  const trendCategory = formdata.gender === 'f' ? femaleCategories : maleCategories
  const fillForm = (e) => {
    const { name, value } = e.target
    setFormdata(prev => ({
      ...prev,
      [name]: value
    }))
    if (name == 'gender') {
      setFormdata(prev => ({
        ...prev,
        category: "Topwear Trends"
      }))
    }
  }
  const incrementDay = (date) => {
    const newDate = moment(date).add(1, 'days')
    return newDate.format('YYYY/MM/DD')
  }
  const datesToShow = (startDate, endDate) => {
    const dates = []
    const lastDate = moment(endDate, "DD MMM YYYY")
    let currentDate = moment(startDate, "DD MMM YYYY")
    while (currentDate.format("YYYY/MM/DD") <= lastDate.format('YYYY/MM/DD')) {
      dates.push(currentDate.format('DD MMM YYYY'))
      currentDate = currentDate.add(1, 'months')
    }
    if (endDate !== dates[dates.length - 1])
      dates.push(endDate)
    return dates
  }
  const getGraphData = (images) => {
    const imgs = images
    const t = []
    for (let i = 0; i < imgs.length; i++) {
      t.push(moment(imgs[i].timeStamp).format('YYYY/MM/DD'))
    }
    let i = 0
    let noOfImage = 0
    const ans = []
    let cDate = t[0]
    while (i < t.length - 1) {
      if (cDate === t[i]) {
        while (t[i] == t[i + 1] && i < t.length - 1) {
          i++;
          noOfImage++;
        }
        if (i < t.length - 1) {
          noOfImage++
          ans.push({
            date: moment(t[i], 'YYYY/MM/DD').format('DD MMM YYYY'),
            noOfImages: noOfImage
          })
          i++;
        }
      }
      else {
        ans.push({
          date: moment(cDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
          noOfImages: noOfImage
        })
      }
      cDate = incrementDay(cDate)
    }
    while (cDate < t[i]) {
      ans.push({
        date: moment(cDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
        noOfImages: noOfImage
      })
      cDate = incrementDay(cDate)
    }
    ans.push({
      date: moment(t[i], 'YYYY/MM/DD').format('DD MMM YYYY'),
      noOfImages: noOfImage + 1
    })
    return ans
  }

  function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  const downloadCsvForGraphData = async (data) => {
    const trendKeys = trendData.map(t => t.pk)
    let dateRows = []
    for (let dateData of data) {
      const st = `${dateData.date},` + trendKeys.map(k => dateData[k] || '0').join(',')
      dateRows.push(st)
    }
    const trendNames = {}
    trendData.forEach(k => { trendNames[k.pk] = k.name; })
    const fileStr = 'Date,"' + trendKeys.map(k => trendNames[k]).join('","') + '"\n' + dateRows.join('\n')
    download(`${searchParmas.get('category')}.csv`, fileStr);
  }
  const handleDownloadCsv = () => {

    const data = graphType === 'cumulative' ? graphData : nonCumulativeGraphData
    downloadCsvForGraphData(data);
  }
  const getTrendGraph = () => {
    setLoading(true)

    const category = formdata.category.replace(/&/g, "%26")
    axios.get(`/trend/graph?category=${category}&gender=${formdata.gender}&from=${dateFilter.from}&to=${dateFilter.to}&dateType=${dateFilter.dateType}&curator=${userLoggedIn.curator}&geography=${formdata.geography}&indiaTier=${formdata.indiaTier}`)
      .then(res => {
        if (res.data.length !== 0) {
          res.data.forEach(trend => {
            trend.graphData = getGraphData(trend.similarCategoryImages)
          })
          const tAns = res.data
          setTrendCount(res.data.length)
          const uValArr = tAns.map(trend => trend.pk)
          const ffAns = []
          const tAns1 = []
          for (let i = 0; i < tAns.length; i++) {
            let gData = tAns[i].graphData
            for (let j = 0; j < gData.length; j++) {
              let t = {}
              t.date = gData[j].date
              t[tAns[i].pk] = gData[j].noOfImages
              tAns1.push(t)
            }
          }
          tAns1.sort((a, b) => moment(a.date, "DD MMM YYYY").valueOf() - moment(b.date, "DD MMM YYYY").valueOf())
          let t1 = 1
          let t = tAns1[0]
          const tAns2 = []
          while (t1 < tAns1.length) {
            if (tAns1[t1].date === tAns1[t1 - 1].date) {
              t = { ...t, ...tAns1[t1] }
            }
            else {
              ffAns.push(t)
              let tDate = moment(t.date, "DD MMM YYYY").add(1, 'days').format('DD MMM YYYY')
              while (tDate !== tAns1[t1].date) {
                ffAns.push(t)
                tDate = moment(tDate, "DD MMM YYYY").add(1, 'days').format("DD MMM YYYY")
              }
              t = { ...ffAns[ffAns.length - 1], ...tAns1[t1] }
            }
            t1++;
          }
          ffAns.push(t)
          const dts = datesToShow(ffAns[0]?.date, ffAns[ffAns.length - 1]?.date)
          const nonCData = ffAns.map((row, index) => {
            if (index === 0) {
              return row
            }
            else {
              let t = {
                date: row.date
              }
              uValArr.forEach(id => {
                t[id] = ffAns[index][id] - ffAns[index - 1][id]
              })
              return t;
            }
          })
          setNonCumulativeGraphData(nonCData)
          setTrendData(res.data)
          setDateToShow(dts)
          setUValArr(uValArr)
          setGraphData(ffAns)
        }
        setSearchParms({ ...formdata, ...dateFilter, graphType: graphType })
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
  }
  const handleGraphType = (type) => {
    setGraphType(type)
    setSearchParms({ ...formdata, ...dateFilter, graphType: type })
  }

  const dateString = (dateObj) => {
    const strings = dateObj.toDateString().split(' ');
    return `${strings[2]} ${strings[1]} ${strings[3]}`
  }

  const getCommentsGraph = () => {
    const category = formdata.category.replace(/&/g, "%26")
    setLoading(true)
    axios.get(`/comments-api/graph?category=${category}&gender=${formdata.gender}&from=${dateFilter.from}&to=${dateFilter.to}&dateType=${dateFilter.dateType}&curator=${userLoggedIn.curator}&geography=${formdata.geography}&indiaTier=${formdata.indiaTier}`)
      .then(res => {
        if (res.data.length !== 0) {

          setTrendData(res.data)
          setTrendCount(res.data.filter(trend => trend.hasOwnProperty('weightage') && trend.weightage > 0).length)
          const response = res.data
          const uValArr = response.map(trend => trend.pk)
          setUValArr(uValArr)
          const datesComments = {}
          for (let trend of res.data) {
            for (let comment of trend.comments) {
              const dayString = dateString(new Date(comment.timeStamp))
              if (!datesComments.hasOwnProperty(dayString)) {
                datesComments[dayString] = {
                  date: dayString,
                  timeStamp: new Date(comment.timeStamp)
                }
              }
              if (!datesComments[dayString].hasOwnProperty(trend.pk)) {
                datesComments[dayString][trend.pk] = 0
              }
              datesComments[dayString][trend.pk] += comment.weightage
            }
          }
          const datesArray = Object.keys(datesComments).map(d => {
            return datesComments[d]
          }).sort((a, b) => a.timeStamp - b.timeStamp)

          const CumulativeGraphData = []
          const NonCumulativeGraphData = []
          const startDate = datesArray[0] ? datesArray[0].date : new Date("2023-03-01")
          const endDate = datesArray[datesArray.length - 1] ? datesArray[datesArray.length - 1].date : new Date()
          const dts = datesToShow(startDate, endDate)
          setDateToShow(dts)
          for (let [i, datesData] of datesArray.entries()) {
            const cumulativeData = {
              date: datesData.date
            }
            const nonCumulativeData = {
              date: datesData.date
            }

            if (i > 0) {
              for (let id of Object.keys(CumulativeGraphData[i - 1])) {
                if (id == 'date' || id == 'timeStamp')
                  continue;
                cumulativeData[id] = CumulativeGraphData[i - 1][id]
                nonCumulativeData[id] = 0
              }
            }
            for (let id of Object.keys(datesData)) {
              if (id == 'date')
                continue;
              cumulativeData[id] = (cumulativeData[id] || 0) + datesData[id]
              nonCumulativeData[id] = (nonCumulativeData[id] || 0) + datesData[id]
            }

            CumulativeGraphData.push(cumulativeData)
            NonCumulativeGraphData.push(nonCumulativeData)
          }

          setNonCumulativeGraphData(NonCumulativeGraphData)
          setGraphData(CumulativeGraphData)
        }
        setSearchParms({ ...formdata, ...dateFilter, graphType: graphType })
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
  }
  return (
    <div>
      <div className='mx-2 flex items-center sticky top-10 mt-2 bg-white max-[350px]:mx-0 justify-between max-[751px]:items-start z-10'>
        <div className='flex items-center max-[760px]:hidden '>
          <select className='p-2 rounded-md outline-none w-24 text-center' value={formdata.gender} onChange={fillForm} name="gender" >
            <option value="" hidden>--select Gender--</option>
            <option value="m">Male</option>
            <option value="f">Female</option>
          </select>
          <select className='mx-2 p-2 rounded-md outline-none text-center' value={formdata.category} onChange={fillForm} name="category">
            <option value="" hidden>--Product Categoty--</option>
            {
              trendCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
            }
          </select>
          <select className='mr-1 p-2 rounded-md outline-none text-center mt-1' value={formdata.geography} onChange={fillForm} name="geography">
            <option value="all">Geography</option>
            <option value="western">Trend rows with Indians</option>
            <option value="asian">Trend rows with Asians</option>
            <option value="indian">Trend rows with Westerners</option>
            <option value="indian-only">Trend images with Indians</option>
            <option value="asian-only">Trend images with Asians</option>
            <option value="western-only">Trend images with Westerners</option>
          </select>
          <select className='mr-1 p-2 rounded-md outline-none text-center mt-1' value={formdata.indiaTier} onChange={fillForm} name="indiaTier">
            <option value="None">Select India Tier</option>
            <option value="All India">All India</option>
            <option value="India tier 1">India tier 1</option>
            <option value="India tier 2">India tier 2</option>
          </select>
          <DateRangeFilter setFetch={setFetch} dateFilter={dateFilter} setDateFilter={setDateFilter} />
          <div className='flex items-center'>
            <input className='ml-2 max-[350px]:ml-0' type="radio" name="graphType" id="cumulative" checked={graphType === 'cumulative'} onChange={() => handleGraphType('cumulative')} />
            <label htmlFor="cumulative">Cumulative</label>
            <input className='ml-2 max-[350px]:ml-0' type="radio" name="graphType" id="non-cumulative" checked={graphType === 'non-cumulative'} onChange={() => handleGraphType('non-cumulative')} />
            <label htmlFor="non-cumulative">Non-cumulative</label>
          </div>
          {
            userLoggedIn && userLoggedIn.curator &&
            <button className='ml-2 primary-btn' onClick={handleDownloadCsv}>Download Csv</button>
          }
        </div>
        <div className='min-[761px]:hidden'>
          <div className='flex items-center' onClick={() => setShowFilter(!showFilter)}>Filters{showFilter ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}</div>
          {
            showFilter && <div className='flex items-start flex-wrap absolute bg-white'>
              <select className='p-2 mr-2 my-1 rounded-md outline-none w-24 text-center' value={formdata.gender} onChange={fillForm} name="gender" >
                <option value="" hidden>--select Gender--</option>
                <option value="m">Male</option>
                <option value="f">Female</option>
              </select>
              <select className='p-2 my-1 max-[350px]:w-48 rounded-md outline-none text-center' value={formdata.category} onChange={fillForm} name="category">
                <option value="" hidden>--Product Categoty--</option>
                {
                  trendCategory.map((category, index) => <option key={index} value={category}>{category}</option>)
                }
              </select>
              <DateRangeFilter setFetch={setFetch} dateFilter={dateFilter} setDateFilter={setDateFilter} />
              <div className='flex ml-2 max-[350px]:flex-col max-[350px]:item-start'>
                <div className='mr-2'>
                  <input className='' type="radio" name="graphType" id="cumulative" checked={graphType === 'cumulative'} onChange={() => handleGraphType('cumulative')} />
                  <label htmlFor="cumulative">Cumulative</label>
                </div>
                <div>
                  <input className='' type="radio" name="graphType" id="non-cumulative" checked={graphType === 'non-cumulative'} onChange={() => handleGraphType('non-cumulative')} />
                  <label htmlFor="non-cumulative">Non-cumulative</label>
                </div>
              </div>
            </div>
          }
        </div>
        <div className='mr-2'>
          {trendCount} Trends
        </div>
      </div>
      <div className=' mt- z-0'>
        {trendCount != 0 && !loading && <Graph fetch={fetch} setFetch={setFetch} graphData={graphType === 'cumulative' ? graphData : nonCumulativeGraphData} dateToShow={dateToShow} trendData={trendData} uVal={uValArr} />}
        {trendCount == 0 && !loading && <div className='relative w-full h-[60vh] '>
          <p className='absolute top-[50%] left-1/2 translate-x-[-50%] translate-y-[-50%] text-lg font-semibold'>No trends found</p>
        </div>}
        {loading &&
          <div className='relative w-full h-[90vh]'> <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-20 h-20 '><TailSpin color='black' /></div></div>
        }
      </div>
    </div>
  )
}

export default TrendGraph
