import React, { Suspense, useEffect, useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import TrendProduct from './TrendProduct'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';



const Trend = ({ trend, setFetch, fetch, socialTrendNames}) => {
	const { userLoggedIn } = useSelector(state => state)
	const [textvalue, setTextValue] = useState('')
	const changeName = (e)=>{
		const pk = e.target.value;
		axios.put(`/retailer-api/rename-trend-id/${trend._id}/${pk}`).then(() => {
			setFetch(!fetch)
		})
	}

	const changeNameFromtext  = (e)=>{
		axios.put(`/retailer-api/rename/${trend._id}/${textvalue}`).then(() => {
			setFetch(!fetch)
		})
	}


	return (
		<div className=' my-10 border-b-2 border-slate-100 pb-6'>
			<div className='flex  items-center my-5'>
					<p className='mx-2'>Category: <span className='font-bold'>{trend.searchedCategory}</span></p>
					<p className='mx-5 ml-8'>Avarage Category Rank: <span className='font-bold'>{trend.avarageRank.toFixed(2)}</span></p>
					{/* <p className='mx-5 m'>Count : <span className='font-bold'>{trend.countOfProducts}</span></p> */}

					{trend.name && <p>Name : <span className='font-bold'>{trend.name}</span></p>}

                              { 
					userLoggedIn.curator && 
						<>
							<select   className='mr-1 p-2 w-52 rounded-md outline-none text-center mt-1'  onChange={changeName}>
								<option value="" selected>--Select name--</option>
								{socialTrendNames.map( socialTrend => <option value={socialTrend.pk} selected={trend.name == socialTrend.name}>{socialTrend.name}</option>)}
								<option value="delete-associated-trend">Delete current associated social trend</option>
							</select>

							<div className='flex flex-row'>
								<input className='bg-slate-50 px-3 py-2 rounded m-2 outline-none' type='text' placeholder='Enter trend name' value={textvalue} onChange={(e)=> setTextValue(e.target.value) }/>
								<button  className='bg-slate-400 text-white px-6 rounded' onClick={changeNameFromtext}>Update</button>
							</div>
						</>
					}
					
				
			</div>
			<div className='flex overflow-auto flex-shrink-0'>
			{
				trend.similarCategoryProducts.map((product, index) => <TrendProduct key={index} product={product}/>)
			}
			</div>
		</div>
	)
}

export default Trend