

const DisplayProducts = ({ products }) => {


  return <div className="m-2 flex flex-row flex-wrap w-[90%]">
    {products.map((product) => {
      return <a href={product.product_url} target='_blank' className="my-1 hover:shadow w-1/4 p-2 shrink-0 ">
        <div className="justify-center w-4/5 h-60 ">
          <div></div>
          <img src={product.image_url} alt={product.title} className='h-full' />
        </div>
        <h4>Rank - {product.rank}</h4>
        <h4>{product.title}</h4>
        <h4>{product.retailer}</h4>
        <h4>{product.price}&#8377;</h4>
      </a>
    })}
  </div>
}

export default DisplayProducts;
