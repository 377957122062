import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import DisplayProducts from "./displayProducts"



const ProductsPopup = ({ tagsData, category, trendId, tier = 'All', geography = 'all', type = 'All' }) => {
  const [products, setProducts] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const getProducts = () => {
    setLoading(true)
    setProducts([])
    axios.get(`image-analysis-api/images?gender=${searchParams.get('gender')}&tag=${tagsData.tagName}&value=${tagsData.tagValue}&category=${category}&tier=${tier}&geography=${geography}&type=${type}&page=${page}${trendId ? `&trendId=${trendId}` : ''}`)
      .then(response => {
        setProducts(response.data.products)
        setTotal(response.data.total)

      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }


  useEffect(getProducts, [tagsData, category, page])

  return <div className="w-[70vw] h-[70vh] ">
    <div className="flex flex-col ">
      <div className="bg-white sticky top-0 pb-2" >
        <h2 className="text-center text-2xl font-semibold m-2 ">Images for {tagsData.tagValue} of {tagsData.tagName}</h2>
        <div className="w-full float-left">
          <div className="float-right w-68">
            <button disabled={page == 1} onClick={() => { setPage(p => p - 1) }} className='primary-btn mx-2'>Prev</button>
            {page}/{(!total || isNaN(total)) ? 1 : Math.ceil(total / 50)}
            <button disabled={!total || page == Math.ceil(total / 50)} onClick={() => { setPage(p => p + 1) }} className='primary-btn mx-2'>Next</button>
          </div>
        </div>
      </div>
      <div>
        <DisplayProducts products={products} />

      </div>
    </div>

  </div>
}

export default ProductsPopup
