
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner';

const TopCommentsPosts = ({ images, category }) => {
  const [commentsList, setCommentsList] = useState([])

  const [responseReceived, setResponseReceived] = useState(false);
  const [posts, setPosts] = useState([])
  const getComments = async () => {
    if (!images)
      return

    const mediaIds = images.map(img => img.mediaId)
    const response = await axios.post('/comments-api/images', {
      mediaIds,
      category
    })
    setResponseReceived(true);
    const data = response.data.filter(post => post.comments && post.comments.length > 0)
    data.forEach(post => {
      post.image = images.filter(img => img.postCode == post.postCode)[0]
    })
    setPosts(data);
  }

  useEffect(() => {
    getComments();
  }, [images])

  useEffect(() => {
    let commentsArray = []
    for (let post of posts) {
      commentsArray = commentsArray.concat(...post.comments)
    }
    commentsArray = commentsArray.sort((b, a) => {
      return a.genericWeightage + (a.categoryWeightages && a.categoryWeightages.hasOwnProperty(category) ? a.categoryWeightages[category] : 0) - (b.genericWeightage + (b.categoryWeightages && b.categoryWeightages.hasOwnProperty(category) ? b.categoryWeightages[category] : 0))
    })
    commentsArray.forEach(comment => {
      comment.weightage = comment.genericWeightage + (comment.categoryWeightages && comment.categoryWeightages.hasOwnProperty(category) ? comment.categoryWeightages[category] : 0)
    })
    setCommentsList(commentsArray)
  }, [posts])



  return (
    <div className='self center m-0   px-5 overflow-auto border-l mt-4 h-max-[90vh] max-h-[100%]'>
      {
        <>
          {commentsList.map((comment, i) => {
            return <div className='mb-4 px-2 border-b' key={i}>
              <p className='font-bold'>{comment.commentOwnerUsername}<span className='float-right'>+{comment.weightage}</span></p>
              <p className='pl-4'>{comment.commentText}</p>
            </div>
          })}
        </>
      }
      {
        responseReceived ? null : <div className=' relative w-full h-1/2'>
          <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ' ><TailSpin color='black' /></div>
        </div>
      }
    </div>
  )
}

export default TopCommentsPosts
