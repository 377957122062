import { allBrands } from './constants'
import { FaCheckSquare } from 'react-icons/fa'
import axios from 'axios'
import { useEffect, useState } from 'react'

const Brandspopup = ({ brands, setBrands, category, gender, rank, tag, month }) => {

  const [brandCounts, setBrandCounts] = useState({})

  const getBrandCounts = () => {
    axios.get(`/retailer-api/designer-feature-brand-count?gender=${gender}&category=${category?.split('&').join('%26')}${tag ? `&tagName=${tag}` : ''}&rank=${rank}&month=${month}`).then(response => {
      setBrandCounts(response.data)
    }).catch(e => {
      console.log(e)
    })

  }

  useEffect(getBrandCounts, [category, gender, rank, tag, month]);

  const flipBrand = (brand) => {
    setBrands(prevBrands => {
      if (prevBrands.includes(brand))
        return prevBrands.filter(b => b != brand)
      return [...prevBrands, brand]
    })
  }
  return <div className="relative inline-block group z-20">
    <div className="h-full bg-slate-100 p-1 px-2 rounded-lg border-b">
      Select Brands({brands.length || 'All'})
    </div>
    <div className="absolute invisible opacity-0 group-hover:visible group-hover:opacity-100 h-[50rem] w-[90rem] max-w-[70vw] max-h-[70vh] top-full transition-opacity duration-500 bg-white  border-2 ">
      <div className='w-full px-4 py-2 border-b-2 border-b-slate-200'>
        <button className='px-4 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600' onClick={() => setBrands([])}>Clear All</button>
      </div>
      <div className="flex flex-col overflow-x-auto flex-wrap overflow-y-hidden h-[90%]">
        {

          allBrands.filter(b => brandCounts[b] && brandCounts[b] > 0).map(brand => <div className="w-60 p-1 mx-1 flex-shrink-0 flex flex-row">
            <div className={`h-4 w-4 rounded mr-2 ${brands && brands.includes(brand) ? '' : 'border bg-white'}`} onClick={() => flipBrand(brand)}>
              {brands && brands.includes(brand) && <FaCheckSquare color='#005eff' />}
            </div>
            <div>{brand}({brandCounts[brand] || 0})</div>
          </div>)
        }
      </div>
    </div>
  </div>
}

export default Brandspopup;
