import axios from "axios";
import { useEffect, useState } from "react";
import { FaImage, FaImages } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import TrendImage from "./TrendImage";
import { SentenceCase } from "../../utility/helper_functions";
import SaveButton from "../../component/saveTrend";
import Popup from "../../component/Popup";
import DesignTrends from "../trendDisplayPage/DesignTrends";
import TopCommentsPosts from "../../component/TopCommentsList";
import SimilarProductsTrend from "../trendDisplayPage/SimilarProductsTrend";

import AmazonProductsDisplay from '../../component/AmazonProductsDisplay'
import CurateSimilarProducts from '../../component/CurateSimilarProducts'
import ProductIds from "../../component/ProductIds";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";
import Graph from "../../component/Graph";
import TrendTrack from "../../component/TrendTrack";
import { FemaleTrendToStlCategory, MaleTrendToStlCategory } from '../../constants'
const Trend = () => {

  const [fetch, setFetch] = useState(true)
  const [trendData, setTrendData] = useState(null);
  const trendId = useParams().trendId
  const [showDesignTrends, setShowDesignTrends] = useState(false)
  const [showPairItWith, setShowPairItWith] = useState(false)
  const [showPriceRange, setShowPriceRange] = useState(false)
  const [showInsertProductIds, setshowInsertProductIds] = useState(false)
  const [showAmazonProductsPopup, setShowAmazonProductsPopup] = useState(false)
  const [showCurateSimilarProductsPopup, setShowCurateProductsPopup] = useState(false)
  const [productsloading, setProductsloading] = useState(false)
  const { userLoggedIn } = useSelector(state => state)
  const [ecommerce, setEcommerce] = useState(null);
  const [showTrackData, setShowTrackData] = useState(false)
  const [subSetSimilarCategoryImages, setSubSetSimilarCategoryImages] = useState([])


  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
  }, []);

  const downloadTrendProductsData = async (pk, selected = false) => {
    const products = await axios.get(`/products/amazon-saved-products/${pk}`)
    let filteredProducts = products.data
    if (selected) {
      const response = await axios.post('/products/get-saved-products', { trendId: pk })
      filteredProducts = filteredProducts.filter((prod) => {
        return response.data.productIds.includes(prod.ASIN);

      })
    }
    let csvString = 'Product Id,Product title,Product URL,Product Image URL,Brand,Price\n'
    for (let prod of filteredProducts) {
      csvString = csvString + `"${prod.ASIN}","${prod.title}","${prod.url}","${prod.thumbnailImage}","${prod.brand}","${prod.price}"\n`
    }
    const link = document.createElement("a");
    const file = new Blob([csvString], { type: 'text/csv;encoding:utf-8' });
    link.href = URL.createObjectURL(file);
    link.download = `products_${trendData.id}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  useEffect(() => {
    if (!showAmazonProductsPopup) {
      setEcommerce(null);
    }
  }, [showAmazonProductsPopup])



  const fetchTrendData = () => {
    if (!fetch) {
      return;
    }
    axios.get(`/trend-api/${trendId}`).then(response => {
      response.data.images = response.data.similarCategoryImages
      response.data.id = response.data.pk
      setTrendData(response.data);
      setSubSetSimilarCategoryImages(response.data.images ? response.data.images.slice(0, 200) : [])
    }).finally(() => {
      setFetch(false)
    })
  }

  const handleProductsPopup = (e) => {
    const ecommerce = e.target.name;
    setEcommerce(ecommerce)
    setShowAmazonProductsPopup(true)
  }

  const updateProducts = (days) => {
    setProductsloading(true)
    axios.get(`/curator-api/update-old-similar-products/${days}/${trendData.pk}`)
      .then(() => {
        Swal.fire('Products updated', 'Please refresh page', 'success');
      }).catch(e => {

      }).finally(() => {

        setProductsloading(false)
      })

  }

  const updateTagsRetailerData = (e) => {
    const retailerCategory = e.target.value
    axios.post(`/trend-api/update-associated-retailer-data/${trendId}`, { retailerCategory }).then(() => {
      setFetch(true)
    })
  }

  useEffect(() => {
    fetchTrendData();
  }, [fetch])


  return <div className="w-[93%] flex pt-8 mx-0 ">
    {
      productsloading && <div className=" fixed w-[100vw] h-[110vh] -top-[10vh] bg-slate-400/50 z-20">
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <ThreeCircles height={'100'} width={'100'} color='#333' />
        </div>
      </div>
    }
    <div className="w-1/5 p-2 fon shrink-t-bold shadow-inner shadow-slate-300 shadow-bg shrink-0">
      <p>Trend Name : <span className="font-bold">{SentenceCase(trendData?.name)}</span></p>
      <p>Category : {trendData?.searchedCategory}</p>
      <p className="flex"><span className="font-light mr-2">{trendData?.similarCategoryImages.length}</span> <FaImages size={24} /></p>
      <div className="">

        <div>
          <h3 className="font-bold text-center my-3">Shop by Trends</h3>
          <div className="w-full flex flex-row flex-wrap">
            <button className={`w-[45%] m-1 shrink-0 h-9 px-3 py-1 rounded-xl text-white flex items-center border border-slate-200`} name='Amazon' onClick={handleProductsPopup}  ><img name='Amazon' className="max-h-full max-w-full" src="/amazon-in-logo.png" /></button>
            <button className={`w-[45%] m-1  shrink-0 h-9 px-3 py-1 rounded-xl text-white flex items-center border border-slate-200`} name='Myntra' onClick={handleProductsPopup}  ><img name='Myntra' className="max-h-full max-w-full" src="/myntra-logo.png" /></button>
            <button className={`w-[45%] m-1 shrink-0 h-9 px-3 py-1 rounded-xl text-white flex items-center border border-slate-200`} name='Ajio' onClick={handleProductsPopup}  ><img name='Ajio' className="max-h-full max-w-full" src="/ajio-logo.png" /></button>
            <button className={`w-[45%] m-1 shrink-0 h-9 px-3 py-1 rounded-xl text-white flex items-center border border-slate-200`} name='Nykaa' onClick={handleProductsPopup}  ><img name='Nykaa' className="max-h-full max-w-full" src="/nykaa-logo.png" /></button>
          </div>
          <br />
        </div>
        <br />
        <button className="primary-btn-padding font-bold" onClick={() => setShowDesignTrends(true)}>🏆WHICH {trendData?.name.toUpperCase()} VARIANTS WILL SELL MORE?</button>
        <button onClick={() => { setShowTrackData(true) }} className="primary-btn-padding" >Trend Lifecycle</button>
        {false &&
          <div className="w-full h-[35vh] p-2">
            <Graph pk={trendData?.pk} />
          </div>

        }
        <button className="primary-btn-padding" onClick={() => setShowPairItWith(true)}>Pair-it-with Beta</button>
        <br />

        <SaveButton trendPk={trendData?.pk} />
        <button className="primary-btn-padding" onClick={e => { downloadTrendProductsData(trendData.id) }} >Download Amazon Products </button>

        {userLoggedIn.curator && <button className='mx-1 bg-blue-500 px-3 py-1 rounded-xl text-white flex items-center' onClick={() => setshowInsertProductIds(true)}>Add Products</button>}
        {userLoggedIn.curator &&
          <>
            <select className='w-full px-3 py-2 rounded-lg my-1' value={trendData?.retailerTagsData?.retailerCategory || 'select'} onChange={updateTagsRetailerData}>
              <option value='select' disabled={true}>--Select retailer Category--</option>
              {trendData && (trendData?.gender == 'M' ? MaleTrendToStlCategory : FemaleTrendToStlCategory).map(cat => <option value={cat}>{cat}</option>)}
            </select>
            <button className='primary-btn-padding' onClick={() => setShowCurateProductsPopup(true)}>Curate similar Products</button>
            <button className="primary-btn-padding" onClick={() => updateProducts('7-days')}>Update data 7 days old <strong>({trendData?.days7}/{trendData?.similarCategoryImages.length})</strong></button>
            <button className="primary-btn-padding" onClick={() => updateProducts('14-days')}>Update data 14 days old <strong>({trendData?.days14}/{trendData?.similarCategoryImages.length})</strong></button>
            <button className="primary-btn-padding" onClick={() => updateProducts('30-days')}>Update data 30 days old <strong>({trendData?.days30}/{trendData?.similarCategoryImages.length})</strong></button>

          </>
        }
        {
          //<button className="primary-btn-padding">Save</button>
        }

        {
          //<button>Add Products</button>
        }
      </div>
    </div>
    <div className="px-[1%] w-3/5 max-h-[90vh] shrink-0 overflow-y-scroll overflow-x-hidden">
      <div className=" w-[100%] p-0 wrap-auto flex flex-row flex-wrap overflow-y-scroll">
        {
          subSetSimilarCategoryImages && subSetSimilarCategoryImages?.map(image => <div className="mb-3 mx-0 shadow-slate-300 w-1/3 p-2 shrink-0" key={image.mediaId}><TrendImage image={image} /></div>)
        }


      </div>

      {
        trendData && trendData.similarCategoryImages.length > subSetSimilarCategoryImages.length && <div className="flex justify-center mb-4 py-2">
          <button className="bg-blue-600 hover:bg-blue-700 active:bg-blue-800 text-white font-semibold px-4 py-1 rounded" onClick={() => {
            setSubSetSimilarCategoryImages(prev => {
              return trendData.similarCategoryImages.slice(0, prev.length + 200);
            })
          }}>Load More</button>
        </div>
      }
    </div>
    <div className=" w-3/5 px-2 min-h-[80%] max-h-[90vh] wrap-auto ">
      <h2 className="text-center font-bold ">Top Comments</h2>
      <TopCommentsPosts category={trendData?.searchedCategory} images={trendData?.images} />
    </div>

    <Popup isPopUp={showDesignTrends} setIsPopUp={setShowDesignTrends} popupfull={true}  >
      <DesignTrends trend={trendData} gender={trendData?.gender} category={trendData?.searchedCategory} />
    </Popup>

    <Popup isPopUp={showPairItWith} setIsPopUp={setShowPairItWith}>
      <SimilarProductsTrend trend={trendData} forPairItWith={true} />
    </Popup>
    <Popup isPopUp={showPriceRange} setIsPopUp={setShowPriceRange}>
      <SimilarProductsTrend trend={trendData} forPairItWith={false} />
    </Popup>
    <Popup isPopUp={showInsertProductIds} setIsPopUp={setshowInsertProductIds}>
      <ProductIds trend={trendData} />
    </Popup>
    <Popup isPopUp={showAmazonProductsPopup && ecommerce != null} setIsPopUp={setShowAmazonProductsPopup} >
      <AmazonProductsDisplay pk={trendData?.id} downloadTrendProductsData={downloadTrendProductsData} ecommerce={ecommerce} category={trendData?.searchedCategory} />
    </Popup>
    <Popup isPopUp={showCurateSimilarProductsPopup} setIsPopUp={setShowCurateProductsPopup} popupfull={true}  >
      <CurateSimilarProducts pk={trendData?.id} category={trendData?.searchedCategory} />
    </Popup>
    <Popup isPopUp={showTrackData} setIsPopUp={setShowTrackData}   >
      <TrendTrack trend={trendData} gender={trendData?.gender} category={trendData?.searchedCategory} />
    </Popup>
  </div>

}

export default Trend;
