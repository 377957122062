import Axios from 'axios'


export const getSimilarImages = async (data) => {
  let res;
  if (!data.photoUrl || data.photoUrl.length == 0) {
    res = await Axios.post("/image", data, { headers: { 'content-type': 'multipart/form-data' } });
  } else {
    res = await Axios.post("/image-url", data);
  }
  return res.data
}


export const getResultFromSearchHash = async (searchHash) => {
  const res = await Axios.get(`/search/${searchHash}`);
  return res.data
}

export const login = async (passwordKey) => {
  const res = await Axios.post(`/login`, { passwordKey });
  return res.data
}

export default {
  getSimilarImages,
  getResultFromSearchHash,
  login
}
