import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Popup from "../../../component/Popup";

const AdminPasscodes = ({ }) => {

  const [users, setUsers] = useState([]);
  const [newUserData, setNewUserData] = useState(null);
  const [updateUserData, setUpdateUserData] = useState(null)
  const userLoggedIn = useSelector(state => state.userLoggedIn)
  const [toDeleteUser, setToDeleteUser] = useState(null)
  const getUsers = async () => {
    axios.get('/api/admin/users').then(resp => {
      setUsers(resp.data)
    })
  }


  const PostUser = async () => {
    axios.post('/api/admin/user', { newUserData }).then(resp => {
      getUsers()
      Swal.fire({ title: "Action Complete", text: "New User Added", timer: 1000 })
      setNewUserData(null)
    })
  }


  const DeleteUser = async () => {
    axios.delete(`/api/admin/user?id=${toDeleteUser._id}`).then(resp => {
      getUsers()
      Swal.fire({ title: "Action Complete", text: "User Deleted", timer: 1000 })
      setToDeleteUser(null)
    })
  }


  const UpdateUsers = async () => {
    axios.patch(`/api/admin/user?id=${updateUserData._id}`, updateUserData).then(resp => {
      getUsers()
      Swal.fire({ title: "Action Complete", text: "User Data Updated", timer: 1000 })
      setUpdateUserData(null)
    })
  }
  useEffect(() => {

    if (!userLoggedIn.admin) {
      window.location.href = '/'
    } else {
      getUsers()
    }
  }, [])


  return <div>
    <h3 className="text-center text-2xl font-semibold my-10">Passcodes</h3>
    <div className="px-10 my-6">
      <div className="flex justify-end"><button className="p-3 bg-slate-500 active:bg-slate-600 hover:bg-slate-800 text-white rounded-lg m-3" onClick={() => setNewUserData({})}>New User</button></div>
      <p> {users.length} users. </p>
      <table className="table table-auto w-full">
        <tr className="table-row">
          <th className="border-2 p-2">Secret Key</th>
          <th className="border-2 p-2">Email</th>
          <th className="border-2 p-2">Password</th>
          <th className="border-2 p-2">Company</th>
          <th className="border-2 p-2">Update Data</th>
        </tr>

        {
          users.map(user => <tr className="table-row">
            <td className="border-2 p-2">{user.secretKey}</td>
            <td className="border-2 p-2">{user.email}</td>
            <td className="border-2 p-2">{user.password}</td>
            <td className="border-2 p-2">{user.company}</td>
            <td className="border-2 p-2">
              <button className="px-2 rounded bg-slate-600 active:bg-slate-800 text-white hover:bg-slate-700" onClick={() => setUpdateUserData(user)}>Update Data</button>
              <button className="px-2 rounded bg-red-600 active:bg-red-800 text-white hover:bg-red-700 mx-2" onClick={() => setToDeleteUser(user)}>Delete User</button>
            </td>
          </tr>)
        }
      </table>
    </div>
    <Popup isPopUp={toDeleteUser} setIsPopUp={() => setToDeleteUser(null)}>
      <div className="w-80 py-20 flex flex-col pl-6">
        <h2 className="text-center font-semibold text-2xl">Delete User </h2>
        {toDeleteUser &&
          <div className="my-8">
            <div>Secretkey : {toDeleteUser.secretKey}</div>
            <div>Email: {toDeleteUser.email}</div>
            <div>Company: {toDeleteUser.company}</div>
          </div>
        }
        <div className="flex ">
          <button className="bg-red-500 hover:bg-red-600 active:bg-red-900 text-white rounded p-2  w-1/2 mx-2" onClick={() => DeleteUser()}>Delete</button>
          <button className="bg-slate-500 hover:bg-slate-600 active:bg-slate-900 text-white rounded p-2 w-1/2 mx-2 " onClick={() => setToDeleteUser(null)}>Cancel</button>
        </div>

      </div>
    </Popup>


    <Popup isPopUp={newUserData != null} setIsPopUp={() => setNewUserData(null)}>
      <div className="w-[500px] py-20 flex flex-col pl-6">
        <h2 className="text-center font-semibold text-2xl">Insert New User </h2>
        {
          newUserData &&
          <div className="my-8">
            <div className="">Secretkey :              <input value={newUserData.secretKey} onChange={e => setNewUserData(prev => ({ ...prev, secretKey: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Email:
              <input value={newUserData.email} onChange={e => setNewUserData(prev => ({ ...prev, email: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Company:
              <input value={newUserData.company} onChange={e => setNewUserData(prev => ({ ...prev, company: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Password:
              <input value={newUserData.password} onChange={e => setNewUserData(prev => ({ ...prev, password: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
          </div>
        }
        <div className="flex ">
          <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-900 text-white rounded p-2  w-1/2 mx-2" onClick={() => PostUser()}>Create User</button>
          <button className="bg-slate-500 hover:bg-slate-600 active:bg-slate-900 text-white rounded p-2 w-1/2 mx-2 " onClick={() => setNewUserData(null)}>Cancel</button>
        </div>

      </div>
    </Popup>


    <Popup isPopUp={updateUserData != null} setIsPopUp={() => setUpdateUserData(null)}>
      <div className="w-[500px] py-20 flex flex-col pl-6">
        <h2 className="text-center font-semibold text-2xl">Update User Data</h2>
        {
          updateUserData &&
          <div className="my-8">
            <div className="">Secretkey :              <input value={updateUserData.secretKey} onChange={e => setUpdateUserData(prev => ({ ...prev, secretKey: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Email:
              <input value={updateUserData.email} onChange={e => setUpdateUserData(prev => ({ ...prev, email: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Company:
              <input value={updateUserData.company} onChange={e => setUpdateUserData(prev => ({ ...prev, company: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
            <div>Password:
              <input value={updateUserData.password} onChange={e => setUpdateUserData(prev => ({ ...prev, password: e.target.value }))} className="border-b border-b-slate-600 outline-none px-2 font-semibold" />
            </div>
          </div>
        }
        <div className="flex ">
          <button className="bg-blue-500 hover:bg-blue-600 active:bg-blue-900 text-white rounded p-2  w-1/2 mx-2" onClick={() => UpdateUsers()}>Update User</button>
          <button className="bg-slate-500 hover:bg-slate-600 active:bg-slate-900 text-white rounded p-2 w-1/2 mx-2 " onClick={() => setUpdateUserData(null)}>Cancel</button>
        </div>

      </div>
    </Popup>

  </div>
}

export default AdminPasscodes;
