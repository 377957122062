import { useParams } from "react-router-dom"
import TagsUpdatePopup from "../rawImage/UpdateTagsPopup";



const Trend = () => {

  const mediaId = useParams().mediaId

  return <TagsUpdatePopup mediaId={mediaId} isPage={true} />

}

export default Trend;
