import axios from "axios"
import { useEffect, useState } from "react"
import DisplayProducts from "./displayProducts"



const ProductsPopup = ({ tagsData, category, ecommerce, rank, trend, children }) => {
  const [products, setProducts] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1)
  const getProducts = () => {
    setLoading(true)
    setProducts([])
    let queryString = `${tagsData ? `tag=${tagsData.tagName}&value=${tagsData.tagValue}&` : ''}category=${category}&rank=${rank}&ecommerce=${ecommerce}&page=${page}${trend ? `&trendid=${trend._id}` : ''}`
    axios.get(`/retailer-analysis-api/products?${queryString}`
    )
      .then(response => {
        setProducts(response.data.products)
        setTotal(response.data.total)
      }).catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }


  useEffect(getProducts, [tagsData, category, ecommerce, rank, page])

  return <div className="w-[70vw] h-[70vh] overflow-x-hidden ">
    <div className="flex flex-col">
      <h2 className="text-center text-2xl m-2">Products of <span className="font-semibold ">{tagsData ? tagsData.tagValue : ''}</span> {tagsData ? tagsData.tagName : ''} {trend ? ` of ${trend.name}` : ''}</h2>
      <div className="w-[90%] float-left">
        <div className="float-right w-68">
          <button disabled={page === 1} onClick={() => { setPage(p => p - 1) }} className='primary-btn mx-2'>Prev</button>
          {page}/{(!total || isNaN(total)) ? 1 : Math.ceil(total / 50)}
          <button disabled={!total || page === Math.ceil(total / 50)} onClick={() => { setPage(p => p + 1) }} className='primary-btn mx-2'>Next</button>
        </div>
      </div>
      {children}
      <div className="pb-8">
        <DisplayProducts products={products} />

      </div>
    </div>

  </div>
}

export default ProductsPopup
